import React, { useState } from 'react';
import axios from 'axios';

function AddressForm() {
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [fullAddress, setFullAddress] = useState('');

  const handleSearch = async () => {
    // Perform reverse geocoding
    const coordinates = await reverseGeocode(city, pincode);
    console.log(coordinates);
    // If coordinates are available, fetch full address
    if (coordinates) {
      const address = await fetchFullAddress(coordinates);
      setFullAddress(address);
    } else {
      setFullAddress('Address not found');
    }
  };

  const reverseGeocode = async (city, pincode) => {
    try {
      // Perform reverse geocoding using a free service
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${city},${pincode}&format=json&addressdetails=1&limit=1`);
      if (response.data.length > 0) {
        return { lat: response.data[0].lat, lon: response.data[0].lon };
      }
    } catch (error) {
      console.error('Error performing reverse geocoding:', error);
    }
    return null;
  };

  const fetchFullAddress = async (coordinates) => {
    try {
      // Fetch full address using coordinates
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${coordinates.lat}&lon=${coordinates.lon}&format=json&addressdetails=1`);
      return response.data.display_name;
    } catch (error) {
      console.error('Error fetching full address:', error);
    }
    return 'Address not found';
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <input
        type="text"
        placeholder="Enter pincode"
        value={pincode}
        onChange={(e) => setPincode(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>
      <div>Full Address: {fullAddress}</div>
    </div>
  );
}

export default AddressForm;
