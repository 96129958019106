import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditMyProfile = (props) => {
    const [Userdata, setUserdata] = useState('');
    const [Firstname, setFirstname] = useState('');
    const [Lastname, setLastname] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Email, setEmail] = useState('');
    const [DOB, setDOB] = useState('');
    const [Gender, setGender] = useState('');
    const [AboutYou, setAboutYou] = useState('');
    const [FacebookLink, setFacebookLink] = useState('');
    const [TwitterLink, setTwitterLink] = useState('');
    const [LinkedInLink, setLinkedInLink] = useState('');
    const [EmailNotification, setEmailNotification] = useState(false);
    const [SupportEmailId, setSupportEmailId] = useState('');
    const [SupportMobile, setSupportMobile] = useState('');
    const [Designation, setDesignation] = useState('');
    const [Organization, setOrganization] = useState('');
    const [EmergencyContactName, setEmergencyContactName] = useState('');
    const [EmergencyContactNo, setEmergencyContactNo] = useState('');

    const [EmergencyContactNameError, setEmergencyContactNameError] = useState('');
    const [EmergencyContactNoError, setEmergencyContactNoError] = useState('');

    const [IdProofType, setIdProofType] = useState('');
    const [IdProofNo, setIdProofNo] = useState('');
    const [IdProofDoc, setIdProofDoc] = useState('');
    const [ShowIdProofDoc, setShowIdProofDoc] = useState(false);
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [MobileError, setMobileError] = useState("");
    const [DOBError, setDOBError] = useState("");
    const [GenderError, setGenderError] = useState("");
    const [FacebookLinkError, setFacebookLinkError] = useState("");
    const [TwitterLinkError, setTwitterLinkError] = useState("");
    const [LinkedInLinkError, setLinkedInLinkError] = useState("");
    const [WeightError, setWeightError] = useState("");
    const [HeightError, setHeightError] = useState("");
    const [SupportEmailIdError, setSupportEmailIdError] = useState("");
    const [SupportMobileError, setSupportMobileError] = useState('');
    const [error, setError] = useState('');
    const [Profile, setProfile] = useState('');
    const [ApiCall, setApiCall] = useState(true);
    const [AddressApiCall, setAddressApiCall] = useState(false);
    const [PAddress1, setPAddress1] = useState('');
    const [PAddress1Error, setPAddress1Error] = useState('');
    const [PAddress2, setPAddress2] = useState('');
    const [City, setCity] = useState('');
    const [State, setState] = useState('');
    const [Country, setCountry] = useState('');
    const [AllState, setAllState] = useState([]);
    const [AllCountry, setAllCountry] = useState([]);
    const [AllCity, setAllCity] = useState([]);
    const [AllCState, setAllCState] = useState([]);
    const [AllCCountry, setAllCCountry] = useState([]);
    const [AllCCity, setAllCCity] = useState([]);
    const [CAddress1, setCAddress1] = useState('');
    const [CPAddress1Error, setCPAddress1Error] = useState('');
    const [CAddress2, setCAddress2] = useState('');
    const [CCity, setCCity] = useState('');
    const [CState, setCState] = useState('');
    const [CCountry, setCCountry] = useState('');
    const [Pincode, setPincode] = useState('');
    const [PincodeError, setPincodeError] = useState('');
    const [CPincode, setCPincode] = useState('');
    const [Nationality, setNationality] = useState('');
    const [AddressProofType, setAddressProofType] = useState('');
    const [AddressProofNo, setAddressProofNo] = useState('');
    const [AddressProofDoc, setAddressProofDoc] = useState('');
    const [ShowAddressProofDoc, setShowAddressProofDoc] = useState(false);
    const [Relation, setRelation] = useState('');
    const [RelFirstname, setRelFirstname] = useState('');
    const [RelLastname, setRelLastname] = useState('');
    const [RelMobile, setRelMobile] = useState('');
    const [RelEmail, setRelEmail] = useState('');
    const [RelGender, setRelGender] = useState(0);
    const [RelDOB, setRelDOB] = useState(0);
    const [RelIdProofType, setRelIdProofType] = useState('');
    const [RelIdProofNo, setRelIdProofNo] = useState('');
    const [RelIdProofDoc, setRelIdProofDoc] = useState('');

    //HEALTH DETAILS
    const [BloodGroup, setBloodGroup] = useState('');
    const [Weight, setWeight] = useState('');
    const [Height, setHeight] = useState('');
    const [MedicalConditions, setMedicalConditions] = useState('');
    const [Allergies, setAllergies] = useState('');
    const [Diabetes, setDiabetes] = useState(0);
    const [ChestPain, setChestPain] = useState(0);
    const [SevereDehydration, setSevereDehydration] = useState(0);
    const [SevereMuscleCramps, setSevereMuscleCramps] = useState(0);
    const [LowBloodSugar, setLowBloodSugar] = useState(0);
    const [UnderMedication, setUnderMedication] = useState(0);
    const [DrugAllergy, setDrugAllergy] = useState(0);
    const [Angina, setAngina] = useState(0);
    const [AbnormalHeartRhythm, setAbnormalHeartRhythm] = useState(0);
    const [Pacemaker, setPacemaker] = useState(0);
    const [HighBloodPressure, setHighBloodPressure] = useState(0);
    const [Epilepsy, setEpilepsy] = useState(0);
    const [BleedingDisorders, setBleedingDisorders] = useState(0);
    const [Asthma, setAsthma] = useState(0);
    const [Anemia, setAnemia] = useState(0);
    const [Hospitalized, setHospitalized] = useState(0);
    const [Infections, setInfections] = useState(0);
    const [Pregnant, setPregnant] = useState(0);
    const [CovidStatus, setCovidStatus] = useState(0);
    const [CurrentMedications, setCurrentMedications] = useState(0);
    const [DoctorName, setDoctorName] = useState('');
    const [DoctorContactNumber, setDoctorContactNumber] = useState('');

    const [MedicationDetail, setMedicationDetail] = useState();
    const [AllergyDetail, setAllergyDetail] = useState();
    const [HospitalizedDetail, setHospitalizedDetail] = useState();
    const [PregnantDetail, setPregnantDetail] = useState();
    const [CurrentMedicationsDetail, setCurrentMedicationsDetail] = useState();
    const [UpdatedAt, setUpdatedAt] = useState('');


    // HEALTH DETAILS  Error
    const [BloodGroupError, setBloodGroupError] = useState('');
    const [MedicalConditionsError, setMedicalConditionsError] = useState('');
    const [AllergiesError, setAllergiesError] = useState('');


    const Section = props.Tab;
    // console.log(props.Tab);
    const [CityName, setCityName] = useState('');
    const [StateName, setStateName] = useState('');
    const [CountryName, setCountryName] = useState('');

    const [CountryCode, setCountryCode] = useState('');
    const [CountryError, setCountryError] = useState('');

    const [CAddressSearchFlag, setCAddressSearchFlag] = useState(false)
    const [CCountryError, setCCountryError] = useState('');
    const [CPincodeError, setCPincodeError] = useState('')
    const [sameAsPermanent, setSameAsPermanent] = useState(false);

    const [CAddressApiCall, setCAddressApiCall] = useState(false)

    const handleSameAsPermanentChange = () => {
        setSameAsPermanent(!sameAsPermanent);
        if (!sameAsPermanent) {
            // Copy Permanent Address Details to Communication Address Details
            setCAddress1(PAddress1);
            setCAddress2(PAddress2);
            setCCountry(Country);
            setCState(State);
            setCCity(City);
            setCPincode(Pincode);
        } else {
            // Clear Communication Address Details
            setCAddress1('');
            setCAddress2('');
            setCCountry('');
            setCState('');
            setCCity('');
            setCPincode('');
        }
    };

    useEffect(() => {
        if (ApiCall) {
            axios.get("get_profile")
                .then((response) => {
                    // console.log(response.data.data.userData);
                    setUserdata(response.data.data.userData[0]);
                    var userData = response.data.data.userData[0];
                    // PERSONAL DETALS
                    // console.log(userData.profile_pic);
                    setProfile(userData.profile_pic);
                    setFirstname(userData.firstname);
                    setLastname(userData.lastname);
                    setMobile(userData.mobile);
                    setEmail(userData.email);
                    setGender(userData.gender);
                    setDOB(userData.dob);
                    setAboutYou(userData.about_you);

                    // SOCIAL MEDIA
                    setFacebookLink(userData.facebook_link ? userData.facebook_link : "");
                    setTwitterLink(userData.twitter_profile_link ? userData.twitter_profile_link : "");
                    setLinkedInLink(userData.linkedin_profile_link ? userData.linkedin_profile_link : "");

                    // console.log(userData.diabetes);
                    //HEALTH DETAILS
                    setBloodGroup(userData.blood_group)
                    setWeight(userData.weight);
                    setHeight(userData.height);
                    setMedicalConditions(userData.medical_conditions);
                    setAllergies(userData.allergies !== null ? userData.allergies : "");
                    setDiabetes(userData.diabetes === 0 ? false : true);
                    setChestPain(userData.chestpain === 0 ? false : true);
                    setSevereDehydration(userData.dehydrationseverity === 0 ? false : true);
                    setSevereMuscleCramps(userData.musclecramps === 0 ? false : true);
                    setLowBloodSugar(userData.lowbloodsugar === 0 ? false : true);
                    setUnderMedication(userData.undermedication === 0 ? false : true);
                    setDrugAllergy(userData.drugallergy === 0 ? false : true);
                    setAngina(userData.angina === 0 ? false : true);
                    setAbnormalHeartRhythm(userData.abnormalheartrhythm === 0 ? false : true);
                    setPacemaker(userData.pacemaker === 0 ? false : true);
                    setHighBloodPressure(userData.highbloodpressure === 0 ? false : true);
                    setEpilepsy(userData.epilepsy === 0 ? false : true);
                    setBleedingDisorders(userData.bleedingdisorders === 0 ? false : true);
                    setAsthma(userData.asthma === 0 ? false : true);
                    setAnemia(userData.anemia === 0 ? false : true);
                    setHospitalized(userData.hospitalized === 0 ? false : true);
                    setInfections(userData.infections === 0 ? false : true);
                    setPregnant(userData.pregnant === 0 ? false : true);
                    setCovidStatus(userData.covidstatus === 0 ? false : true);
                    setCurrentMedications(userData.currentmedications === 0 ? false : true);
                    setDoctorName(userData.familydoctorname !== null ? userData.familydoctorname : "");
                    setDoctorContactNumber(userData.familydoctorcontactno !== null ? userData.familydoctorcontactno : "");
                    setMedicationDetail(userData.meditaion_details !== null ? userData.meditaion_details : "");
                    setAllergyDetail(userData.drug_allergy_details !== 0 ? userData.drug_allergy_details : "");
                    setHospitalizedDetail(userData.hospitalization_details !== null ? userData.hospitalization_details : "");
                    setPregnantDetail(userData.stage_pregnancy !== null ? userData.stage_pregnancy : "");
                    setCurrentMedicationsDetail(userData.current_medication_names !== null ? userData.current_medication_names : "");
                    setUpdatedAt(userData.updated_at !== null ? userData.updated_at : "");

                    // GENERAL DETAILS
                    setOrganization(userData.organization ? userData.organization : "");
                    setDesignation(userData.designation ? userData.designation : "");
                    setEmergencyContactName(userData.emergency_contact_person ? userData.emergency_contact_person : "");
                    setEmergencyContactNo(userData.emergency_contact_no1 ? userData.emergency_contact_no1 : "");
                    setIdProofType(userData.id_proof_type ? userData.id_proof_type : "");
                    setIdProofNo(userData.id_proof_no ? userData.id_proof_no : "");
                    setIdProofDoc(userData.id_proof_doc_upload ? userData.id_proof_doc_upload : "");
                    setShowIdProofDoc(userData.id_proof_doc_upload ? true : false);

                    // COMMUNICATION SETTINGS
                    setEmailNotification(userData.email_notification_frequency ? true : false);
                    setSupportEmailId(userData.support_email_id ? userData.support_email_id : "");
                    setSupportMobile(userData.support_mobile ? userData.support_mobile : "");

                    //ADDRESS DETAILS
                    setCity(userData.city ? userData.city : "");
                    setCCity(userData.ca_city ? userData.ca_city : "");
                    setState(userData.state ? userData.state : "");
                    setCState(userData.ca_state ? userData.ca_state : "");
                    setCountry(userData.country ? userData.country : "");
                    setCountryCode(userData.country_code ? userData.country_code : "")
                    setCCountry(userData.ca_country ? userData.ca_country : "");
                    setPAddress1(userData.address1 ? userData.address1 : "");
                    setPAddress2(userData.address2 ? userData.address2 : "");
                    setCAddress1(userData.ca_address1 ? userData.ca_address1 : "");
                    setCAddress2(userData.ca_address2 ? userData.ca_address2 : "");
                    setPincode(userData.pincode ? userData.pincode : "");
                    setCPincode(userData.ca_pincode ? userData.ca_pincode : "");
                    setNationality(userData.nationality ? userData.nationality : "");
                    setAddressProofType(userData.address_proof_type ? userData.address_proof_type : 0)
                    setAddressProofNo(userData.address_proof_no ? userData.address_proof_no : "");
                    setAddressProofDoc(userData.address_proof_doc_upload ? userData.address_proof_doc_upload : "")
                    setShowAddressProofDoc(userData.address_proof_doc_upload ? true : false);
                    setSameAsPermanent(userData.sameAsPermanent === 1 ? true : false);

                    //FAMILY MEMBER DETAILS
                    setRelation(userData.relation)
                    setRelFirstname(userData.rel_firstname ? userData.rel_firstname : "");
                    setRelLastname(userData.rel_lastname ? userData.rel_lastname : "");
                    setRelMobile(userData.rel_mobile ? userData.rel_mobile : "");
                    setRelEmail(userData.rel_email ? userData.rel_email : "");
                    setRelGender(userData.rel_gender ? userData.rel_gender : "");
                    setRelDOB(userData.rel_dob ? userData.rel_dob : "");
                    // setRelIdProofType(userData.rel_id_proof_type ?  : "");
                    setRelIdProofNo(userData.rel_id_proof_no ? userData.rel_id_proof_no : "");
                    setRelIdProofDoc(userData.rel_id_proof_doc ? userData.rel_id_proof_doc : "");

                    //OTHER
                    setApiCall(false);
                    setAddressApiCall(true)
                })
                .catch((error) => {
                    // console.log(error);
                })
        }
    }, [ApiCall])

    const bloodGroups = [
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-"
    ];

    const ProofType = [
        "Select Id Proof Type",
        "Aadhar",
        // "PAN Card",
        "Driving Licence",
        "Passport"
    ];

    // PERSONAL DETAILS CODE STARTS FROM HERE------------------------------------------------------------------------
    const deleteProfilePic = () => {
        axios.post("delete_profile", {})
            .then((response) => {
                // console.log(response.data.message);
                toast.success(response.data.message);
                setApiCall(true);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    function handleFileSelect(event) {
        const selectedFile = event.target.files[0];
        const imageSizeLimit = localStorage.getItem('MAX_UPLOAD_FILE_SIZE'); // 2 MB limit
        setProfile(selectedFile);
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

        // console.log(selectedFile.size);

        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            if (selectedFile.size < imageSizeLimit) {
                const formData = new FormData();
                formData.append('profile_pic', selectedFile);

                axios.post("update_profile_pic", formData)
                    .then((response) => {
                        // console.log(response.data.message);
                        toast.success(response.data.message)
                        setApiCall(true);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            } else {
                setProfile(Profile);
                toast.error('Image size exceeds 2MB limit.');
            }
        } else {
            setProfile(Profile);
            toast.error("Invalid file type. Please select image file (jpg, png,gif)");
        }
    }

    const ValidatePersonalDetails = useCallback((e) => {
        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setMobileError("");
        setGenderError("");
        setDOBError("");
        let flag = true;
        let flag_field = '';
        if (Firstname.trim() === "") {
            setFirstNameError("Please enter your first name.");
            flag = false;
            flag_field = 'Firstname';
        } else if (!/^[A-Za-z]+$/i.test(Firstname.trim())) {
            setFirstNameError("Invalid characters in first name. Only alphabets are allowed.");
            flag = false;
            flag_field = 'Firstname';
        }

        if (Lastname.trim() === "") {
            setLastNameError("Please enter your last name.");
            flag = false;
            flag_field = 'Lastname';
        } else if (!/^[A-Za-z]+$/i.test(Lastname.trim())) {
            setLastNameError("Invalid characters in last name. Only alphabets are allowed.");
            flag = false;
            flag_field = 'Lastname';
        }

        if (Email === "") {
            setEmailError("Please enter Email id.");
            flag = false;
            flag_field = 'Email';
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            setEmailError('Please enter a valid email address');
            flag = false;
            flag_field = 'Email';
        }

        if (Mobile === "") {
            setMobileError("Please enter Mobile number.");
            flag = false;
            flag_field = 'Mobile';
        } else if (!/^[0-9]{10}$/i.test(Mobile)) {
            setMobileError("Please enter a valid 10-digit Mobile number.");
            flag = false;
            flag_field = 'Mobile';
        }

        if (parseInt(Gender) === 0) {
            setGenderError("Please select Gender.");
            flag = false;
            flag_field = 'Gender';
        }

        if (DOB === "") {
            setDOBError("Please select DOB.");
            flag = false;
            flag_field = 'DOB';
        }
        // console.log("HandleValidation field", flag_field);
        // console.log("HandleValidation flag", flag);

        return flag;
    }, [Firstname, Lastname, Email, Mobile, Gender, DOB]);

    const SubmitPersonalDetails = useCallback((e) => {
        e.preventDefault();
        const flag = ValidatePersonalDetails();
        if (flag) {
            const payload = {
                "firstname": Firstname,
                "lastname": Lastname,
                "mobile": Mobile,
                "email": Email,
                "dob": DOB,
                "gender": Gender,
                "about_you": AboutYou
            }

            axios.post("personal_details", payload)
                .then((response) => {
                    toast.success(response.data.message);
                    setApiCall(true);
                    props.setProfileApiCall(true);
                    props.setSection('my-profile');
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 400) {
                        toast.error(error.response.data.message);
                    }
                })
        }
    }, [Firstname, Lastname, Email, Mobile, Gender, DOB, AboutYou, props, ValidatePersonalDetails]);
    // PERSONAL DETAILS CODE ENDS FROM HERE

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            // return uploadAdapter(loader);
        };
    }

    const handleAboutYou = (e, editor) => {
        const data = editor.getData();
        setAboutYou(data)
    };

    // GENERAL DETAILS CODE STARTS FROM HERE----------------------------------------------------------------------------
    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setIdProofDoc(selectedFile);
        setShowIdProofDoc(false)
    };

    const ValidateGeneralDetails = useCallback((e) => {
        setEmergencyContactNameError("");
        setEmergencyContactNoError("")
        let flag = true;

        if (EmergencyContactName.trim() === "") {
            setEmergencyContactNameError("Please enter emergency contact name.");
            flag = false;
        } else if (!/^[A-Za-z\s]+$/i.test(EmergencyContactName.trim())) {
            setEmergencyContactNameError("Invalid characters in emergency contact name. Only alphabets are allowed.");
            flag = false;
        }

        if (EmergencyContactNo === "") {
            setEmergencyContactNoError("Please enter emergency contact number.");
            flag = false;
        } else if (!/^[0-9]{10}$/i.test(EmergencyContactNo)) {
            setEmergencyContactNoError("Please enter a valid 10-digit emergency contact number.");
            flag = false;
        }

        return flag;
    }, [EmergencyContactName, EmergencyContactNo])

    const SubmitGeneralDetails = useCallback((e) => {
        e.preventDefault();
        const flag = ValidateGeneralDetails();
        if (flag) {
            const formData = new FormData();
            formData.append("organization", Organization !== null ? Organization : "");
            formData.append("designation", Designation !== null ? Designation : "");
            formData.append("emergency_contact_person", EmergencyContactName !== null ? EmergencyContactName : "");
            formData.append("emergency_contact_no", EmergencyContactNo !== null ? EmergencyContactNo : "");
            formData.append("id_proof_type", IdProofType !== null ? IdProofType : "");
            formData.append("id_proof_no", IdProofNo !== null ? IdProofNo : "");
            formData.append("id_proof_doc_upload", IdProofDoc !== null ? IdProofDoc : "");

            axios.post("general_details", formData)
                .then((response) => {
                    toast.success(response.data.message);
                    setApiCall(true);
                    props.setProfileApiCall(true);
                    props.setSection('general-details');
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [Designation, EmergencyContactName, EmergencyContactNo, IdProofDoc, IdProofNo, IdProofType, Organization, ValidateGeneralDetails, props])

    // ADDRESS DETAILS CODE STARTS FROM HERE----------------------------------------------------------------------------
    //GET ALL COUNTRIES
    useEffect(() => {
        if (AllCountry.length === 0) {
            axios.post('country', {
                // state_id: StateId
            })
                .then((response) => {
                    // console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        setAllCountry(response.data.data.AllCountries);
                        setAllCCountry(response.data.data.AllCountries);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [AllCountry])

    //GET ALL STATES
    useEffect(() => {
        if (Country) {
            axios.post('state', {
                country_id: Country
            })
                .then((response) => {
                    // console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        setAllState(response.data.data.AllState);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [Country])

    //GET ALL STATES
    useEffect(() => {
        if (CCountry) {
            axios.post('state', {
                country_id: CCountry
            })
                .then((response) => {
                    // console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        setAllCState(response.data.data.AllState);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [CCountry])

    // GET ALL CITIES
    useEffect(() => {
        if (State) {
            axios.post('city', {
                state_id: State
            })
                .then((response) => {
                    // console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        // setAllCity(response.data.data.AllCities);
                        setAllCity(response.data.data.AllCities);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [State])

    useEffect(() => {
        if (CState) {
            axios.post('city', {
                state_id: CState
            })
                .then((response) => {
                    // console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        // setAllCity(response.data.data.AllCities);
                        setAllCCity(response.data.data.AllCities);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [CState])

    const handleAddressImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setAddressProofDoc(selectedFile);
        setShowAddressProofDoc(false);
    };

    const ValidateAddressDetails = useCallback((e) => {
        setPincodeError("");
        setPAddress1Error("");
        setCPAddress1Error("");
        let flag = true;
        let flag_field = '';
        if (Pincode === "") {
            setPincodeError("Please enter your pincode.");
            flag = false;
            flag_field = 'Pincode';
        } else if (!/^\d{6}$/i.test(Pincode)) {
            setPincodeError("Invalid pincode. Please enter valid pincode.");
            flag = false;
            flag_field = 'Pincode';
        }

        if (Country === "") {
            setCountryError("Please select country.");
            flag = false;
        }

        if (PAddress1 === "") {
            setPAddress1Error("Please enter your house no/flat block no.");
            flag = false;
            flag_field = 'PAddress1';
        }

        if (CCountry === '') {
            setCCountryError("Please select country.")
        }
        if (CPincode === "") {
            setCPincodeError("Please enter your pincode.");
            flag = false;
            flag_field = 'Pincode';
        } else if (!/^\d{6}$/i.test(CPincode)) {
            setCPincodeError("Invalid pincode. Please enter valid pincode.");
            flag = false;
            flag_field = 'Pincode';
        }

        if (CAddress1 === "") {
            setCPAddress1Error("Please enter your house no/flat block no.");
            flag = false;
            flag_field = 'CAddress1';
        }

        // console.log("HandleValidation field", flag_field);
        // console.log("HandleValidation flag", flag);
        return flag;
    }, [CAddress1, CCountry, CPincode, Country, PAddress1, Pincode]);

    // console.log(sameAsPermanent);

    const SubmitAddressDetails = useCallback((e) => {
        e.preventDefault();
        const flag = ValidateAddressDetails();
        if (flag) {
            const formData = new FormData();
            //ADDRESS DETAILS
            formData.append("address1", PAddress1 !== null ? PAddress1 : "");
            formData.append("address2", PAddress2 !== null ? PAddress2 : "");
            formData.append("city", City !== null ? City : "");
            formData.append("state", State !== null ? State : "");
            formData.append("country", Country !== null ? Country : "");
            formData.append("pincode", Pincode !== null ? Pincode : "");
            formData.append("sameAsPermanent", sameAsPermanent ? 1 : 0);

            formData.append("ca_address1", CAddress1 !== null ? CAddress1 : "");
            formData.append("ca_address2", CAddress2 !== null ? CAddress2 : "");
            formData.append("ca_city", CCity !== null ? CCity : "");
            formData.append("ca_state", CState !== null ? CState : "");
            formData.append("ca_country", CCountry !== null ? CCountry : "");
            formData.append("ca_pincode", CPincode !== null ? CPincode : "");

            formData.append("nationality", Nationality !== null ? Nationality : "");
            formData.append("address_proof_type", AddressProofType !== null ? AddressProofType : "");
            formData.append("address_proof_no", AddressProofNo !== null ? AddressProofNo : "");
            formData.append("address_proof_doc_upload", AddressProofDoc !== null ? AddressProofDoc : "");

            axios.post("address_details", formData)
                .then((response) => {
                    toast.success(response.data.message);
                    props.setProfileApiCall(true);
                    setApiCall(true);
                    props.setSection('address');
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [AddressProofDoc, AddressProofNo, AddressProofType, CAddress1, CAddress2, CCity, CCountry, CPincode, CState, City, Country, Nationality, PAddress1, PAddress2, Pincode, State, ValidateAddressDetails, props, sameAsPermanent]);

    //HEALTH DETAILS---------------------------------------------------------------------------------------------------
    // console.log(BloodGroup);
    const HandleMedicalValidation = useCallback((e) => {
        setBloodGroupError("");
        setWeightError("");
        setHeightError("");
        setMedicalConditionsError("");
        setAllergiesError("");

        let flag = true;
        // console.log(BloodGroup);
        if (BloodGroup === "" || BloodGroup === '0') {
            setBloodGroupError("Please Enter BloodGroup.");
            flag = false;
        }

        if (Weight === "" || Weight === 0) {
            setWeightError("Please Enter Weight.");
            flag = false;
        }
        if (Weight !== '') {
            if (!/^[+-]?\d+(\.\d+)?$/i.test(Weight)) {
                setWeightError('Please enter a valid weight');
                flag = false;
            }
        }

        if (Height === "" || Height === 0) {
            setHeightError("Please Enter Height.");
            flag = false;
        }
        if (Height !== '') {
            if (!/^[+-]?\d+(\.\d+)?$/i.test(Height)) {
                setHeightError('Please enter a valid Height');
                flag = false;
            }
        }

        if (MedicalConditions === "") {
            setMedicalConditionsError("Please Enter Medical Conditions.");
            flag = false;
        }
        // if (Allergies === "") {
        //     setAllergiesError("Please Enter Allergies.");
        //     flag = false;
        // }

        return flag;
    }, [BloodGroup, Weight, Height, MedicalConditions]);

    const handleMedicalSubmit = useCallback((e) => {
        // alert("here")
        e.preventDefault();
        const flag = HandleMedicalValidation();
        if (flag) {
            var formData = new FormData();
            //HEALTH DETAILS
            formData.append("blood_group", BloodGroup);
            formData.append("weight", Weight);
            formData.append("height", Height);
            formData.append("medical_conditions", MedicalConditions);
            formData.append("allergies", Allergies);
            formData.append("diabetes", Diabetes ? 1 : 0);
            formData.append("chestpain", ChestPain ? 1 : 0);
            formData.append("dehydrationseverity", SevereDehydration ? 1 : 0);
            formData.append("musclecramps", SevereMuscleCramps ? 1 : 0);
            formData.append("lowbloodsugar", LowBloodSugar ? 1 : 0);
            formData.append("undermedication", UnderMedication ? 1 : 0);
            formData.append("drugallergy", DrugAllergy ? 1 : 0);
            formData.append("angina", Angina ? 1 : 0);
            formData.append("abnormalheartrhythm", AbnormalHeartRhythm ? 1 : 0);
            formData.append("pacemaker", Pacemaker ? 1 : 0);
            formData.append("highbloodpressure", HighBloodPressure ? 1 : 0);
            formData.append("epilepsy", Epilepsy ? 1 : 0);
            formData.append("bleedingdisorders", BleedingDisorders ? 1 : 0);
            formData.append("asthma", Asthma ? 1 : 0);
            formData.append("anemia", Anemia ? 1 : 0);
            formData.append("hospitalized", Hospitalized ? 1 : 0);
            formData.append("infections", Infections ? 1 : 0);
            formData.append("pregnant", Pregnant ? 1 : 0);
            formData.append("covidstatus", CovidStatus ? 1 : 0);
            formData.append("currentmedications", CurrentMedications ? 1 : 0);
            formData.append("familydoctorname", DoctorName);
            formData.append("familydoctorcontactno", DoctorContactNumber);
            formData.append("meditaion_details", MedicationDetail);
            formData.append("drug_allergy_details", AllergyDetail);
            formData.append("hospitalization_details", HospitalizedDetail);
            formData.append("stage_pregnancy", PregnantDetail);
            formData.append("current_medication_names", CurrentMedicationsDetail);
            // console.log(formData);
            axios.post("/edit_user_medical", formData)
                .then((response) => {
                    // console.log("response:-",response);
                    toast.success(response.data.message);
                    props.setProfileApiCall(true);
                    setApiCall(true);
                    props.setSection('health-details')
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [HandleMedicalValidation, BloodGroup, Weight, Height, MedicalConditions, Allergies, Diabetes, ChestPain, SevereDehydration, SevereMuscleCramps, LowBloodSugar, UnderMedication, DrugAllergy, Angina, AbnormalHeartRhythm, Pacemaker, HighBloodPressure, Epilepsy, BleedingDisorders, Asthma, Anemia, Hospitalized, Infections, Pregnant, CovidStatus, CurrentMedications, DoctorName, DoctorContactNumber, MedicationDetail, AllergyDetail, HospitalizedDetail, PregnantDetail, CurrentMedicationsDetail, props]);

    //SOCAIL MEDIA CODE STARTS FROM HERE-------------------------------------------------------------------------------
    const SocailMediaValidation = useCallback((e) => {
        let flag = true;
        let flag_field = '';

        if (FacebookLink !== '') {
            const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-_/?=&%]+$/;
            if (!facebookRegex.test(FacebookLink)) {
                setFacebookLinkError("Please enter a valid Facebook link.");
                flag = false;
                flag_field = 'FacebookLink';
            }
        }

        if (TwitterLink !== '') {
            const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
            if (!twitterRegex.test(TwitterLink)) {
                setTwitterLinkError("Please enter a valid Twitter link.");
                flag = false;
                flag_field = 'TwitterLink';
            }
        }

        if (LinkedInLink !== '') {
            const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/([a-zA-Z0-9_-]+)/;
            if (!linkedInRegex.test(LinkedInLink)) {
                setLinkedInLinkError("Please enter a valid LinkedIn link.");
                flag = false;
                flag_field = 'LinkedInLink';
            }
        }



        return flag;
    }, [FacebookLink, TwitterLink, LinkedInLink]);

    const SubmitSocialMedia = useCallback((e) => {
        e.preventDefault();
        const flag = SocailMediaValidation();
        if (flag) {
            const payload = {
                "facebook_link": FacebookLink !== null ? FacebookLink : "",
                "twitter_link": TwitterLink !== null ? TwitterLink : "",
                "linkedin_profile_link": LinkedInLink !== null ? LinkedInLink : "",
            }

            axios.post("social_media", payload)
                .then((response) => {
                    toast.success(response.data.message);
                    props.setProfileApiCall(true);
                    setApiCall(true);
                    props.setSection('social-media-accounts');
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [FacebookLink, LinkedInLink, SocailMediaValidation, TwitterLink, props])

    //COMMUNICATION SETTINGS--------------------------------------------------------------------------------------------
    const CommunicationSettingsValidation = useCallback((e) => {
        let flag = true;
        let flag_field = '';
        if (SupportEmailId !== '') {

            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(SupportEmailId)) {
                setSupportEmailIdError('Please enter a valid email address');
                flag = false;
                flag_field = 'SupportEmailId';
            }
        }

        if (SupportMobile !== "") {
            if (!/^[0-9]{10}$/i.test(SupportMobile)) {
                setSupportMobileError("Please Enter valid Contact number.");
                flag = false;
            }
        }

        return flag;
    }, [SupportEmailId, SupportMobile]);

    const SubmitCommunicationSettings = useCallback((e) => {
        e.preventDefault();
        const flag = CommunicationSettingsValidation();
        if (flag) {
            const payload = {
                "support_email_id": SupportEmailId !== null ? SupportEmailId : "",
                "support_mobile": SupportMobile !== null ? SupportMobile : "",
                "email_notification_frequency": (EmailNotification ? 1 : 0),
            }

            axios.post("communication_settings", payload)
                .then((response) => {
                    toast.success(response.data.message);
                    props.setProfileApiCall(true);
                    setApiCall(true);
                    props.setSection('communication_settings');
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [CommunicationSettingsValidation, EmailNotification, SupportEmailId, SupportMobile, props])

    //FAMILY MEMBERS --------------------------------------------------------------------------------------------------
    const handleRelImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setRelIdProofDoc(selectedFile);
    };

    const SubmitFamilyMembers = useCallback((e) => {
        e.preventDefault();
        // const flag = FamilyMembersValidation();
        // if (flag) {
        var formData = new FormData();
        //FAMILY MEMBER DETAILS
        formData.append("Relation", Relation);
        formData.append("RelFirstname", RelFirstname);
        formData.append("RelLastname", RelLastname);
        formData.append("RelMobile", RelMobile);
        formData.append("RelEmail", RelEmail);
        formData.append("RelGender", RelGender);
        formData.append("RelDOB", RelDOB);
        formData.append("RelIdProofType", RelIdProofType);
        formData.append("RelIdProofNo", RelIdProofNo);
        formData.append("RelIdProofDoc", RelIdProofDoc);

        axios.post("edit_profile", formData)
            .then((response) => {
                toast.success(response.data.message);
                props.setProfileApiCall(true);
                setApiCall(true);
            })
            .catch((error) => {
                console.log(error);
            })
        // }
    }, [Relation, RelFirstname, RelLastname, RelMobile, RelEmail, RelGender, RelDOB, RelIdProofType, RelIdProofNo, RelIdProofDoc, props]);

    //ENTER TAB FUNCTIONALITY-------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                if (Section === 'my-profile') SubmitPersonalDetails(e);
                if (Section === 'edit-general-details') SubmitGeneralDetails(e);
                if (Section === 'edit-address') SubmitAddressDetails(e);
                if (Section === 'edit-social-media-accounts') SubmitSocialMedia(e)
                if (Section === 'edit-health-details') handleMedicalSubmit(e);
                if (Section === 'communication_settings') SubmitCommunicationSettings(e)
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.body.style.overflow = 'auto';
        };
    }, [Firstname, Lastname, Email, Mobile, Gender, DOB, Section, SubmitPersonalDetails, SubmitGeneralDetails, handleMedicalSubmit, SubmitAddressDetails, SubmitSocialMedia, SubmitCommunicationSettings]);

    // SAME AS ABOVE PINCODE FUNCTIONALITY
    const getCCountryCode = (CountryId, CountryName) => {
        // console.log(CountryId,CountryName);
        if (CountryId !== "0") {
            setCPincode(""); setCAddress1(""); setCAddress2(""); setCState(""); setCCity("");
            return fetch(`https://restcountries.com/v3.1/name/${CountryName}`)
                .then(response => {
                    if (!response.ok) {
                        setCCountryError("Failed to fetch country data")
                        throw new Error('Failed to fetch country data');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.length > 0) {
                        const countryCode = data[0].cca2;
                        setCountryCode(countryCode)
                        setCCountryError('');
                        setCPincodeError('')
                    } else {
                        setCCountry("")
                        setCCountryError("Please select country");
                        setCountryCode('')
                    }
                })
                .catch(error => {
                    setCCountry("")
                    setCCountryError("Please select country");
                    setCountryCode('')
                });
        } else {
            setCCountryError("Please select country");
            setCountryCode('')
            setCPincodeError('');
        }

    }

    const GetCLocation = (pincode) => {
        // console.log(CCountry, pincode);
        if (CCountry !== "") {
            if (pincode.length !== 6) {
                setCPincodeError('Pincode must be 6 digits');
                return;
            }
            // console.log(CountryCode);
            if (CountryCode !== "") {
                props.setLoader(true);
                setCCity(''); setCState('');
                setCPincodeError('');

                axios
                    .get(`https://nominatim.openstreetmap.org/search?postalcode=${pincode}&country=${CountryCode}&format=json`)
                    .then((response) => {
                        // console.log(response);
                        if (response.data && response.data.length > 0) {
                            const addressData = response.data[0];
                            // console.log(addressData);
                            const display_name = addressData.display_name.split(', ');

                            let city = display_name[display_name.length - 3].trim();
                            const state = display_name[display_name.length - 2].trim();
                            const country = display_name[display_name.length - 1].trim();
                            if (city.toLowerCase().includes('district')) {
                                city = city.replace(/district/i, '').trim();
                            } else if (city.toLowerCase().includes('city')) {
                                city = city.replace(/city/i, '').trim();
                            }
                            // console.log("City:", city);
                            // console.log("State:", state);
                            // console.log("Country:", country);

                            setCityName(city);
                            setStateName(state);
                            setCountryName(country)
                            setCAddressSearchFlag(true);
                        } else {
                            setCPincodeError('Location not found for this pincode.');
                            setCCity('');
                            setCState('');
                            props.setLoader(false);
                        }
                    })
                    .catch((error) => {
                        props.setLoader(false);
                        setCPincodeError('Error fetching location data.');
                        console.error(error);
                    });
            } else {
                setCCountryError("Select country first");
                setCPincodeError('');
                props.setLoader(false);
            }
        } else {
            setCPincodeError("Select country first");
            props.setLoader(false);
        }
    }

    useEffect(() => {
        // console.log("here",AddressSearchFlag,CityName, StateName, CountryName);
        if (CAddressSearchFlag) {
            const payload = {
                "city_name": CityName,
                "state_name": StateName,
                "country_name": CountryName
            }
            axios.post("get_location", payload)
                .then((response) => {
                    // console.log(response.data.data);
                    props.setLoader(false);
                    setCAddressSearchFlag(false);
                    setCCity(response.data.data.CityId);
                    setCState(response.data.data.StateId);
                    // setCountry(response.data.data.CountryId);
                })
                .catch((error) => {
                    props.setLoader(false);
                    setCPincodeError('Error fetching location data.');
                    console.log(error);
                })
        }
    }, [CAddressSearchFlag, CityName, CountryName, StateName, props])

    // ----------------23May24
    // console.log(Country, Pincode)
    useEffect(() => {
        if (AddressApiCall) {
            if (Country !== "") {
                if (Country === 101) {
                    const pincodeString = String(Pincode);  // Ensure Pincode is a string
                    if (pincodeString.length !== 6) {
                        setCountryError("");
                        setPincodeError('Pincode must be 6 digits');
                        setAddressApiCall(false); // Reset the flag after processing
                        return;
                    }
                    const payload = {
                        "country_id": Country,
                        "pincode": Pincode
                    }
                    axios.post("get_address", payload)
                        .then((response) => {
                            if (response.status === 200) {
                                let FullAddress = response.data.data.FullAddress;
                                setCity(FullAddress.city_id);
                                setState(FullAddress.state_id);
                                setPincodeError("");
                                setCountryError("");
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            } else {
                setCountryError("Please select country");
                setPincodeError('');
            }
            setAddressApiCall(false);
        }
    }, [AddressApiCall, Country, Pincode])

    useEffect(() => {
        if (CAddressApiCall) {
            if (CCountry !== "") {
                if (CCountry === 101) {
                    const pincodeString = String(CPincode);  // Ensure Pincode is a string
                    if (pincodeString.length !== 6) {
                        setCCountryError("");
                        setCPincodeError('Pincode must be 6 digits');
                        setCAddressApiCall(false); // Reset the flag after processing
                        return;
                    }
                    const payload = {
                        "country_id": CCountry,
                        "pincode": CPincode
                    }
                    axios.post("get_address", payload)
                        .then((response) => {
                            if (response.status === 200) {
                                let FullAddress = response.data.data.FullAddress;
                                setCCity(FullAddress.city_id);
                                setCState(FullAddress.state_id);
                                setCPincodeError("");
                                setCCountryError("");
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            } else {
                setCCountryError("Please select country");
                setCPincodeError('');
            }
            setCAddressApiCall(false);
        }
    }, [CAddressApiCall, CCountry, CPincode])
    // -----------------------

    return (
        <>
            <form onSubmit={(e) => SubmitPersonalDetails(e)}>
                <div className={(Section === 'edit-my-profile' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none")}>
                    <div className="section__header d-flex align-items-center justify-content-between mb-4">
                        <h2 className="section__header--title h3">Personal Details</h2>
                    </div>
                    <div className="col-sm-12">
                        <div className="profile-card">
                            <div className="card-body p-5">
                                <div className="row">

                                    {/* PROFILE IMAGE */}
                                    <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                        <div className="img-container">
                                            <img
                                                className="user-img"
                                                src={`${Profile}`}
                                                alt={Firstname + " " + Lastname}
                                            />
                                            <div className="img-btn">
                                                <div className="" onClick={() => document.getElementById('fileInput').click()}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={25}
                                                        viewBox="0 -960 960 960"
                                                        width={25}
                                                    >
                                                        <path d="M440-440ZM120-120q-33 0-56.5-23.5T40-200v-480q0-33 23.5-56.5T120-760h126l74-80h240v80H355l-73 80H120v480h640v-360h80v360q0 33-23.5 56.5T760-120H120Zm640-560v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80ZM440-260q75 0 127.5-52.5T620-440q0-75-52.5-127.5T440-620q-75 0-127.5 52.5T260-440q0 75 52.5 127.5T440-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Z" />
                                                    </svg>
                                                </div>
                                                <input type="file" id="fileInput" style={{ display: 'none' }} onChange={(e) => handleFileSelect(e)} />

                                                <div className="" onClick={() => deleteProfilePic()}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={25}
                                                        viewBox="0 -960 960 960"
                                                        width={25}
                                                    >
                                                        <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* BASIC INFO */}
                                    <div className="col-sm-10">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-floating mt-3 mb-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={Firstname}
                                                        onChange={(e) => setFirstname(e.target.value)}
                                                    />
                                                    <label>Your Firstname<span className="checkout__input--label__star">*</span></label>
                                                </div>
                                                <small className="text-danger">{FirstNameError}</small>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-floating mt-3 mb-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={Lastname}
                                                        onChange={(e) => setLastname(e.target.value)}
                                                    />
                                                    <label>Your Lastname<span className="checkout__input--label__star">*</span></label>
                                                </div>
                                                <small className="text-danger">{LastNameError}</small>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-floating mt-3 mb-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={Mobile}
                                                        onChange={(e) => setMobile(e.target.value)}
                                                    />
                                                    <label>Mobile Number<span className="checkout__input--label__star">*</span></label>
                                                </div>
                                                <small className="text-danger">{MobileError}</small>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-floating mt-3 mb-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={Email}
                                                        onChange={(e) => { setEmail(e.target.value) }}
                                                        style={{ backgroundColor: '#d8d8d8' }}
                                                        disabled
                                                    />
                                                    <label>Email ID<span className="checkout__input--label__star">*</span></label>
                                                </div>
                                                <small className="text-danger">{EmailError}</small>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="form-floating mt-3 mb-0">
                                                    <label className="select-lable"> Select  Gender<span className="checkout__input--label__star">*</span></label>
                                                    <select className="form-control checkout__input--field border-radius-5" required="" value={Gender} onChange={(e) => { setGender(e.target.value) }}
                                                    >
                                                        <option value={0}>-- Select Gender -- </option>
                                                        <option value={1}>Male</option>
                                                        <option value={2}>Female</option>
                                                        <option value={3}>Other</option>
                                                    </select>
                                                    <small className="text-danger">{GenderError}</small>
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="form-floating mt-3 mb-0">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="event-date"
                                                        value={DOB}
                                                        onChange={(e) => { setDOB(e.target.value) }} max={new Date().toISOString().split('T')[0]}
                                                    />
                                                    <label className="select-label">Date Of Birth<span className="checkout__input--label__star">*</span></label>
                                                    <small className="text-danger">{DOBError}</small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>My Bio</label>
                                        <div className="form-floating mt-3 mb-0">
                                            {/* <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                style={{ minHeight: "100px", display: "inline-block", resize: "both" }}
                                                value={AboutYou} onChange={(e) => setAboutYou(e.target.value)}
                                                rows={8} // Set the number of visible rows
                                                cols={50} // Set the number of visible columns
                                            /> */}
                                            {/* <label>My Bio</label> */}
                                            <CKEditor
                                                config={{
                                                    extraPlugins: [uploadPlugin]
                                                }}
                                                editor={ClassicEditor}
                                                data={AboutYou ? AboutYou : ""}
                                                onChange={handleAboutYou}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-sm-12 d-flex justify-content-center gap-3">
                                        <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                            <div className="continue__shipping--btn secondary__btn border-radius-5"
                                            >
                                                <div
                                                    onClick={() => props.setSection('my-profile')}
                                                > Cancel</div>
                                            </div>

                                            <button className="continue__shipping--btn primary__btn border-radius-5" type="submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <form onSubmit={(e) => SubmitGeneralDetails(e)}>
                <div className={(Section === 'edit-general-details' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none")}>
                    <div className="section__header mb-4">
                        <h2 className="section__header--title h3"> Basic Information</h2>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={EmergencyContactName}
                                        onChange={(e) => setEmergencyContactName(e.target.value)}
                                    />
                                    <label> Please provide your emergency contact name:<span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{EmergencyContactNameError}</small>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={EmergencyContactNo}
                                        onChange={(e) => setEmergencyContactNo(e.target.value)}
                                    />
                                    <label> Please provide your emergency contact number:<span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{EmergencyContactNoError}</small>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Organization}
                                        onChange={(e) => { setOrganization(e.target.value) }}
                                    />
                                    <label>Current Organisation</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Designation}
                                        onChange={(e) => { setDesignation(e.target.value) }}
                                    />
                                    <label>Current Designation</label>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable"> Id Proof Type</label>
                                    <select className="form-control checkout__input--field border-radius-5" required=""
                                        onChange={(e) => setIdProofType(e.target.value)} value={IdProofType}
                                    >
                                        {ProofType.map((group, index) => (
                                            <option key={index} value={index}>{group}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={IdProofNo}
                                        onChange={(e) => setIdProofNo(e.target.value)}
                                    />
                                    <label>Id Proof Number</label>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-4 mb-0">
                                    <label className="checkout__input--label mb-2">
                                    </label>
                                    <input
                                        className="form-control mb-1"
                                        type="file"
                                        onChange={handleImageChange}
                                        accept="image/*"
                                    />
                                    <label>  Id Proof Document</label>  <br />
                                    {ShowIdProofDoc ? <img src={IdProofDoc} alt='' style={{ 'height': "50px", 'width': '50px' }} /> : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 d-flex justify-content-center gap-3">
                                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                        <div className="continue__shipping--btn secondary__btn border-radius-5">
                                            <div onClick={() => props.setSection('general-details')}> Cancel</div>
                                        </div>
                                        <button className="continue__shipping--btn primary__btn border-radius-5" type="submit">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <form onSubmit={(e) => SubmitAddressDetails(e)}>
                {/* ADDRESS DETAILS */}
                <div className={(Section === 'edit-address' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none")}>
                    <div className="section__header mb-4">
                        <h2 className="section__header--title h3"> Your Address</h2>
                    </div>
                    <hr />
                    <br />
                    <div className="section__header mb-4">
                        <h6 className="section__header--title h3"> Permanent Address Details</h6>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable"> Country<span className="checkout__input--label__star">*</span></label>
                                    <select className="form-control" required=""
                                        onChange={(e) => {
                                            setCountry(e.target.value);
                                            setAddressApiCall(true);
                                            //getCountryCode(e.target.value, e.target.selectedOptions[0].text) 
                                        }} value={Country}
                                    >
                                        <option value="">-- Select Country --</option>
                                        {
                                            (AllCountry && AllCountry.length > 0 ?
                                                AllCountry.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    );
                                                }) : null
                                            )
                                        }
                                    </select>
                                    <small className="text-danger">{CountryError}</small>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Pincode}
                                        onChange={(e) => {
                                            setPincode(e.target.value.replace(/\D/g, ''));
                                            setAddressApiCall(true)
                                            // GetLocation(e.target.value.replace(/\D/g, ''));
                                        }}
                                    />
                                    <label> Pincode<span className="checkout__input--label__star">*</span></label>
                                    <small className="text-danger">{PincodeError}</small>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={PAddress1}
                                        onChange={(e) => { setPAddress1(e.target.value) }}
                                    />
                                    <label>House No./Flat Block No.<span className="checkout__input--label__star">*</span></label>
                                    <small className="text-danger">{PAddress1Error}</small>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={PAddress2}
                                        onChange={(e) => { setPAddress2(e.target.value) }}
                                    />
                                    <label>Apartment </label>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable">  City</label>
                                    <select className="form-control" required=""
                                        onChange={(e) => setCity(e.target.value)} value={City}
                                    >
                                        <option value="0">-- Select City --</option>
                                        {
                                            (AllCity && AllCity.length > 0 ?
                                                AllCity.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    );
                                                }) : null
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable"> State</label>
                                    <select className="form-control" required=""
                                        onChange={(e) => setState(e.target.value)} value={State}

                                    >
                                        <option value="0">-- Select State --</option>
                                        {
                                            (AllState && AllState.length > 0 ?
                                                AllState.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    );
                                                }) : null
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>

                    <hr />
                    <br />
                    <div className="section__header mb-4">
                        <h6 className="section__header--title h3"> Communication Address Details</h6>
                    </div>
                    <div className="col-sm-12">
                        {/* Add a checkbox for "Same as Permanent Address" */}
                        {/* {console.log(sameAsPermanent)} */}
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={sameAsPermanent}
                                    onChange={handleSameAsPermanentChange}
                                />
                                {"  "}Same as Permanent Address
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable"> Country</label>
                                    <select className="form-control m--1" required=""
                                        onChange={(e) => {
                                            setCCountry(e.target.value);
                                            setCAddressApiCall(true)
                                            // getCCountryCode(e.target.value, e.target.selectedOptions[0].text) 
                                        }}
                                        value={CCountry}
                                        disabled={sameAsPermanent ? true : false}
                                    >
                                        <option value="">-- Select Country --</option>
                                        {
                                            (AllCCountry && AllCCountry.length > 0 ?
                                                AllCCountry.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id} >{item.name}</option>
                                                    );
                                                }) : null
                                            )
                                        }
                                    </select>
                                    <small className="text-danger">{CCountryError}</small>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={CPincode}
                                        onChange={(e) => {
                                            setCPincode(e.target.value);
                                            setCAddressApiCall(true)
                                            // GetCLocation(e.target.value.replace(/\D/g, '')) 
                                        }}
                                        disabled={sameAsPermanent ? true : false}
                                    />
                                    <label> Pincode</label>
                                    <small className="text-danger">{CPincodeError}</small>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={CAddress1}
                                        onChange={(e) => { setCAddress1(e.target.value) }}
                                        disabled={sameAsPermanent ? true : false}
                                    />
                                    <label>House No./Flat Block No.</label>
                                    <small className="text-danger">{CPAddress1Error}</small>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={CAddress2}
                                        onChange={(e) => { setCAddress2(e.target.value) }}
                                        disabled={sameAsPermanent ? true : false}
                                    />
                                    <label>Apartment</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable">  City</label>
                                    <select className="form-control" required=""
                                        onChange={(e) => setCCity(e.target.value)} value={CCity}
                                        disabled={sameAsPermanent ? true : false}
                                    >
                                        <option value="0">-- Select City --</option>
                                        {
                                            (AllCCity && AllCCity.length > 0 ?
                                                AllCCity.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    );
                                                }) : null
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable">  State</label>
                                    <select className="form-control" required=""
                                        onChange={(e) => setCState(e.target.value)} value={CState}
                                        disabled={sameAsPermanent ? true : false}
                                    >
                                        <option value="0">-- Select State --</option>
                                        {
                                            (AllCState && AllCState.length > 0 ?
                                                AllCState.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    );
                                                }) : null
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* MedicalConditions */}
                        <div className="row"></div>
                        <br />
                    </div>

                    <hr />
                    <br />
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Nationality}
                                        onChange={(e) => { setNationality(e.target.value) }}
                                    />
                                    <label> Nationality</label>
                                </div>
                            </div>

                            {/* <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <label className="select-lable">   Address Proof Type</label>
                                    <select className="form-control" required=""
                                        onChange={(e) => setAddressProofType(e.target.value)} value={AddressProofType}

                                    >
                                        {ProofType.map((group, index) => (
                                            <option key={index} value={index}>{group}</option>
                                        ))}
                                    </select>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="row">

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={AddressProofNo}
                                        onChange={(e) => { setAddressProofNo(e.target.value) }}
                                    />
                                    <label> Address Proof Number</label>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-4 mb-0">
                                    <input
                                        className="form-control mb-1"
                                        type="file"
                                        onChange={handleAddressImageChange}
                                        accept="image/*"
                                    />
                                    <span style={{ "float": "right" }}>
                                        {ShowAddressProofDoc ? <img src={AddressProofDoc} alt='' style={{ "height": "50px", "width": "50px" }} /> : null}
                                    </span>
                                    <label>Address Proof Document</label>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-center gap-3">
                                <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                    <div className="continue__shipping--btn secondary__btn border-radius-5"
                                    >
                                        <div onClick={() => props.setSection('address')}> Cancel</div>
                                    </div>

                                    <button className="continue__shipping--btn primary__btn border-radius-5" type="submit">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <form onSubmit={(e) => SubmitSocialMedia(e)}>
                <div className={(Section === 'edit-social-media-accounts' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none")}>
                    <div className="main checkout__mian ">
                        <div className="row  py-5">
                            <div className="section__header mb-4">
                                <h2 className="section__header--title h3">Digital Presence</h2>
                            </div>
                            <div className="col-sm-6 my-2">
                                <div className="profile-card">
                                    <div className="card-body p-5 text-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 48 48"
                                            width="50px"
                                            height="50px"
                                        >
                                            <path
                                                fill="#039be5"
                                                d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                                            />
                                            <path
                                                fill="#fff"
                                                d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                                            />
                                        </svg>
                                        <p className="h4">Facebook</p>
                                        <div className="checkout__email--phone my-3">
                                            <div className="form-floating mt-3 mb-0">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    value={FacebookLink}
                                                    onChange={(e) => { setFacebookLink(e.target.value) }}
                                                />
                                                <label>Facebook Link</label>
                                            </div>
                                            <small className="text-muted float-end">e.g. https://www.facebook.com/username</small>
                                            <small className="text-danger">{FacebookLinkError}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 my-2">
                                <div className="profile-card">
                                    <div className="card-body p-5 text-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 48 48"
                                            width="50px"
                                            height="50px"
                                        >
                                            <path
                                                fill="#03A9F4"
                                                d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                                            />
                                        </svg>
                                        <p className="h4">Twitter</p>
                                        <div className="checkout__email--phone my-3">
                                            <div className="form-floating mt-3 mb-0">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    value={TwitterLink}
                                                    onChange={(e) => { setTwitterLink(e.target.value) }}
                                                />
                                                <label>Twitter Link</label>
                                            </div>
                                            <small className="text-muted float-end">e.g. https://twitter.com/username</small>
                                            <small className="text-danger">{TwitterLinkError}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 my-2">
                                <div className="profile-card">
                                    <div className="card-body p-5 text-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 48 48"
                                            width="50px"
                                            height="50px"
                                        >
                                            <path
                                                fill="#0288D1"
                                                d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                            />
                                            <path
                                                fill="#FFF"
                                                d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                            />
                                        </svg>
                                        <p className="h4">Linkedin</p>
                                        <div className="checkout__email--phone my-3">
                                            <div className="form-floating mt-3 mb-0">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    value={LinkedInLink}
                                                    onChange={(e) => { setLinkedInLink(e.target.value) }}
                                                />
                                                <label>Linked In Link</label>
                                            </div>
                                            {/* Show help example for all 3 fields */}
                                            <small className="text-end text-muted float-end">e.g. https://www.linkedin.com/in/username</small>
                                            <small className="text-danger">{LinkedInLinkError}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-center gap-3">
                                <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                    <div className="continue__shipping--btn secondary__btn border-radius-5"
                                    >
                                        <div
                                            onClick={() => props.setSection('social-media-accounts')}
                                        > Cancel</div>
                                    </div>

                                    <button className="continue__shipping--btn primary__btn border-radius-5" type="submit">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            {/* HEALTH DETAILS */}
            <form>
                <div className={(Section === 'edit-health-details' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none")}>
                    <div className="section__header mb-4">
                        <h2 className="section__header--title h3"> Medical Profile</h2>
                        {/* Last Updated At : {UpdatedAt} */}
                    </div>

                    <div className="row p-0 mb-5 mt-4">
                        <h4 className="">Physical Attributes</h4>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">What is your blood group?</h4>
                                <div className="form-floating mb-0">
                                    <select className="form-control" required=""
                                        value={BloodGroup}
                                        onChange={(e) => setBloodGroup(e.target.value)}
                                    >
                                        <option value="0">-- Blood Group --</option>
                                        {bloodGroups.map((group, index) => (
                                            <option key={index} value={group}>{group}</option>
                                        ))}
                                    </select>
                                </div>
                                <small className="text-danger">{BloodGroupError}</small>
                            </div>

                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">What is your height(Cm)?</h4>
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Height}
                                        onChange={(e) => {
                                            setHeight(e.target.value);
                                        }}
                                    />
                                    <label>Height(Cm)<span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{HeightError}</small>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">What is your current weight(Kg)</h4>
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Weight}
                                        onChange={(e) => {
                                            setWeight(e.target.value);
                                        }}
                                    />
                                    <label>Weight(Kg)<span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{WeightError}</small>
                            </div>
                        </div>

                       
                    </div>

                    <div className="row p-0 mb-5 mt-4">
                        <h4 className="">Medical Conditions</h4>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Medical Conditions</h4>
                                <div className="form-floating mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={MedicalConditions}
                                        onChange={(e) => {
                                            setMedicalConditions(e.target.value);
                                        }}
                                    />
                                    <label>Medical Conditions<span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{MedicalConditionsError}</small>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Do you have diabetes?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Diabetes}
                                        checked={Diabetes}
                                        onChange={() => setDiabetes(!Diabetes)}
                                    />
                                    {/* <label className="form-check-label">Diabetes</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you experienced chest pain in the last 6 weeks?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={ChestPain}
                                        checked={ChestPain}
                                        onChange={() => setChestPain(!ChestPain)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you ever been diagnosed with angina?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Angina}
                                        checked={Angina}
                                        onChange={() => setAngina(!Angina)}
                                    />
                                    {/* <label className="form-check-label">Angina</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you experienced abnormal heart rhythms?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={AbnormalHeartRhythm}
                                        checked={AbnormalHeartRhythm}
                                        onChange={() => setAbnormalHeartRhythm(!AbnormalHeartRhythm)}
                                    />
                                    {/* <label className="form-check-label">Abnormal Heart Rhythm</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Do you have a pacemaker?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Pacemaker}
                                        checked={Pacemaker}
                                        onChange={() => setPacemaker(!Pacemaker)}
                                    />
                                    {/* <label className="form-check-label">Pacemaker</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you suffered from severe dehydration in the last 4 weeks?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={SevereDehydration}
                                        checked={SevereDehydration}
                                        onChange={() => setSevereDehydration(!SevereDehydration)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you experienced severe muscle cramps in the last 4 weeks?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={SevereMuscleCramps}
                                        checked={SevereMuscleCramps}
                                        onChange={() => setSevereMuscleCramps(!SevereMuscleCramps)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you been diagnosed with high blood pressure?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={HighBloodPressure}
                                        checked={HighBloodPressure}
                                        onChange={() => setHighBloodPressure(!HighBloodPressure)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you had episodes of low blood sugar in the last 4 weeks?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={LowBloodSugar}
                                        checked={LowBloodSugar}
                                        onChange={() => setLowBloodSugar(!LowBloodSugar)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you been diagnosed with epilepsy?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Epilepsy}
                                        checked={Epilepsy}
                                        onChange={() => setEpilepsy(!Epilepsy)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Do you have any bleeding disorders?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={BleedingDisorders}
                                        checked={BleedingDisorders}
                                        onChange={() => setBleedingDisorders(!BleedingDisorders)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Do you suffer from asthma?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Asthma}
                                        checked={Asthma}
                                        onChange={() => setAsthma(!Asthma)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you been diagnosed with anemia?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Anemia}
                                        checked={Anemia}
                                        onChange={() => setAnemia(!Anemia)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you been hospitalized recently?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Hospitalized}
                                        checked={Hospitalized}
                                        onChange={(e) => setHospitalized(!Hospitalized)}
                                    />
                                </div>
                            </div>
                        </div>
                        {Hospitalized ? (
                            <div className="col-sm-6 my-3">
                                <div className='profile-card text-center p-3'>
                                    <h4 className="m-4">Hospitalized Details</h4>
                                    <div className="form-floating mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={HospitalizedDetail}
                                            onChange={(e) => {
                                                setHospitalizedDetail(e.target.value);
                                            }}
                                        />
                                        <label>If Yes details of Hospital</label>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Are you currently experiencing any infections?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Infections}
                                        checked={Infections}
                                        onChange={(e) => setInfections(!Infections)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Are you pregnant?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={Pregnant}
                                        checked={Pregnant}
                                        onChange={(e) => setPregnant(!Pregnant)}
                                    />
                                    {/* <label className="form-check-label">Pregnant</label> */}
                                </div>
                            </div>
                        </div>
                        {Pregnant ? (
                            <div className="col-sm-6 my-3">
                                <div className='profile-card text-center p-3'>
                                    <h4 className="m-4">Stage of Pregnancy</h4>
                                    <div className="form-floating mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={PregnantDetail}
                                            onChange={(e) => {
                                                setPregnantDetail(e.target.value);
                                            }}
                                        />
                                        <label>If Yes Stage of Pregnancy</label>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Have you suffered from Covid-19?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={CovidStatus}
                                        checked={CovidStatus}
                                        onChange={(e) => setCovidStatus(!CovidStatus)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row p-0 mb-5 mt-4">
                        <h4 className="">Medication and Allergies</h4>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Are you currently under any medication?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={UnderMedication}
                                        checked={UnderMedication}
                                        onChange={(e) => setUnderMedication(!UnderMedication)}
                                    />
                                </div>
                            </div>
                        </div>
                        {UnderMedication ? (
                            <div className="col-sm-6 my-3">
                                <div className='profile-card text-center p-3'>
                                    <h4 className="m-4">Details of Medication</h4>
                                    <div className="form-floating mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={MedicationDetail}
                                            onChange={(e) => {
                                                setMedicationDetail(e.target.value);
                                            }}
                                        />
                                        <label>If Yes details of Medication</label>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Current Medications</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={CurrentMedications}
                                        checked={CurrentMedications}
                                        onChange={(e) => setCurrentMedications(!CurrentMedications)}
                                    />
                                </div>
                            </div>
                        </div>
                        {CurrentMedications ? (
                            <div className="col-sm-6 my-3">
                                <div className='profile-card text-center p-3'>
                                    <h4 className="m-4">Details of Medication</h4>
                                    <div className="form-floating mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={CurrentMedicationsDetail}
                                            onChange={(e) => {
                                                setCurrentMedicationsDetail(e.target.value);
                                            }}
                                        />
                                        <label>Mention Names</label>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Do you have any known allergies?</h4>
                                <div className="form-floating mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Allergies ? Allergies : ""}
                                        onChange={(e) => {
                                            setAllergies(e.target.value);
                                        }}
                                    />
                                    <label>Allergies</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Do you have any known drug allergies?</h4>
                                <div className="form-check form-switch d-flex align-items-center justify-content-center my-4 py-2">
                                    {/* checked */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={DrugAllergy}
                                        checked={DrugAllergy}
                                        onChange={(e) => setDrugAllergy(!DrugAllergy)}
                                    />
                                </div>
                            </div>
                        </div>
                        {DrugAllergy ? (
                            <div className="col-sm-6 my-3">
                                <div className='profile-card text-center p-3'>
                                    <h4 className="m-4">Details of Drug Allergy</h4>
                                    <div className="form-floating mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={AllergyDetail ? AllergyDetail : ""}
                                            onChange={(e) => {
                                                setAllergyDetail(e.target.value);
                                            }}
                                        />
                                        <label>If Yes details of Drug Allergy</label>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>



                    <div className="row p-0 mb-5 mt-4">
                        <h4 className="">Healthcare Providers</h4>

                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Please provide the name of your family doctor: </h4>
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={DoctorName}
                                        onChange={(e) => {
                                            setDoctorName(e.target.value);
                                        }}
                                    />
                                    <label>Family Doctor Name</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center p-3'>
                                <h4 className="m-4">Please provide the contact number of your family doctor (excluding 0 or +91):</h4>
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={DoctorContactNumber}
                                        onChange={(e) => {
                                            setDoctorContactNumber(e.target.value);
                                        }}
                                    />
                                    <label>Family Doctor Contact Number</label>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className={(Section === 'edit-health-details' ? "col-sm-12 d-flex justify-content-center gap-3" : "col-sm-12 d-flex justify-content-center gap-3 d-none")}>
                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                        <div className="continue__shipping--btn secondary__btn border-radius-5"
                        >
                            <div onClick={(e) => props.setSection('health-details')}> Cancel</div>
                        </div>

                        <button className="continue__shipping--btn primary__btn border-radius-5" type="submit" onClick={(e) => {
                            handleMedicalSubmit(e)
                        }}>Save</button>
                    </div>
                </div>

            </form>

            {/* <form onSubmit={(e) => SubmitCommunicationSettings(e)}>
                <div className={(Section === 'edit-communication_settings' ? "row justify-content-center py-5 communication-s" : "row justify-content-center py-5 communication-s d-none")}>

                    <div className="section__header mb-4">
                        <h2 className="section__header--title h3">Communication Settings</h2>
                    </div>
                    <div className="col-sm-4 my-2">
                        <div className="profile-card">
                            <div className="card-body p-5 text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={50}
                                    viewBox="0 -960 960 960"
                                    width={50}
                                >
                                    <path d="M80-560q0-100 44.5-183.5T244-882l47 64q-60 44-95.5 111T160-560H80Zm720 0q0-80-35.5-147T669-818l47-64q75 55 119.5 138.5T880-560h-80ZM160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" />
                                </svg>
                                <p className="h4">Email notification frequency</p>
                                <div className="form-check form-switch d-flex align-items-left justify-content-left my-5">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={EmailNotification}
                                        checked={EmailNotification}
                                        onChange={() => setEmailNotification(!EmailNotification)}
                                    />
                                    <label className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked"
                                    >
                                        Display same name
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 my-2">
                        <div className="profile-card">
                            <div className="card-body p-5 text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={50}
                                    viewBox="0 -960 960 960"
                                    width={50}
                                >
                                    <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                                </svg>
                                <p className="h4">Support Email Id </p>
                                <div className="checkout__email--phone my-3">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            placeholder=""
                                            type="text"
                                            value={SupportEmailId}
                                            onChange={(e) => { setSupportEmailId(e.target.value) }}
                                        />
                                        <label>Support Email Id</label>
                                    </div>
                                    <small className="text-danger">{SupportEmailIdError}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 my-2">
                        <div className="profile-card">
                            <div className="card-body p-5 text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={50}
                                    viewBox="0 -960 960 960"
                                    width={50}
                                >
                                    <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                                </svg>
                                <p className="h4">Support Phone No.</p>
                                <div className="checkout__email--phone my-3">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            placeholder=""
                                            type="text"
                                            value={SupportMobile}
                                            onChange={(e) => { setSupportMobile(e.target.value) }}
                                        />
                                        <label>Support Phone No.</label>
                                    </div>
                                    <small className="text-danger">
                                        {SupportMobileError}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 d-flex justify-content-center gap-3">
                            <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                <div className="continue__shipping--btn secondary__btn border-radius-5"
                                >
                                    <div
                                        onClick={() => props.setSection('communication_settings')}
                                    > Cancel</div>
                                </div>

                                <button className="continue__shipping--btn primary__btn border-radius-5" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form> */}

            {/* <form onSubmit={(e) => SubmitFamilyMembers(e)}>
                <div className={(Section === 'edit-family-members' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none")}>
                    <div className="section__header d-flex align-items-center justify-content-between mb-4">
                        <h2 className="section__header--title h3">Edit Family Members</h2>
                    </div>
                    <div className="col-sm-12">
                        <div className="profile-card">
                            <div className="card-body p-5">
                                <div className="row align-items-center">

                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label
                                                        className="checkout__input--label mb-2">
                                                        Relation
                                                        <span className="checkout__input--label__star">*</span>
                                                    </label>
                                                    <input
                                                        className="checkout__input--field border-radius-5"
                                                        placeholder="Enter Relation"
                                                        type="text"
                                                        value={Relation}
                                                        onChange={(e) => setRelation(e.target.value)}
                                                    />
                                                  
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label
                                                        className="checkout__input--label mb-2">
                                                        Firstname{" "}
                                                        <span className="checkout__input--label__star">*</span>
                                                    </label>
                                                    <input
                                                        className="checkout__input--field border-radius-5"
                                                        placeholder="Enter Firstname"
                                                        type="text"
                                                        value={RelFirstname}
                                                        onChange={(e) => setRelFirstname(e.target.value)}
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label className="checkout__input--label mb-2">
                                                        Lastname{" "}
                                                        <span className="checkout__input--label__star">*</span>
                                                    </label>
                                                    <input
                                                        className="checkout__input--field border-radius-5"
                                                        placeholder="Enter Lastname"
                                                        type="text"
                                                        value={RelLastname}
                                                        onChange={(e) => setRelLastname(e.target.value)}
                                                    />
                                                 
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label
                                                        className="checkout__input--label mb-2">
                                                        Mobile Number{" "}
                                                        <span className="checkout__input--label__star">*</span>
                                                    </label>
                                                    <input
                                                        className="checkout__input--field border-radius-5"
                                                        placeholder="Enter Mobile Number"
                                                        type="text"
                                                        value={RelMobile}
                                                        onChange={(e) => setRelMobile(e.target.value)}
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label
                                                        className="checkout__input--label mb-2">
                                                        Email ID{" "}
                                                        <span className="checkout__input--label__star">*</span>
                                                    </label>
                                                    <input
                                                        className="checkout__input--field border-radius-5"
                                                        placeholder="Enter Email ID"
                                                        type="text"
                                                        value={RelEmail}
                                                        onChange={(e) => { setRelEmail(e.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label
                                                        className="checkout__input--label mb-2">
                                                        Gender{" "}
                                                        <span className="checkout__input--label__star">*</span>
                                                    </label>

                                                    <select className="checkout__input--field border-radius-5" value={RelGender} onChange={(e) => { setRelGender(e.target.value) }}>
                                                        <option value={0} selected={RelGender === 0}>Select Gender</option>
                                                        <option value={1} selected={RelGender === 1}>Male</option>
                                                        <option value={2} selected={RelGender === 2}>Female</option>
                                                        <option value={3} selected={RelGender === 3}>Other</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label
                                                        className="checkout__input--label mb-2">
                                                        Date Of Birth{" "}
                                                        <span className="checkout__input--label__star">*</span>
                                                    </label>

                                                    <input className="checkout__input--field border-radius-5" placeholder="DD-MM-YYYY" type="date" id='DOB' value={RelDOB} onChange={(e) => {
                                                        setRelDOB(e.target.value)
                                                    }} max={new Date().toISOString().split('T')[0]} />
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label className="checkout__input--label mb-2">
                                                        Id Proof Type
                                                    </label>
                                                    <select className="checkout__input--field border-radius-5" onChange={(e) => setRelIdProofType(e.target.value)}>
                                                        {ProofType.map((group, index) => (
                                                            <option key={index} value={index} selected={parseInt(RelIdProofType) === index}>{group}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label className="checkout__input--label mb-2">
                                                        Id Proof Number
                                                    </label>
                                                    <input
                                                        className="checkout__input--field border-radius-5"
                                                        placeholder="Enter Id Proof Number"
                                                        type="text"
                                                        value={RelIdProofNo}
                                                        onChange={(e) => setRelIdProofNo(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="checkout__email--phone mb-20">
                                                    <label className="checkout__input--label mb-2">
                                                        Id Proof Document
                                                    </label>

                                                    <input
                                                        type="file"
                                                        onChange={handleRelImageChange}
                                                        accept="image/*"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-center gap-3">
                                                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                                        <div className="continue__shipping--btn secondary__btn border-radius-5"
                                                        >
                                                            <div
                                                                onClick={() => props.setSection('general-details')}
                                                            > Cancel</div>
                                                        </div>

                                                        <button className="continue__shipping--btn primary__btn border-radius-5" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form> */}


        </>

    )
}

export default EditMyProfile