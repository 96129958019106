import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
// import { useParams, useNavigate } from 'react-router-dom';
import validator from 'validator'
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BasicInformation = (props) => {

  //const navigate = useNavigate();

  const [EventName, setEventName] = useState('');
  // const [DisplayName, setDisplayName] = useState('');
  const [EventUrl, setEventUrl] = useState('');
  const [DisplayNameStatus, setDisplayNameStatus] = useState(false);
  const [EventCategoryAll, setEventCategoryAll] = useState([]);
  const [EventTypeAll, setEventTypeAll] = useState([]);
  const [EventInfoStatus, setEventInfoStatus] = useState(3);
  const [Loader, setLoader] = useState(false);

  const [EventPublicStatus, setEventPublicStatus] = useState('');
  const [EventPrivateStatus, setEventPrivateStatus] = useState('');
  const [EventDraftStatus, setEventDraftStatus] = useState('active');
  const [TypeApiCall, setTypeApiCall] = useState(true)

  // const eventNameRef = useRef();
  // const displayNameRef = useRef();
  // const eventUrlRef = useRef();

  const [EventNameError, setEventNameError] = useState('');
  // const [EventDisplayNameError, setEventDisplayNameError] = useState('');
  const [EventTypeError, setEventTypeError] = useState('');
  const [EventCategoryError, setEventCategoryError] = useState('');
  const [EventUrlError, setEventUrlError] = useState('');

  const [EventCategoryChk, setEventCategoryChk] = useState([]);
  const [EventTypeChk, setEventTypeChk] = useState([]);
  // console.log(EventCategoryChk); 

  const EventDetailsData = props.EventData ? props.EventData : '';
  const EventCategoryData = props.EventCategoryData ? props.EventCategoryData : '';
  const EventTypeData = props.EventTypeData ? props.EventTypeData : '';
  // console.log(EventDetailsData.event_info_status); 

  const LoginAsOrganiserFlag = localStorage.getItem("LoginAsOrganiser") ? localStorage.getItem("LoginAsOrganiser") : '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (EventDetailsData) {

      if (EventDetailsData.event_info_status === 1) {
        setEventPublicStatus('active'); setEventPrivateStatus(''); setEventDraftStatus('');
      } else if (EventDetailsData.event_info_status === 2) {
        setEventPublicStatus(''); setEventPrivateStatus('active'); setEventDraftStatus('');
      } else if (EventDetailsData.event_info_status === 2) {
        setEventPublicStatus(''); setEventPrivateStatus(''); setEventDraftStatus('active');
      } else {
        setEventDraftStatus('active');
      }

      setEventInfoStatus(EventDetailsData.event_info_status);
      setEventName(EventDetailsData.name ? EventDetailsData.name : '');
      // setDisplayName(EventDetailsData.display_name ? EventDetailsData.display_name : '');
      // EventDetailsData.display_name_status === 1 ? setDisplayNameStatus(true) : setDisplayNameStatus(false);
      setEventUrl(EventDetailsData.event_url ? EventDetailsData.event_url : '');
    }

    setEventCategoryChk(EventCategoryData);
    setEventTypeChk(EventTypeData);
  }, [EventCategoryData, EventDetailsData, EventTypeData])

  useEffect(() => {
    axios.get("category")
      .then((response) => {
        //console.log(response.data.data.Allcategory);
        if (response.data.status === 200) {
          setEventCategoryAll(response.data.data.Allcategory);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  const handleTypeChange = (index, evnt) => {
    //console.log(index);
    let TypeArray = [...EventTypeChk];
    TypeArray[index]['checked'] = evnt.target.checked;
    setEventTypeChk(TypeArray);
  }

  //console.log(EventInfoStatus);
  const handleCatChange = (index, evnt) => {
    // console.log(index);
    let CategoryArray = [...EventCategoryChk];
    CategoryArray[index]['checked'] = evnt.target.checked;
    setEventCategoryChk(CategoryArray);

    // const { name, value,checked } = evnt.target;
    // console.log(index,value,checked);
    // const rowsCatData = {};
    // const rowsCategoryInput = [...CategoryArray, rowsCatData];
    // rowsCategoryInput[index][name] = value;
    // // console.log(rowsContactInput);
    // setCategoryArray(rowsCategoryInput);
    //console.log(evnt.target.checked)
    // console.log(index);

    // const { value, checked } = evnt.target;
    // console.log(index,value,checked);
    // const { eventCategory } = CategoryArray;

    // if (checked) {
    //   setCategoryArray({
    //     eventCategory: [...eventCategory, value],
    //   });
    // } else {
    //   setCategoryArray({
    //       eventCategory: eventCategory.filter(
    //             (e) => e !== value
    //         ),
    //     });
    // }

  }

  useEffect(() => {
    if (TypeApiCall) {
      axios.get("types")
        .then((response) => {
          //console.log(response.data.data.AllEventTypes);
          if (response.data.status === 200) {
            setEventTypeAll(response.data.data.AllEventTypes);
            setTypeApiCall(false)
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [TypeApiCall])

  const handleValidation = () => {
    //console.log(BorrowingGuaranteed);
    var flag = true;

    if (EventName === "") {
      //eventNameRef.current.focus();
      setEventNameError("Please enter event name");
      flag = false;
    } else {
      setEventNameError("");
    }

    // if(DisplayNameStatus === false){
    //   if (DisplayName === "") {
    //     //eventNameRef.current.focus();
    //     setEventDisplayNameError("Please enter display event name");
    //     flag = false;  
    //   }else{
    //     setEventDisplayNameError("");
    //   }
    // }else{
    //   setEventDisplayNameError("");
    // }

    // var error_type_flag = 0;
    // for (var i = 0; i < EventTypeChk.length; i++) {
    //   //console.log(EventTypeChk[i]['checked']);
    //   if (EventTypeChk[i]['checked'] === true) {
    //     error_type_flag = 1;
    //   }
    // }
    // //console.log(error_flag);
    // if (error_type_flag === 0) {
    //   setEventTypeError("Please select event type");
    //   flag = false;
    // } else {
    //   setEventTypeError("");
    // }

    var error_cat_flag = 0;
    for (var j = 0; j < EventCategoryChk.length; j++) {
      if (EventCategoryChk[j]['checked'] === true) {
        error_cat_flag = 1;
      }
    }
    //console.log(error_cat_flag);
    if (error_cat_flag === 0) {
      //eventNameRef.current.focus();
      setEventCategoryError("Please select event category");
      flag = false;
    } else {
      setEventCategoryError("");
    }

    // if (EventUrl === "") {
    //   //eventNameRef.current.focus();
    //   setEventUrlError("Please enter event url");
    //   flag = false;
    // }
    // else if (EventUrl !== "" && !validator.isURL(EventUrl)) {
    //   setEventUrlError('Please enter valid url');
    //   flag = false;
    // }
    // else {
    //   setEventUrlError("");
    // }
    return flag;
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      setLoader(true);
      const payload = {
        event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
        event_info_status: EventInfoStatus ? EventInfoStatus : 3,
        event_name: EventName,
        display_name_status: DisplayNameStatus ? 1 : 0,
        // display_name: DisplayName,
        event_url: '',
        event_type: localStorage.getItem("EventType"),
        // category_id: CategoryArray ? Object.assign({}, CategoryArray.eventCategory) : [],
        category_id: EventCategoryChk,
        event_types: EventTypeChk,
        created_by: localStorage.getItem("ID"),
        by_admin: LoginAsOrganiserFlag
      };
      //console.log(payload);
      axios.post("create_event", payload)
        .then((response) => {
          setLoader(false);
          //console.log(response.data.data.event_id);
          if (response.status === 200) {
           
            // props.setEventBasicInfoMsg(response.data.message);
            if(response.data.data.event_id !== 0){
              props.setEventResponceTabs(1);
              toast.success(response.data.message)
              localStorage.setItem("EventId", response.data.data.event_id);
            }else{
              props.setEventResponceTabs(0);
              toast.error(response.data.message)
            }
           
          }
        })
        .catch(() => {
          setLoader(false);
          console.log('error');
        });
    }
  };

  const handleChange = (e) => {
   
    // if (e.target.value.includes('_') || e.target.value.includes('/')) {
    //   const filteredValue = e.target.value.replace(/[_/]/g, '');
    //   setEventName(filteredValue);
    // } else {
    //   setEventName(e.target.value);
    // }

    const filteredValue = e.target.value.replace(/[^a-zA-Z0-9\-@.'"\s]/g, '');
    // Update the state with the filtered value
    setEventName(filteredValue);

  };


  // console.log(EventName);
  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
      <div className="main checkout__mian">
        <form method="post" onSubmit={handleSubmit} >
          <div className="checkout__content--step section__shipping--address ">
            <div className="mb-5">
              <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">

                <h2 className="section__header--title h3">Event Essentials</h2>
                  <div className="ebi">
                    <div className="event-visibility hand_cursor">
                      <div className={'visibility gap-1 ' + EventPublicStatus} onClick={() => { setEventInfoStatus(1); setEventPublicStatus('active'); setEventPrivateStatus(''); setEventDraftStatus(''); }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={20}
                          viewBox="0 -960 960 960"
                          width={20}
                        >
                          <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                        </svg>
                        Public
                      </div>

                      <div className={'visibility gap-1 ' + EventPrivateStatus} onClick={() => { setEventInfoStatus(2); setEventPublicStatus(''); setEventPrivateStatus('active'); setEventDraftStatus(''); }} >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={20}
                          viewBox="0 -960 960 960"
                          width={20}
                        >
                          <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                        </svg>
                        Private
                      </div>

                      <div className={'visibility gap-1 ' + EventDraftStatus} onClick={() => { setEventInfoStatus(3); setEventPublicStatus(''); setEventPrivateStatus(''); setEventDraftStatus('active'); }} >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={20}
                          viewBox="0 -960 960 960"
                          width={20}
                        >
                          <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                        </svg>
                        Draft
                      </div>

                    </div>
                  </div>
              </div>

              <div className="row mb-5">
                <div className="col-sm-12">
                  <div className="form-floating mt-3 mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={EventName}
                      // onChange={(e) => { setEventName(e.target.value); }}
                      onChange={handleChange} 
                      maxLength={100}
                    />
                    <label>Event Name <span className="checkout__input--label__star">*</span> <small style={{color: 'gray'}}>(Allowed only this special characters -, @, ., ', ")</small></label>
                  </div>
                  <small className="text-danger">{EventNameError}</small>
                </div>

                {/* <div className="col-sm-6">
                  <div className="form-floating mt-3 mb-0">
                    <input
                      type="text"
                      className="form-control"

                      placeholder=""
                    />
                    <label>Display Event Name<span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">Please enter display Event name</small>
                </div> */}

                {/* <div className="col-sm-6">
                  <div className="form-floating mt-3 mb-0">

                    <label className="select-lable"> Select Event type<span className="checkout__input--label__star">*</span></label>

                    <select className="form-control" required="">
                      <option value="">Choose...</option>
                      <option>California</option>
                    </select>

                    <small className="text-danger">{EventTypeError}</small>

                  </div>

                </div> */}

                {/* <div className="col-sm-6">
                  <div className="form-floating mt-3 mb-0">
                    <input
                      className="form-control"
                      type="date"

                      name="event-date"
                    />
                    <label className="select-label">Event date<span className="checkout__input--label__star">*</span></label>
                    <small className="text-danger">Please enter Event date</small>
                  </div>
                </div> */}

                {/* <div className="col-sm-6">
                  <div className="form-floating mt-3 mb-0">
                    <textarea
                      type="text"
                      className="form-control"

                      placeholder=""
                      style={{ minHeight: "100px", display: "inline-block", resize: "both" }}
                    />
                    <label >Event address<span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">Please enter event address</small>
                </div> */}
              </div>

            </div>
            {/* START : Event Category */}
            <div className="mb-5">
              {/* END: Event Name */}
              {/* <div className="section__header mb-4">
                <h2 className="section__header--title h3">Event Category</h2>
              </div> */}

              <div className="row">

                {/* <div className="col-lg-12 col-md-12 mb-20">
                  <label className="checkout__input--label mb-3" for="input1">
                    Event Type <span className="checkout__input--label__star">*</span>
                  </label>

                  <div className="row mb-3">
                    {EventTypeChk ?
                      EventTypeChk.map((item, index) => {
                        var digit1 = index + 1;
                        return (
                          <div className="col-sm-3 my-2" key={index}>
                            <div className="plans">
                              <label className="plan basic-plan">
                                <input name="event_type"
                                  type="radio"
                                  className="btn-check"
                                  id={'btn-check-outlined' + digit1}
                                  autoComplete="off"
                                  defaultValue={item.id}
                                  onClick={(evnt) => { handleTypeChange(index, evnt); }}
                                  defaultChecked={item.checked}
                                />
                                <div className="plan-content">
                                  <img loading="lazy" src="https://raw.githubusercontent.com/ismailvtl/ismailvtl.github.io/master/images/life-saver-img.svg" alt="" />
                                  <div className="plan-details" for={'btn-check-outlined' + digit1}>
                                    <span className="h3 m-0"> {item.name}</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        );
                      }) : null}
                  </div>

                  <small className="text-danger">{EventTypeError}</small>

                </div> */}

               
                <div className="col-lg-12 col-md-12 mb-20">
                  <label className="checkout__input--label mb-3" htmlFor="input1">
                    Event Category <span className="checkout__input--label__star">*</span>
                  </label>


                  <div className="row mb-3">
                    {EventCategoryChk ?
                      EventCategoryChk.map((item, index) => {
                        var digit = index + 1;
                        return (
                          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3 my-2 my-2" key={index}>
                            <div className="plans">
                              <label className="plan basic-plan">
                                <input name={item.id}
                                  type="checkbox"
                                  className="btn-check"
                                  id={'btn-check-outlined-' + digit}
                                  autoComplete="off"
                                  onClick={(evnt) => { handleCatChange(index, evnt); }}
                                  defaultChecked={item.checked}
                                />
                                <div className="plan-content">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={25}
                                    viewBox="0 -960 960 960"
                                    width={25}
                                  >
                                    <path d={item.logo} />
                                  </svg>
                                  <div className="plan-details" htmlFor={'btn-check-outlined' + digit}>
                                    <span className="h3 m-0"> {item.name}</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        );
                      }) : null}
                  </div>

                  <small className="text-danger">
                    {EventCategoryError}
                  </small>

                </div>

                {/* <div className="col-sm-12">
                  <div className="form-floating mt-3 mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={EventUrl}
                      onChange={(e) => {
                        setEventUrl(e.target.value);
                      }}
                    />
                    <label>Event URL<span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger"> {EventUrlError}</small>
                </div> */}

              </div>

            </div>
            {/* END: Event Category */}
          </div>
          <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            {/* <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              href="index.php"
            >
              Cancel
            </div> */}

            <input type="submit" name="command" value="Save & Next (1/11)" className="continue__shipping--btn primary__btn border-radius-5" />

          </div>
        </form>
      </div>

    </>
  )
}

export default BasicInformation;

