import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import HeaderSecond from '../HeaderSecond';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import cardBg from '../img/other/card-bg.jpg'
import NoEventImg from '../img/events/no-events.png'

import trustedBrand from '../img/other/trusted-brand.png'
import targetedOutreach from '../img/other/targeted-outreach.png'
import wideRecognition from '../img/other/wide-recognition.png'
import effectivePromotions from '../img/other/effective-promotions.png'
import completeServices from '../img/other/complete-services.png'
import promptSupport from '../img/other/prompt-support.png'
import promoteYourEvent from '../img/other/promote-your-event.png'
import personalizedExperience from '../img/other/personalized-experience.png'
import dataDrivenMetrics from '../img/other/data-driven-metrics.png'

import startedEvent from '../img/other/started-event.png'
import registrationForm from '../img/other/registration-form.png'
import enhance from '../img/other/enhance.png'
import liveTickets from '../img/other/live-tickets.png'
import handleAttendees from '../img/other/handle-attendees.png'


const HowItWorks = () => {
    
    const navigate = useNavigate()
    const common_class = 'p-4 pb-2 item';
    const [WorksTabs, setWorksTabs] = useState(1);
    const [CreateEvent, setCreateEvent] = useState(common_class);
    const [RegistrationForm, setRegistrationForm] = useState(common_class);
    const [PersonaliseEvent, setPersonaliseEvent] = useState(common_class);
    const [GoLive, setGoLive] = useState(common_class);
    const [ManageRegistration, setManageRegistration] = useState(common_class);
    
    const show_div_class = 'row p-5 my-5';
    const d_none_class = 'd-none'
    const [DisplayCreateEvent, setDisplayCreateEvent] = useState(common_class);
    const [DisplayRegistrationForm, setDisplayRegistrationForm] = useState(common_class);
    const [DisplayPersonaliseEvent, setDisplayPersonaliseEvent] = useState(common_class);
    const [DisplayGoLive, setDisplayGoLive] = useState(common_class);
    const [DisplayManageRegistration, setDisplayManageRegistration] = useState(common_class);

    const HandleBack = (e) => {
        const CountryCode = localStorage.getItem("CountryCode");
        const CityName = localStorage.getItem("City") ? localStorage.getItem("City") : localStorage.getItem("Country");
        navigate("/" + CountryCode + "/" + CityName);
    }
   
    useEffect(() => {
        if(WorksTabs === 1){
            setCreateEvent(common_class+' active'); setRegistrationForm(common_class); 
            setPersonaliseEvent(common_class); setGoLive(common_class); setManageRegistration(common_class);
           
            setDisplayCreateEvent(show_div_class);
            setDisplayRegistrationForm(show_div_class+' '+d_none_class);
            setDisplayPersonaliseEvent(show_div_class+' '+d_none_class);
            setDisplayGoLive(show_div_class+' '+d_none_class);
            setDisplayManageRegistration(show_div_class+' '+d_none_class);

        }else if(WorksTabs === 2){
            setRegistrationForm(common_class+' active'); setCreateEvent(common_class);
            setPersonaliseEvent(common_class); setGoLive(common_class); setManageRegistration(common_class);

            setDisplayCreateEvent(show_div_class+' '+d_none_class);
            setDisplayRegistrationForm(show_div_class);
            setDisplayPersonaliseEvent(show_div_class+' '+d_none_class);
            setDisplayGoLive(show_div_class+' '+d_none_class);
            setDisplayManageRegistration(show_div_class+' '+d_none_class);

        } else if(WorksTabs === 3){
            setPersonaliseEvent(common_class+' active'); setRegistrationForm(common_class); 
            setGoLive(common_class); setManageRegistration(common_class); setCreateEvent(common_class);

            setDisplayCreateEvent(show_div_class+' '+d_none_class);
            setDisplayRegistrationForm(show_div_class+' '+d_none_class);
            setDisplayPersonaliseEvent(show_div_class);
            setDisplayGoLive(show_div_class+' '+d_none_class);
            setDisplayManageRegistration(show_div_class+' '+d_none_class);
        } else if(WorksTabs === 4){
            setGoLive(common_class+' active'); setRegistrationForm(common_class); setCreateEvent(common_class); 
            setPersonaliseEvent(common_class); setManageRegistration(common_class);

            setDisplayCreateEvent(show_div_class+' '+d_none_class);
            setDisplayRegistrationForm(show_div_class+' '+d_none_class);
            setDisplayPersonaliseEvent(show_div_class+' '+d_none_class);
            setDisplayGoLive(show_div_class);
            setDisplayManageRegistration(show_div_class+' '+d_none_class);
        } else if(WorksTabs === 5){
            setManageRegistration(common_class+' active'); setRegistrationForm(common_class); setCreateEvent(common_class); setPersonaliseEvent(common_class); setGoLive(common_class);

            setDisplayCreateEvent(show_div_class+' '+d_none_class);
            setDisplayRegistrationForm(show_div_class+' '+d_none_class);
            setDisplayPersonaliseEvent(show_div_class+' '+d_none_class);
            setDisplayGoLive(show_div_class+' '+d_none_class);
            setDisplayManageRegistration(show_div_class);
        } 
    }, [WorksTabs]);

    return (
        <>
            <HeaderSecond />

            <main className="main__content_wrapper">

                {/* <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        Explore Events
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => HandleBack(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">Search Event</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="section--padding py-5">
                    <div className="container">
                        <div className="text-center py-5">
                            <h3>Why Choose RACES?</h3>
                        </div>
                        <div className="row why-choose my-4 justify-content-center">
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4'>
                                <div className="card p-5" style={{ backgroundImage: `url(${cardBg})` }}>
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={trustedBrand} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Trusted Brand</h4>
                                            <p>We're a well-known name in the running events industry, carefully selecting partnerships</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4'>
                                <div className="card p-5" style={{ backgroundImage: `url(${cardBg})` }}>
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={targetedOutreach} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Targeted Outreach</h4>
                                            <p>We offer comprehensive marketing solutions that enable targeted outreach, ensuring your event reaches the right audience with precision and efficiency.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4'>
                                <div className="card p-5" style={{ backgroundImage: `url(${cardBg})` }}>
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={wideRecognition} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Wide Recognition</h4>
                                            <p>With deep connections in the industry, we are widely recognized within the running community.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4'>
                                <div className="card p-5" style={{ backgroundImage: `url(${cardBg})` }}>
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={effectivePromotions} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Effective Promotions</h4>
                                            <p>Our non-spam approach leads to better engagement and responses to event promotions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4'>
                                <div className="card p-5" style={{ backgroundImage: `url(${cardBg})` }}>
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={completeServices} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Complete Services</h4>
                                            <p>Partnering with RACES gives you access to a full suite of services for a successful running event.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="title text-center">Let the professionals at RACES handle the expertise, so you can focus on making your event a success!</h3>
                    </div>
                </section>


                <section className="section--padding py-5 walkthrough">
                    <div className="container text-center">
                        <div className="text-center py-5">
                            <h3>How We works</h3>
                        </div>
                        <div className="quickview__inner">

                            <div className="row">
                                <div className="container" style={{ overflowX: "auto", whiteSpace: "nowrap", display: "flex", justifyContent: "space-evenly", borderBottom: "1px solid #666666" }}>
                                  
                                    <div className={CreateEvent} onClick={() => { setWorksTabs(1); }}>
                                        <p className='mb-3'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="40px">
                                                <path d="M680-80v-120H560v-80h120v-120h80v120h120v80H760v120h-80Zm-480-80q-33 0-56.5-23.5T120-240v-480q0-33 23.5-56.5T200-800h40v-80h80v80h240v-80h80v80h40q33 0 56.5 23.5T760-720v244q-20-3-40-3t-40 3v-84H200v320h280q0 20 3 40t11 40H200Zm0-480h480v-80H200v80Zm0 0v-80 80Z" />
                                            </svg>
                                        </p>
                                        <p className="title mb-3">CREATE YOUR EVENT</p>
                                    </div>

                                    <div className={RegistrationForm} onClick={() => { setWorksTabs(2); }} >
                                        <p className='mb-3'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="40px">
                                                <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h360v80H200v560h560v-360h80v360q0 33-23.5 56.5T760-120H200Zm120-160v-80h320v80H320Zm0-120v-80h320v80H320Zm0-120v-80h320v80H320Zm360-80v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Z" />
                                            </svg>
                                        </p>
                                        <p className="title mb-3">CREATE YOUR REGISTRATION FORM</p>
                                    </div>

                                    <div className={PersonaliseEvent} onClick={() => { setWorksTabs(3); }} >
                                        <p className='mb-3'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="40px">
                                                <path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z" />
                                            </svg>
                                        </p>
                                        <p className="title mb-3">PERSONALISE YOUR EVENT</p>
                                    </div>

                                    <div className={GoLive} onClick={() => { setWorksTabs(4); }}>
                                        <p className='mb-3'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="40px">
                                                <path d="m380-340 280-180-280-180v360Zm-60 220v-80H160q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v80H320ZM160-280h640v-480H160v480Zm0 0v-480 480Z" />
                                            </svg>
                                        </p>
                                        <p className="title mb-3">GO LIVE!</p>
                                    </div>

                                    <div className={ManageRegistration} onClick={() => { setWorksTabs(5); }}>
                                        <p className='mb-3'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="40px">
                                                <path d="M300-360h60v-160h-60v50h-60v60h60v50Zm100-50h320v-60H400v60Zm200-110h60v-50h60v-60h-60v-50h-60v160Zm-360-50h320v-60H240v60Zm80 450v-80H160q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v80H320ZM160-280h640v-480H160v480Zm0 0v-480 480Z" />
                                            </svg>
                                        </p>
                                        <p className="title mb-3">MANAGE YOUR REGISTRATIONS</p>
                                    </div>
                                </div>
                            </div>

                                

                            <div className={DisplayCreateEvent}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-center">
                                    <img src={startedEvent} alt="" style={{ width: 250 }} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 d-flex align-items-center">
                                    <div>
                                        <div className="wt-data">
                                            <p className="wt-count h3 mb-4">01</p>
                                            <h3 className="wt-title mb-4"> Get Started with Your Event</h3>
                                        </div>
                                        <p className="wt-description text-left">Sign up and create your event. It's fast and effortless!</p>
                                    </div>
                                </div>
                            </div>
                            <div className={DisplayRegistrationForm}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-center">
                                    <img src={registrationForm} alt="" style={{ width: 250 }} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 d-flex align-items-center">
                                    <div>
                                        <div className="wt-data">
                                            <p className="wt-count h3 mb-4">02</p>
                                            <h3 className="wt-title mb-4"> Next Up: Registration Form</h3>
                                        </div>
                                        <p className="wt-description text-left">Personalize your registration form with multiple questions as per your event design. Offer various options such as race distances, early bird offers, age categories, etc.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={DisplayPersonaliseEvent}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-center">
                                    <img src={enhance} alt="" style={{ width: 250 }} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 d-flex align-items-center">
                                    <div>
                                        <div className="wt-data">
                                            <p className="wt-count h3 mb-4">03</p>
                                            <h3 className="wt-title mb-4"> Enhance it with Images & Content</h3>
                                        </div>
                                        <p className="wt-description text-left">Add event details, upload a cover image, showcase organizer profiles and offer discounts too!</p>
                                    </div>
                                </div>
                            </div>
                            <div className={DisplayGoLive}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-center">
                                    <img src={liveTickets} alt="" style={{ width: 250 }} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 d-flex align-items-center">
                                    <div>
                                        <div className="wt-data">
                                            <p className="wt-count h3 mb-4">04</p>
                                            <h3 className="wt-title mb-4"> Start Selling Your Tickets!</h3>
                                        </div>
                                        <p className="wt-description text-left">Make your event live and share the link to start selling tickets. Accept online payments through debit cards, credit cards, UPI and Internet banking.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={DisplayManageRegistration}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-center">
                                    <img src={handleAttendees} alt="" style={{ width: 250 }} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 d-flex align-items-center">
                                    <div>
                                        <div className="wt-data">
                                            <p className="wt-count h3 mb-4">05</p>
                                            <h3 className="wt-title mb-4"> Handle Attendees & More</h3>
                                        </div>
                                        <p className="wt-description text-left">Access a complete list of your attendees and view your revenues instantly. Engage with your attendees directly from the dashboard and send them emails with one click.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <div className="checkout__content--step__footer wt-btn-ui">
                            <div className="continue__shipping--btn primary__btn border-radius-5">LET’S GET UNDERWAY</div>
                        </div> */}

                    </div>
                </section>

                <section className="section--padding py-5">
                    <div className="container">
                        <div className="text-center py-5">
                            <h3>RACES FEATURES</h3>
                        </div>
                        <div className="row why-choose my-4 justify-content-center">
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
                                <div className="card p-5" >
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={promptSupport} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Prompt Support</h4>
                                            <p>Our team is here to help you with any questions or issues during registration. We're available to make sure everything goes smoothly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
                                <div className="card p-5" >
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={promoteYourEvent} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Personalized Experience</h4>
                                            <p>Tailor the registration process to fit your event’s unique needs. Customize forms and communications to create a seamless and personal experience for participants.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
                                <div className="card p-5" >
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={personalizedExperience} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Promote your Event</h4>
                                            <p>Boost your event's visibility with our go-to-market tools. Easily share your event on social media and through other digital mediums to attract more participants.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
                                <div className="card p-5" >
                                    <div className="row">
                                        <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                                            <img src={dataDrivenMetrics} alt="" className="" />
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='title'>Data-Driven Metrics</h4>
                                            <p>Use our platform to track registrations and other important data. Get insights that help you understand your event's performance and make improvements.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );

}

export default HowItWorks