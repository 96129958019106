import React, { useEffect } from 'react';
import Login from "../logo/loginsuccess.gif";

const WelcomePopup = ({ onClose, actionType}) => {
    useEffect(() => {
        // console.log(actionType);
        const timer = setTimeout(() => {
            onClose(); // Call the onClose function to close the popup
        }, 3000); // Adjust the delay as needed (in milliseconds)

        // Clear the timer if the component is unmounted or the onClose function changes
        return () => clearTimeout(timer);
    },  [onClose, actionType]);

    

    return (
        <div className="modal is-visible" data-animation="slideInUp">
        <div className="modal-dialog quickview__main--wrapper">
          <header className="modal-header quickview__header">
          </header>
          <div className="quickview__inner">
            <div className="row">
              <div className="login__section">
                <form action="#">
                  <div className="login__section--inner">
                    <div className="p-5">
                      <div className="account__login--header text-center">
                        <img
                           src={`${Login}`}
                          alt=""
                          style={{ width: 100 }}
                        />
                        <h3 className="account__login--header__title my-4">
                          Login Successful!
                        </h3>
                        <h4 className="h4 my-4">
                          {/* Your account has been log in successfully */}
                          You have been successfully logged in
                        </h4>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
};

export default WelcomePopup;
