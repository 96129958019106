import React, { useState, useEffect } from 'react';
import '../App.css';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventSucessPopup from "../Create_event_details/EventSucessPopup";

const Settings = (props) => {
  //console.log(props.EventData);
    
    const [NoOfPreviousConducts, setNoOfPreviousConducts] = useState(0);
    const [NoOfRunnersEstimate, setNoOfRunnersEstimate] = useState(0);
    const [NoOfEventYear, setNoOfEventYear] = useState(0);
    const [YtcrBaseTicketPrice, setYtcrBaseTicketPrice] = useState('0.00');
    const [YtcrBasePrice, setYtcrBasePrice] = useState(props.YtcrFeePercent);
  
    const [ContractOfFiveYear, setContractOfFiveYear] = useState(false);
    const [BackendSupport, setBackendSupport] = useState(false);
    const [BulkRegistration, setBulkRegistration] = useState(false);
    const [CheckValidEntries, setCheckValidEntries] = useState(false);
    const [Loader, setLoader] = useState(false);
    const [CalculatedFlag, setCalculatedFlag] = useState(true);

    const [EventSettingId, setEventSettingId] = useState(0);
    const EventSettingDetails = props.EventData !== '' ? props.EventData : '';
    const super_admin = localStorage.getItem("SuperAdmin") ? localStorage.getItem("SuperAdmin") : 0;
   //console.log(props.YtcrFeePercent);

   //--------------- new
   
   const [OverallLimit, setOverallLimit] = useState('');
   const [EventRegistrationStatus, setEventRegistrationStatus] = useState(2);
   const [SingleStatus, setSingleStatus] = useState('');
   const [MultipleStatus, setMultipleStatus] = useState('active');
   const [AllowUniqueRegistration, setAllowUniqueRegistration] = useState(false);
   

    useEffect(() => {
      window.scrollTo(0, 0);
      if (EventSettingDetails) {
          // console.log("Event Details : "+EventDetailsData.time_zone);
          // setNoOfPreviousConducts(EventSettingDetails.no_of_previous_conducts);
          // setNoOfRunnersEstimate(EventSettingDetails.no_of_runners_estimate); 
          // setNoOfEventYear(EventSettingDetails.no_of_event_year); 
          // setYtcrBaseTicketPrice(EventSettingDetails.ticket_ytcr_base_price); 

          // setContractOfFiveYear(EventSettingDetails.contract_of_five_year === 1 ? true : false); 
          // setBackendSupport(EventSettingDetails.backend_support === 1 ? true : false); 
          // setBulkRegistration(EventSettingDetails.bulk_registration === 1 ? true : false); 
          // setCheckValidEntries(EventSettingDetails.check_valid_entries === 1 ? true : false);
          // setEventSettingId(EventSettingDetails.id); 
          // setYtcrBasePrice(EventSettingDetails.ytcr_base_price);
          // setCalculatedFlag(false);
          setOverallLimit(EventSettingDetails.overall_limit ? EventSettingDetails.overall_limit : ''); 
          setAllowUniqueRegistration(EventSettingDetails.allow_unique_registration === 1 ? true : false); 
          setEventRegistrationStatus(EventSettingDetails.event_registration_status);

          if(EventSettingDetails.event_registration_status === 2){
            setMultipleStatus('active'); setSingleStatus('');
          }else{
            setMultipleStatus(''); setSingleStatus('active');
          }
      }
  }, [EventSettingDetails])

    const handleSubmit = (e) => {
      
        e.preventDefault();
          setLoader(true);
          const payload = {
              event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
              user_id: localStorage.getItem("ID") ? localStorage.getItem("ID") : 0,
              // event_setting_id        : EventSettingId,
              // no_of_previous_conducts : NoOfPreviousConducts,
              // no_of_runners_estimate  : NoOfRunnersEstimate,
              // no_of_event_year        : NoOfEventYear,
              // contract_of_five_year   : ContractOfFiveYear === true ? 1 : 0,
              // backend_support         : BackendSupport === true ? 1 : 0,
              // bulk_registration       : BulkRegistration === true ? 1 : 0,
              // check_valid_entries     : CheckValidEntries === true ? 1 : 0,
              // ytcr_base_price         : YtcrBaseTicketPrice,
              // edit_ytcr_base_price    : YtcrBasePrice
              overall_limit              : OverallLimit,
              allow_unique_registration  : AllowUniqueRegistration === true ? 1 : 0,
              event_registration_status  : EventRegistrationStatus,

          };
          //console.log(payload);
          axios.post("event_setting", payload)
            .then((response) => {
              setLoader(false);
              //console.log(response.data);
                if (response.status === 200) {
                  props.setEventResponceTabs(4);
                  toast.success(response.data.message)
                  //setsuccessMsg(response.data.message);
                }
            })
            .catch(() => {
              setLoader(false);
              console.log('error');
            });
    };

    useEffect(() => {
     
      if(CalculatedFlag){
        //console.log('test');
        const ytcr_price = YtcrBasePrice;

        const no_of_previous_conducts = NoOfPreviousConducts !== '' && NoOfPreviousConducts !== '0' ? (NoOfPreviousConducts * ytcr_price) / 100 : 0;
        // console.log(no_of_previous_conducts);
  
        const no_of_runners_estimate = NoOfRunnersEstimate !== '' && NoOfRunnersEstimate !== '0' ? (NoOfRunnersEstimate * ytcr_price) / 100 : 0;
  
        const no_of_event_year = NoOfEventYear !== '' && NoOfEventYear !== '0' ? (NoOfEventYear * ytcr_price) / 100 : 0;
        
        const contract_of_five_year = ContractOfFiveYear === true ? (10 * ytcr_price) / 100 : 0;
        
        //------- extra add-on --------------
        const backend_support     = BackendSupport === true ? (5 * ytcr_price) / 100 : 0;
        const bulk_registration   = BulkRegistration === true ? (2 * ytcr_price) / 100 : 0;
        const check_valid_entries = CheckValidEntries === true ? (3 * ytcr_price) / 100 : 0;
  
        let final_ticket_ytcr_base_price = (ytcr_price - no_of_previous_conducts - no_of_runners_estimate - no_of_event_year - contract_of_five_year + backend_support + bulk_registration + check_valid_entries);
       
        setYtcrBaseTicketPrice(parseFloat(final_ticket_ytcr_base_price).toFixed(2));
        setCalculatedFlag(false);
      }
      
    }, [NoOfPreviousConducts,NoOfRunnersEstimate,NoOfEventYear,ContractOfFiveYear,BackendSupport,BulkRegistration,CheckValidEntries,CalculatedFlag]);

    return (
        <>

        {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
       
        <div className="main checkout__mian">

            <form method="post" onSubmit={handleSubmit} > 
                <div className="checkout__content--step section__shipping--address ">
                 
                    <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                      <h2 className="section__header--title h3">Event Settings</h2>
                    </div>
                 
                </div>

                <div className="row mb-3">

                  <div className="col-sm-6 my-3 ">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={OverallLimit}
                        onChange={(e) => { setOverallLimit(e.target.value.replace(/\D/g, '')) }}
                        maxLength={4}
                      />
                      <label>Overall Limit</label>
                    </div>
                  </div>

                  <div className="col-sm-6 my-3 d-flex align-items-center gap-4"> 
                    <label className="">Registrations</label>
                    <div className="ebi">
                      <div className="event-visibility hand_cursor">
                        <div className={'visibility gap-1 ' + SingleStatus} onClick={() => { setEventRegistrationStatus(1); setSingleStatus('active'); setMultipleStatus(''); }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                            viewBox="0 -960 960 960"
                            width={20}
                          >
                            <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                          </svg>
                          Single
                        </div>

                        <div className={'visibility gap-1 ' + MultipleStatus} onClick={() => { setEventRegistrationStatus(2); setSingleStatus(''); setMultipleStatus('active'); }} >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                            viewBox="0 -960 960 960"
                            width={20}
                          >
                            <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                          </svg>
                          Multiple
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 my-3 ">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={AllowUniqueRegistration}
                        checked={AllowUniqueRegistration}
                        onChange={() => { setAllowUniqueRegistration(!AllowUniqueRegistration); }}
                      />
                      <label className="form-check-label">
                         Allow only unique registrations
                      </label>
                    </div>  
                  </div>

                 {/* <h4 className="mb-3 ">Discounts:</h4> */}

                  {/* <div className="col-sm-12">
                    <div className="form-floating mb-0">
                        
                      <select className="form-control" name="no_of_previous_conducts" value={NoOfPreviousConducts} onChange={(e) => { setNoOfPreviousConducts(e.target.value); setCalculatedFlag(true); }} > 
                       
                        <option value="0">1</option>
                        <option value="5">2</option>
                        <option value="7">3</option>
                        <option value="8">4</option>
                        <option value="10">5 or more than 5</option>
                      </select>

                      <label>Number of previously conducted editions</label>
                    </div>
                  </div>

                  <div className="col-sm-12 my-3 ">
                    <div className="form-floating mb-0">
                        
                      <select className="form-control" name="no_of_runners_estimate" value={NoOfRunnersEstimate} onChange={(e) => { setNoOfRunnersEstimate(e.target.value); setCalculatedFlag(true); }} > 
                        
                        <option value="0">0-1000</option>
                        <option value="10">1000-2000</option>
                        <option value="15">2000-3000</option>
                        <option value="20">3000-5000</option>
                        <option value="30">5000-7500</option>
                        <option value="40">7500 and above</option>
                      </select>

                      <label>Number of runners you estimate participate in your event</label>
                    </div>
                  </div>

                  <div className="col-sm-12 my-3 ">
                    <div className="form-floating mb-0">
                        
                      <select className="form-control" name="no_of_previous_conducts" value={NoOfEventYear} onChange={(e) => { setNoOfEventYear(e.target.value); setCalculatedFlag(true); }} > 
                       
                        <option value="0">1</option>
                        <option value="2">2</option>
                        <option value="4">3</option>
                        <option value="5">4 or more than 4</option>
                      </select>

                      <label>Number of other events you conduct each year</label>
                    </div>
                  </div>

                  <div className="col-lg-12 my-3 ">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={ContractOfFiveYear}
                        checked={ContractOfFiveYear}
                        onChange={() => { setContractOfFiveYear(!ContractOfFiveYear); setCalculatedFlag(true); }}
                      />
                      <label className="form-check-label">
                          Are you willing to enter into a contract of five years or more
                      </label>
                    </div>  
                  </div>
                  
                  <hr className="my-4" />
                  <h4 className="mb-3 ">Add-on services:</h4>

                  <div className="col-lg-12 my-3 ">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={BackendSupport}
                        checked={BackendSupport}
                        onChange={() => { setBackendSupport(!BackendSupport); setCalculatedFlag(true); }}
                      />
                      <label className="form-check-label">
                       Backend Support - We Manage your communications across social media, email,
                       and voice,handling inquiries and clean up the data.
                      </label>
                    </div>  
                  </div>

                  <div className="col-lg-12 my-3 ">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={BulkRegistration}
                        checked={BulkRegistration}
                        onChange={() => { setBulkRegistration(!BulkRegistration); setCalculatedFlag(true); }}
                      />
                      <label className="form-check-label">
                       Bulk Registration - Cleaning data received in excel sheets.
                      </label> 
                    </div>  
                  </div>

                  <div className="col-lg-12 my-3 ">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={CheckValidEntries}
                        checked={CheckValidEntries}
                        onChange={() => {setCheckValidEntries(!CheckValidEntries); setCalculatedFlag(true); }}
                      />
                      <label className="form-check-label">
                        Check Registrants eligibility for the event.
                      </label> 
                    </div>  
                  </div>
                 
                  { super_admin === '3' ?
                  <div className="col-sm-6 my-3 ">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={YtcrBasePrice}
                        onChange={(e) => { setYtcrBasePrice(e.target.value) }}
                      />
                      <label>YouTooCanRun Base Price</label>
                    </div>
                  </div>
                  : '' }

                  <div className="col-sm-6 my-3 ">
                    <div className="form-floating mt-1 mb-0">
                        
                      <input type="text" className="form-control" value={YtcrBaseTicketPrice} 
                       onChange={(e) => { setYtcrBaseTicketPrice(e.target.value);  }} readOnly/>
                    </div>
                  </div> */}

                </div>
              
                <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                    <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(2) }} style={{cursor:'pointer'}}>Back</div>
                    {/* <input type="button" name="command" value="Next (6/6)" className="continue__shipping--btn primary__btn border-radius-5"  /> */}
                    <input type="submit" name="command" value="Save & Next (4/11)" className="continue__shipping--btn primary__btn border-radius-5" />
                </div>
            </form>
        </div>
        </>
    )
}

export default Settings