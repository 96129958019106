//------------- check user access right
  
const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
const OrgUserAccessModules = localStorage.getItem("OrgUserAccessModules") ? JSON.parse(localStorage.getItem("OrgUserAccessModules")) : '';

export const UserAccessRights = (props) => {
   
    let access_module = props.module_name ? props.module_name : '';
    let access_display = props.module_display ? props.module_display : '';

    if(Object.keys(OrgUserAccessModules).includes(access_module)){
        if(access_module === 'Event' && (access_display === 'yes') && OrgUserAccessModules.Event === 0){
            return true;
        }else if(access_module === 'Event' && (access_display === 'no') && (OrgUserAccessModules.Event === 0 || OrgUserAccessModules.Event === 1)){
            return true;
        }else if(access_module === 'MyProfile' && OrgUserAccessModules.MyProfile === 0){
            return true;
        }else if(access_module === 'Insight' && OrgUserAccessModules.Insight === 0){
            return true;
        }else if(access_module === 'Organiser' && OrgUserAccessModules.Organiser === 0){
            return true;
        }else{ 
            return false;
        }
       
    }else{
        return false;
    } 
   
}



