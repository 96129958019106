import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import HeaderSecond from '../HeaderSecond';
import axios from '../axios'
import EventImg from '../img/events/event-view.jpg';
import NoEventImage from '../img/events/no-events.png';
import { ToastContainer, toast } from 'react-toastify';
import ShareEvent from '../Event/ShareEvent';

const ShowWishlist = (props) => {
    const navigate = useNavigate();
    const [Index, setIndex] = useState(5);
    const [UserEventsFollow, setUserEventsFollow] = useState([]);
    const [TotalUserEventFollow, setTotalTotalUserEventFollow] = useState(0);
    const [ApiCall, setApiCall] = useState(true);
    localStorage.removeItem('EventId');
    localStorage.removeItem('EventType');
    const [Login, setLogin] = useState(false);
    const [ShareEventFlag, setShareEventFlag] = useState(false);
    const [EventName, setEventName] = useState('');
    const [EventIdShareEvent, setEventIdShareEvent] = useState('');
    const handleShareEvent = (eventId, eventName) => {
        setShareEventFlag(true);
        setEventName(eventName);
        setEventIdShareEvent(eventId);
    }

    useEffect(() => {
        if (ApiCall) {
            axios.post("userfollowevent", {})
                .then((response) => {
                    // console.log("response:-",response.data.data.userfollowevent);
                    if (response.data.data.hasOwnProperty('userfollowevent')) {
                        setUserEventsFollow(response.data.data.userfollowevent);
                    } else {
                        setUserEventsFollow([]);
                    }
                    setTotalTotalUserEventFollow(response.data.data.userfollowevent ? response.data.data.userfollowevent.length : 0)
                    setApiCall(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [ApiCall])

    const handleWishList = (eventId, is_follow) => {
        // alert("here");
        // is_follow == 0 then need to follow means add entry in table
        // is_follow == 1 then need to unfollow means delete the entry form table
        const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
        if (RacesToken) {
            const payload = {
                "event_id": eventId,
                "is_follow": is_follow
            }
            axios.post("/follow", payload)
                .then((response) => {
                    // console.log(response.data.message);
                    if (response.status) {
                        toast.success(response.data.message);
                        setApiCall(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('Error occured while adding to wishlist')
                    }
                })
        } else {
            // const CountryCode = localStorage.getItem("CountryCode");
            // const CityName = localStorage.getItem("City") ? localStorage.getItem("City") : localStorage.getItem("Country");
            // navigate("/" + CountryCode + "/" + CityName, { state: { "login_popup": true } });
            setLogin(true);
        }
    }

    function capitalizeFirstLetter(string) {
        return string.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    const HandleBack = (e) => {
        const CountryCode = localStorage.getItem("CountryCode");
        const CityName = localStorage.getItem("City") ? localStorage.getItem("City") : localStorage.getItem("Country");
        navigate("/" + CountryCode + "/" + CityName);
    }
    // const HandleViewEvent = (EventId) => {
    //     // alert(EventId);
    //     const CountryCode = localStorage.getItem("CountryCode");
    //     const CityName = localStorage.getItem("City") ? localStorage.getItem("City") : localStorage.getItem("Country");
    //     navigate("/" + CountryCode + "/" + CityName, { state: { "event_id": EventId } });
    // }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Escape") {
                setShareEventFlag(false);
            }
        };
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    const handleEventDetails = (e, EventId) => {
        navigate('/event_details', { state: { event_id: EventId } })
    }
    return (
        <>
            <ToastContainer theme='colored' />
            <HeaderSecond Login={Login} setLogin={setLogin} />
            {ShareEventFlag ? <ShareEvent setShareEventFlag={setShareEventFlag} EventName={EventName} EventIdShareEvent={EventIdShareEvent} /> : null}

            {/* Start breadcrumb section */}
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                    Favourites
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white">
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">Favourites</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="shop__header bg__gray--color d-flex align-items-center justify-content-between mb-30">
                                    <div className="product__view--mode__list product__short--by align-items-center d-lg-flex">
                                        <p className="product__showing--count">Showing {TotalUserEventFollow !== 0 ? 1 : 0}–{Index < TotalUserEventFollow ? Index : TotalUserEventFollow} of {TotalUserEventFollow} results</p>
                                    </div>


                                    <div className="product__view--mode__list product__short--by align-items-center d-lg-flex" title="Back to Home">
                                        <svg onClick={(e) => HandleBack(e)} className="back-to-home" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" /></svg>
                                    </div>
                                </div>

                                <div className="shop__product--wrapper">
                                    {/* EVENTS LISTING TABLE START HERE */}
                                    <div className="tab_content">
                                        <div className="tab_pane active show">
                                            <div className="product__section--inner product__grid--inner">
                                                {

                                                    UserEventsFollow && UserEventsFollow.length > 0 ? (
                                                        <div className="row" style={{ "position": "relative" }}>
                                                            {UserEventsFollow.map((event, index) => {
                                                                // console.log(Index,index);
                                                                // console.log("user",item.banner_image);
                                                                return (
                                                                    ((Index) > parseInt(index)) ?
                                                                        <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 my-4">
                                                                            <div key={event.id} className="product__items"
                                                                                // onClick={(e) => { HandleViewEvent(event.id) }}
                                                                                onClick={(e) => handleEventDetails(e, event.id)}
                                                                            >
                                                                                <div className="product__items--thumbnail" >
                                                                                    <div className="product__items--link">
                                                                                        <img
                                                                                            className="product__items--img product__primary--img"
                                                                                            src={event.banner_image || EventImg}
                                                                                            alt="Event View"
                                                                                            style={{ height: '230px', objectFit: 'fill' }}
                                                                                        />
                                                                                    </div>
                                                                                    {event.TicketDetails && event.TicketDetails.length > 0 && (
                                                                                        event.TicketDetails.some(ticket => (parseInt(ticket.show_early_bird) === 1 && parseInt(ticket.ticket_status) !== 2)) ? (
                                                                                            <div className="product__badge style1 special-badge gap-1">
                                                                                                Early Bird
                                                                                            </div>
                                                                                        ) : null
                                                                                    )}
                                                                                    <ul className="product__items--action d-flex justify-content-center">

                                                                                        {/* Wishlist */}
                                                                                        <li className="product__items--action__list">
                                                                                            <div className={event.is_follow ? "hand_cursor product__items--action__btn wishlisted" : "hand_cursor product__items--action__btn"} onClick={(e) => {
                                                                                                e.stopPropagation(); // Stop event propagation
                                                                                                handleWishList(event.id, event.is_follow);
                                                                                            }}>
                                                                                                <svg
                                                                                                    className="product__items--action__btn--svg"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="17.51"
                                                                                                    height="15.443"
                                                                                                    viewBox="0 0 24.526 21.82"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M12.263,21.82a1.438,1.438,0,0,1-.948-.356c-.991-.866-1.946-1.681-2.789-2.4l0,0a51.865,51.865,0,0,1-6.089-5.715A9.129,9.129,0,0,1,0,7.371,7.666,7.666,0,0,1,1.946,2.135,6.6,6.6,0,0,1,6.852,0a6.169,6.169,0,0,1,3.854,1.33,7.884,7.884,0,0,1,1.558,1.627A7.885,7.885,0,0,1,13.821,1.33,6.169,6.169,0,0,1,17.675,0,6.6,6.6,0,0,1,22.58,2.135a7.665,7.665,0,0,1,1.945,5.235,9.128,9.128,0,0,1-2.432,5.975,51.86,51.86,0,0,1-6.089,5.715c-.844.719-1.8,1.535-2.794,2.4a1.439,1.439,0,0,1-.948.356ZM6.852,1.437A5.174,5.174,0,0,0,3,3.109,6.236,6.236,0,0,0,1.437,7.371a7.681,7.681,0,0,0,2.1,5.059,51.039,51.039,0,0,0,5.915,5.539l0,0c.846.721,1.8,1.538,2.8,2.411,1-.874,1.965-1.693,2.812-2.415a51.052,51.052,0,0,0,5.914-5.538,7.682,7.682,0,0,0,2.1-5.059,6.236,6.236,0,0,0-1.565-4.262,5.174,5.174,0,0,0-3.85-1.672A4.765,4.765,0,0,0,14.7,2.467a6.971,6.971,0,0,0-1.658,1.918.907.907,0,0,1-1.558,0A6.965,6.965,0,0,0,9.826,2.467a4.765,4.765,0,0,0-2.975-1.03Zm0,0"
                                                                                                        transform="translate(0 0)"
                                                                                                        fill="currentColor"
                                                                                                    />
                                                                                                </svg>
                                                                                                <span className="visually-hidden">Favourites</span>
                                                                                            </div>
                                                                                        </li>

                                                                                        <li className="product__items--action__list">
                                                                                            <div className="product__items--action__btn hand_cursor" onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                handleShareEvent(event.id, event.name)
                                                                                            }}>
                                                                                                <svg
                                                                                                    className="product__items--action__btn--svg"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="16.47"
                                                                                                    height="13.088"
                                                                                                    viewBox="0 0 15.47 11.088"
                                                                                                >
                                                                                                    <g transform="translate(0 -72.508)">
                                                                                                        <path
                                                                                                            data-name="Path 114"
                                                                                                            d="M15.359,80.9l-2.011-2.011a.525.525,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49l1.106,1.106H10.576L8.3,78.052l2.273-2.274h1.618l-1.106,1.106a.287.287,0,0,0,.2.49h1.683a.531.531,0,0,0,.374-.155l2.011-2.011a.38.38,0,0,0,0-.535l-.859-.859a.227.227,0,1,0-.32.321l.806.806L13.027,76.9a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542h-2.04a.227.227,0,0,0-.16.066l-2.34,2.34-.544-.544,2.519-2.519a.382.382,0,0,1,.272-.112h2.293a.317.317,0,0,0,.225-.542l-1.054-1.054h1.282a.076.076,0,0,1,.053.022l.4.4a.227.227,0,1,0,.32-.321l-.4-.4a.531.531,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49L12.194,74.1H10.229a.832.832,0,0,0-.592.245L7.118,76.867,4.6,74.349a.832.832,0,0,0-.592-.245H.378A.378.378,0,0,0,0,74.481v.92a.378.378,0,0,0,.378.378H3.66l2.273,2.274L3.66,80.326H.378A.378.378,0,0,0,0,80.7v.92A.378.378,0,0,0,.378,82H4.007a.835.835,0,0,0,.592-.245l2.519-2.519.8.8a.227.227,0,1,0,.32-.32L3.914,75.392a.227.227,0,0,0-.16-.066H.453v-.769H4.007a.382.382,0,0,1,.272.113l6.043,6.043a.227.227,0,0,0,.16.066h2.04a.317.317,0,0,0,.224-.542l-1.054-1.054h1.282a.075.075,0,0,1,.053.022l1.958,1.958-1.958,1.958a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542H10.229a.383.383,0,0,1-.272-.113l-.968-.968a.227.227,0,0,0-.32.32l.968.968a.833.833,0,0,0,.592.245h1.965l-1.105,1.105a.287.287,0,0,0,.2.49h1.683a.525.525,0,0,0,.374-.155l2.011-2.011A.379.379,0,0,0,15.359,80.9Zm-11.08.539a.389.389,0,0,1-.272.113H.453v-.769h3.3a.226.226,0,0,0,.16-.066l2.34-2.34.543.544Z"
                                                                                                            transform="translate(0 0)"
                                                                                                            fill="currentColor"
                                                                                                        />
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <span className="visually-hidden">Compare</span>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>

                                                                                <div className="product__items--content cursor-pointer" style={{ height: "200px", overflow: "hidden" }}>

                                                                                    <div className="row">
                                                                                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column align-items-center justify-content-center" style={{ borderRight: "1px solid #f52222" }}>
                                                                                            <h4 className="h4">{event.start_event_month ? event.start_event_month : null}</h4>
                                                                                            <h4 className="h4">{event.start_event_date ? event.start_event_date : null}</h4>
                                                                                        </div>
                                                                                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10" >

                                                                                            <h4 className="h4 d-flex" style={{ height: "55px", overflow: "hidden" }}>  {capitalizeFirstLetter(event.display_name)}</h4>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='my-3 d-flex gap-3'>

                                                                                        <small className="m-0 card-event-city">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                height={17}
                                                                                                viewBox="0 -960 960 960"
                                                                                                width={17}
                                                                                            >
                                                                                                <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                                                            </svg> {event.city_name}</small>
                                                                                    </div>

                                                                                    <div className="row d-flex align-items-center card-rgn-bt-dot">
                                                                                        <div className="col-sm-12 mt-4 mb-0">
                                                                                            <small className='mb-2 card-rgn-txt'>Register By
                                                                                                : {" "}  <span className='rgn-date'>{event.registration_end_date ? event.registration_end_date : null}</span></small>
                                                                                        </div>

                                                                                        <div className="col-sm-6 my-3">
                                                                                            <p style={{ whiteSpace: "nowrap" }}>
                                                                                                {event.TicketDetails && event.TicketDetails.length > 0 && (
                                                                                                    event.TicketDetails.some(ticket => parseInt(ticket.ticket_status) === 2) ? (
                                                                                                        <span className="current__price m-0">Free</span>
                                                                                                    ) : (
                                                                                                        event.TicketDetails.map((ticket, index) => (
                                                                                                            parseInt(ticket.ticket_status) === 1 && (
                                                                                                                <span key={index}>
                                                                                                                    {index > 0 && " - "}
                                                                                                                    {ticket.show_early_bird === 1 ? (
                                                                                                                        <span className="text-left">
                                                                                                                            <span className="ticket-prices">
                                                                                                                                <span className="original-price"> ₹{ticket.ticket_price}</span>&nbsp;
                                                                                                                                <span className="current__price m-0">₹{ticket.discount_ticket_price}</span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    ) : (
                                                                                                                        <span className="current__price m-0">₹{ticket.ticket_price}</span>
                                                                                                                    )}
                                                                                                                </span>
                                                                                                            )
                                                                                                        ))
                                                                                                    )
                                                                                                )}
                                                                                            </p>
                                                                                        </div>

                                                                                        <div className="col-sm-6 my-3 text-right">
                                                                                            <p className="blog__content--btn primary__btn">Register</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                );
                                                            })}
                                                        </div>
                                                    ) :
                                                        <div className="row">
                                                            <div className="product__section--inner py-5 text-center">
                                                                <img src={NoEventImage} alt="" style={{ width: 250 }} />
                                                                <h4 className="pt-4">No events found</h4>
                                                                <p>
                                                                    Sorry, We couldn't find any events that match your search, please change the filter.
                                                                </p>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* END EVENTS LISTING TABLE START HERE */}

                                    {Index < UserEventsFollow.length ?
                                        <div className="pagination__area bg__gray--color">
                                            <nav className="pagination justify-content-center">
                                                <div
                                                    className="continue__shipping--btn secondary__btn border-radius-5"
                                                    onClick={(e) => setIndex(Index + 5)}
                                                >
                                                    Load more
                                                </div>
                                            </nav>
                                        </div>
                                        : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    );
}
export default ShowWishlist
