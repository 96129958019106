import HeaderSecond from '../HeaderSecond'
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import { useDownloadExcel } from 'react-export-table-to-excel';
import axios from '../axios'
import Pagination from '../Components/Pagination'
import LoaderImg from '../img/logo/running.gif';

const EventDashbaordRegistration = () => {
    const navigate = useNavigate();
    const { EventId } = useParams();

    const location = useLocation();
    const { state } = location;
    // const EventName = state.event_name;
    const EventName = state ? state.event_name : localStorage.getItem("EventName");
    const [ApiCall, setApiCall] = useState(true);
    const [UserData, setUserData] = useState([]);
    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [SearchUser, setSearchUser] = useState("");
    const [TransactionStatus, setTransactionStatus] = useState("");
    const [Loader, setLoader] = useState(false);

    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 30
    const [TotalRecord, setTotalRecord] = useState(0);

    const handleBackToHome = () => {
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }
//  console.log(page);
    useEffect(() => {
        if (ApiCall || page) {
            setLoader(true);
            axios.post("get_registered_users", {
                "event_id": EventId,
                "user_name": SearchUser,
                "from_date": FromDate,
                "to_date": ToDate,
                "TransactionStatus": TransactionStatus,
                "limit": limit,
                "page": page,
            })
                .then((response) => {
                    // console.log(response)
                    if (response.status === 200) {
                        setUserData(response.data.data.UserData);
                        setTotalRecord(response.data.data.TotalRecord);
                        setApiCall(false);
                    }
                    setApiCall(false);
                    setLoader(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoader(false);
                })
        }
    }, [EventId, ApiCall, SearchUser, FromDate, ToDate, TransactionStatus, page])

    // console.log(UserData)
    const HandleClear = (e) => {
        e.preventDefault();
        setSearchUser(''); setFromDate(''); setToDate(''); setTransactionStatus('');
        setApiCall(true);
    }

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Participants_' + EventName,
        sheet: 'Users'
    })

    return (
        <>
            {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
            <HeaderSecond />
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        {EventName}
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">My Event</span>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">{EventName}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='section--padding  pt-5'>
                    <div className='container'>
                        <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">

                            <h2 className="section__header--title h3">Registrations</h2>

                            <div className="event-visibility hand_cursor" onClick={(e) => { navigate('/event_dashboard/' + EventId, { state: { event_name: EventName } }); }}>
                                <div className="visibility gap-1 ">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height={20}
                                        viewBox="0 -960 960 960"
                                        width={20}
                                    >
                                        <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
                                    </svg>
                                    Back
                                </div>
                            </div>
                        </div>


                        <div className='dashboard-card border-radius-10 mb-3 p-4'>
                            <div className="row my-2">
                                <div className="col-sm-6 d-flex align-items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>
                                    <p className='h4'>Search Filter</p>
                                </div>
                                <div className="col-sm-6 d-flex align-items-center justify-content-end gap-2">
                                    <div>
                                        {
                                            (SearchUser !== "" || TransactionStatus !== "" || FromDate !== "" || ToDate !== "") ?
                                                <p className="account__login--btn secondary__btn" onClick={(e) => {
                                                    HandleClear(e);
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z" /></svg> Clear</p>

                                                : null
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className='row my-2 align-items-center'>
                                <div className='col-sm-3'>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={SearchUser}
                                            onChange={(e) => {
                                                setSearchUser(e.target.value);
                                                setApiCall(true)
                                            }}
                                        />
                                        <label>User Name <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-floating">
                                        <select className="form-control" name="category" value={TransactionStatus} onChange={(e) => { setTransactionStatus(e.target.value); setApiCall(true); }}>
                                            <option value="">-- Select Status --</option>
                                            <option value="1">Success</option>
                                            <option value="3">Free</option>
                                            <option value="2">Failure</option>
                                            <option value="101">In Process</option>
                                            <option value="102">Success/Free</option>
                                        </select>
                                        <label>Transaction Status <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={FromDate}
                                            onChange={(e) => {
                                                setFromDate(e.target.value);
                                                setApiCall(true);
                                            }}
                                        />
                                        <label className="select-label">Date From <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={ToDate}
                                            onChange={(e) => {
                                                setToDate(e.target.value);
                                                setApiCall(true);
                                            }}
                                        />
                                        <label className="select-label">Date To <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='dashboard-card p-4 border-radius-10 '>
                            {/* <button onClick={onDownload}> Export excel </button> */}
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <table className="table table-hover" ref={tableRef}>
                                        {
                                            UserData && UserData.length > 0 ?
                                                <>
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">Sr. No.</th>
                                                            <th scope="col">User Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Mobile</th>
                                                            <th scope="col">Number of tickets</th>
                                                            <th scope="col">Total Amount</th>
                                                            <th scope="col">Booking Date</th>
                                                            <th scope="col">Transaction Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {UserData.map((user, index) => {
                                                            const transactionStatusText = user.transaction_status === 0 ? 'In Progress' :
                                                                user.transaction_status === 1 ? 'Success' :
                                                                    user.transaction_status === 2 ? 'Failure' : user.transaction_status === 3 ? 'Free' : '';
                                                                const no = page !== 1 ? ((page * limit - limit) + parseFloat(index+1)) : index + 1;   
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row" className=' text-center'>{no}</th>
                                                                    <td>{user.firstname !== "null" ? user.firstname : ""}{" "}{user.lastname !== "null" ? user.lastname : ""}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.mobile}</td>
                                                                    <td className="cursor-pointer text-center" onClick={(e) => { navigate('/event_netsale/' + EventId, { state: { event_name: EventName, user_id: user.user_id, EventBookingId: user.EventBookingId } }); }}>{user.TotalTickets}</td>
                                                                    <td className='text-center'>₹{parseFloat(user.TotalAmount).toFixed(2)}</td>
                                                                    <td>{user.booking_date}</td>
                                                                    <td>{transactionStatusText}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </>
                                                :
                                                <>
                                                    <p>No registation found</p>
                                                </>
                                        }
                                    </table>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-12'>
                                
                                {
                                    TotalRecord > 0 ?
                                    <Pagination
                                        records={UserData.length}
                                        link={"event_registrations/"+EventId}
                                        SelectedPage={page}
                                        search={"&event_id=" + EventId + "&user_name=" + SearchUser + "&from_date=" + FromDate + "&to_date=" + ToDate + "&TransactionStatus=" + TransactionStatus}
                                        NoOfRecords={TotalRecord}
                                    /> : null
                                }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}

export default EventDashbaordRegistration