import React, { useState, useEffect } from 'react';
import '../App.css';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventSucessPopup from "../Create_event_details/EventSucessPopup";


const Integration = (props) => {

    const [GstCharges, setGstCharges] = useState(false);
    const [GstValue, setGstValue] = useState('');
    const [Loader, setLoader] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [successMsg, setsuccessMsg] = useState('Event created successfully');
    const EventId = localStorage.getItem("EventId");
    const [TcsPopup, setTcsPopup] = React.useState(false);

    const [TermsAndConditionsError, setTermsAndConditionsError] = useState('');
    const [TermsAndConditions, setTermsAndConditions] = useState(false);

    const [embedTicketPopup, setembedTicketPopup] = useState("<button onclick='popup("+EventId+");' style='padding: 10px 16px; background-color: #d01c68; color: #fff; border-color: #d01c68;'>Register Now</button><noscript id='tsNoJsMsg'>Javascript on your browser is not enabled.</noscript><script src='http://localhost/ytcr_backend/public/assets/js/popup_scripts.js' type='text/javascript'></script>");
   
    const [embedTicketIframe, setembedTicketIframe] = useState('<iframe id="ts-iframe" src="http://localhost/ytcr_backend/public/ticket_iframe.html?'+EventId+'" frameborder="1" height="600" width="80%"></iframe><link rel="stylesheet" href = "https://www.townscript.com/static/Bookingflow/css/ts-iframe.style.css" >');

    
    const handleValidation = (e) => {
   
      var flag = true;

      if (TermsAndConditions === false) {
        setTermsAndConditionsError("Accept TCS terms of services");
        flag = false;
      } else {
        setTermsAndConditionsError("");
        setTcsPopup(false);
        setOpen(true);
      }

      return flag;
    }

    //console.log(props.EventTcsPopupFlag);
    const handleSubmit = (e) => {
      e.preventDefault();
        
      if(props.EventTcsPopupFlag === 0){
          setLoader(true);
          const payload = {
            event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
            popup_flag: 1
          };
        // console.log(payload);
          axios.post("event_integration", payload)
            .then((response) => {
              setLoader(false);
                //console.log(response);
                if (response.status === 200) {
                  // toast.success(response.data.message)
                  // setsuccessMsg(response.data.message);
                  setTcsPopup(true);
                }
            })
            .catch(() => {
              // setLoader(false);
              console.log('error');
            });
      }else{
         setOpen(true);
      }
         
    };

    const handleCopy = () => {
      navigator.clipboard.writeText(embedTicketPopup);
    }  

    const handleCopy1 = () => {
      navigator.clipboard.writeText(embedTicketIframe);
    } 
    
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      const handleKeyPress = (event) => {
          if (event.key === "Escape") {
            setTcsPopup(false); 
          }
      };
      // Add event listener when the component mounts
      document.addEventListener("keydown", handleKeyPress);
      // Remove event listener when the component unmounts
      return () => {
          document.removeEventListener("keydown", handleKeyPress);
      };
    }, []);

    return (
        <>
       
       {TcsPopup ?
         
            <div className="modal is-visible" data-animation="slideInUp">
              <div className="modal-dialog quickview__main--wrapper">
                <header className="modal-header quickview__header">
                  <button className="close-modal quickview__close--btn" onClick={(e) => { setTcsPopup(false); }} >✕</button>
                </header>
                <div className="quickview__inner">
                  <div className="row">
                    <div className="login__section">
                        <div className="login__section--inner">
                        <div className='p-5'>
                          <h3>What is TCS and how does TCS work?</h3>
                          <hr/>
                          <p>Starting 1st October 2018, every e-commerce operator has to collect TCS as per the GST regulations.</p>
                          <p><strong>What is TCS under GST?</strong></p>
                          <p>Tax Collected at Source (TCS) under GST means the tax collected by an e-commerce operator from the consideration received by it on behalf of the supplier of goods, or services who makes supplies through the operator’s online platform. TCS will be charged as a percentage on the net taxable supplies.</p>
                          <p><strong>What is meant by the “net value of taxable supplies”?</strong></p>
                          <p>The “net value of taxable supplies” means the aggregate value of taxable supplies of goods or services or both, made during any month by a registered supplier through such operator reduced by the aggregate value of taxable supplies returned to such supplier during the said month.&nbsp;</p>
                          <p><strong>What is the rate of TCS notified by the Government?</strong></p>
                          <p>Rate of TCS is 0.5% under each Act (i.e. the CGST Act, 2017, and the respective SGST Act / UTGST Act respectively) and the same is 1% under the IGST Act, 2017.&nbsp;</p>
                          <p><strong>Is every e-commerce operator required to collect tax on behalf of the actual supplier?</strong></p>
                          <p>Every e-commerce operator is required to collect tax where the supplier is supplying goods or services through e-commerce operators and consideration with respect to the supply is to be collected by the said e-commerce operator.</p>
                          <p><strong>How can actual suppliers/organizers claim credit for TCS?</strong></p>
                          <p>Based on the statement (FORM GSTR-8) filed by the e-commerce operator, the TCS would be credited to the electronic cash ledger of the actual supplier in the respective tax head. The said credit can be used at the time of discharge of tax liability by the actual supplier.</p>
                          <p>If the supplier is not able to use the amount lying in the said cash ledger, the actual supplier may claim a refund of the excess balance lying in his electronic cash ledger in accordance with the provisions contained in section 54(1) of the CGST Act, 2017</p>
                          <p><strong>How does youtoocanrun handle TCS collected?</strong></p>
                          <p>Youtoocanrun files the TCS collected from the transaction under an Organizer’s account on a monthly basis. Total TCS collected for a month for an organizer is filed at one go.</p>
                       
                          
                          <p className='d-flex gap-3 align-items-center mt-3' style={{marginBottom: '5px'}}> <input type='checkbox' name='confirm' style={{width:'20px', height:'20px'}} value={TermsAndConditions} checked={TermsAndConditions} onChange={() => setTermsAndConditions(!TermsAndConditions)}/> I acknowledge the terms of services related to TCS.</p>
                          <small className='text-danger'>{TermsAndConditionsError}</small>
                        </div>
                        
                        <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" style={{float: 'right'}} onClick={(e) => handleValidation(e)} />
                  
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        :
        ''
      }

       {open ? <EventSucessPopup setOpen={setOpen} successMsg={successMsg} EventName={props.EventName}/> : null}
       
        {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
       
        <div className="main checkout__mian">
            <form onSubmit={(e) => handleSubmit(e)} >
                <div className="checkout__content--step section__shipping--address ">
                  <div className="mb-5">
                    <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                       <h2 className="section__header--title h3">Integrations</h2>
                    </div>
                  </div>

                  <div className="row mb-5 create-event-integration">
                  
                    {/* <div className="col-sm-6"> 
                        <div className="form-check form-switch d-flex align-items-left justify-content-left my-5">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            value={GstCharges}
                            checked={GstCharges}
                            onChange={() => setGstCharges(!GstCharges)}
                            />
                            <label className="form-check-label">
                              GST Charges
                            </label>
                        </div>
                    </div>
                    
                    {GstCharges === true ?
                    <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                            <input
                            type="text"
                            className="form-control"
                            value={GstValue}
                            onChange={(e) => { setGstValue(e.target.value); }}
                            onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                            />
                            <label>GST Value</label>
                        </div>
                    </div>
                    : '' } */}

                <div className="col-sm-12 mb-5">
                      <label className='pre-title'>
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z"/></svg>
                          <strong> Embed Tickets on your website (Pop-up)</strong>
                        </label>
                      <p className='my-3'>To embed ticketing on your website which opens over the content on clicking a button, paste this HTML code</p>
                      {/* <textarea
                            type="text"
                            className="form-control"
                            style={{ minHeight: "100px", display: "inline-block", resize: "both", cursor: "pointer", fontSize:"inherit" }}
                            value={embedTicketPopup}
                            onClick={handleCopy}
                            readOnly
                      /> */}
                      <pre onClick={handleCopy}>
                        <code>
                          {embedTicketPopup}
                        </code>
                      </pre>
                </div>

                <div className="col-sm-12 mb-5">

                    <label className='pre-title'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z"/></svg>
                          <strong> Embed Tickets on your website (iframe)</strong>
                        </label>
                      <p className='my-3'>To embed ticketing within the content on your website, paste this HTML code</p>

                      <pre onClick={handleCopy1}>
                        <code>
                          {embedTicketIframe}
                        </code>
                      </pre>
                      
                </div>
                {/* <hr/>
                <div className="col-sm-12 my-5 ">
                  <div className='d-flex gap-3 align-items-center'> 
                    <input type='checkbox' name='confirm' style={{width:'20px', height:'20px'}} value={TermsAndConditions}
                        checked={TermsAndConditions} onChange={() => setTermsAndConditions(!TermsAndConditions)}/>
                    <p className='my-3' >I acknowledge the terms of services related to <a className='' onClick={(e) => { setTcsPopup(true); }}>TCS</a>.</p>
                   
                  </div>
                  <small className='text-danger'>{TermsAndConditionsError}</small>
                </div>
                 */}

                </div>

                </div>
              
                <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                    <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(9) }} style={{cursor:'pointer'}}>Back</div>
                    {/* <input type="button" name="command" value="Next (6/6)" className="continue__shipping--btn primary__btn border-radius-5"  /> onClick={(e) => handleSubmit(e)} onClick={(e) => {setTcsPopup(true); }}  */} 
                    <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />
                </div>
            </form>
        </div>
        </>
    )
}

export default Integration