import React, { useState, useRef, useEffect,useCallback } from 'react'
// import Header from '../Components/Header';
import axios from '../axios';
import { toast } from 'react-toastify';
import ResetPassword from './ResetPassword';
import { Link, useNavigate } from 'react-router-dom';
// import Loader from '../Loader';

const ForgetPassword = (props) => {
    document.title = 'ForgetPassword-YTCR';
    const navigate = useNavigate();
    const [Email, setEmail] = useState("");
    const [Token, setToken] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [loader, setLoader] = useState("");
    var BaseUrl = window.location.origin;
    // console.log(BaseUrl);


    const HandleSubmit = useCallback((e) => {
        e.preventDefault();
        setEmailError("");
        let flag = true;
        if (Email === "") {
            setEmailError("Please enter Email id.");
            flag = false;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            setEmailError('Please enter a valid email address');
            flag = false;
        }

        if (Email === "") {
            setEmailError("Email field is required.");
        } else {
            if (flag) {
                setLoader(true);

                axios.post("send_reset_password_link", {
                    "email": Email,
                    "base_url": BaseUrl
                }).then((response) => {
                    // console.log(response);
                    if (parseInt(response.data.success) === 0) {
                        setLoader(false);
                        // toast.error(response.data.message);
                        if (response.data.error_field === "email") {
                            setEmailError(response.data.message)
                        }
                    } else {
                        // console.log(response.data.data.token);
                        setToken(response.data.data.token)
                        setLoader(false);

                        props.setForgotPasswordFlag(false);
                        props.setOpen(false);
                        props.setResetPasswordEmail(Email);
                        // props.SuccessResetPasswordFlag(false);

                        // toast.success(response.data.message)
                    }

                }).catch((data) => {
                    // console.log(data.response.data);
                    const message = data.response.data.message;
                    const status = data.response.data.status;
                    if (status === 400) {
                        setEmailError(message)
                    }
                });
            }
        }
    }, [Email,BaseUrl,props]);

    // console.log(props.setResetPassword);
    const DivRef1 = useRef(null);

    const handleClose = (e) => {
        if (DivRef1.current && !DivRef1.current.contains(e.target)) {
            //   props.setForgotPasswordFlag(false);
        }
    }

    useEffect(() => {
        const handleEscKeyPress = (event) => {
            if (event.key === 'Escape') {
                props.setForgotPasswordFlag(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleEscKeyPress);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleEscKeyPress);
        };
    }, [props.setForgotPasswordFlag]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                HandleSubmit(e);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.body.style.overflow = 'auto';
        };
    }, []);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                HandleSubmit(e);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.body.style.overflow = 'auto';
        };
    }, [Email,HandleSubmit]);


    return (
        <>
            {
                Token === "" ?
                    <div className="modal is-visible" data-animation="slideInUp" onClick={(e) => { handleClose(e) }}>
                        <div className="modal-dialog quickview__main--wrapper">
                            <header className="modal-header quickview__header">
                                <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
                                    props.setForgotPasswordFlag(false);
                                }}>✕ </button>
                            </header>
                            <div className="quickview__inner">
                                <div className="row">

                                    <div className="login__section">

                                        <form onSubmit={HandleSubmit}>
                                            <div className="login__section--inner" ref={DivRef1}>
                                                <div className="p-5">
                                                    <div className="account__login--header mb-25">
                                                        <h3 className="account__login--header__title mb-10">Forgot Password</h3>
                                                        <p className="account__login--header__desc">Enter your Email address</p>
                                                    </div>
                                                    <div className="account__login--inner">
                                                        <div className="my-3">
                                                            <label>
                                                                <input className="account__login--input mb-2" placeholder="Email Id" type="text" name="username"
                                                                    onChange={(e) =>
                                                                        setEmail(e.target.value)
                                                                    }
                                                                    value={Email} />
                                                            </label>
                                                            <small className="text-danger mb-5">{EmailError}</small>
                                                        </div>

                                                        <div className="d-flex gap-4">
                                                            <button className="account__login--btn secondary__btn mt-4" onClick={() => {
                                                                props.setForgotPasswordFlag(false);
                                                            }}>Close</button>

                                                            <button className="account__login--btn primary__btn mt-4" type="submit">Continue</button>
                                                        </div>

                                                        <div className="account__login--divide my-3">
                                                            <span className="account__login--divide__text"></span>
                                                        </div>
                                                    </div>

                                                    <p>By continuing, you agree to YooTooCanRun's {" "}
                                                        <Link to="https://youtoocanrun.com/terms-and-conditions-for-use/" target="blank" >Terms of Services</Link> and <Link to="https://youtoocanrun.com/privacy-policy/" target="blank"> Privacy Statement</Link>.</p>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <ResetPassword Token={Token} />
            }
        </>
    )
}

export default ForgetPassword