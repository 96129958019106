import React, { useState,useEffect } from 'react';
import '../App.css';
import AddCommunication from "../Create_event_details/AddCommunication";
import AddTermConditions from "../Create_event_details/AddTermConditions";
import communiIcon from "../img/other/communications.png";
import LoaderImg from '../img/logo/running.gif';
import tc from "../img/other/t&c.png";
import axios from '../axios';
import { toast } from 'react-toastify';

const Communication = (props) => {

    const [AddNewCommunicate, setAddNewCommunicate] = useState(false);
    const [AddNewTermConditions, setAddNewTermConditions] = useState(false);

    const [DeleteCommPopup, setDeleteCommPopup] = useState(false);
    const [ConfirmDeleteTicket, setConfirmDeleteTicket] = useState(false);
    const [CommId, setCommId] = useState('');
    const [EventStatus, setEventStatus] = useState(false);
    const [ActionFlag, setActionFlag] = useState('');
    const [evntStatus, setevntStatus] = useState(''); 
    const [eventIndex, seteventIndex] = useState(''); 
    const [EventFlag, setEventFlag] = useState(false);
   
    const [CommunicationDetailsAll, setCommunicationDetailsAll] = useState(false);
    const [TermConditionsDetailsAll, setTermConditionsDetailsAll] = useState(false);
    const [TermStatus, setTermStatus] = useState(false);
    const [Loader, setLoader] = useState(false);
    
    const handleComm = (comm_id, operate) => {
       
        if (operate === 'edit') {
            setAddNewCommunicate(true);
        }
        if (operate === "delete") {
            setDeleteCommPopup(true);
        }
        if (operate === 'edit_terms') {
            setAddNewTermConditions(true);
        }
        if (operate === "delete_terms") {
            setDeleteCommPopup(true);
        }

        if (operate === "change_status" || operate === "change_status_term") {
            setDeleteCommPopup(true);
        }
    }

    const DeleteCommunications = () => {
        
        //console.log(ConfirmDeleteTicket,CommId);
        if (CommId !== "") {
          
            axios.post('delete_event_comm_faq',
                {
                    'event_id': localStorage.getItem("EventId"),
                    'event_comm_id': CommId,
                    'common_flag': ActionFlag === 'delete_terms' ? 'delete_terms' : ''
                }  
            )
                .then((response) => {
                    props.setEventApiCall(true)
                    toast.success(response.data.message)
                    setConfirmDeleteTicket(false)
                    setCommId('')
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    useEffect(() => {
        if(props.TicketDivShowFlag === false){
            setAddNewCommunicate(false);
        }
    }, [props.TicketDivShowFlag]);

    const ActionChangeStatus = () => {
        
        if(ActionFlag === 'change_status'){
            setEventFlag(true);  
            let EventArray = [...CommunicationDetailsAll];
            EventArray[eventIndex]['status'] = !evntStatus;
            setCommunicationDetailsAll(EventArray);  
        }

        if(ActionFlag === 'change_status_term'){
            setEventFlag(true);  
            let EventArray1 = [...TermConditionsDetailsAll];
            EventArray1[eventIndex]['status'] = !evntStatus;
            setTermConditionsDetailsAll(EventArray1);  
        }

        //console.log(FAQDetailsAll);
        setLoader(true);
        const payload = {
            coupon_id: CommId,
            coupon_status: evntStatus,
            action_flag: ActionFlag === 'change_status' ? 'communication_changes_status' : 'term_changes_status'
        };
        //console.log(payload);
        axios.post("status_coupon",payload)
        .then((response) => {
         // console.log(response);
            if (response.status === 200) {
                setLoader(false);
                toast.success(response.data.message)
                setevntStatus(''); 
                seteventIndex('');
                props.setEventApiCall(true);
                setEventStatus(false);
                setTermStatus(false);
            }
        })
        .catch((error) => {
            setLoader(false);
            console.log(error);
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.CommunicationDetails && EventFlag === false){
            setCommunicationDetailsAll(props.CommunicationDetails);
        }
        if(props.TermConditionsDetails && EventFlag === false){
            setTermConditionsDetailsAll(props.TermConditionsDetails);
        }
    }, [props.CommunicationDetails,props.TermConditionsDetails,EventFlag]);

    return (
        <>
            {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
            {
                DeleteCommPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">
                                                  
                                                    { ActionFlag && ActionFlag === 'change_status' ?
                                                      <p className="account__login--header__desc">Are you sure to change status this Communication?</p>
                                                    
                                                    : ActionFlag && ActionFlag === 'change_status_term' ?
                                                      <p className="account__login--header__desc">Are you sure to change status this Terms & Conditions?</p>
                                                    :
                                                      <p className="account__login--header__desc">Are you sure you want to delete this record?</p>
                                                    }
                                                </div>
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setDeleteCommPopup(false);
                                                    }}>Cancel</button>

                                                    {/* <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmDeleteTicket(true); DeleteCommunications(); setDeleteCommPopup(false);  }}>Yes</button> */}

                                                    { ActionFlag && ActionFlag === 'change_status' || ActionFlag && ActionFlag === 'change_status_term' ?
                                                        <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmDeleteTicket(true); ActionChangeStatus(); setDeleteCommPopup(false);  }}>Yes</button>
                                                    :
                                                       <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmDeleteTicket(true); DeleteCommunications(); setDeleteCommPopup(false);  }}>Yes</button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

                {
                AddNewCommunicate === true ?
             
                <AddCommunication setAddNewCommunicate={setAddNewCommunicate} setTicketDivShowFlag={props.setTicketDivShowFlag} TicketDivShowFlag={props.TicketDivShowFlag} setEventApiCall={props.setEventApiCall} CommId={CommId} setLoader={setLoader}/>
                :

                AddNewTermConditions === true ?
                 <AddTermConditions setAddNewTermConditions={setAddNewTermConditions} setTicketDivShowFlag={props.setTicketDivShowFlag} TicketDivShowFlag={props.TicketDivShowFlag} setEventApiCall={props.setEventApiCall} CommId={CommId} setLoader={setLoader}/>
                :
                <div className="main checkout__mian">
                    {/* <form action="#"> */}
                    <div className="checkout__content--step section__shipping--address ">

                        <div className="mb-5">
                           

                            <div className="section__header mb-4 d-flex align-items-center justify-content-between">
                                <h2 className="section__header--title h3">Communications</h2>
                                {/* <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setCommId(''); setAddNewCommunicate(true); }} style={{cursor:'pointer'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add communication
                                </div> */}
                            </div>
                           
                             <div className="row">
                                {CommunicationDetailsAll && CommunicationDetailsAll.length > 0
                                    ?
                                    CommunicationDetailsAll.map((item, index) => {
                                        return (
                                            <>
                                                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-5 form-que" key={index}>
                                                    <div className="card w-100">
                                                    
                                                    <div className="mx-3 w-100 input-field">
                                                        <div className="form-floating mb-0 text-left">
                                                         <div className="">
                                                            <h4 className="m-0">{item.subject_name}</h4>
                                                         </div> 
                                                          {/* <hr />
                                                         <div dangerouslySetInnerHTML={{ __html: item.message_content }} /> */}
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div className="mx-3 option-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setCommId(item.id); handleComm(item.id, 'delete');  }} ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                    </div>

                                                    {/* <div className="continue__shipping--btn secondary__btn border-radius-5 d-flex align-items-center" onClick={() => { setCommId(item.id); handleComm(item.id, 'edit');  }} style={{cursor:'pointer'}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg> Edit
                                                    </div> */}

                                                    <div className="mx-3 option-icon-edit">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="delete-icon" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setCommId(item.id); handleComm(item.id, 'edit');  }} style={{cursor:'pointer'}}
                                                        ><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                    </div>

                                                    <div className="col-sm-2">
                                                        <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                                            <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                                            value={item.status}
                                                            checked={item.status}
                                                            onChange={(e) => setEventStatus(!EventStatus)} 
                                                            onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.status); seteventIndex(index);  }} />
                                                        </div> 
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    :

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 py-5 my-2 pb-0">
                                    <div className="w-100 not-avilable-card p-3 d-flex flex-column align-items-center">
                                        <img className=""  src={`${communiIcon}`} alt="" style={{ width: "130px" }} />
                                        <p className="section__header--title h4 mb-4">No Communication Added</p>
                                        <p className="h4 mb-4">Please click on "<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add Communication" button to add new communication.</p>
                                    </div>
                                  </div>
                                }
                            </div> 

                            <hr />

                            <div className="section__header mb-4 mt-4 d-flex align-items-center justify-content-between">
                                <h2 className="section__header--title h3">Terms & Conditions</h2>
                                {/* <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setCommId(''); setAddNewTermConditions(true); }} style={{cursor:'pointer'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add Terms & Conditions
                                </div> */}
                            </div>
                            

                            <div className="row">
                                {TermConditionsDetailsAll && TermConditionsDetailsAll.length > 0
                                    ?
                                    TermConditionsDetailsAll.map((item, index) => {
                                        return (
                                            <>
                                                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-5 form-que" key={index}>
                                                    <div className="card w-100">
                                                    
                                                    <div className="mx-3 w-100 input-field">
                                                        <div className="form-floating mb-0 text-left">
                                                         <div className="">
                                                            <h4 className="m-0">{item.title}</h4>
                                                         </div> 
                                                          {/* <hr />
                                                         <div dangerouslySetInnerHTML={{ __html: item.message_content }} /> */}
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div className="mx-3 option-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setCommId(item.id); handleComm(item.id, 'delete_terms'); setActionFlag('delete_terms'); }} ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                    </div>

                                                    {/* <div className="continue__shipping--btn secondary__btn border-radius-5 d-flex align-items-center" onClick={() => { setCommId(item.id); handleComm(item.id, 'edit_terms');  }} style={{cursor:'pointer'}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg> Edit
                                                    </div> */}

                                                    <div className="mx-3 option-icon-edit">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="delete-icon" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setCommId(item.id); handleComm(item.id, 'edit_terms');  }} style={{cursor:'pointer'}}
                                                        ><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                    </div>

                                                    <div className="col-sm-2">
                                                        <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                                            <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                                            value={item.status}
                                                            checked={item.status}
                                                            onChange={(e) => setTermStatus(!TermStatus)} 
                                                            onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status_term'); setevntStatus(item.status); seteventIndex(index);  }} />
                                                        </div> 
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    :

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 py-5 my-2 pb-0">
                                        <div className="w-100 not-avilable-card p-3 d-flex flex-column align-items-center">
                                            <img className="mb-4"  src={`${tc}`} alt="" style={{ width: "130px" }} />
                                            <p className="section__header--title h4 mb-4">No Terms & Conditions Added</p>
                                            <p className="h4 mb-4">Please click on "<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add Terms & Conditions" button to add new Terms & Conditions.</p>
                                        </div>
                                    </div>
                                }
                            </div> 

                        </div>
                    </div>

                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                        <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(7) }} style={{cursor:'pointer'}} >Back</div>
                        <input type="submit" name="command" value="Save & Next (9/11)" className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(9); }} />
                    </div>
                    {/* </form> */}
                </div>
            }
        </>
    )
}

export default Communication;

