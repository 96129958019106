import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useParams, useNavigate } from 'react-router-dom';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// const CustomTooltip = ({ text }) => (
//   <div className="custom-tooltip">
//     {text}
//   </div>
// );

const AddCommunication = (props) => {

  const [Loader, setLoader] = useState(false);
  //console.log(PlatformFeePercent);
  const [SubjectName, setSubjectName] = useState('');
  const [SubjectNameError, setSubjectNameError] = useState('');
  const [MassegeContent, setMassegeContent] = useState('');
  const [MassegeContentError, setMassegeContentError] = useState('');
  const [EventCommId, setEventCommId] = useState('');
  // const [ImageContent, setImageContent] = useState('');
  const [OldMassegeContent, setOldMassegeContent] = useState('');
  const [EditFlag, setEditFlag] = useState(false);
  
  //console.log(props.EventTypeData);
   useEffect(() => {
    
    if ((props.CommId && props.CommId !== 0) || EditFlag) {
      // console.log('ss');
      axios.post('edit_event_comm_faq',
        {
          'event_id': localStorage.getItem("EventId"),
          'event_comm_id': props.CommId,
          'event_edit_flag': 'communication_edit'
        }
      )
        .then((response) => {
          //console.log(response.data);
          if (response.data.data.communication_edit_details) {
            let comm_details = response.data.data.communication_edit_details[0];
              setEventCommId(comm_details.id !== '' ? comm_details.id : '')
              setSubjectName(comm_details.subject_name !== '' ? comm_details.subject_name : '');
              setMassegeContent(comm_details.message_content !== '' ? comm_details.message_content : '');
              setOldMassegeContent(comm_details.message_content !== '' ? comm_details.message_content : '');
              
              if(comm_details.content_image && comm_details.content_image !== ''){
                // setImageContent(comm_details.content_image);
                setMassegeContent(comm_details.message_content !== '' ? comm_details.message_content+''+comm_details.content_image : '');
              }
          }
          setEditFlag(false);
        })
        .catch((error) => {
          console.log(error);
        })
    }
    props.setTicketDivShowFlag(true);
   }, [props.CommId,EditFlag])
  
  
  const CommEditId = props.CommId ? props.CommId : 0;
  // console.log(props.CommId);
  
   const handleValidation = () => {
    // console.log(TicketStatus);
    var flag = true;
    if (SubjectName === "") {
      setSubjectNameError("Please enter subject name");
      flag = false
    } else {
      setSubjectNameError("");
    }

    if (MassegeContent === "") {
      setMassegeContentError("Please enter message content");
      flag = false
    } else {
      setMassegeContentError("");
    }

    return flag;
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      props.setLoader(true);
      props.setTicketDivShowFlag(true);
      const payload = {
        event_id: localStorage.getItem("EventId"),
        user_id: localStorage.getItem("ID"),
        subject_name: SubjectName,
        message_content: OldMassegeContent,
        event_comm_id : EventCommId
      };
      // console.log(payload);
      axios.post("event_communication", payload)
        .then((response) => {
          props.setLoader(false);
          //console.log(response.data);
          if (response.status === 200) {
            toast.success(response.data.message);
            props.setAddNewCommunicate(false);
            props.setEventApiCall(true);
            props.setTicketDivShowFlag(false);
          }
        })
        .catch((error) => {
          props.setLoader(false);
          console.log(error);
        });
    }

  };

  const handleDescriptionChange = (e, editor) => {
    const data = editor.getData();
    setMassegeContent(data);
    setOldMassegeContent(data);
  };

  // console.log(MassegeContent);

  function MyCustomUploadAdapterPlugin(editor) {
    // console.log(editor);
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
    };
  }
  // console.log(EventCommId);
  class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            this.loader.file
                
                .then(file => {
                    setLoader(true);
                    formData.append('event_id', localStorage.getItem("EventId"));
                    formData.append('user_id', localStorage.getItem("ID"));
                    formData.append('event_comm_id', CommEditId);
                    formData.append('file', file);
                    // formData.append('message_content', OldMassegeContent);
                    
                    axios.post("communication_message_image", formData)
                        .then((response) => {
                          // console.log(response);
                            if (response.status === 200) {
                                // setLoader(false);
                            }
                            setLoader(false);
                            props.setEventApiCall(true);
                            setEditFlag(true);
                        })
                        .catch(() => {
                            setLoader(false);
                            console.log('error');
                        });
                });
        });
    }

    abort() {
        // Implement abort functionality if needed
    }
}

// const Img = '<img src="http://localhost/ytcr_backend/public/uploads/communication_email_images/agenda.png" alt="Example Image">';


  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

      <div className="row">
        <div className="col-lg-8 col-md-6">
        <div className="main checkout__mian">
        <form onSubmit={handleSubmit}>
          <div className="checkout__content--step section__shipping--address ">
            <div className="mb-5">
              <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                <h2 className="section__header--title h3">Add Communication</h2>
                <div className="event-visibility hand_cursor">

                </div>
              </div>
            </div>

            <div className="row ">

                <div className="col-sm-12">
                  <div className="form-floating mt-3 mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={SubjectName}
                      onChange={(e) => {
                        setSubjectName(e.target.value);
                      }}
                      
                    />
                    <label>Subject Name <span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">{SubjectNameError}</small>
                </div>

                <div className="col-sm-12 mt-4">
                    <label>Message <span className="checkout__input--label__star">*</span> </label>
                    <div className="form-floating mt-3 mb-0">
                        <CKEditor
                          
                            editor={ClassicEditor}
                            data={MassegeContent ? MassegeContent : ""}
                            onChange={handleDescriptionChange}
                            
                            config={{
                              extraPlugins: [MyCustomUploadAdapterPlugin], // Add the custom upload adapter plugin
                               // Additional CKEditor configurations if needed
                            }}
                        
                        />
                    </div>
                    <small className="text-danger">{MassegeContentError}</small>
                </div>
            </div>
          
          </div>
          <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              onClick={(e) => { props.setAddNewCommunicate(false); props.setTicketDivShowFlag(false) }}
              style={{cursor:'pointer'}} >
              Cancel
            </div>

            <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />

          </div>
        </form >
     
      </div >
    </div> 
        
          <div className="col-lg-4 col-md-6">
                  <aside className="checkout__sidebar sidebar border-radius-10">
                    <div className="px-5 pb-3">
                      <div className="row">
                        <div className="col-sm-6 text-left">
                          <h2 className="section__header--title h3 py-3">Placeholders</h2>
                        </div>

                        <div className="col-sm-12 text-left">
                          <p className="pb-4 mb-0">You can use following placeholders in your message content.</p>
                        </div>

                        <div className="col-sm-12 text-left">
                          <p className="pb-2 mb-0"> <strong>{'Example:'}</strong></p>
                          <p className="pb-1 mb-0">{'Hi {FIRSTNAME},'}</p>
                          <p className="pb-1 mb-0">{'Welcome to {EVENTNAME}.'}</p>
                        </div>
                        
                      </div>
                    </div>
                  
                    <div className=" placeholder-ui px-5">
                      <div className="row placeholder-list">
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">FIRSTNAME</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">LASTNAME</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">EVENTID</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">EVENTNAME</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">EVENTSTARTDATE</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">EVENTSTARTTIME</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">EVENTENDDATE</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">EVENTENDTIME</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">YTCRTEAM</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">EVENTURL</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">COMPANYNAME</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">TOTALTICKETS</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">VENUE</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">TICKETAMOUNT</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">RACECATEGORY</p>
                        </div>
                        <div className="col-sm-6 card p-3 my-3 text-left">
                          <p className="">REGISTRATIONID</p>
                        </div>
                      </div>
                    </div>
                     
                  </aside>
          </div> 
        

      </div>

    </>
  )
}

export default AddCommunication;

