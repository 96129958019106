import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useParams, useNavigate } from 'react-router-dom';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';

const AddAgeCriteria = (props) => {

  const [Loader, setLoader] = useState(false);
  
  const [DistanceCategory, setDistanceCategory] = useState(0);
  const [AgeCategory, setAgeCategory] = useState('');
  const [AgeStart, setAgeStart] = useState(0);
  const [AgeEnd, setAgeEnd] = useState(0);
  const [Gender, setGender] = useState('');

  const [DistanceCategoryError, setDistanceCategoryError] = useState('');
  const [AgeCategoryError, setAgeCategoryError] = useState('');
  const [AgeStartError, setAgeStartError] = useState('');
  const [AgeEndError, setAgeEndError] = useState('');
  const [GenderError, setGenderError] = useState('');

  const [EventCommId, setEventCommId] = useState('');

  //console.log(Gender); 
   useEffect(() => {
    
    if (props.CommId && props.CommId !== 0) {
      axios.post('edit_event_comm_faq',
        {
          'event_id': localStorage.getItem("EventId"),
          'event_comm_id': props.CommId,
          'event_edit_flag': 'age_criteria_edit'
        }
      )
        .then((response) => {
         //console.log(response.data);
          if (response.data.data.age_criteria_details) {
            let comm_details = response.data.data.age_criteria_details[0];
            //console.log(comm_details);
             setEventCommId(comm_details.id !== '' ? comm_details.id : '')
             setDistanceCategory(comm_details.distance_category !== '' ? comm_details.distance_category : 0);
             setAgeCategory(comm_details.age_category !== '' ? comm_details.age_category : '');
             setAgeStart(comm_details.age_start !== '' ? comm_details.age_start : 0);
             setAgeEnd(comm_details.age_end !== '' ? comm_details.age_end : 0);
             setGender(comm_details.gender !== '' ? comm_details.gender : '');
          }

        })
        .catch((error) => {
          console.log(error);
        })
    }
    // props.setTicketDivShowFlag(true);
   }, [props.CommId])
  

  //console.log(StartDate);
  
   const handleValidation = () => {
    //console.log('ss');
    var flag = true;
    if (DistanceCategory === 0) {
      setDistanceCategoryError("Please select distance category");
      flag = false
    } else {
      setDistanceCategoryError("");
    }

    if (AgeCategory === "") {
      setAgeCategoryError("Please enter age category name");
      flag = false
    } else {
      setAgeCategoryError("");
    }

    if (AgeStart === 0) {
      setAgeStartError("Please select age start");
      flag = false
    } else {
      setAgeStartError("");
    }

    if (AgeEnd === 0) {
      setAgeEndError("Please select age end");
      flag = false
    } else if(parseInt(AgeStart) > parseInt(AgeEnd)){
      setAgeEndError("Please select end age less than start age");
      flag = false
    } else {
      setAgeEndError("");
    }

    if (Gender === "") {
      setGenderError("Please select gender");
      flag = false
    } else {
      setGenderError("");
    }

    return flag;
  };

 // console.log(AgeStart,AgeEnd);
   const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      setLoader(true);
      props.setTicketDivShowFlag(true);
      const payload = {
        event_id: localStorage.getItem("EventId"),
        user_id: localStorage.getItem("ID"),
        distance_category: DistanceCategory,
        age_category: AgeCategory,
        age_start: AgeStart,
        age_end: AgeEnd,
        gender: Gender,
        event_comm_id : EventCommId
      };
      // console.log(payload);
      axios.post("add_edit_age_criteria", payload)
        .then((response) => {
          setLoader(false);
          //console.log(response.data);
          if (response.status === 200) {
            toast.success(response.data.message);
            props.setAddingAgeCriteria(false);
            props.setEventApiCall(true);
            props.setTicketDivShowFlag(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }

  };

  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

     
        <div className="main checkout__mian">
        <form onSubmit={handleSubmit}>
          <div className="checkout__content--step section__shipping--address ">
            <div className="mb-5">
              <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                <h2 className="section__header--title h3">Add Age Category</h2>
              </div>
            </div>

            <div className="row ">

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-3">
                  <div className="form-floating mb-0">
                    <select className="form-control" name="filed_mapping" value={DistanceCategory}  onChange={(e) => { setDistanceCategory(e.target.value); }}> 
                      <option value="0">-- Select --</option>
                         {props.TicketsDetails ?
                            props.TicketsDetails.map((item) => {
                                return (
                                <option key={item.id} value={item.id}>
                                    {item.ticket_name}
                                </option>
                                );
                            })
                          : null
                        }
                    </select>
                    <label className="form-check-label">Distance Category <span className="checkout__input--label__star">*</span></label>
                    <small className="text-danger">{DistanceCategoryError}</small>
                  </div>
                </div>
                
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-3">
                  <div className="form-floating mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={AgeCategory}
                      onChange={(e) => {
                        setAgeCategory(e.target.value);
                      }}
                    />
                    <label>Age Category Name <span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">{AgeCategoryError}</small>
                </div>
                 
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 my-3">
                  <div className="form-floating mb-0"> 
                    <select className="form-control" name="filed_mapping" value={AgeStart}  onChange={(e) => { setAgeStart(e.target.value); }}> 
                      <option value="0">-- Select --</option>
                         {props.AgeDetails ?
                            props.AgeDetails.map((item) => {
                                return (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                                );
                            })
                          : null
                        }
                    </select>
                    <label className="form-check-label">Age Start <span className="checkout__input--label__star">*</span></label>
                    <small className="text-danger">{AgeStartError}</small>
                  </div>
                </div>   

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 my-3">
                  <div className="form-floating mb-0">
                    <select className="form-control" name="filed_mapping" value={AgeEnd}  onChange={(e) => { setAgeEnd(e.target.value); }}> 
                      <option value="0">-- Select --</option>
                         {props.AgeDetails ?
                            props.AgeDetails.map((item) => {
                                return (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                                );
                            })
                          : null
                        }
                    </select>
                    <label className="form-check-label">Age End <span className="checkout__input--label__star">*</span></label>
                    <small className="text-danger">{AgeEndError}</small>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-6 my-3">
                
                <label className="checkout__input--label mb-1" for="input1">
                    Gender <span className="checkout__input--label__star">*</span>
                </label>

                <div className="row mb-3">
                    <div className="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-4 col-xxl-4 my-2 my-2">
                      <div className="plans">
                        <label className="plan basic-plan">
                          <input name='sss'
                            type="radio"
                            className="btn-check"
                            id={'btn-check-outlined'}
                            autoComplete="off"
                            onClick={(e) => { setGender(e.target.value); }}
                            value={Gender === 1 ? Gender : 1} 
                            checked={Gender == 1}
                            // defaultChecked={item.checked}
                          />
                          <div className="plan-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height={25}
                              viewBox="0 -960 960 960"
                              width={25}
                            >
                              <path d='' />
                            </svg>
                            <div className="plan-details" for={'btn-check-outlined'}>
                              <span className="h3 m-0"> Male</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
               
                    <div className="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-4 col-xxl-4 my-2 my-2">
                      <div className="plans">
                        <label className="plan basic-plan">
                          <input name='sss'
                            type="radio"
                            className="btn-check"
                            id={'btn-check-outlined'}
                            autoComplete="off"
                            onClick={(e) => { setGender(e.target.value); }}
                            value={Gender === 2 ? Gender :2} 
                            checked={Gender == 2}
                          />
                          <div className="plan-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height={25}
                              viewBox="0 -960 960 960"
                              width={25}
                            >
                              <path d='' />
                            </svg>
                            <div className="plan-details" for={'btn-check-outlined'}>
                              <span className="h3 m-0"> Female</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-4 col-xxl-4 my-2 my-2">
                      <div className="plans">
                        <label className="plan basic-plan">
                          <input name='sss'
                            type="radio"
                            className="btn-check"
                            id={'btn-check-outlined'}
                            autoComplete="off"
                            onClick={(e) => { setGender(e.target.value); }}
                            value={Gender === 3 ? Gender : '3'} 
                            checked={Gender == 3}
                          />
                          <div className="plan-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height={25}
                              viewBox="0 -960 960 960"
                              width={25}
                            >
                              <path d='' />
                            </svg>
                            <div className="plan-details" for={'btn-check-outlined'}>
                              <span className="h3 m-0"> Other</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                </div>
                <small className="text-danger">{GenderError}</small>
              </div>

                
            </div>
          
          </div>
          <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              onClick={(e) => { props.setAddingAgeCriteria(false); props.setTicketDivShowFlag(false) }}
              style={{cursor:'pointer'}} >
              Cancel
            </div>

            <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />

          </div>
        </form >

    </div>

    </>
  )
}

export default AddAgeCriteria;

