import React, { useEffect, useState } from 'react'
import axios from '../axios'
import { useNavigate } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {UserAccessRights} from '../Custom';

const ShowOrganizer = (props) => {

    const navigate = useNavigate();
    const Section = props.Tab;
    // console.log(Section);
    const [OrganiserData, setOrganiserData] = useState([])
    useEffect(() => {
        axios.post("get_organizer", {})
            .then((response) => {
                // console.log(response.data.data.organizerData[0]);
                setOrganiserData(response.data.data.organizerData[0]);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])
    const renderAbout = (about) => {
        return { __html: about };
    };

    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const openLightbox = (index) => {
        setImageIndex(index);
        setIsOpen(true);
    };

    //--------------- access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    const OrgUserAccessModules = localStorage.getItem("OrgUserAccessModules") ? JSON.parse(localStorage.getItem("OrgUserAccessModules")) : '';

    useEffect(() => {
        if(AccessRightFlag === '1'){
            const access = UserAccessRights({module_name: "Organiser"});
            if(access === true){
            alert("You don't have any access to this module");
            navigate('/');
            }
        }
    }, []);


    return (
        <>
            <div className={Section === 'show' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none"}>
                <div className="hand_cursor section__header d-flex align-items-center justify-content-between mb-4">
                    <h2 className="section__header--title h3">{""}</h2>
                   
                    {AccessRightFlag === '1' ?
                        <>
                            {OrgUserAccessModules && OrgUserAccessModules.Organiser === 2 ?
                                <div className="continue__shipping--btn secondary__btn border-radius-5"
                                onClick={(e) => props.setTab('edit_org')} >
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        height={24}
                                        viewBox="0 -960 960 960"
                                        width={24}>
                                        <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                                    </svg>
                                    Edit
                                </div>
                            : null }
                        </>
                    : 
                        <div className="continue__shipping--btn secondary__btn border-radius-5"
                        onClick={(e) => props.setTab('edit_org')} >
                            <svg xmlns="http://www.w3.org/2000/svg"
                                height={24}
                                viewBox="0 -960 960 960"
                                width={24}>
                                <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                            </svg>
                            Edit
                        </div>
                    }

                </div>

                <div className="row p-0 mb-5">
                    {/* <h4 className="">Name</h4> */}
                    <div className="row">
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Organisation Name</h4>
                                <p className="m-4">{OrganiserData && OrganiserData.name ? OrganiserData.name : "NA"}</p>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Email</h4>
                                <p className="m-4">{OrganiserData && OrganiserData.email ? OrganiserData.email : "NA"}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row p-0 mb-5">
                    {/* <h4 className="">Name</h4> */}
                    <div className="row">
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Mobile</h4>
                                <p className="m-4">{OrganiserData && OrganiserData.mobile ? OrganiserData.mobile : "NA"}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 my-3">
                        <div className='profile-card'>
                            <h4 className="m-4 text-center">About</h4>
                            <p className="m-4">
                                {OrganiserData && OrganiserData.about ?
                                    <div dangerouslySetInnerHTML={renderAbout(OrganiserData.about)}></div>
                                    :
                                    "NA"
                                }
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row p-0 mb-5 mt-4">
                    <div className="row">
                        {
                            (OrganiserData && (OrganiserData.gst === 1 || OrganiserData.length === 0)) ?
                                <>
                                    <div className="col-sm-6 my-3">
                                        <div className='profile-card text-center'>
                                            <h4 className="m-4">GST Number</h4>
                                            <p className="m-4">{OrganiserData && OrganiserData.gst_number ? OrganiserData.gst_number : "NA"}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 my-3">
                                        <div className='profile-card text-center'>
                                            <h4 className="m-4">GST Percentage</h4>
                                            {/* <p className="m-4">{OrganiserData && OrganiserData.gst_percentage ? OrganiserData.gst_percentage : "NA"}</p> */}
                                            <p className="m-4">18</p>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                    </div>

                    <div className="row">
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Contact Person</h4>
                                <p className="m-4">{OrganiserData && OrganiserData.contact_person ? OrganiserData.contact_person : "NA"}</p>
                            </div>
                        </div>
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Contact Number</h4>
                                <p className="m-4">{OrganiserData && OrganiserData.contact_no ? OrganiserData.contact_no : "NA"}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row p-0 mb-5">
                    {/* <h4 className="">Name</h4> */}
                    <div className="row">
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Organiser Logo Image</h4>
                                {/* <p className="m-4">{OrganiserData && OrganiserData.email ? OrganiserData.email : "NA"}</p> */}
                                <p className="m-4">
                                    {OrganiserData && OrganiserData.logo_image ?
                                        <img
                                            src={OrganiserData.logo_image}
                                            alt=""
                                            style={{ height: "50px", width: "50px", cursor: "pointer" }}
                                            onClick={() => openLightbox(0)} // Pass index 0, assuming there's only one image
                                        />
                                        :
                                        null}
                                </p>
                            </div>
                        </div>

                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Banner Image</h4>
                                {/* <p className="m-4">{OrganiserData && OrganiserData.name ? OrganiserData.name : "NA"}</p> */}
                                <p className="m-4">
                                    {OrganiserData && OrganiserData.banner_image ?
                                        <img src={OrganiserData && OrganiserData.banner_image ? OrganiserData.banner_image : ""} alt="" style={{ height: "50px", width: "100px", cursor: "pointer" }} onClick={() => openLightbox(1)} />
                                        :
                                        null}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="row p-0 mb-5">
                    {/* <h4 className="">Name</h4> */}
                    <div className="row">
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Registered Pancard</h4>
                                {/* <p className="m-4">{OrganiserData && OrganiserData.email ? OrganiserData.email : "NA"}</p> */}
                                <p className="m-4">
                                    {OrganiserData && OrganiserData.company_pan ?
                                        OrganiserData.company_pan
                                        // <img
                                        //     src={OrganiserData.company_pan}
                                        //     alt=""
                                        //     style={{ height: "50px", width: "50px", cursor: "pointer" }}
                                        //     onClick={() => openLightbox(0)} // Pass index 0, assuming there's only one image
                                        // />
                                        :
                                        null}
                                </p>
                            </div>
                        </div>

                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Registered GST Certificate</h4>
                                {/* <p className="m-4">{OrganiserData && OrganiserData.name ? OrganiserData.name : "NA"}</p> */}
                                <p className="m-4">
                                    {OrganiserData && OrganiserData.gst_certificate ?
                                        OrganiserData.gst_certificate
                                        // <img src={OrganiserData && OrganiserData.gst_certificate ? OrganiserData.gst_certificate : ""} alt="" style={{ height: "50px", width: "100px", cursor: "pointer" }} onClick={() => openLightbox(1)} />
                                        :
                                        null}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={imageIndex === 0 ? OrganiserData.logo_image : OrganiserData.banner_image} // Determine which image to show based on imageIndex
                    onCloseRequest={() => setIsOpen(false)}
                    reactModalStyle={{
                        overlay: { zIndex: 9999 }
                    }}
                />
            )}
        </>
    )
}

export default ShowOrganizer