import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useParams, useNavigate } from 'react-router-dom';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';
import RegistrationPopup from '../Components/RegistrationPopup';


const CustomTooltip = ({ text }) => (
  <div className="custom-tooltip" style={{top: '70px'}}>
    {text}
  </div>
);

const AddTickets = (props) => {
  
  //console.log(props.TicketCopyIdData);
  //const navigate = useNavigate();
  //const TicketId = props.EditTicketId ? props.EditTicketId : 0;
  
  let TicketId;
  let EditTicketId;
  if(props.EditTicketId !== ''){
    TicketId = props.EditTicketId;
    EditTicketId = props.EditTicketId;
  }else if(props.TicketCopyIdData !== ''){
    TicketId = props.TicketCopyIdData;
    EditTicketId = 0;
  }else{
    TicketId = 0;
    EditTicketId = 0;
  }

  const YtcrFeePercent = props.YtcrFeePercent ? props.YtcrFeePercent : 0;
  //console.log(YtcrFeePercent);
 
  const PaymentGatewayFeePercent = props.PaymentGatewayFeePercent ? props.PaymentGatewayFeePercent : 0;
  //console.log(props.PaymentGatewayFeePercent);
  const [TicketStatus, setTicketStatus] = useState(1);
  const [TicketName, setTicketName] = useState('');
  const [TotalQuantity, setTotalQuantity] = useState('');
  const [TicketPrice, setTicketPrice] = useState('');
  const [StartDate, setStartDate] = useState('');
  const [StartTime, setStartTime] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [EndTime, setEndTime] = useState('');
  const [AdvancedSettings, setAdvancedSettings] = useState(true);
  const [FreeStatusFlag, setFreeStatusFlag] = useState(false);
  const [DonationStatusFlag, setDonationStatusFlag] = useState(false);
  const [ApplyAgeLimit, setApplyAgeLimit] = useState(false);
  const [AgeStart, setAgeStart] = useState(0);
  const [AgeEnd, setAgeEnd] = useState(0);
  
  const [WhoPayYtcrFee, setWhoPayYtcrFee] = useState(2);
  const [WhoPayPaymentGatewayFee, setWhoPayPaymentGatewayFee] = useState(2);

  const [MinBookCount, setMinBookCount] = useState('');
  const [MaxBookCount, setMaxBookCount] = useState('');
  const [MinDonationAmt, setMinDonationAmt] = useState('');
  const [TicketDescription, setTicketDescription] = useState('');
  const [MsgAttendee, setMsgAttendee] = useState('');
  const [Category, setCategory] = useState(0);

  const [EarlyBird, setEarlyBird] = useState(false);
  const [NoOfTickets, setNoOfTickets] = useState('');
  const [EarlyBirdStartDate, setEarlyBirdStartDate] = useState('');
  const [EarlyBirdEndDate, setEarlyBirdEndDate] = useState('');
  const [EarlyBirdStartTime, setEarlyBirdStartTime] = useState('');
  const [EarlyBirdEndTime, setEarlyBirdEndTime] = useState('');
  const [Discount, setDiscount] = useState(1);
  const [DiscountValue, setDiscountValue] = useState('');

  const [NoOfTicketsError, setNoOfTicketsError] = useState('');
  const [EarlyBirdStartDateError, setEarlyBirdStartDateError] = useState('');
  const [EarlyBirdEndDateError, setEarlyBirdEndDateError] = useState('');
  const [DiscountError, setDiscountError] = useState('');
  const [DiscountValueError, setDiscountValueError] = useState('');

  //console.log(props.AgeDetails);
  const [Loader, setLoader] = useState(false);

  const [EventPublicStatus, setEventPublicStatus] = useState('active');
  const [EventPrivateStatus, setEventPrivateStatus] = useState('');
  const [EventDraftStatus, setEventDraftStatus] = useState('');
  // console.log(EventDraftStatus);
  const [TicketNameError, setTicketNameError] = useState('');
  const [TotalQuantityError, setTotalQuantityError] = useState('');
  const [TicketPriceError, setTicketPriceError] = useState('');
  const [StartDateError, setStartDateError] = useState('');
  const [EndDateError, setEndDateError] = useState('');
  const [WhoPayYtcrFeeError, setWhoPayYtcrFeeError] = useState('');
  const [WhoPayPaymentGatewayFeeError, setWhoPayPaymentGatewayFeeError] = useState('');
  const [MinBookCountError, setMinBookCountError] = useState('');
  const [MaxBookCountError, setMaxBookCountError] = useState('');
  const [MinDonationAmtError, setMinDonationAmtError] = useState('');
  const [StartTimeError, setStartTimeError] = useState('');
  const [EndTimeError, setEndTimeError] = useState('');
  const [TicketDescriptionError, setTicketDescriptionError] = useState('');
  const [SettingStartTimeError, setSettingStartTimeError] = useState('');
  const [SettingEndTimeError, setSettingEndTimeError] = useState('');
  const [CategoryError, setCategoryError] = useState('');
  // console.log(EarlyBirdStartDate);
  const [NewTicketPrice, setNewTicketPrice] = useState('');
  const [EditStartDate, setEditStartDate] = useState('');
  const [EditEarlyBirdStartDate, setEditEarlyBirdStartDate] = useState('');

  const [AgeStartError, setAgeStartError] = useState('');
  const [AgeEndError, setAgeEndError] = useState('');
  const [TicketCalculationDetails, setTicketCalculationDetails] = useState([]);

  const EventTypeData = props.EventTypeData ? props.EventTypeData : '';

  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const inputRef8 = useRef(null);
 
  const [CalculationActiveFlag, setCalculationActiveFlag] = useState(false);
  // console.log(Discount);
  useEffect(() => {
    if (TicketId !== 0) {
      axios.post('get_ticket_detail',
        {
          'ticket_id': TicketId
        }
      )
        .then((response) => {
          //console.log(response.data.data.Ticket[0]);
          if (response.data.data.Ticket[0]) {
            let ticket = response.data.data.Ticket[0];
            setTicketStatus(ticket.ticket_status)
            if (ticket.ticket_status === 1) {
              setEventPublicStatus('active');
              setEventPrivateStatus('');
              setEventDraftStatus('');
            }
            if (ticket.ticket_status === 2) {
              setEventPublicStatus('');
              setEventPrivateStatus('active');
              setEventDraftStatus('');
            }
            if (ticket.ticket_status === 3) {
              setEventPublicStatus('');
              setEventPrivateStatus('');
              setEventDraftStatus('active');
            }

            setTicketName(ticket.ticket_name);
            setTotalQuantity(ticket.total_quantity);
            setTicketPrice(ticket.ticket_price);
            setStartDate(ticket.ticket_start_date);
            setStartTime(ticket.ticket_start_time);
            setEndDate(ticket.ticket_end_date);
            setEndTime(ticket.ticket_end_time);
            setAdvancedSettings(ticket.advanced_settings === 1 ? true : true);
            setWhoPayYtcrFee(ticket.player_of_fee);
            setWhoPayPaymentGatewayFee(ticket.player_of_gateway_fee)
            setMinBookCount(ticket.min_booking);
            setMaxBookCount(ticket.max_booking);
            setTicketDescription(ticket.ticket_description);
            setMsgAttendee(ticket.msg_attendance)

            setEarlyBird(ticket.early_bird === 1 ? true : false);
            setNoOfTickets(ticket.no_of_tickets);
            setEarlyBirdStartDate(ticket.eb_start_date);
            setEarlyBirdEndDate(ticket.eb_end_date);
            setEarlyBirdStartTime(ticket.eb_start_time);
            setEarlyBirdEndTime(ticket.eb_end_time);
            setDiscount(ticket.discount ? ticket.discount : 1);
            setDiscountValue(ticket.discount_value)
            setMinDonationAmt(ticket.minimum_donation_amount);
            setCalculate(true);
            setCategory(ticket.category);

            setEditStartDate(ticket.ticket_start_date !== '' ? ticket.ticket_start_date : '');
            setEditEarlyBirdStartDate(ticket.eb_start_date);

            if (ticket.ticket_status === 2) {
              setFreeStatusFlag(true);
            } else {
              setFreeStatusFlag(false);
            }

            setApplyAgeLimit(ticket.apply_age_limit === 1 ? true : false);
            setAgeStart(ticket.age_start); 
            setAgeEnd(ticket.age_end);
            setCalculationActiveFlag(true);
            //setCalculate(true)
          }

        })
        .catch((error) => {
          console.log(error);
        })
    }
    props.setTicketDivShowFlag(true);
  }, [TicketId])

  //console.log(Discount);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month index starts from 0
  const day = String(currentDate.getDate()).padStart(2, '0');
  const CurrentDate1 = `${year}-${month}-${day}`;

  //console.log(StartDate);

  const handleValidation = () => {
    // console.log(TicketStatus);
    var flag = true;
    var flag_field = "";
    if (TicketName === "") {
      setTicketNameError("Please enter race category name");
      flag = false;
      flag_field = "TicketName";
    } else {
      setTicketNameError("");
    }

    if (Category === 0) {
      setCategoryError("Please select category");
      flag = false;
    } else {
      setCategoryError("");
    }

    if (TotalQuantity === "") {
      setTotalQuantityError("Please enter maximum registration");
      flag = false;
      flag_field = "TotalQuantity";
    } else { setTotalQuantityError(""); }

    if (TicketStatus === 1) { //|| TicketStatus === 2
      if ((TicketPrice === "") || (TicketPrice === 0)) {
        setTicketPriceError("Please enter race category price");
        flag = false;
        flag_field = "TicketPrice";
      } else { setTicketPriceError(""); }
    } else { setTicketPriceError(""); }

    if ((TicketStatus === 1) || (TicketStatus === 2)) {
      if (MinBookCount === "" || parseInt(MinBookCount) === 0) {
        setMinBookCountError("Please enter minimum booking count");
        flag = false;
      } else if (parseFloat(TotalQuantity) < parseFloat(MinBookCount)) {
        setMinBookCountError("Please enter minimum booking count less than maximum registration");
        flag = false;
      } else { setMinBookCountError(""); }

      if (MaxBookCount === "" || parseInt(MaxBookCount) === 0) {
        setMaxBookCountError("Please enter allow registrations upto");
        flag = false;
      } else if (parseFloat(TotalQuantity) < parseFloat(MaxBookCount)) {
        setMaxBookCountError("Please enter allow registrations upto less than maximum registration");
        flag = false;
      } else { setMaxBookCountError(""); }
    }
    //console.log(TotalQuantity,MinBookCount);

    if (TicketStatus === 3 && MinDonationAmt === "") {
      setMinDonationAmtError("Please enter maximum donation amount");
      flag = false;
    } else { setMinDonationAmtError(""); }

    if (StartDate === "") {
      setStartDateError("Please select registration starts from");
      flag = false;
    } else { setStartDateError(""); }

    if (EndDate === "") {
      setEndDateError("Please select registration ends on");
      flag = false;
    } else { setEndDateError(""); }

    if (StartTime === "") {
      setStartTimeError("Please select start time");
      flag = false;
    } else { setStartTimeError(""); }

    if (EndTime === "") {
      setEndTimeError("Please select end time");
      flag = false;
    } else { setEndTimeError(""); }

    if (StartTime !== '' && EndTime !== '') {

      const start = new Date(`${StartDate}T${StartTime}`);
      const end = new Date(`${EndDate}T${EndTime}`);

      if (start >= end) {
        setEndTimeError("Please select sale end time is greater than sale start time");
        flag = false;
      } else {
        setEndTimeError('');
      }

    }

    if (AdvancedSettings === true) {

      if (WhoPayYtcrFee === 0) {
        setWhoPayYtcrFeeError("Please select Who will pay Convenience Fee");
        flag = false;
      } else { setWhoPayYtcrFeeError(""); }

      if (WhoPayPaymentGatewayFee === 0) {
        setWhoPayPaymentGatewayFeeError("Please select Who will pay Payment Gateway fee");
        flag = false;
      } else { setWhoPayPaymentGatewayFeeError(""); }

      if (TicketDescription === "" && TicketStatus === 1) {
        setTicketDescriptionError("Please enter description");
        flag = false;
      } else { setTicketDescriptionError(""); }
    }

    if (EarlyBird === true) {
      if (NoOfTickets === 0 || NoOfTickets === "") {
        setNoOfTicketsError("Please enter valid no of registrations limit");
        flag = false;
        flag_field = "NoOfTickets";
      } else if (parseFloat(TotalQuantity) < parseFloat(NoOfTickets)) {
        setNoOfTicketsError("Please enter no of registrations limit less than quantity");
        flag = false;
      }else { setNoOfTicketsError(''); }
   
      if (DiscountValue === 0 || DiscountValue === "") {
        setDiscountValueError("Please enter discount value");
        flag = false;
        flag_field = "DiscountValue";
      } else { setDiscountValueError(''); }

      if (EarlyBirdStartDate === "") {
        setEarlyBirdStartDateError("Please Select Start Date");
        flag = false;
        flag_field = "EarlyBirdStartDate";
      } else { setEarlyBirdStartDateError(''); }

      if (EarlyBirdEndDate === "") {
        setEarlyBirdEndDateError("Please Select End Date");
        flag = false;
        flag_field = "EarlyBirdEndDate";
      } else { setEarlyBirdEndDateError(''); }

      if (EarlyBirdStartTime === "") {
        setSettingStartTimeError("Please Select Start Time");
        flag = false;
      } else { setSettingStartTimeError(''); }

      if (EarlyBirdEndTime === "") {
        setSettingEndTimeError("Please Select End Time");
        flag = false;
      } else { setSettingEndTimeError(''); }

      if (EarlyBirdStartTime !== '' && EarlyBirdEndTime !== '') {
        // const start_hr =  parseInt(EarlyBirdStartTime.split(':')[0]);
        // const end_hr =  parseInt(EarlyBirdEndTime.split(':')[0]);
        // const start_min =  parseInt(EarlyBirdStartTime.split(':')[1]);
        // const end_min =  parseInt(EarlyBirdEndTime.split(':')[1]);
        // //console.log('aa');
        // //console.log(end_hr);
        // if (start_hr > end_hr) {
        //     setSettingEndTimeError("Please select end time is greater than start time");
        //     flag = false;
        // }else if(start_min >= end_min) {
        //     setSettingEndTimeError("Please select end time is greater than start time");
        //     flag = false;
        // }else{
        //      setSettingEndTimeError(''); 
        // }

        const start = new Date(`${EarlyBirdStartDate}T${EarlyBirdStartTime}`);
        const end = new Date(`${EarlyBirdEndDate}T${EarlyBirdEndTime}`);

        if (start >= end) {
          setSettingEndTimeError("Please select end time is greater than start time");
          flag = false;
        } else {
          setSettingEndTimeError('');
        }
      }

    }

    if(ApplyAgeLimit === true){
      if (AgeStart === 0) {
        setAgeStartError("Please select age start");
        flag = false
      } else {
        setAgeStartError("");
      }
  
      if (AgeEnd === 0) {
        setAgeEndError("Please select age end");
        flag = false
      } else {
        setAgeEndError("");
      }
    }
    // console.log(flag, flag_field);
    return flag;
  };
  // console.log(TicketPrice);
  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      setLoader(true);
      props.setTicketDivShowFlag(true);
      const payload = {
        event_id: localStorage.getItem("EventId"),
        ticket_id: EditTicketId !== 0 ? TicketId : 0,
        ticket_status: TicketStatus,
        ticket_name: TicketName,
        category: Category,
        total_quantity: TotalQuantity,
        ticket_price: TicketPrice,
        start_date: StartDate,
        start_time: StartTime,
        end_date: EndDate,
        end_time: EndTime,
        advanced_settings: AdvancedSettings ? 1 : 0,
        player_of_fee: WhoPayYtcrFee,
        player_of_gateway_fee: WhoPayPaymentGatewayFee,
        min_booking: MinBookCount,
        max_booking: MaxBookCount,
        ticket_description: TicketDescription,
        msg_attendance: MsgAttendee,
        minimum_donation_amount: MinDonationAmt,
        created_by: localStorage.getItem("ID"),
        early_bird: EarlyBird ? 1 : 0,
        no_of_tickets: NoOfTickets,
        eb_start_date: EarlyBirdStartDate,
        eb_start_time: EarlyBirdStartTime,
        eb_end_date: EarlyBirdEndDate,
        eb_end_time: EarlyBirdEndTime,
        discount: Discount,
        discount_value: DiscountValue,
        apply_age_limit: ApplyAgeLimit ? 1 : 0,
        age_start: AgeStart,
        age_end: AgeEnd,
        ticket_calculation_details: TicketCalculationDetails
      };
    //  console.log(payload);
      axios.post("add_edit_event_ticket", payload)
        .then((response) => {
          setLoader(false);
          //console.log(response.data.data);
          if (response.status === 200) {
            if(response.data.data === 0){
              toast.success(response.data.message);
              props.setAddNewEvent(false);
              props.setEditTicketId('');
              props.setEventApiCall(true);
              props.setTicketDivShowFlag(false);
            }else{
              toast.error(response.data.message);
            }
           
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };

  
  //--------------------------------

  const [tooltipText, setTooltipText] = useState('');
  const [tooltipMaxText, settooltipMaxText] = useState('');
  // const [PriceToolTip, setPriceToolTip] = useState(false);
  const [YtcrFee, setYtcrFee] = useState(0);
  const [PlatformFee, setPlatformFee] = useState(0);
  const [PaymentGatewayFee, setPaymentGatewayFee] = useState(0);
  const [TotalPayable, setTotalPayable] = useState(0);
  const [MoneyToYou, setMoneyToYou] = useState(0);
  const [Calculate, setCalculate] = useState(true);
  const [YtcrFeeGst, setYtcrFeeGst] = useState(0);
  const [RegistrationFeeGst, setRegistrationFeeGst] = useState(0);
  const [PaymentGatewayGst, setPaymentGatewayGst] = useState(0);
  let payment_gateway_fee_amount = 0;
  let final_payment_gatway_fee = 0;
  let paymentGatwayFeeGstAmt = 0;

  //----------------------- new calulation on 4 June 2024
  const RaceCategoryChargesDetails = props.RaceCategoryChargesDetails && props.RaceCategoryChargesDetails !== '' ? props.RaceCategoryChargesDetails : '';
  //console.log(RaceCategoryChargesDetails);
  
 
  const [BasicAmountGst, setBasicAmountGst] = useState(0);
  const [RegistrationAmountGst, setRegistrationAmountGst] = useState(0);
  const [ConvenienceFeeAmount, setConvenienceFeeAmount] = useState(0);
  const [ConvenienceFeeGstAmount, setConvenienceFeeGstAmount] = useState(0);
  const [TotalConvenienceFees, setTotalConvenienceFees] = useState(0);
  const [GSTOnPlatformFeesAmount, setGSTOnPlatformFeesAmount] = useState(0);
  const [TotalPlatformFees, setTotalPlatformFees] = useState(0);
  const [NetRegistrationAmount, setNetRegistrationAmount] = useState(0);
  
  const [Payment_Gateway_1_85_Buyer, setPayment_Gateway_1_85_Buyer] = useState(0);
  const [Payment_Gateway_18_Gst, setPayment_Gateway_18_Gst] = useState(0);
  const [Final_Total_Payment_Gateway, setFinal_Total_Payment_Gateway] = useState(0);
  const [Total_Buyer_Participant, setTotal_Buyer_Participant] = useState(0);
  const [TotalOrganiser, setTotalOrganiser] = useState(0);
  const [ConvenienceFeeFlag, setConvenienceFeeFlag] = useState(0); // 0 is percentage / 1 is amount
  const [NewDisplayPlatformFee, setNewDisplayPlatformFee] = useState(0);
  const [NewDisplayPaymentGatewayFee, setNewDisplayPaymentGatewayFee] = useState(0);
  const [ConvenienceDisplayFeeBase, setConvenienceDisplayFeeBase] = useState(0);

 
  //console.log(CalculationActiveFlag);
  // useEffect(() => {
  //   if (RaceCategoryChargesDetails.length > 0 && RaceCategoryChargesDetails) {
     
  //     for (var i = 0; i < RaceCategoryChargesDetails.length; i++) {
  //       if (RaceCategoryChargesDetails[i]['registration_amount'] >= parseFloat(TicketPrice)){
  //         //console.log(RaceCategoryChargesDetails[i]['convenience_fee']);
  //         setConvenienceFeeBase(RaceCategoryChargesDetails[i]['convenience_fee']);
  //         setNewPlatformFee(RaceCategoryChargesDetails[i]['platform_fee']);
  //         setNewPaymentGatewayFee(RaceCategoryChargesDetails[i]['payment_gateway_fee']);
  //         setCalculate(true);
  //         break;
  //       }else if(i === RaceCategoryChargesDetails.length - 1 && RaceCategoryChargesDetails[i]['registration_amount'] <= parseFloat(TicketPrice)){
  //         //console.log(RaceCategoryChargesDetails[i]['convenience_fee']);
  //         setConvenienceFeeBase(RaceCategoryChargesDetails[i]['convenience_fee']);
  //         setNewPlatformFee(RaceCategoryChargesDetails[i]['platform_fee']);
  //         setNewPaymentGatewayFee(RaceCategoryChargesDetails[i]['payment_gateway_fee']); // 1.85
  //         setCalculate(true);
  //         break;
  //       }
  //     }
  //   }
  // }, [TicketPrice,CalculationActiveFlag,RaceCategoryChargesDetails,Calculate])

  // console.log(ConvenienceFeeBase,NewPlatformFee,NewPaymentGatewayFee);
  
  // if (props.CollectGst === 1 && props.TaxesPrice === 2) {  // get for organization page
  //   PlatformFeePercent = props.PlatformFeePercent ? props.PlatformFeePercent : 0;
  // } else {
  //   PlatformFeePercent = 0;
  // }
  // console.log(props.PlatformFeePercent);
  //---------------------------- common set variables
  const Convenience_Fees_Gst_Percentage = 18;
  const GST_On_Platform_Fees = 18;
  const Payment_Gateway_Gst = 18;

  //-------------------------------------------------------
  const TicketPriceFloat = TicketPrice !== '' ? parseFloat(TicketPrice) : 0; 
  let GstPercentage = 0;

  if (props.CollectGst === 1 && props.TaxesPrice === 2) {  // get for organization page
    GstPercentage = props.PlatformFeePercent ? props.PlatformFeePercent : 0;
  } else {
    GstPercentage = 0;
  }
  
  let BasePriceGst = 0;
  let Basic_Amount_Gst = 0;
  let Convenience_Fee_Amount = 0;
  let GST_On_Convenience_Fees = 0;
  let Total_Convenience_Fees = 0;
  let GST_On_Platform_Fees_Amount = 0;
  let Total_Platform_Fees = 0;
  let Net_Registration_Amount = 0;
  let Payment_Gateway_Buyer = 0;
  let Payment_Gateway_gst_amount = 0;
  let Total_Payment_Gateway = 0;
  let Total_Buyer = 0;
  let Total_Organiser = 0;

  // Me - Organiser / Buys - Participant
  let ConvenienceFeeBase = 0;
  let NewPlatformFee = 0;
  let NewPaymentGatewayFee = 0;
  useEffect(() => {
    if (Calculate) {
   
      if (RaceCategoryChargesDetails.length > 0 && RaceCategoryChargesDetails) {
     
        for (var i = 0; i < RaceCategoryChargesDetails.length; i++) {
          if (RaceCategoryChargesDetails[i]['registration_amount'] >= parseFloat(TicketPrice)){
            //console.log(RaceCategoryChargesDetails[i]['convenience_fee']);
            // setConvenienceFeeBase(RaceCategoryChargesDetails[i]['convenience_fee']);
            // setNewPlatformFee(RaceCategoryChargesDetails[i]['platform_fee']);
            // setNewPaymentGatewayFee(RaceCategoryChargesDetails[i]['payment_gateway_fee']);
             ConvenienceFeeBase = (RaceCategoryChargesDetails[i]['convenience_fee']);
             NewPlatformFee = (RaceCategoryChargesDetails[i]['platform_fee']);
             NewPaymentGatewayFee = (RaceCategoryChargesDetails[i]['payment_gateway_fee']);
             setNewDisplayPlatformFee(RaceCategoryChargesDetails[i]['platform_fee']);
             setNewDisplayPaymentGatewayFee(NewPaymentGatewayFee);
             setConvenienceDisplayFeeBase(ConvenienceFeeBase);
            break;
          }else if(i === RaceCategoryChargesDetails.length - 1 && RaceCategoryChargesDetails[i]['registration_amount'] <= parseFloat(TicketPrice)){
            //console.log(RaceCategoryChargesDetails[i]['convenience_fee']);
            // setConvenienceFeeBase(RaceCategoryChargesDetails[i]['convenience_fee']);
            // setNewPlatformFee(RaceCategoryChargesDetails[i]['platform_fee']);
            // setNewPaymentGatewayFee(RaceCategoryChargesDetails[i]['payment_gateway_fee']); // 1.85
             ConvenienceFeeBase = (RaceCategoryChargesDetails[i]['convenience_fee']);
             NewPlatformFee = (RaceCategoryChargesDetails[i]['platform_fee']);
             NewPaymentGatewayFee = (RaceCategoryChargesDetails[i]['payment_gateway_fee']);
             setNewDisplayPlatformFee(RaceCategoryChargesDetails[i]['platform_fee']);
             setNewDisplayPaymentGatewayFee(NewPaymentGatewayFee);
             setConvenienceDisplayFeeBase(ConvenienceFeeBase);
             
            break;
          }
        }
      }

      //------------------------
      if (props.CollectGst === 1 && props.TaxesPrice === 2) {
        BasePriceGst = TicketPriceFloat !== 0 ? TicketPriceFloat * (GstPercentage / 100) : 0; // GST %
        Basic_Amount_Gst = (parseFloat(BasePriceGst) + TicketPriceFloat);
      } else {
        BasePriceGst = '0.00';
        Basic_Amount_Gst = parseFloat(TicketPriceFloat); // registration amt
      }
      // console.log(props.CollectGst,props.TaxesPrice);
      // console.log(WhoPayYtcrFee,WhoPayPaymentGatewayFee);
      if(parseFloat(ConvenienceFeeBase) === 30 || parseFloat(ConvenienceFeeBase) === 40 || parseFloat(ConvenienceFeeBase) === 10){
        //console.log('ss');
        Convenience_Fee_Amount = parseFloat(ConvenienceFeeBase);
        setConvenienceFeeFlag(0);
      }else{
        //console.log(Basic_Amount_Gst,parseFloat(ConvenienceFeeBase));
        Convenience_Fee_Amount = Basic_Amount_Gst * (ConvenienceFeeBase / 100);  
        setConvenienceFeeFlag(1);
      }
      // console.log(Convenience_Fee_Amount);

      GST_On_Convenience_Fees = Convenience_Fee_Amount * (Convenience_Fees_Gst_Percentage / 100); // GST 18%
      Total_Convenience_Fees = (Convenience_Fee_Amount + GST_On_Convenience_Fees);
      GST_On_Platform_Fees_Amount = NewPlatformFee * (GST_On_Platform_Fees / 100); // GST 18%
      Total_Platform_Fees = (parseFloat(NewPlatformFee) + parseFloat(GST_On_Platform_Fees_Amount));
      Net_Registration_Amount = (parseFloat(Basic_Amount_Gst) + parseFloat(Total_Convenience_Fees) + parseFloat(Total_Platform_Fees));

      //console.log(GST_On_Convenience_Fees,Total_Convenience_Fees,GST_On_Platform_Fees_Amount,Total_Platform_Fees,Net_Registration_Amount);

      if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 1) {  //Me + Me
        
        Payment_Gateway_Buyer = Basic_Amount_Gst * (NewPaymentGatewayFee / 100); // 1.85%
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));
        Total_Buyer = (parseFloat(Basic_Amount_Gst));
        Total_Organiser = (parseFloat(Total_Buyer) - parseFloat(Total_Payment_Gateway) - parseFloat(Total_Platform_Fees) - parseFloat(Total_Convenience_Fees));
        
      }
      else if (parseInt(WhoPayYtcrFee) === 2 && parseInt(WhoPayPaymentGatewayFee) === 2) {  // Buyer + Buyer
    
        Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));
       
       // console.log(Payment_Gateway_Buyer,Payment_Gateway_gst_amount,Total_Payment_Gateway,Net_Registration_Amount);

        Total_Buyer = (parseFloat(Total_Payment_Gateway) + parseFloat(Net_Registration_Amount));
        
        Total_Organiser = (parseFloat(Total_Buyer) - parseFloat(Total_Payment_Gateway) - parseFloat(Total_Platform_Fees) - parseFloat(Total_Convenience_Fees));
      }
      else if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 2) { // Me + Buyer
       
        Payment_Gateway_Buyer = Basic_Amount_Gst * (NewPaymentGatewayFee / 100); // 1.85%
       
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));
        Total_Buyer = (parseFloat(Basic_Amount_Gst) + parseFloat(Total_Payment_Gateway));
        Total_Organiser = (parseFloat(Total_Buyer) - parseFloat(Total_Payment_Gateway) - parseFloat(Total_Platform_Fees) - parseFloat(Total_Convenience_Fees));
       
      }
      else if (parseInt(WhoPayYtcrFee) === 2 && parseInt(WhoPayPaymentGatewayFee) === 1) { // Buyer + Me
       
        Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));
       
        Total_Buyer = (parseFloat(Basic_Amount_Gst) + parseFloat(Total_Convenience_Fees) + parseFloat(Total_Platform_Fees) );
        Total_Organiser = (parseFloat(Total_Buyer) - parseFloat(Total_Payment_Gateway) - parseFloat(Total_Platform_Fees) - parseFloat(Total_Convenience_Fees));
      }
      // console.log(Payment_Gateway_Buyer);
      // console.log(Basic_Amount_Gst,Total_Convenience_Fees,Total_Platform_Fees);
      
      setBasicAmountGst(parseFloat(BasePriceGst).toFixed(2));
      setRegistrationAmountGst(parseFloat(Basic_Amount_Gst).toFixed(2)); 
      setConvenienceFeeAmount(parseFloat(Convenience_Fee_Amount).toFixed(2)); 
      setConvenienceFeeGstAmount(parseFloat(GST_On_Convenience_Fees).toFixed(2)); 
      setTotalConvenienceFees(parseFloat(Total_Convenience_Fees).toFixed(2)); 
      setGSTOnPlatformFeesAmount(parseFloat(GST_On_Platform_Fees_Amount).toFixed(2)); 
      setTotalPlatformFees(parseFloat(Total_Platform_Fees).toFixed(2)); 
      setNetRegistrationAmount(parseFloat(Net_Registration_Amount).toFixed(2)); 
     
      setPayment_Gateway_1_85_Buyer(parseFloat(Payment_Gateway_Buyer).toFixed(2));
      setPayment_Gateway_18_Gst(parseFloat(Payment_Gateway_gst_amount).toFixed(2)); 
      setFinal_Total_Payment_Gateway(parseFloat(Total_Payment_Gateway).toFixed(2)); 
      setTotal_Buyer_Participant(parseFloat(Total_Buyer).toFixed(2)); 
      setTotalOrganiser(parseFloat(Total_Organiser).toFixed(2)); 
      
    }
    
    const calculation_array = {
      "ticket_price" : TicketPriceFloat,
      "payment_gateway_fees": NewPaymentGatewayFee,
      "convenience_fees_gst_percentage": 18,
      "gst_on_platform_fees": 18,
      "payment_gateway_gst": 18,
      "collect_gst": props.CollectGst,
      "including_excluding gst": props.TaxesPrice, 
      "basic_amount": TicketPrice,
      "registration_18_percent_GST": parseFloat(BasePriceGst).toFixed(2),
      "registration_amount": parseFloat(Basic_Amount_Gst).toFixed(2),
      "convenience_fee_base": ConvenienceFeeBase,
      "convenience_fee_amount": parseFloat(Convenience_Fee_Amount).toFixed(2),
      "18_percent_GST_convenience_fees": parseFloat(GST_On_Convenience_Fees).toFixed(2),
      "total_convenience_fees": parseFloat(Total_Convenience_Fees).toFixed(2),
      "platform_fees_5_each": parseFloat(NewPlatformFee).toFixed(2), 
      "18_percent_GST_platform_fees": parseFloat(GST_On_Platform_Fees_Amount).toFixed(2),
      "total_platform_fees": parseFloat(Total_Platform_Fees).toFixed(2),
      "net_registration_amount": parseFloat(Net_Registration_Amount).toFixed(2),
      "payment_gateway_1.85_buyer": parseFloat(Payment_Gateway_Buyer).toFixed(2),
      "18_per_payment_gateway_GST": parseFloat(Payment_Gateway_gst_amount).toFixed(2),
      "total_PG": parseFloat(Total_Buyer).toFixed(2),
      "total_buyer": parseFloat(Total_Buyer).toFixed(2),
      "to_organiser": parseFloat(Total_Organiser).toFixed(2)
    }
    //console.log(calculation_array);
    setTicketCalculationDetails(calculation_array);

  }, [Calculate, TicketPrice, WhoPayYtcrFee, WhoPayPaymentGatewayFee, EditTicketId]);
 //console.log(Basic_Amount_Gst,NewPaymentGatewayFee);

  //--------------------------------------------------------
  const new_calculation_array = {
    "Collect Gst": props.CollectGst,
    "Including/Excluding GST": props.TaxesPrice, 
    "Basic Amount": TicketPrice,
    "Registration 18% GST": BasicAmountGst,
    "Registration Amount": RegistrationAmountGst,
    "Convenience fee base": ConvenienceDisplayFeeBase,
    "Convenience fee amount": ConvenienceFeeAmount,
    "18% GST On Convenience Fees": ConvenienceFeeGstAmount,
    "Total Convenience Fees": TotalConvenienceFees,
    "Platform Fees @ 5 Each": NewDisplayPlatformFee,
    "18% GST On Platform Fees": GSTOnPlatformFeesAmount,
    "Total Platform Fees": TotalPlatformFees,
    "Net Registration Amount": NetRegistrationAmount,
    "PG @ 1.85% buyer": Payment_Gateway_1_85_Buyer,
    "18% PG GST": Payment_Gateway_18_Gst,
    "Total PG": Final_Total_Payment_Gateway,
    "Total buyer": Total_Buyer_Participant,
    "To Organiser": TotalOrganiser
  }
  // console.log(new_calculation_array);

 


  //----------------------------- old-----------------
  // const PlatformFeePercent = 18;
  // useEffect(() => {
  //   if (Calculate) {
     
  //     const ytcr_fee_gst = YtcrFeePercent !== 0 ? YtcrFeePercent * (18 / 100) : 0;
  //     // console.log(Calculate,TicketPrice);
  //     const TicketPriceFloat = TicketPrice !== '' ? parseFloat(TicketPrice) : 0; // Convert TicketPrice to float
  //     // console.log(YtcrFeePercent,PlatformFeePercent,PaymentGatewayFeePercent);
  //     //  const YtcrFeeAmount = TicketPriceFloat !== 0 ? TicketPriceFloat * (YtcrFeePercent / 100) : 0;
  //     const YtcrFeeAmount = YtcrFeePercent && YtcrFeePercent !== 0 ? YtcrFeePercent : 0;
  //     const PlatformFeeAmount = TicketPriceFloat !== 0 ? TicketPriceFloat * (PlatformFeePercent / 100) : 0; // GST %
  //     const PaymentGatewayFeeAmount = TicketPriceFloat !== 0 ? TicketPriceFloat * (PaymentGatewayFeePercent / 100) : 0;
  //     //console.log(PaymentGatewayFeeAmount);
  //     // console.log(TicketPriceFloat,YtcrFeeAmount,PlatformFeeAmount,PaymentGatewayFeeAmount);

  //     if (props.CollectGst === 1 && props.TaxesPrice === 2) {

  //       BasePriceGst = TicketPriceFloat !== 0 ? TicketPriceFloat * (PlatformFeePercent / 100) : 0; // GST %
  //       setRegistrationFeeGst(parseFloat(BasePriceGst).toFixed(2));
  //       // paymentGatwayFeeGstAmt = final_payment_gatway_fee !== 0 ? final_payment_gatway_fee * (PlatformFeePercent / 100) : 0; // GST %
  //       // setPaymentGatewayGst(parseFloat(paymentGatwayFeeGstAmt).toFixed(2));
  //       //console.log(final_payment_gatway_fee);
  //     } else {
  //       BasePriceGst = 0;
  //       setRegistrationFeeGst('0.00');
  //       // setPaymentGatewayFee('0.00');
  //       // paymentGatwayFeeGstAmt = PaymentGatewayFeeAmount !== 0 ? PaymentGatewayFeeAmount * (PlatformFeePercent / 100) : 0; // GST %
  //       // console.log(paymentGatwayFeeGstAmt);
  //       // setPaymentGatewayGst(parseFloat(paymentGatwayFeeGstAmt).toFixed(2));
  //     }

  //     if (parseInt(WhoPayPaymentGatewayFee) === 1 && parseInt(WhoPayYtcrFee) === 1) {
  //       payment_gateway_fee_amount = (TicketPriceFloat + BasePriceGst);
  //     } else if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 2) { // Me + Buyer
  //       payment_gateway_fee_amount = (TicketPriceFloat + BasePriceGst);
  //     } else {
  //       payment_gateway_fee_amount = (TicketPriceFloat + BasePriceGst + YtcrFeePercent + ytcr_fee_gst);
  //     }
  //     final_payment_gatway_fee = payment_gateway_fee_amount !== 0 ? payment_gateway_fee_amount * (1 / 100) : 0;
  //     setPaymentGatewayFee(parseFloat(final_payment_gatway_fee).toFixed(2));

  //     //----------
  //     paymentGatwayFeeGstAmt = final_payment_gatway_fee !== 0 ? final_payment_gatway_fee * (props.PaymentGatewayGstPercent / 100) : 0; // GST %
  //     // console.log(final_payment_gatway_fee,props.PaymentGatewayGstPercent,paymentGatwayFeeGstAmt);
  //     setPaymentGatewayGst(parseFloat(paymentGatwayFeeGstAmt).toFixed(2));


  //     //---------- total without payment gatway charges
  //     const total_without_pg = (parseFloat(TicketPriceFloat) + parseFloat(YtcrFeePercent) + parseFloat(PlatformFeeAmount) + parseFloat(YtcrFeeGst));

  //     const pg_fee = total_without_pg !== 0 ? total_without_pg * (props.PaymentGatewayFeePercent / 100) : 0;
  //     const pg_fee_gst = pg_fee !== 0 ? pg_fee * (props.PaymentGatewayGstPercent / 100) : 0;

  //     //console.log(TicketPriceFloat,YtcrFeePercent,PlatformFeeAmount,YtcrFeeGst,'total-'+total_without_pg,pg_fee,props.PaymentGatewayGstPercent,parseFloat(pg_fee_gst).toFixed(2));  

  //     // Note ----------- PlatformFeeAmount as (GST %)

  //     let Buyer_payment = TicketPriceFloat + PlatformFeeAmount;
  //     let Money_to_you = TicketPriceFloat;
  //     //console.log(Buyer_payment,);


  //     if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 1) {  //Me + Me
  //       Buyer_payment = parseFloat((TicketPriceFloat + PlatformFeeAmount - ytcr_fee_gst).toFixed(2));

  //       Money_to_you = parseFloat((TicketPriceFloat + PlatformFeeAmount - YtcrFeeAmount - ytcr_fee_gst - parseFloat(final_payment_gatway_fee) - parseFloat(paymentGatwayFeeGstAmt)).toFixed(2));
  //       // console.log(PlatformFeePercent);
  //       //console.log(TicketPriceFloat,PlatformFeeAmount,YtcrFeeAmount,ytcr_fee_gst,final_payment_gatway_fee,paymentGatwayFeeGstAmt);
  //     }
  //     else if (parseInt(WhoPayYtcrFee) === 2 && parseInt(WhoPayPaymentGatewayFee) === 2) {  // Buyer + Buyer
  //       //console.log(PlatformFee);

  //       Buyer_payment = (TicketPriceFloat + PlatformFeeAmount + YtcrFeeAmount + final_payment_gatway_fee + paymentGatwayFeeGstAmt);
  //       Money_to_you = parseFloat((TicketPriceFloat + PlatformFeeAmount).toFixed(2));
  //       // console.log(TicketPriceFloat,PlatformFeeAmount,YtcrFeeAmount, parseFloat(pg_fee), parseFloat(pg_fee_gst),ytcr_fee_gst);
  //     }
  //     else if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 2) { // Me + Buyer
  //       Buyer_payment = parseFloat((TicketPriceFloat + PlatformFeeAmount + parseFloat(final_payment_gatway_fee) + parseFloat(paymentGatwayFeeGstAmt) - ytcr_fee_gst).toFixed(2));
  //       // console.log(TicketPriceFloat, PlatformFeeAmount, parseFloat(final_payment_gatway_fee) , parseFloat(paymentGatwayFeeGstAmt), ytcr_fee_gst);
  //       Money_to_you = parseFloat(((TicketPriceFloat + PlatformFeeAmount) - (YtcrFeeAmount + ytcr_fee_gst))).toFixed(2);

  //     }
  //     else if (parseInt(WhoPayYtcrFee) === 2 && parseInt(WhoPayPaymentGatewayFee) === 1) { // Buyer + Me
  //       //let ytcr_fee = YtcrFeeAmount ? YtcrFeeAmount : 0;
  //       Buyer_payment = (TicketPriceFloat + PlatformFeeAmount + YtcrFeeAmount);
  //       Money_to_you = parseFloat(((TicketPriceFloat + PlatformFeeAmount) - (parseFloat(final_payment_gatway_fee) + parseFloat(paymentGatwayFeeGstAmt))).toFixed(2));
  //     }

  //     // console.log(TicketPriceFloat,PlatformFeeAmount,final_payment_gatway_fee,paymentGatwayFeeGstAmt);
  //     Buyer_payment = parseFloat((Buyer_payment + ytcr_fee_gst).toFixed(2));
  //     //console.log(Buyer_payment);

  //     setYtcrFee(parseFloat(YtcrFeeAmount).toFixed(2));
  //     setPlatformFee(parseFloat(PlatformFeeAmount).toFixed(2));
  //     // setPaymentGatewayFee(parseFloat(PaymentGatewayFeeAmount).toFixed(2));
  //     setTotalPayable(parseFloat(Buyer_payment).toFixed(2));
  //     setMoneyToYou(parseFloat(Money_to_you).toFixed(2));
  //     setNewTicketPrice(parseFloat(TicketPrice).toFixed(2));
  //     setYtcrFeeGst(parseFloat(ytcr_fee_gst).toFixed(2));
  //     setCalculate(false);
  //   }
  // }, [Calculate, TicketPrice, YtcrFeePercent, PlatformFeePercent, PaymentGatewayFeePercent, WhoPayYtcrFee, WhoPayPaymentGatewayFee]);


  const handleEarlyBird = () => {
    if (EarlyBird === true) {
      setNoOfTickets(""); setDiscountValue(""); setEarlyBirdStartDate(""); setEarlyBirdStartTime(""); setEarlyBirdEndDate(""); setEarlyBirdEndTime("");
    }
  }


  const handleInputChange = () => {
    
    if(TicketStatus === 1){
      inputRef5.current.blur();
      inputRef6.current.blur();
      inputRef7.current.blur();
      inputRef8.current.blur();
    }else{
      inputRef5.current.blur();
      inputRef6.current.blur();
    }
  
  }

  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
      <div className="row">
        <div className="col-lg-8 col-md-6">
          <div className="main checkout__mian">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="checkout__content--step section__shipping--address ">
                <div className="mb-5">
                  <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                    <h2 className="section__header--title h3">New Race Category</h2>
                    <div className="event-visibility hand_cursor">

                      <div className={'visibility gap-1 ' + EventPublicStatus} onClick={() => { setTicketStatus(1); setEventPublicStatus('active'); setEventPrivateStatus(''); setEventDraftStatus(''); setFreeStatusFlag(false); setDonationStatusFlag(false); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z" /></svg>
                        Paid
                      </div>

                      <div className={'visibility gap-1 ' + EventPrivateStatus} onClick={() => { setTicketStatus(2); setEventPublicStatus(''); setEventPrivateStatus('active'); setEventDraftStatus(''); setFreeStatusFlag(true); setDonationStatusFlag(false); }} >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={20}
                          viewBox="0 -960 960 960"
                          width={20}
                        >
                          <path d="M300-520q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T360-660q0-25-17.5-42.5T300-720q-25 0-42.5 17.5T240-660q0 25 17.5 42.5T300-600Zm360 440q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T720-300q0-25-17.5-42.5T660-360q-25 0-42.5 17.5T600-300q0 25 17.5 42.5T660-240Zm-444 80-56-56 584-584 56 56-584 584Z" /></svg>
                        Free
                      </div>

                      {/* <div className={'visibility gap-1 ' + EventDraftStatus} onClick={() => { setTicketStatus(3); setEventPublicStatus(''); setEventPrivateStatus(''); setEventDraftStatus('active'); setFreeStatusFlag(false); setDonationStatusFlag(true); EditPriceCheck(); }} >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={20}
                      viewBox="0 -960 960 960"
                      width={20}
                    >
                      <path d="M640-440 474-602q-31-30-52.5-66.5T400-748q0-55 38.5-93.5T532-880q32 0 60 13.5t48 36.5q20-23 48-36.5t60-13.5q55 0 93.5 38.5T880-748q0 43-21 79.5T807-602L640-440Zm0-112 109-107q19-19 35-40.5t16-48.5q0-22-15-37t-37-15q-14 0-26.5 5.5T700-778l-60 72-60-72q-9-11-21.5-16.5T532-800q-22 0-37 15t-15 37q0 27 16 48.5t35 40.5l109 107ZM280-220l278 76 238-74q-5-9-14.5-15.5T760-240H558q-27 0-43-2t-33-8l-93-31 22-78 81 27q17 5 40 8t68 4q0-11-6.5-21T578-354l-234-86h-64v220ZM40-80v-440h304q7 0 14 1.5t13 3.5l235 87q33 12 53.5 42t20.5 66h80q50 0 85 33t35 87v40L560-60l-280-78v58H40Zm80-80h80v-280h-80v280Zm520-546Z" /></svg>
                    Donation
                  </div> */}

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={TicketName}
                          onChange={(e) => {
                            setTicketName(e.target.value);
                          }}
                          maxLength={100}
                        />
                        <label>Race Category Name <span className="checkout__input--label__star">*</span></label>
                      </div>
                      <small className="text-danger">{TicketNameError}</small>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-floating">
                        <select className="form-control" name="category" value={Category} onChange={(e) => { setCategory(e.target.value); }}>
                          <option value="0">-- Select Category --</option>
                          {EventTypeData.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        <label>Category <span className="checkout__input--label__star">*</span></label>
                      </div>
                      <small className="text-danger">{CategoryError}</small>
                    </div>

                  </div>
                </div>

                <div className="row ">
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={TotalQuantity}
                        onChange={(e) => {
                          setTotalQuantity(e.target.value.replace(/\D/g, ''));
                        }}
                      />
                      <label>Maximum Registration <span className="checkout__input--label__star">*</span></label>
                    </div>
                    <small className="text-danger">{TotalQuantityError}</small>
                  </div>

                  {TicketStatus === 1 ?
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={TicketPrice}
                          onChange={(e) => {
                            setTicketPrice(e.target.value.replace(/[^\d.]/g, '')); setCalculate(true);
                          }}
                        />
                        <label>Race Category Price <span className="checkout__input--label__star">*</span></label>
                      </div>
                      <small className="text-danger">{TicketPriceError}</small>
                    </div>
                    : null
                  }
                  {TicketStatus === 3 ?
                    <div className="col-sm-6">
                      <div className="form-floating mt-3 mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={MinDonationAmt}
                          onChange={(e) => {
                            setMinDonationAmt(e.target.value.replace(/\D/g, ''));
                          }}
                        />
                        <label>Minimum Donation Amount <span className="checkout__input--label__star">*</span></label>
                      </div>
                      <small className="text-danger">{MinDonationAmtError}</small>
                    </div>
                    : null
                  }
                </div>

                <div className="row">
                  {((TicketStatus === 1) || (TicketStatus === 2)) ?
                    <div className="col-sm-6">

                      <div className="form-floating mt-3 mb-0 w-100 d-flex justify-content-end align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={MinBookCount}
                          onChange={(e) => {
                            setMinBookCount(e.target.value.replace(/\D/g, ''));
                          }}
                        />
                        <label>Minimum per booking count <span className="checkout__input--label__star">*</span>
                        </label>
                        <div className="px-3"
                          onMouseOver={() => setTooltipText('Minimum number of tickets that can be purchased in a booking')}
                          onMouseLeave={() => setTooltipText('')}
                          style={{ position: "absolute" }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                          </svg>
                          {tooltipText && <CustomTooltip text={tooltipText} />}
                        </div>
                      </div>
                      <small className="text-danger">{MinBookCountError}</small>
                    </div>
                    : null
                  }

                  {((TicketStatus === 1) || (TicketStatus === 2)) ?
                    <div className="col-sm-6">

                      <div className="form-floating mt-3 mb-0 w-100 d-flex justify-content-end align-items-center">
                        <input
                          type="text"
                          className="form-control "
                          placeholder=""
                          value={MaxBookCount}
                          onChange={(e) => {
                            setMaxBookCount(e.target.value.replace(/\D/g, ''));
                          }}
                        />
                        <label>Allow registrations upto <span className="checkout__input--label__star">*</span>
                        </label>
                        <div className="px-3" onMouseOver={() => settooltipMaxText('Maximum number of tickets that can be purchased in a booking')}
                          onMouseLeave={() => settooltipMaxText('')}
                          style={{ position: "absolute" }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                          {tooltipMaxText && <CustomTooltip text={tooltipMaxText} />}
                        </div>
                      </div>

                      <small className="text-danger">{MaxBookCountError}</small>
                    </div>
                    : null
                  }
                </div>

                {/* <div className="col-lg-4 tooltip">
              <div className="checkout__email--phone mb-20">
                <label className="checkout__input--label mb-2" for="input1">Money to you<span className="checkout__input--label__star">*</span></label>
                <div className="d-flex align-items-center">
                  <div className="">
                    <h4 className="h3">₹{MoneyToYou}</h4>
                  </div>
                  <div className="mx-3">
                    <p className="m-0">View Breakup</p>
                    <span className="tooltiptext border-radius-10 p-4">
                      <div className="d-flex">
                        <div className="col-8 text-left">
                          <small className="text-white">Ticket Price</small>
                        </div>
                        <div className="col-4 text-right">
                          <small className="text-white">₹{TicketPrice}</small>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex">
                        <div className="col-8 text-left">
                          <small className="text-white">YTCR Fee</small>
                        </div>
                        <div className="col-4 text-right">
                          <small className="text-white">₹{YtcrFee}</small>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-8 text-left">
                          <small className="text-white">Platform Fee(By Buyer)</small>
                        </div>
                        <div className="col-4 text-right">
                          <small className="text-white">₹{PlatformFee}</small>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-8 text-left">
                          <small className="text-white">Payment Gateway fee</small>
                        </div>
                        <div className="col-4 text-right">
                          <small className="text-white">₹{PaymentGatewayFee}</small>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex">
                        <div className="col-8 text-left">
                          <small className="text-white">Total Payable (By Buyer)</small>
                        </div>
                        <div className="col-4 text-right">
                          <small className="text-white">₹{TotalPayable}</small>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-8 text-left">
                          <small className="text-white">Money to you</small>
                        </div>
                        <div className="col-4 text-right">
                          <small className="text-white">₹{MoneyToYou}</small>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div> */}

                <div className="row ">
                  <div className="col-sm-6">
                    <div className="form-floating mt-3 mb-0">
                      <input
                        className="form-control"
                        type="date"
                        name="event-date"
                        value={StartDate}
                        onChange={(e) => { setStartDate(e.target.value); setEndDate(''); setEndTime(''); }}
                        min={StartDate == '' ? new Date(CurrentDate1).toISOString().split("T")[0] : new Date(CurrentDate1).toISOString().split('T')[0]}
                      />
                      <label className="select-label">Registration Starts From <span className="checkout__input--label__star">*</span></label>
                      <small className="text-danger">{StartDateError}</small>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-floating mt-3 mb-0">
                      <input
                        className="form-control"
                        type="time"
                        name="event-date"
                        value={StartTime}
                        ref={inputRef5}
                        onChange={(e) => { setStartTime(e.target.value); handleInputChange(); }}
                      />
                      <label className="select-label"> Start Time <span className="checkout__input--label__star">*</span></label>
                      <small className="text-danger">{StartTimeError}</small>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-sm-6">
                    <div className="form-floating mt-3 mb-0">
                      <input
                        className="form-control"
                        type="date"
                        name="event-date"
                        value={EndDate}
                        onChange={(e) => { setEndDate(e.target.value) }}
                        min={StartDate ? new Date(StartDate).toISOString().split("T")[0] : new Date().toISOString().split('T')[0]}
                      />
                      <label className="select-label">Registration Ends on <span className="checkout__input--label__star">*</span></label>
                      <small className="text-danger">{EndDateError}</small>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-floating mt-3 mb-0">
                      <input
                        className="form-control"
                        type="time"
                        name="event-date"
                        value={EndTime}
                        ref={inputRef6}
                        onChange={(e) => { setEndTime(e.target.value); handleInputChange(); }}
                      />
                      <label className="select-label"> End Time <span className="checkout__input--label__star">*</span></label>
                      <small className="text-danger">{EndTimeError}</small>
                    </div>
                  </div>
                </div>

                {FreeStatusFlag === false ?
                  <div className="col-lg-12 mb-20">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left my-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={AdvancedSettings}
                        checked={AdvancedSettings}
                        onChange={() => setAdvancedSettings(!AdvancedSettings)}
                      />
                      <label className="form-check-label">
                        Show advanced settings
                      </label>
                    </div>
                  </div>
                  : ''}

                {FreeStatusFlag === false ?
                  <div className={AdvancedSettings ? "" : "d-none"}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <label className="select-lable">Who will pay Convenience Fee <span className="checkout__input--label__star">*</span></label>
                          <select className="form-control" required=""
                            value={WhoPayYtcrFee}
                            onChange={(e) => { setWhoPayYtcrFee(e.target.value); setCalculate(true) }}
                          >
                            <option value="1">Organiser</option>
                            <option value="2">Participant</option>
                            {/* <option value="3">Other</option> 
                            Me - Organiser / Buys - Participant
                            */}
                          </select>
                          <small className="text-danger">{WhoPayYtcrFeeError}</small>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <label className="select-lable">Who will pay Payment Gateway fee <span className="checkout__input--label__star">*</span></label>
                          <select className="form-control" required=""
                            value={WhoPayPaymentGatewayFee}
                            onChange={(e) => { setWhoPayPaymentGatewayFee(e.target.value); setCalculate(true) }}
                          >
                            <option value="1">Organiser</option>
                            <option value="2">Participant</option>
                            {/* <option value="3">Other</option> */}
                          </select>
                          <small className="text-danger">{WhoPayPaymentGatewayFeeError}</small>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          {/* <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={TicketDescription}
                        onChange={(e) => {
                          setTicketDescription(e.target.value);
                        }}
                      /> */}
                          <textarea className="form-control resp-textarea" value={TicketDescription} onChange={(e) => {
                            setTicketDescription(e.target.value);
                          }} cols={20} maxLength={500}></textarea>
                          <label> Description <span className="checkout__input--label__star">*</span></label>
                          <small className="text-danger">{TicketDescriptionError}</small>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={MsgAttendee}
                            onChange={(e) => {
                              setMsgAttendee(e.target.value);
                            }}
                          />
                          <label>Message Attendee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                : ''}
                
                {/* new added */}
                
                <div className="col-sm-6">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left my-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={ApplyAgeLimit}
                        checked={ApplyAgeLimit}
                        onChange={() => setApplyAgeLimit(!ApplyAgeLimit)}
                      />
                      <label className="form-check-label">
                        Apply Age Limit <small style={{color: 'gray'}}>(Age calculated as on Event date)</small> 
                      </label> 
                    </div>
                </div>
                
                {ApplyAgeLimit === true ?
                  <div className="row">
                    
                    <div className="col-sm-6">
                      <div className="form-floating mb-0"> 
                        <select className="form-control" name="filed_mapping" value={AgeStart}  onChange={(e) => { setAgeStart(e.target.value); }}> 
                          <option value="0">-- Select --</option>
                            {props.AgeDetails ?
                                props.AgeDetails.map((item) => {
                                    return (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                    );
                                })
                              : null
                            }
                        </select>
                        <label className="form-check-label">Age Start <span className="checkout__input--label__star">*</span></label>
                        <small className="text-danger">{AgeStartError}</small>
                      </div>
                    </div>   

                    <div className="col-sm-6">
                      <div className="form-floating mb-0">
                        <select className="form-control" name="filed_mapping" value={AgeEnd}  onChange={(e) => { setAgeEnd(e.target.value); }}> 
                          <option value="0">-- Select --</option>
                            {props.AgeDetails ?
                                props.AgeDetails.map((item) => {
                                    return (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                    );
                                })
                              : null
                            }
                        </select>
                        <label className="form-check-label">Age End <span className="checkout__input--label__star">*</span></label>
                        <small className="text-danger">{AgeEndError}</small>
                      </div>
                    </div>

                  </div>
                : '' }
                {/* ---------------------- */}

                {FreeStatusFlag === false && DonationStatusFlag !== true ?
                  <div className="col-lg-12 mb-20">
                    <div className="form-check form-switch d-flex align-items-left justify-content-left my-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={EarlyBird}
                        checked={EarlyBird}
                        onChange={() => { setEarlyBird(!EarlyBird); handleEarlyBird(); }}
                      />
                      <label className="form-check-label">
                        Early bird settings
                      </label>
                    </div>
                  </div>
                  : ''}

                {FreeStatusFlag === false && DonationStatusFlag !== true && TicketStatus !== 3 ?
                  <div className={EarlyBird ? "" : "d-none"}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={NoOfTickets}
                            onChange={(e) => {
                              setNoOfTickets(e.target.value.replace(/\D/g, ''));
                            }}
                          />
                          <label>No of Registrations Limit <span className="checkout__input--label__star">*</span></label>
                        </div>
                        <small className="text-danger">{NoOfTicketsError}</small>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <label className="select-lable">Discount</label>
                          <select className="form-control" required=""
                            value={Discount}
                            onChange={(e) => { setDiscount(e.target.value); }}
                          >
                            <option value="1">Percentage</option>
                            <option value="2">Amount</option>
                          </select>
                          {/* <small className="text-danger">{DiscountError}</small> */}
                        </div>
                      </div>

                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={DiscountValue}
                            onChange={(e) => {
                              setDiscountValue(e.target.value.replace(/\D/g, ''));
                            }}
                          />
                          <label>Discount Value <span className="checkout__input--label__star">*</span></label>
                        </div>
                        <small className="text-danger">{DiscountValueError}</small>
                      </div>


                    </div>

                    <div className="row ">
                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <input
                            className="form-control"
                            type="date"
                            name="event-date1"
                            value={EarlyBirdStartDate}
                            onChange={(e) => { setEarlyBirdStartDate(e.target.value); setEarlyBirdEndDate(''); setEarlyBirdEndTime(';'); }}
                            min={EarlyBirdStartDate === '' ? new Date(CurrentDate1).toISOString().split("T")[0] : new Date(CurrentDate1).toISOString().split('T')[0]}
                          />
                          <label className="select-label"> Start Date <span className="checkout__input--label__star">*</span></label>
                          <small className="text-danger">{EarlyBirdStartDateError}</small>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <input
                            className="form-control"
                            type="time"
                            name="event-date"
                            value={EarlyBirdStartTime}
                            ref={inputRef7}
                            onChange={(e) => { setEarlyBirdStartTime(e.target.value); handleInputChange(); }}
                          />
                          <label className="select-label"> Start Time <span className="checkout__input--label__star">*</span></label>
                          <small className="text-danger">{SettingStartTimeError}</small>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <input
                            className="form-control"
                            type="date"
                            name="event-date1"
                            value={EarlyBirdEndDate}
                            onChange={(e) => { setEarlyBirdEndDate(e.target.value) }}
                            min={EarlyBirdStartDate ? new Date(EarlyBirdStartDate).toISOString().split("T")[0] : new Date().toISOString().split('T')[0]}
                          />
                          <label className="select-label"> End Date <span className="checkout__input--label__star">*</span></label>
                          <small className="text-danger">{EarlyBirdEndDateError}</small>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-floating mt-3 mb-0">
                          <input
                            className="form-control"
                            type="time"
                            name="event-date"
                            value={EarlyBirdEndTime}
                            ref={inputRef8}
                            onChange={(e) => { setEarlyBirdEndTime(e.target.value); handleInputChange(); }}
                          />
                          <label className="select-label"> End Time <span className="checkout__input--label__star">*</span></label>
                          <small className="text-danger">{SettingEndTimeError}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''}

                {/* </div> */}
              </div>
              <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                <div
                  className="continue__shipping--btn secondary__btn border-radius-5"
                  onClick={(e) => { props.setAddNewEvent(false); props.setEditTicketId(''); props.setTicketDivShowFlag(false) }}
                  style={{ cursor: 'pointer' }} >
                  Cancel
                </div>

                <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => handleSubmit(e)} />

              </div>
            </form >

          </div >
        </div>

        {FreeStatusFlag === true ?
          ''
          :
          <div className="col-lg-4 col-md-6">
            <aside className="checkout__sidebar sidebar border-radius-10">
              <div className="card  p-5">
                <div className="row">
                  <div className="col-sm-6 text-left">
                    <h2 className="section__header--title h3">Money to you</h2>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h2 className="section__header--title h3">₹{TicketPrice ? TotalOrganiser : '0.00'}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card p-5">
                    <div className="row">
                      <div className="col-8 text-left">
                        {/* Ticket Price */}
                        <p className="">Base Registration Fee</p>
                      </div>
                      <div className="col-4 text-right">
                        <p className="">₹{TicketPriceFloat !== '' && TicketPriceFloat !== 'NaN' ? TicketPriceFloat : '0.00'}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-8 text-left">
                        {/* YTCR Fee */}
                        <p className="">Convenience Fee</p>
                      </div>
                      <div className="col-4 text-right">
                        {/* <p className="">₹{YtcrFee}</p>  */}
                        <p className="">₹{ConvenienceFeeAmount && ConvenienceFeeAmount !== 0 ? ConvenienceFeeAmount : '0.00'}</p>
                      </div>
                    </div>

                    <div className="row">
                      {/* Payment Gateway fee */}
                      <div className="col-8 text-left">
                        <p className="">Platform Fee</p>
                      </div>
                      <div className="col-4 text-right">
                        <p className="">₹{parseFloat(NewDisplayPlatformFee).toFixed(2)}</p> 
                      </div>
                    </div>

                    <div className="row">
                      {/* Payment Gateway fee */}
                      <div className="col-8 text-left">
                        <p className="">Payment Gateway Charges ({NewDisplayPaymentGatewayFee}%)</p>
                      </div>
                      <div className="col-4 text-right">
                        <p className="">₹{Payment_Gateway_1_85_Buyer}</p>
                      </div>
                    </div>
                    <hr />

                    {props.CollectGst === 1 && props.TaxesPrice === 2 ?
                      <div className="row">
                        <div className="col-8 text-left">
                          <p className="">Registration Fee GST {GST_On_Platform_Fees}%</p>
                        </div>
                        <div className="col-4 text-right">
                          <p className="">₹{BasicAmountGst}</p>
                        </div>
                      </div>
                    : ''}

                    <div className="row">
                      <div className="col-8 text-left">
                        <p className="">Convenience Fee GST 18%</p>
                      </div>
                      <div className="col-4 text-right">
                        <p className="">₹{ConvenienceFeeGstAmount}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8 text-left">
                        <p className="">Platform Fee GST 18%</p>
                      </div>
                      <div className="col-4 text-right">
                        <p className="">₹{GSTOnPlatformFeesAmount}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8 text-left">
                        <p className="">Payment Gateway GST 18%</p>
                      </div>
                      <div className="col-4 text-right">
                        <p className="">₹{Payment_Gateway_18_Gst}</p>
                      </div>
                    </div>

                    <hr />
                    <div className="row">
                      {/* Total Payable (By Buyer) */}
                      <div className="col-8 text-left">
                        <p className="">Total Payable (By Participant)</p>
                      </div>
                      <div className="col-4 text-right">
                        <p className="">₹{Total_Buyer_Participant}</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-8 text-left">
                        {/* Money to you */}
                        <p className=""><strong>Receivable Amount</strong></p>
                      </div>
                      <div className="col-4 text-right">
                        <p className=""><strong>₹{TicketPrice ? TotalOrganiser : '0.00'}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        }

      </div>

    </>
  )
}

export default AddTickets;

