import React, { useState, useEffect } from 'react';
import axios from './axios';
import { useNavigate } from 'react-router-dom';
const CategorySection = (props) => {
    var QuicklyFilter = props.QuicklyFilter;
    const navigate = useNavigate();
    const [AllType, setAllType] = useState([]);
    const [ApiCall, setApiCall] = useState(true);

    const RedirectSearchEventPage = (filter_name, filter) => {
        // console.log(filter_name,filter);
        navigate('/events', { state: { "filter_name": filter_name, "filter": filter } });
    }

    useEffect(() => {
        if (ApiCall) {
            axios.get('types')
                .then((response) => {
                    //console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        setAllType(response.data.data.AllEventTypes);
                        setApiCall(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [ApiCall])
    // console.log(AllType);

    return (
        <>
            {/* Start Browse by Category */}
            <section className="banner__section pt-0 my-5">
                <div className="container">
                    <div className="section__heading mb-2">
                        <h3 className="section__heading--maintitle">Choose By Distance</h3>
                    </div>
                    <div className="row row-cols-lg-5 row-cols-md-2 row-cols-sm-2 row-cols-1 cursor-pointer">
                        {
                            (AllType && AllType.length > 0 ?
                                AllType.map((item) => {
                                    // console.log("Rendering item with ID:", item.id);
                                    return (
                                        <>
                                        {item.logo !== "" ?
                                            <div className="col p-2" key={item.id} onClick={(e) => RedirectSearchEventPage("category", item.id)}>
                                                <div className="banner__items">
                                                    <div className="banner__items--thumbnail position__relative">
                                                        <img className="banner__items--thumbnail__img" src={`${item.logo}`} alt="banner-img" />
                                                        <div className="banner__items--content__style2">
                                                            <h2 className="banner__items--content__style3--title text-white">{item.name}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        : '' }
                                        </>
                                    );
                                }) : null
                            )
                        }
                    </div>
                </div>
            </section>
            {/* End Browse by Category */}

            {/* Start Categories section */}
            <section className="categories__section color-scheme-3 py-5 my-5">
                <div className="container">
                    <div className="section__heading mb-4">
                        <h3 className="section__heading--maintitle">Quick Selection</h3>
                    </div>
                    <div className="categories__section--inner quick-filter">
                        <div className="row ">

                            {/* <div className="col mb-30" style={{width:"25%"}}>
                                <div className="categories__product--items hand_cursor" onClick={(e) => RedirectSearchEventPage("quick_filter", "tomorrow")}>
                                    <div className={QuicklyFilter === "tomorrow" ? "categories__product--items__link active" : "categories__product--items__link d-flex justify-content-between bg-4"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z" /></svg>
                                        <h3 className="categories__product--title h4">Tomorrow</h3>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 mb-30"  >
                                <div className="categories__product--items hand_cursor" onClick={(e) => RedirectSearchEventPage("quick_filter", "week")}>
                                    <div className={QuicklyFilter === "week" ? "categories__product--items__link active" : "categories__product--items__link d-flex justify-content-between bg-2"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path d="M320-400q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm160 0q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm160 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z" /></svg>
                                        <h3 className="categories__product--title h4">This Week</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 mb-30"  >
                                <div className="categories__product--items hand_cursor" onClick={(e) => RedirectSearchEventPage("quick_filter", "month")}>
                                    <div className={QuicklyFilter === "month" ? "categories__product--items__link active" : "categories__product--items__link d-flex justify-content-between bg-3"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path d="M480-400q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z" /></svg>
                                        <h3 className="categories__product--title h4">This Month</h3>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 mb-30"  >
                                <div className="categories__product--items hand_cursor" onClick={(e) => RedirectSearchEventPage("quick_filter", "weekend")}>
                                    <div className={QuicklyFilter === "weekend" ? "categories__product--items__link active" : "categories__product--items__link d-flex justify-content-between bg-1"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path d="M160-160q-50 0-85-35t-35-85v-200q0-33 23.5-56.5T120-560q33 0 56.5 23.5T200-480v160h560v-160q0-33 23.5-56.5T840-560q33 0 56.5 23.5T920-480v200q0 50-35 85t-85 35H160Zm120-240v-80q0-53-34.5-93T160-628v-52q0-50 35-85t85-35h400q50 0 85 35t35 85v52q-53 11-86.5 52.5T680-480v80H280Z" /></svg>
                                        <h3 className="categories__product--title h4">This Weekend</h3>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 mb-30"  >
                                <div className="categories__product--items hand_cursor" onClick={(e) => RedirectSearchEventPage("quick_filter", "quarter")}>
                                    <div className={QuicklyFilter === "quarter" ? "categories__product--items__link active" : "categories__product--items__link d-flex justify-content-between bg-1"}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path d="M160-160q-50 0-85-35t-35-85v-200q0-33 23.5-56.5T120-560q33 0 56.5 23.5T200-480v160h560v-160q0-33 23.5-56.5T840-560q33 0 56.5 23.5T920-480v200q0 50-35 85t-85 35H160Zm120-240v-80q0-53-34.5-93T160-628v-52q0-50 35-85t85-35h400q50 0 85 35t35 85v52q-53 11-86.5 52.5T680-480v80H280Z" /></svg> */}

                                        <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50" ><path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm80 240v-80h400v80H280Zm0 160v-80h280v80H280Z"/></svg>
                                        <h3 className="categories__product--title h4">This Quarter</h3>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col mb-30"  style={{width:"25%"}}>
                                <div className="categories__product--items hand_cursor" onClick={(e) => RedirectSearchEventPage("quick_filter", "today")}>
                                    <div className={QuicklyFilter === "today" ? "categories__product--items__link active" : "categories__product--items__link d-flex justify-content-between bg-5"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path d="M360-300q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z" /></svg>
                                        <h3 className="categories__product--title h4">Today</h3>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>
            {/* End Categories section */}
        </>
    )

}

export default CategorySection;