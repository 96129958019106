import React, { useEffect, useState } from "react";
import axios from "../axios";
import FormQuestionPopup from "../Create_event_details/FormQuestionPopup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const SubQuestionTreePopup = (props) => {

  const [SubFormQuestionAll, setSubFormQuestionAll] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [CloseFlag, setCloseFlag] = useState(0);
  // const [EditQuestionFlag, setEditQuestionFlag] = useState(props.EditQuestionFlag);
  // const new_flag =  localStorage.getItem("new_flag") ? localStorage.getItem("new_flag") : 2;

  useEffect(() => {
    if(CloseFlag === 1){
      props.setOpenTree(false);
    }
  }, [CloseFlag])

  // console.log(props.TicketsDetails);
  const [AddSvg, setAddSvg] = useState("m560-120-57-57 144-143H200v-480h80v400h367L503-544l56-57 241 241-240 240Z");
  const [MinusSvg, setMinusSvg] = useState("M200-440v-80h560v80H200Z");
  const [ExpandCollasp, setExpandCollasp] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [questionId, setquestionId] = useState('');
  const [questionName, setquestionName] = useState('');
  const [formName, setformName] = useState('');
  const [questionType, setquestionType] = useState('');
  const [questionOptionArray, setquestionOptionArray] = useState([]);
  const [isSubquestion, setisSubquestion] = useState(0);
  const [MainGeneralFormId, setMainGeneralFormId] = useState(0);
  const [PreviousQueId, setPreviousQueId] = useState('');
  const [MainQuestionHint, setMainQuestionHint] = useState('');

  useEffect(() => {
    const payload = {
      event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
      question_id: props.questionId ? props.questionId : 0,
    };
    axios.post("ViewSubquestionsTree", payload)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          setSubFormQuestionAll(response.data.data);
          setMainGeneralFormId(props.questionId);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [props.questionId])

  const RecursiveComponent = (data) => {
    // console.log(data.data);
    return (
      <>
          <div className="row tree-view" >
            <div className="col-sm-12" >
                
            {data.data.map((item, index) => (
                <div key={index}>
                    <div className="tcard py-3 px-2" >
                        <svg className="p-2" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" >
                            <path d={item.question_form_option.length === 0 ? '' : AddSvg} />
                        </svg>
                        <p className="m-0">{item.question_label}</p>
                        
                        {/* {item.event_questions_flag === 1 ? */}
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                        {/* : item.sub_question_tree_flag === 0 ?
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setOpen1(true); setquestionName(item.question_label); setquestionId(item.id); setquestionType(item.question_form_type); setquestionOptionArray(item.question_form_option); setisSubquestion(item.is_subquestion); setformName(item.form_id); setPreviousQueId(item.parent_question_id); setMainQuestionHint(item.question_hint); }}><path d="M80 0v-160h800V0H80Zm160-320h56l312-311-29-29-28-28-311 312v56Zm-80 80v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm560-504-56-56 56 56ZM608-631l-29-29-28-28 57 57Z"/></svg>
                        : ''
                        } */}

                    </div>

                    {item.ChildQuestionArray && item.ChildQuestionArray.length > 0 && (
                      <div className="tcard-child py-3 px-3">
                        <div className="px-3"></div>
                            <RecursiveComponent data={item.ChildQuestionArray} />
                      </div>
                    )}

                </div>
          
            ))}
            </div>
          </div>
      </>

      )
  }

  return (
    <>

      <div className="modal is-visible" data-animation="slideInUp" >
        <div className="modal-dialog quickview__main--wrapper">

          {/* <form method="post" > */}
            <div className="quickview__inner">
              <div className="row">

                <div className="login__section">


                  <div className="login__section--inner" >
                    <div className="p-5">
                      <div className="account__login--header mb-25">
                        <h3 className="account__login--header__title mb-10">Sub Questions Details</h3>

                      </div>

                      {/* {RecursiveComponent(SubFormQuestionAll)} */}
                      <RecursiveComponent data={SubFormQuestionAll} setAddGeneralQuestions={props.setAddGeneralQuestions} setOpenTree={props.setOpenTree} setCloseFlag={setCloseFlag} OpenTree={props.OpenTree} formName={formName} />

                      {/* ----- End ----- */}

                      <div className="d-flex gap-4">
                        <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                          props.setOpenTree(false);
                        }}>Close</button>

                        {/* <input type="submit" name="command" value="Save" className="account__login--btn primary__btn mt-2" /> */}

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          {/* </form> */}
        </div>
      </div>

      {open1 ? <FormQuestionPopup setOpen1={setOpen1} questionName={questionName} questionId={questionId} questionType={questionType} isSubquestion={isSubquestion} questionOptionArray={questionOptionArray} setAddGeneralQuestions={props.setAddGeneralQuestions} setOpenTree={props.setOpenTree} subquesFlag={1} setCloseFlag={setCloseFlag} FieldMappingDetails={props.FieldMappingDetails} MainGeneralFormId={MainGeneralFormId} formName={formName} TicketsDetails={props.TicketsDetails} PreviousQueId={PreviousQueId} MainQuestionHint={MainQuestionHint} /> : null}
      
    </>
  );
};

export default SubQuestionTreePopup;
