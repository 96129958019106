// Calculation(TicketArr[index], OrgGstPercentage, TicketYtcrBasePrice, YTCR_FEE_PERCENT, PAYMENT_GATEWAY_FEE_PERCENT, PAYMENT_GATEWAY_GST_PERCENT, CollectGst, PriceTaxesStatus);

// Me - Organiser / Buys - Participant

export const Calculation = (TicketArr = [], CollectGst, ExcPriceTaxesStatus, AmountArr, RaceCategoryCharges, OtherAmountReset = 0) => {
//   console.log(TicketArr);
   // console.log(TicketArr["ticket_calculation_details"].collect_gst);
    // console.log(TicketArr, RegistrationGstPercentage, YtcrFee, YtcrGstPercentage, PaymentGatewayPercentage, PaymentGatewayGstPercentage, CollectGst, ExcPriceTaxesStatus, AmountArr);
    let RegistrationGstPercentage = TicketArr ? TicketArr["OrgGstPercentage"] : 0;
    let YtcrFee = TicketArr ? TicketArr["TicketYtcrBasePrice"] : 0;
    let YtcrGstPercentage = TicketArr ? TicketArr["YTCR_FEE_PERCENT"] : 0;
    let PaymentGatewayPercentage = TicketArr ? TicketArr["PAYMENT_GATEWAY_FEE_PERCENT"] : 0;
    let PaymentGatewayGstPercentage = TicketArr ? TicketArr["PAYMENT_GATEWAY_GST_PERCENT"] : 0;

    //---------- early_bird is yes / no
    const TicketPrice = (TicketArr["show_early_bird"] === 1) ? parseFloat(TicketArr["discount_ticket_price"]) : parseFloat(TicketArr["ticket_price"]);
     // (item.hasOwnProperty("appliedCouponId")
     
    //---------- applied Coupon
    let TicketPriceFloat = (TicketArr.hasOwnProperty("appliedCouponAmount")) ? TicketPrice - (TicketArr["appliedCouponAmount"]/TicketArr["count"]) : TicketPrice;
 
    
    //----------- new calculation

    // let ConvenienceFeeBase = TicketArr["ticket_calculation_details"].convenience_fee_base;
    // let NewPlatformFee = TicketArr["ticket_calculation_details"].platform_fees_5_each;
    // let NewPaymentGatewayFee = TicketArr["ticket_calculation_details"].payment_gateway_fees;

    let ConvenienceFeeBase = 0;
    let NewPlatformFee = 0;
    let NewPaymentGatewayFee = 0;

    if (RaceCategoryCharges.length > 0 && RaceCategoryCharges) {
     
        for (var i = 0; i < RaceCategoryCharges.length; i++) {
          if (RaceCategoryCharges[i]['registration_amount'] >= parseFloat(TicketPriceFloat)){
            //console.log(RaceCategoryChargesDetails[i]['convenience_fee']);
             ConvenienceFeeBase = (RaceCategoryCharges[i]['convenience_fee']);
             NewPlatformFee = (RaceCategoryCharges[i]['platform_fee']);       // 5 Rs
             NewPaymentGatewayFee = (RaceCategoryCharges[i]['payment_gateway_fee']); // 1.85 %
            
            break;
          }else if(i === RaceCategoryCharges.length - 1 && RaceCategoryCharges[i]['registration_amount'] <= parseFloat(TicketPrice)){
            //console.log(RaceCategoryCharges[i]['convenience_fee']);
             ConvenienceFeeBase = (RaceCategoryCharges[i]['convenience_fee']);
             NewPlatformFee = (RaceCategoryCharges[i]['platform_fee']);
             NewPaymentGatewayFee = (RaceCategoryCharges[i]['payment_gateway_fee']);
         
            break;
          }
        }
    }

   // console.log(ConvenienceFeeBase,NewPlatformFee,NewPaymentGatewayFee);
   // console.log(TicketArr["ticket_calculation_details"]);
    
   
    // console.log(TicketPriceFloat);

    const YtcrAmount = isNaN(YtcrFee) ? 0 : parseFloat((YtcrFee * (YtcrGstPercentage / 100)).toFixed(2));

    let RegistrationFee;
    const WhoPayYtcrFee = TicketArr["player_of_fee"];
    const WhoPayPaymentGatewayFee = TicketArr["player_of_gateway_fee"];
    let BuyerAmtWithoutPaymentGateway = 0;
    let PaymentGatewayAmount = 0;
    let PaymentGatewayWithGst = 0;

    //----------------------------
    // if (parseInt(WhoPayPaymentGatewayFee) === 1 && parseInt(WhoPayYtcrFee) === 1) { //Organiser+Organiser
    //     BuyerAmtWithoutPaymentGateway = (TicketPriceFloat + RegistrationFee);
    // } else if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 2) { // Organiser + Participant
    //     BuyerAmtWithoutPaymentGateway = (TicketPriceFloat + RegistrationFee);
    // } else { //me = 1;buyer = 2
    //     BuyerAmtWithoutPaymentGateway = (TicketPriceFloat + RegistrationFee + YtcrAmount + YtcrFee);
    // }

    
    // // console.log(BuyerAmtWithoutPaymentGateway, TicketPriceFloat);
    // PaymentGatewayAmount = BuyerAmtWithoutPaymentGateway !== 0 ? BuyerAmtWithoutPaymentGateway * (PaymentGatewayPercentage / 100) : 0;
    // PaymentGatewayWithGst = PaymentGatewayAmount !== 0 ? PaymentGatewayAmount * (PaymentGatewayGstPercentage / 100) : 0;
    
    //----------------------------------------

    //------ collect gst and include/exclude gst condition
    // if (CollectGst === 1 && ExcPriceTaxesStatus === 2) {
    //     RegistrationFee = isNaN(TicketPriceFloat) ? 0 : TicketPriceFloat * (RegistrationGstPercentage / 100);
    // } else {
    //     RegistrationFee = 0;
    // }

   

    // console.log(PaymentGatewayAmount, PaymentGatewayWithGst);
   
    TicketArr["RegistrationGstPercentage"] = (RegistrationGstPercentage);
    TicketArr["YtcrGstPercentage"] = (YtcrGstPercentage);
    TicketArr["PaymentGatewayPercentage"] = (PaymentGatewayPercentage);
    TicketArr["PaymentGatewayGstPercentage"] = (PaymentGatewayGstPercentage);

    let BuyerPayment;
    let MoneyToOrg;

    //--------------------------------------------------------------------------------

    let BasePriceGst = 0;
    let Basic_Amount_Gst = 0;
    
    let GST_On_Convenience_Fees = 0;
    let Total_Convenience_Fees = 0;
    let GST_On_Platform_Fees_Amount = 0;
    let Total_Platform_Fees = 0;
    let Net_Registration_Amount = 0;
    let Payment_Gateway_Buyer = 0;
    let Payment_Gateway_gst_amount = 0;
    let Total_Payment_Gateway = 0;
    let Total_Buyer = 0;
    let Total_Organiser = 0;
    let Convenience_Fee_Amount = 0;

    const Convenience_Fees_Gst_Percentage = 18;
    const GST_On_Platform_Fees = 18;
    const Payment_Gateway_Gst = 18;

    let GstPercentage = 0;

    if (CollectGst === 1 && ExcPriceTaxesStatus === 2) {  // get for organization page
        GstPercentage = 18;
    } else {
        GstPercentage = 0;
    }

    if (CollectGst === 1 && ExcPriceTaxesStatus === 2) { 
        BasePriceGst = TicketPriceFloat !== 0 ? TicketPriceFloat * (GstPercentage / 100) : 0; // GST %
        Basic_Amount_Gst = (parseFloat(BasePriceGst) + TicketPriceFloat);
    } else {
        BasePriceGst = '0.00';
        Basic_Amount_Gst = parseFloat(TicketPriceFloat); // registration amt
    }
// console.log(TicketPriceFloat,BasePriceGst,GstPercentage,ConvenienceFeeBase);
    if(parseFloat(ConvenienceFeeBase) === 30 || parseFloat(ConvenienceFeeBase) === 40 || parseFloat(ConvenienceFeeBase) === 10){
        //console.log('ss');
        Convenience_Fee_Amount = parseFloat(ConvenienceFeeBase);
    }else{
        Convenience_Fee_Amount = Basic_Amount_Gst * (ConvenienceFeeBase / 100);  
    }
    
    GST_On_Convenience_Fees = parseFloat(Convenience_Fee_Amount) * (Convenience_Fees_Gst_Percentage / 100); // GST 18%
    Total_Convenience_Fees = (parseFloat(Convenience_Fee_Amount) + GST_On_Convenience_Fees);
    GST_On_Platform_Fees_Amount = NewPlatformFee * (GST_On_Platform_Fees / 100); // GST 18%
    Total_Platform_Fees = (parseFloat(NewPlatformFee) + parseFloat(GST_On_Platform_Fees_Amount));
    Net_Registration_Amount = (parseFloat(Basic_Amount_Gst) + parseFloat(Total_Convenience_Fees) + parseFloat(Total_Platform_Fees));
    
   // console.log(GST_On_Convenience_Fees,Total_Convenience_Fees,GST_On_Platform_Fees_Amount,Total_Platform_Fees,Net_Registration_Amount);
    let totalPlatformFee = 0;
    let totalTaxes = 0;
    let Extra_Amount_Payment_Gateway = 0; 
    let Extra_Amount_Payment_Gateway_Gst = 0; 
    let Extra_Amount = 0; 

    // Extra_Amount_Payment_Gateway = i.value * (NewPaymentGatewayFee / 100); // 1.85%
    // Extra_Amount_Payment_Gateway_Gst =  i.value * (Payment_Gateway_Gst / 100); //18%
   

    if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 1) {  //Organiser + Organiser
        
        let itemValue = 0;
        if (AmountArr && Array.isArray(AmountArr) && AmountArr.length > 0) {
          for (let i of AmountArr) {
            itemValue += parseFloat(i.value);
            Extra_Amount = itemValue;
          }
        }

        Payment_Gateway_Buyer = Basic_Amount_Gst * (NewPaymentGatewayFee / 100); // 1.85%
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));

        BuyerPayment = Basic_Amount_Gst;  // yes
        // console.log(BuyerPayment);
        
        //----------------------------------
        totalPlatformFee = 0;
        totalTaxes = parseFloat(BasePriceGst);

    }
    else if (parseInt(WhoPayYtcrFee) === 2 && parseInt(WhoPayPaymentGatewayFee) === 2) {  // Participant + Participant
        //console.log(Net_Registration_Amount);
        //--------additional amt
        let itemValue = 0;
        if (AmountArr && Array.isArray(AmountArr) && AmountArr.length > 0) {
          for (let i of AmountArr) {
            itemValue += parseFloat(i.value);
            //Payment_Gateway_Buyer = (Net_Registration_Amount) * (NewPaymentGatewayFee / 100); // 1.85%
            Extra_Amount = itemValue;
            Extra_Amount_Payment_Gateway = itemValue * (NewPaymentGatewayFee / 100); // 1.85%
            Extra_Amount_Payment_Gateway_Gst = Extra_Amount_Payment_Gateway * (Payment_Gateway_Gst / 100); //18%
          }
        }else if(OtherAmountReset === 0){
            Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        }else{
            Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        }
        //console.log(Extra_Amount_Payment_Gateway,Extra_Amount_Payment_Gateway_Gst);
     
        //----------------------
        Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));
       
        BuyerPayment = (parseFloat(Total_Payment_Gateway) + parseFloat(Net_Registration_Amount));
        // console.log(BuyerPayment);
        // console.log(Payment_Gateway_Buyer,Payment_Gateway_gst_amount,Total_Payment_Gateway,Net_Registration_Amount);
        //----------------------------------
        totalPlatformFee = parseFloat(Convenience_Fee_Amount) + parseFloat(NewPlatformFee) + parseFloat(Payment_Gateway_Buyer);
        totalTaxes = parseFloat(BasePriceGst) + parseFloat(GST_On_Convenience_Fees) + parseFloat(GST_On_Platform_Fees_Amount) + parseFloat(Payment_Gateway_gst_amount);
    }
    else if (parseInt(WhoPayYtcrFee) === 1 && parseInt(WhoPayPaymentGatewayFee) === 2) { // Organiser + Participant
        
        let itemValue = 0;
        if (AmountArr && Array.isArray(AmountArr) && AmountArr.length > 0) {
          for (let i of AmountArr) {
            itemValue += parseFloat(i.value);
            Extra_Amount = itemValue;
          }
        }

        Payment_Gateway_Buyer = Basic_Amount_Gst * (NewPaymentGatewayFee / 100); // 1.85%
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));
        BuyerPayment = (parseFloat(Basic_Amount_Gst) + parseFloat(Total_Payment_Gateway));
    
        //----------------------------------
        // console.log(Payment_Gateway_gst_amount,Payment_Gateway_Buyer,BasePriceGst);
        totalPlatformFee = parseFloat(Payment_Gateway_Buyer);
        totalTaxes = parseFloat(BasePriceGst) + parseFloat(Payment_Gateway_gst_amount);
    }
    else if (parseInt(WhoPayYtcrFee) === 2 && parseInt(WhoPayPaymentGatewayFee) === 1) { // Participant + Organiser
        
        //--------additional amt
        let itemValue = 0;
        if (AmountArr && Array.isArray(AmountArr) && AmountArr.length > 0) {
          for (let i of AmountArr) {
            itemValue += parseFloat(i.value);
            Extra_Amount = itemValue;
            Payment_Gateway_Buyer = (itemValue + Net_Registration_Amount) * (NewPaymentGatewayFee / 100); // 1.85%
          }
        }else if(OtherAmountReset === 0){
            Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        }else{
            Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        }

        //Payment_Gateway_Buyer = Net_Registration_Amount * (NewPaymentGatewayFee / 100); // 1.85%
        Payment_Gateway_gst_amount = Payment_Gateway_Buyer * (Payment_Gateway_Gst / 100); //18%
        Total_Payment_Gateway = (parseFloat(Payment_Gateway_Buyer) + parseFloat(Payment_Gateway_gst_amount));
      
        BuyerPayment = (parseFloat(Basic_Amount_Gst) + parseFloat(Total_Convenience_Fees) + parseFloat(Total_Platform_Fees) );
       
        //----------------------------------
        totalPlatformFee = parseFloat(Convenience_Fee_Amount) + parseFloat(NewPlatformFee);
        totalTaxes = parseFloat(BasePriceGst) + parseFloat(GST_On_Convenience_Fees) + parseFloat(GST_On_Platform_Fees_Amount);
        //console.log(totalPlatformFee,totalTaxes);
    }
    
    //---------- free ticket only handle this condition
    // if (AmountArr && Array.isArray(AmountArr)) {
    //    //console.log(AmountArr);
    //     for (let item of AmountArr) {
    //         if (parseInt(item.ticket_id) === TicketArr["id"] && item.value) {
    //             //BuyerAmtWithoutPaymentGateway += parseFloat(item.value);
    //             BuyerPayment += parseFloat(item.value);
    //             //TicketArr["OtherAmount"] = parseFloat(item.value);
    //         }
    //     }
    // }
    TicketArr["Extra_Amount"] = parseFloat(Extra_Amount).toFixed(2);
    TicketArr["Extra_Amount_Payment_Gateway"] = parseFloat(Extra_Amount_Payment_Gateway).toFixed(2);
    TicketArr["Extra_Amount_Payment_Gateway_Gst"] = parseFloat(Extra_Amount_Payment_Gateway_Gst).toFixed(2);

    
    TicketArr["RegistrationFee"] = parseFloat(BasePriceGst).toFixed(2);
    TicketArr["Convenience_Fee"] = parseFloat(Convenience_Fee_Amount).toFixed(2);
    TicketArr["Platform_Fee"] = parseFloat(NewPlatformFee).toFixed(2);
    TicketArr["Total_Platform_Fee"] = parseFloat(totalPlatformFee).toFixed(2);
  
    TicketArr["Payment_Gateway_Charges"] = parseFloat(Payment_Gateway_Buyer).toFixed(2);
    TicketArr["Registration_Fee_GST"] = parseFloat(BasePriceGst).toFixed(2);
    TicketArr["Convenience_Fee_GST_18"] = parseFloat(GST_On_Convenience_Fees).toFixed(2);
    TicketArr["Platform_Fee_GST_18"] = parseFloat(GST_On_Platform_Fees_Amount).toFixed(2);
    TicketArr["Payment_Gateway_GST_18"] = parseFloat(Payment_Gateway_gst_amount).toFixed(2);
    TicketArr["Payment_Gateway_Fee"] = parseFloat(NewPaymentGatewayFee).toFixed(2);
    TicketArr["Total_Taxes"] = parseFloat(totalTaxes).toFixed(2);
    
    TicketArr["BuyerAmtWithoutPaymentGateway"] = parseFloat(BuyerAmtWithoutPaymentGateway).toFixed(2);
    
    TicketArr["Main_Price"] = parseFloat(TicketPriceFloat).toFixed(2);

    TicketArr["CollectGst"] = CollectGst; 
    TicketArr["ExcPriceTaxesStatus"] = ExcPriceTaxesStatus;
    // console.log(TicketArr);
    // console.log(BuyerPayment,MoneyToOrg);
    TicketArr["BuyerPayment"] = parseFloat(BuyerPayment).toFixed(2);
    TicketArr["OrgPayment"] = parseFloat(MoneyToOrg).toFixed(2);
    // console.log(TicketArr);
    return TicketArr;
};

