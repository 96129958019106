import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChart = ({ data }) => {
  const categories = data.map(item => item.date);
  const threshold = 7; // Threshold to determine whether to show all dates or alternate dates

  let tickPositions;
  if (categories.length <= threshold) {
    tickPositions = categories.map((_, index) => index); // Show all dates
  } else {
    tickPositions = categories.map((_, index) => index).filter((_, index) => index % 2 === 0); // Show alternate dates
  }

  const chartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Daily Category Count'
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      tickPositions: tickPositions, // Explicitly set tick positions
      labels: {
        rotation: -45, // Rotate labels if there are too many dates to fit
        style: {
          fontSize: '10px'
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Category Count',
        style: {
          fontSize: '13px'
        }
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0;font-size:13px">{series.name}: </td>' +
        '<td style="padding:0;font-size:13px"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'Categories',
        data: data.map(item => item.count)
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default BarChart;
