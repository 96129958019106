import React, { useState, useEffect } from 'react';
import './App.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from './axios';

const EventTypeSection = () => {
 
  const navigate = useNavigate();
  
  const handleEventSelType = (event, type) => {
      //console.log(type);
      if(type == 1){
        localStorage.setItem("EventType", 1);
      }else if(type == 2){
        localStorage.setItem("EventType", 2);
      }
      navigate("/create_events");
  }

  return (
    <>

      <section className="product__section color-scheme-3 pt-5 mt-5">
        <div className="container">
          <div className="section__heading text-center mb-5">
            <h2 className="section__heading--maintitle">Events Selection</h2>
          </div>
         
          <div className="product__section--inner product__swiper--column4 swiper py-5" >
            <div className="swiper-wrapper">
                  <p className="m-0" onClick={(e) => handleEventSelType(e,1)}>Physical Event</p>
                  <p className="m-0">|</p>
                  <p className="m-0" onClick={(e) => handleEventSelType(e,2)}>Virtual Event</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EventTypeSection;

