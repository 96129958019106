import React, { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../axios';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import HeaderSecond from '../HeaderSecond';


const CancellationPolicy = () => {
  // console.log("Create Event page");
  const navigate = useNavigate();

  const handleBackToHome = () => {
    localStorage.removeItem('EventViewFlag');
    localStorage.removeItem('EventDetailsId');

    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = localStorage.getItem('Country');
    navigate('/' + CountryCode + '/' + CountryName);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <ToastContainer theme="colored" />

      <HeaderSecond />
      <main className="main__content_wrapper">
        {/* Start breadcrumb section */}
        <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <div className="breadcrumb__content">
                  <h1 className="breadcrumb__content--title text-white mb-10">
                    Cancellation Policy
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex">
                    <li className="breadcrumb__content--menu__items">
                      <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                        Home
                      </div>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">Cancellation Policy</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End breadcrumb section */}

        <section className="section--padding pt-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 my-5" style={{ textAlign: "justify" }}>
              <p>CANCELLATION POLICY</p><p>Cancellations received after the stated deadline will not be eligible for a refund.</p><p>Cancellations will be accepted via Email only.</p><p>All refund requests must be made by the attendee or credit card holder.</p><p>Your refund will be processed within 7 working days from the date of initiation. If you have any further questions or concerns, please feel free to contact our customer support.</p><p>Refund requests must include the name of the attendee and/or transaction number.</p><p>Refunds will be credited back to the source account used for payment and will follow the norms as per the refund policy of the Event Organizer.&nbsp;</p><p>Cancellation of the participant participating in an event is subject to the event terms and conditions.</p><p>Refunds will not be made if the event is canceled/ postponed due to ‘force majeure’ reasons and will be at the sole discretion of the Event Organiser.</p><p>RACES - a wholly-owned&nbsp;division of&nbsp;&nbsp;YouTooCanRun<strong>&nbsp;</strong>is only a collection agency and is in no way responsible for the satisfactory conduct of the event. Similarly, except where explicitly stated,&nbsp;RACES - a wholly-owned&nbsp;division of&nbsp;&nbsp;YouTooCanRun&nbsp;is not responsible for the goods or services traded/delivered through its website. We are thus not responsible for cancellation/refund on behalf of third parties.</p>

              </div>
            </div>

          </div>
        </section>

      </main >

    </>
  )
}

export default CancellationPolicy;

