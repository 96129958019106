import React, { useEffect, useState, useRef, useCallback } from "react";
import { token } from "../features/TokenSlice";
import { useDispatch } from "react-redux";
import axios from "../axios";
import { ToastContainer } from 'react-toastify';
import { Link } from "react-router-dom";
//import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const Login = (props) => {
  const dispatch = useDispatch();
  const [UserName, setUserName] = useState('');
  const [UserPassword, setUserPassword] = useState('');

  const [usernameError, setUsernameError] = useState();
  const [error, setError] = useState('');
  const [ErrFlag, setErrFlag] = useState(false)

  const [passwordError, setPasswordError] = useState();
  const [LoginLoader, setLoginLoader] = useState(false);
  const DivRef = useRef(null);

  // console.log(UserName, UserPassword);
  const handleValidation = useCallback(() => {
    // console.log(users);
    setUsernameError("");
    setPasswordError("");
    var flag = true;

    if (UserName === "") {
      setUsernameError("Please enter Email id.");
      flag = false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(UserName)) {
      setUsernameError('Please enter a valid email address');
      flag = false;
    }
    if (UserPassword === "") {
      setPasswordError("Please enter password");
      flag = false;
    }
    return flag;
  }, [UserName, UserPassword, setUsernameError, setPasswordError]);


  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      setLoginLoader(true);
      axios
        .post("login", {
          Email: UserName,
          Password: UserPassword,
        })
        .then((response) => {
          //console.log(response.data.data.OrgUserAccessModules)
          if (response.data.status === 200) {
            // console.log(response.data.user_id);
            if (parseInt(response.data.validate) === 0) {
              // console.log("validate is 0");
              props.setMsgFlag("Login");
              localStorage.setItem(
                "RacesToken",
                JSON.stringify({ token: response.data.data.token })
              );

              localStorage.setItem("ID", response.data.data.userData.id)
              localStorage.setItem("EMAIL", response.data.data.userData.email)
              localStorage.setItem("MOBILE", response.data.data.userData.mobile)
              localStorage.setItem("SuperAdmin", response.data.data.userData.type)
              localStorage.setItem("Company_info", response.data.data.company_info_flag)
              
              if(response.data.data.OrgUserAccessModules){
                localStorage.setItem("OrgUserAccessModules", JSON.stringify(response.data.data.OrgUserAccessModules));
                localStorage.setItem("LoginAsOrganiser", response.data.data.userData.organizer_user)
              } 
              localStorage.setItem("Role_Name", response.data.data.role_name);
              localStorage.setItem("Access_Right_Flag", response.data.data.AccessRightFlag);

              dispatch(token({ token: response.data.data.token }));
              props.setOpen(false);
              props.setLoginWishlist(false);
              props.setWelcome(true);
              if (props.Login)
                props.setLogin(false)
            } else {
              // console.log("validate is 1")
              localStorage.setItem("ID", response.data.user_id)
              props.setOpen(false)
              props.setUserVerify(true)
              if (props.Login)
                props.setLogin(false)
              props.setLoginWishlist(false);
            }
          }
        })
        .catch((response) => {
          // console.log(response);
          if (response.response.data.status === 400) {
            setErrFlag(true);
            setError(response.response.data.message);
          }
        });
    }
  }, [UserName, UserPassword, handleValidation, props, dispatch]);

  const handleClose = (e) => {
    if (DivRef.current && !DivRef.current.contains(e.target)) {
      // props.setOpen(false);
    }
  }

  useEffect(() => {
    const handleEscKeyPress = (event) => {
      if (event.key === 'Escape') {
        props.setOpen(false);
        props.setLoginWishlist(false);
      }
    };
    document.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [props, props.setOpen]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        // console.log(UserName, UserPassword);
        handleSubmit(e);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.body.style.overflow = 'auto';
    };
  }, [UserName, UserPassword, handleSubmit]);


  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword);
  };
  // useEffect(() => {
  //   const loadGoogleSignInScript = () => {
  //     const script = document.createElement('script');
  //     script.src = 'https://apis.google.com/js/platform.js';
  //     script.onload = initGoogleSignIn;
  //     document.body.appendChild(script);
  //   };

  //   const initGoogleSignIn = () => {
  //     window.gapi.load('auth2', () => {
  //       window.gapi.auth2.init({
  //         client_id: '144759388569-mqd1729a8qd3kvdtap2fsvrs06a5sp9b.apps.googleusercontent.com',
  //       });
  //     });
  //   };

  //   loadGoogleSignInScript();

  //   return () => {
  //     // Clean up the script when the component unmounts
  //     const script = document.querySelector('script[src="https://apis.google.com/js/platform.js"]');
  //     if (script) {
  //       document.body.removeChild(script);
  //     }
  //   };
  // }, []);

  const onSignIn = googleUser => {
    const profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId());
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail());
  };

  const onFailure = (error) => {
    console.log('Login Failed:', error);
    //setIsLoggedIn(false);
  };

  const onLogoutSuccess = () => {
    console.log('User signed out.');
  };


  return (
    <>
      <ToastContainer theme="colored" />

      <div className="modal is-visible" data-animation="slideInUp" onClick={(e) => { handleClose(e) }}>
        <div className="modal-dialog quickview__main--wrapper">
          <header className="modal-header quickview__header">
            {/* <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
              props.setOpen(false);
            }}>✕ </button> */}
          </header>
          <div className="quickview__inner">
            <div className="row">

              <div className="login__section">

                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="login__section--inner" ref={DivRef}>
                    <div className="p-5">
                      <div className="account__login--header mb-25">
                        <h3 className="account__login--header__title mb-10">Log In</h3>
                        <p className="account__login--header__desc">Your all-in-one event registering tool</p>
                      </div>
                      <div className="account__login--inner">
                        <div className="my-3">
                          <label>
                            <input className="account__login--input mb-2" placeholder="Email Id" type="email" name="username"
                              onChange={(e) => { setUserName(e.target.value) }} onClick={() => setErrFlag(false)}

                              value={UserName} />
                          </label>
                          <small className="text-danger mb-5">{usernameError}</small>
                        </div>
                        {/* <div className="my-3">
                          <label>
                            <input className="account__login--input mb-2" placeholder="Password" type="password"
                              name="password"
                              onChange={(e) => { setUserPassword(e.target.value) }} onClick={() => setErrFlag(false)}
                              value={UserPassword}
                            />
                          </label>
                          <small className="text-danger mb-5">{passwordError}</small>
                        </div> */}
                        <div className="my-3">
                          <label className="d-flex align-items-center">

                            <input
                              className="account__login--input mb-2"
                              placeholder="Password"
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              onChange={(e) => { setUserPassword(e.target.value) }}
                              onClick={() => setErrFlag(false)}
                              value={UserPassword}
                            />
                            {showPassword ?
                              <div className="tooltip">
                                <svg onClick={(e) => handlePasswordToggle(e)} style={{ position: "absolute", right: "10px", cursor: "pointer", top: "-14px" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
                                </svg>
                                <span className="tooltiptext position-fixed border-radius-10 p-4 w-auto">Hide</span>
                              </div>

                              :
                              <div className="tooltip">
                                <svg onClick={(e) => handlePasswordToggle(e)} style={{ position: "absolute", right: "10px", cursor: "pointer", top: "-14px" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>
                                <span className="tooltiptext position-fixed border-radius-10 p-4 w-auto">Show</span>
                              </div>
                            }
                          </label>
                          <small className="text-danger mb-5">{passwordError}</small>
                        </div>
                        {ErrFlag ?
                          <div className="text-danger mb-5" style={{ "textAlign": "center" }} id="err">{error}</div>
                          : null}

                        <div className="d-flex gap-4">
                          <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                            props.setOpen(false); props.setLoginWishlist(false);
                          }}>Close</button>

                          <button className="account__login--btn primary__btn mt-2" type="submit">Continue</button>

                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', borderBottom: 'none' }}>
                          <span className="account__login--divide__text" onClick={(e) => {
                            props.setForgotPasswordFlag(true);
                            props.setOpen(false); props.setLoginWishlist(false);
                          }}>
                            Forgot Password?
                          </span>
                        </div>

                        <div className="account__login--divide my-3">
                          <span className="account__login--divide__text">OR</span>
                        </div>

                        <div className="account__social d-flex justify-content-center mb-5">

                          {/* <div className="account__social--link border text-secondary cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px" height="25px"><path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg>
                            Continue with Google

                            
                          </div> */}

                          {/* <GoogleOAuthProvider>
                            <GoogleLogin
                              clientId="144759388569-mqd1729a8qd3kvdtap2fsvrs06a5sp9b.apps.googleusercontent.com"
                              buttonText="Login with Google"
                              onSuccess={onSuccess}
                              onFailure={onFailure}
                              cookiePolicy={'single_host_origin'}
                            />
                          </GoogleOAuthProvider> */}

                          <div>
                            <span style={{ marginTop: "10px" }}>Don’t have an account? {"  "} </span>&nbsp;
                            <span className="account__login--divide__text" style={{ fontSize: "18px" }} onClick={() => {
                              props.setSignUp(true);
                              props.setOpen(false);
                            }
                            }>Signup</span>
                          </div>

                        </div>
                        <p>By continuing, you agree to YooTooCanRun's {" "}
                          <Link to="https://youtoocanrun.com/terms-and-conditions-for-use/" target="blank" >  Terms of Services</Link> and <Link to="https://youtoocanrun.com/privacy-policy/" target="blank"> Privacy Statement</Link>.</p>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
