import React, { useState,useEffect } from 'react';
import '../App.css';
import AddAgeCriteria from "../Create_event_details/AddAgeCriteria";
import ageCriteriaIcon from "../img/other/age-limit.png";
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';

const AgeCriteria = (props) => {

    const [AddingAgeCriteria, setAddingAgeCriteria] = useState(false);
    //console.log(props.FAQDetails);

    const [DeleteCommPopup, setDeleteCommPopup] = useState(false);
    const [ConfirmDeleteTicket, setConfirmDeleteTicket] = useState(false);
    const [CommId, setCommId] = useState('');
    const [EventStatus, setEventStatus] = useState(false);
    const [ActionFlag, setActionFlag] = useState('');
    const [evntStatus, setevntStatus] = useState(''); 
    const [eventIndex, seteventIndex] = useState(''); 
    const [EventFlag, setEventFlag] = useState(false);
    const [AgeCriteriaDetailsAll, setAgeCriteriaDetailsAll] = useState('');
    const [Loader, setLoader] = useState(false);
    
    const handleComm = (comm_id, operate) => {
       
        if (operate === 'edit') {
            setAddingAgeCriteria(true);
        }
        if (operate === "delete") {
            setDeleteCommPopup(true);
        }
        if (operate === "change_status") {
            setDeleteCommPopup(true);
        }
    }

    const DeleteFAQ = () => {
        
        //console.log(ConfirmDeleteTicket,CommId);
        if (CommId !== "") {
            axios.post('delete_event_comm_faq',
                {
                    'event_id': localStorage.getItem("EventId"),
                    'event_comm_id': CommId,
                    'common_flag': 'age_delete'
                }
            )
            .then((response) => {
                props.setEventApiCall(true)
                toast.success(response.data.message)
                setConfirmDeleteTicket(false)
                setCommId('')
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    const ActionChangeStatus = () => {
        
        if(ActionFlag === 'change_status'){
            setEventFlag(true);  
            let EventArray = [...AgeCriteriaDetailsAll];
            EventArray[eventIndex]['status'] = !evntStatus;
            setAgeCriteriaDetailsAll(EventArray);  
        }
        //console.log(FAQDetailsAll);
        setLoader(true);
        const payload = {
            coupon_id: CommId,
            coupon_status: evntStatus,
            action_flag: 'age_criteria_changes_status'
        };
        //console.log(payload);
        axios.post("status_coupon",payload)
        .then((response) => {
         // console.log(response);
            if (response.status === 200) {
                setLoader(false);
                toast.success(response.data.message)
                setevntStatus(''); 
                seteventIndex('');
                props.setEventApiCall(true);
                setEventStatus(false)
            }
        })
        .catch((error) => {
            setLoader(false);
            console.log(error);
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.AgeCriteriaDetails && EventFlag === false){
            setAgeCriteriaDetailsAll(props.AgeCriteriaDetails);
            //console.log('ss');
        }
    }, [props.AgeCriteriaDetails,EventFlag]);


    return (
        <>
            {
                DeleteCommPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">
                                                    
                                                    <p className="account__login--header__desc">Are you sure you want to delete this record?</p>
                                                </div>
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setDeleteCommPopup(false);
                                                    }}>Cancel</button>
                                                    
                                                    { ActionFlag && ActionFlag === 'change_status' ?
                                                        <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmDeleteTicket(true); ActionChangeStatus(); setDeleteCommPopup(false);  }}>Yes</button>
                                                    :
                                                       <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmDeleteTicket(true); DeleteFAQ(); setDeleteCommPopup(false);  }}>Yes</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {AddingAgeCriteria === true ?
        
                <AddAgeCriteria setAddingAgeCriteria={setAddingAgeCriteria} setTicketDivShowFlag={props.setTicketDivShowFlag} TicketDivShowFlag={props.TicketDivShowFlag} setEventApiCall={props.setEventApiCall} CommId={CommId} TicketsDetails={props.TicketsDetails} AgeDetails={props.AgeDetails} />
                :
                <div className="main checkout__mian">
                    {/* <form action="#"> */}
                    <div className="checkout__content--step section__shipping--address ">

                        <div className="mb-5">
                        
                            <div className="section__header mb-4 d-flex align-items-center justify-content-between">
                                <h2 className="section__header--title h3">Age Category</h2>
                                <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setCommId(''); setAddingAgeCriteria(true); }} style={{cursor:'pointer'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add Age Category
                                </div>
                            </div>
                           
                             <div className="row">
                                {AgeCriteriaDetailsAll && AgeCriteriaDetailsAll.length > 0
                                    ?
                                    AgeCriteriaDetailsAll.map((item, index) => {
                                        return (
                                            <>
                                                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-5 form-que" >
                                                    <div className="card w-100" key={index+789}>
                                                    
                                                    <div className="mx-3 w-100 input-field">
                                                        <div className="form-floating mb-0 text-left">
                                                        <div className="">
                                                            <h4 className="m-0">{item.age_category}</h4>
                                                         </div> 
                                                         
                                                        </div>
                                                    </div>
                                                                                                       
                                                    <div className="mx-3 option-icon"  onClick={() => { setCommId(item.id); handleComm(item.id, 'delete'); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                    </div>

                                                    <div className="mx-3 option-icon-edit" onClick={() => { setCommId(item.id); handleComm(item.id, 'edit');  }} style={{cursor:'pointer'}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                    </div>

                                                    <div className="col-sm-2">
                                                        <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                                            <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                                            value={item.status}
                                                            checked={item.status}
                                                            onChange={(e) => setEventStatus(!EventStatus)} 
                                                            onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.status); seteventIndex(index);  }} />
                                                        </div> 
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    :

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 py-5 my-2 pb-0">
                                        <div className="w-100 not-avilable-card p-3 d-flex flex-column align-items-center">
                                            <img className=""  src={`${ageCriteriaIcon}`} alt="" style={{ width: "130px" }} />
                                            <p className="section__header--title h4 mb-4">No Age Category Added</p>
                                            <p className="h4 mb-4">Please click on "<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add Age Category" button to add new age category.</p>
                                        </div>
                                    </div>
                                }
                            </div> 
                        </div>
                    </div>

                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                        <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(5) }} style={{cursor:'pointer'}} >Back</div>
                        <input type="submit" name="command" value="Save & Next (7/11)" className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(7); }} />
                    </div>
                    {/* </form> */}
                </div>
            }
        </>
    )
}

export default AgeCriteria;

