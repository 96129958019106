import React, { useState,useEffect } from 'react';
import '../App.css';
import AddTickets from "../Create_event_details/AddTickets";
import axios from '../axios';
import { toast } from 'react-toastify';

const Tickets = (props) => {

    const [AddNewEvent, setAddNewEvent] = useState(false);
    // console.log(props.NewFlag);
    const EventData = props.EventData ? props.EventData : [];
    const EventTicketsAll = props.EventTicketsAll ? props.EventTicketsAll : [];

    const [DeleteTicketPopup, setDeleteTicketPopup] = useState(false);
    const [ConfirmDeleteTicket, setConfirmDeleteTicket] = useState(false);
    const [DeleteTicketId, setDeleteTicketId] = useState('');
    const [EditTicketId, setEditTicketId] = useState('');

    const [CollectGst, setCollectGst] = useState('');
    const [YesStatus, setYesStatus] = useState('');
    const [NoStatus, setNoStatus] = useState('active');

    const [Price, setPrice] = useState('');
    const [InclusiveStatus, setInclusiveStatus] = useState('active');
    const [ExclusiveStatus, setExclusiveStatus] = useState('');
    // const [TicketCopyDataFlag, setTicketCopyDataFlag] = useState(false);
    const [TicketCopyData, setTicketCopyData] = useState([]);

    // console.log(TicketCopyData); 
    //console.log(props.TicketDivShowFlag,AddNewEvent);
    const handleTickets = (ticket_id, operate) => {
        let TicketId = ticket_id ? ticket_id : 0;
        if (operate === 'add') {

        }
        if (operate === 'edit') {
            setEditTicketId(TicketId)
        }
        if (operate === "delete") {
            setDeleteTicketPopup(true);
        }
    }

    const DeleteTickets = () => {
       // if (ConfirmDeleteTicket && DeleteTicketId !== "") {
            axios.post('delete_event_ticket',
                {
                    'ticket_id': DeleteTicketId
                }
            )
                .then((response) => {
                    props.setEventApiCall(true)
                    toast.success(response.data.message)
                    setConfirmDeleteTicket(false)
                    setDeleteTicketId('')
                })
                .catch((error) => {
                    console.log(error);
                })
        //}
    }

    useEffect(() => {
        if(props.TicketDivShowFlag === false){
            setAddNewEvent(false);
        }
        //--------------

        if(EventTicketsAll && EventTicketsAll.length > 0){
            const copyTicketData = EventTicketsAll[0].id;
            setTicketCopyData(copyTicketData);
        }else{ setTicketCopyData(''); }
    }, [props.TicketDivShowFlag]);

    // console.log(TicketCopyData); 
       
    const UpdateStatusData = (collect_gst,tax_price) => {
        //console.log('ss');
        const payload = {
            'event_id': localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
            'collect_gst': collect_gst,
            'prices_taxes_status': tax_price,
            'event_img_id': 0,
            'common_flag': 'ticket_gst_taxes_price'
        };
       // console.log(payload);
        axios.post('delete_event_images',payload)
        .then((response) => {
            // console.log(response);
            props.setEventApiCall(true)
            toast.success(response.data.message)
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.EventData){
            setCollectGst(props.EventData.collect_gst);
            setPrice(props.EventData.prices_taxes_status);

            if(props.EventData.collect_gst === 1){
                setYesStatus('active'); setNoStatus('');
            }

            if(props.EventData.prices_taxes_status === 2){
                setExclusiveStatus('active'); setInclusiveStatus('');
            }
        }
    }, [props.EventData]);

    return (
        <>
            {
                DeleteTicketPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">
                                                    {/* <h3 className="account__login--header__title mb-10">Confirm Delete Ticket</h3> */}
                                                    <p className="account__login--header__desc">Are you sure you want to delete this race Category?</p>
                                                </div>
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setDeleteTicketPopup(false); setDeleteTicketId('')
                                                    }}>Cancel</button>

                                                    <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmDeleteTicket(true); setDeleteTicketPopup(false); DeleteTickets() }}>Yes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {AddNewEvent === true ?
                <AddTickets EditTicketId={EditTicketId} setAddNewEvent={setAddNewEvent} setEditTicketId={setEditTicketId} setEventApiCall={props.setEventApiCall} YtcrFeePercent={props.YtcrFeePercent} PlatformFeePercent={props.PlatformFeePercent} PaymentGatewayFeePercent={props.PaymentGatewayFeePercent} setTicketDivShowFlag={props.setTicketDivShowFlag} TicketDivShowFlag={props.TicketDivShowFlag} EventTypeData={props.EventTypeData} CollectGst={CollectGst} TaxesPrice={Price} PaymentGatewayGstPercent={props.PaymentGatewayGstPercent} AgeDetails={props.AgeDetails} TicketCopyIdData={TicketCopyData} RaceCategoryChargesDetails={props.RaceCategoryChargesDetails} />
                :
                <div className="main checkout__mian">
                    {/* <form action="#"> */}
                    <div className="checkout__content--step section__shipping--address ">

                        <div className="mb-5">
                            <div className="section__header mb-4 d-flex align-items-center justify-content-between">
                                <h2 className="section__header--title h3">Race Category</h2>
                                <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddNewEvent(true); }} style={{cursor:'pointer'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M720-400v-120H600v-80h120v-120h80v120h120v80H800v120h-80Zm-360-80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm80-80h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0-80Zm0 400Z" /></svg> New Race Category
                                </div>
                            </div>
                            
                            <div className="row px-2">
                                <div className="col-sm-6">
                                    <div className="profile-card p-4 my-4 text-center">
                                        <h4 className="mb-3"> Do you want to collect GST on Registration Fee?
                                        </h4>
                                        <div className="ebi d-flex justify-content-center">
                                            <div className="event-visibility hand_cursor" style={{width: "fit-content"}}>
                                                <div className={'visibility gap-1 ' + YesStatus} onClick={() => { setCollectGst(1); setYesStatus('active'); setNoStatus(''); UpdateStatusData(1,Price); }}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={20}
                                                        viewBox="0 -960 960 960"
                                                        width={20}
                                                    >
                                                        <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                                                    </svg>
                                                    Yes
                                                </div>

                                                <div className={'visibility gap-1 ' + NoStatus} onClick={() => { setCollectGst(0); setYesStatus(''); setNoStatus('active'); UpdateStatusData(0,Price); }} >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={20}
                                                        viewBox="0 -960 960 960"
                                                        width={20}
                                                    >
                                                        <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                                                    </svg>
                                                    No
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                              
                               
                                <div className="col-sm-6">
                                    <div className="profile-card p-4 my-4 text-center">
                                        <h4 className="mb-3"> The basic registration fee will be : </h4>
                                        <div className="ebi d-flex justify-content-center">
                                            <div className="event-visibility hand_cursor" style={{width: "fit-content"}}>
                                                <div className={'visibility gap-1 ' + InclusiveStatus} onClick={() => { setPrice(1); setInclusiveStatus('active'); setExclusiveStatus(''); UpdateStatusData(CollectGst,1); }}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={20}
                                                        viewBox="0 -960 960 960"
                                                        width={20}
                                                    >
                                                        <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                                                    </svg>
                                                    Inclusive Taxes
                                                </div>

                                                <div className={'visibility gap-1 ' + ExclusiveStatus} onClick={() => { setPrice(2); setInclusiveStatus(''); setExclusiveStatus('active'); UpdateStatusData(CollectGst,2); }} >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={20}
                                                        viewBox="0 -960 960 960"
                                                        width={20}
                                                    >
                                                        <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                                                    </svg>
                                                    Exclusive Taxes
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        <hr />

                            <div className="row mt-4">
                                {EventTicketsAll && EventTicketsAll.length > 0
                                    ?
                                    EventTicketsAll.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-20" key={index+536}>
                                                    <div className="row coupon">
                                                   <div style={{ position: "absolute", top: "5px", justifyContent: "flex-end"}}>
                                                    {item.ticket_status === 1 ?
                                                        <span className="badge bg-danger bg-gradient" >Paid</span>
                                                    : item.ticket_status === 2 ?
                                                        <span className="badge bg-warning bg-gradient" >Free</span> 
                                                    : '' }
                                                   </div>

                                                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 left">
                                                            <div>
                                                                <p>Enjoy Your Event</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-xxl-8 center">
                                                            <div>
                                                                <p className="section__header--title h4">{item.ticket_name}</p>
                                                                <p className="h4">{item.total_quantity} Registrations | ₹{item.ticket_price}</p>
                                                                <hr />
                                                                <p className="m-0"><span>Start Date: </span>{item.ticket_sale_start_date}</p>
                                                                <p className="m-0"><span>End Date: </span>{item.ticket_sale_end_date}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 right">
                                                            <div>
                                                                {/* EDIT TICKET BUTTON */}
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" onClick={() => { setAddNewEvent(true); handleTickets(item.id, 'edit'); }}><path d="M80 0v-160h800V0H80Zm160-320h56l312-311-29-29-28-28-311 312v56Zm-80 80v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm560-504-56-56 56 56ZM608-631l-29-29-28-28 57 57Z" /></svg>

                                                                {/* DELETE TICKET BUTTON */}
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" onClick={(e) => { handleTickets(item.id, 'delete'); setDeleteTicketId(item.id) }}><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    :

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-20 no-ticket">
                                        <div className="row coupon">
                                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 left">

                                            </div>
                                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-xxl-8 center">
                                                <div>
                                                    <p className="section__header--title h4">No Race Categories Added</p>
                                                    <hr />
                                                    <p className="h4">Please click on add "+ new race category" button to add new race category</p>
                                                    <br />
                                                    <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddNewEvent(true); }} style={{cursor:'pointer'}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M720-400v-120H600v-80h120v-120h80v120h120v80H800v120h-80Zm-360-80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm80-80h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0-80Zm0 400Z" /></svg>
                                                        New Race Category
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 right">
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                        <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(3) }} style={{cursor:'pointer'}} >Back</div>
                        <input type="submit" name="command" value="Save & Next (5/11)" className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(5); }} />
                    </div>
                    {/* </form> */}
                </div>
            }
        </>
    )
}

export default Tickets;

