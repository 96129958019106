import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from '../axios'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { token } from "../features/TokenSlice";

const SignUpPage = (props) => {
    const dispatch = useDispatch();

    document.title = 'SignUp-YouTooCanRun';
    const DivRef = useRef(null);
    const [FirstName, setFirstName] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastName, setLastName] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [Email, setEmail] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [CPassword, setCPassword] = useState("");
    const [CPasswordError, setCPasswordError] = useState("");
    const [TermsAndCondition, setTermsAndCondition] = useState(false);
    const [TermsAndConditionError, setTermsAndConditionError] = useState("");
    const [Mobile, setMobile] = useState("");
    const [MobileError, setMobileError] = useState("");
    const [DOB, setDOB] = useState("");
    const [DOBError, setDOBError] = useState("");
    const [Gender, setGender] = useState("");
    const [GenderError, setGenderError] = useState("");
    const [loader, setLoader] = useState("");
    const [GeneralApiError, setGeneralApiError] = useState("");
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);

    const handlePasswordToggle = (e) => {
        e.preventDefault()
        setShowPassword(!showPassword);
    };

    const handleCPasswordToggle = (e) => {
        e.preventDefault()
        setShowCPassword(!showCPassword);
    };
    // console.log(Password);
    const HandleValidation = useCallback((e) => {

        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setPasswordError("");
        setCPasswordError("")
        setTermsAndConditionError("");
        setMobileError("");
        setGenderError("");
        setDOBError("");
        setError("");
        let flag = true;
        if (FirstName.trim() === "") {
            setFirstNameError("Please enter your first name.");
            flag = false;
        } else if (!/^[A-Za-z]+$/i.test(FirstName.trim())) {
            setFirstNameError("Invalid characters in first name. Only alphabets are allowed.");
            flag = false;
        }

        if (LastName.trim() === "") {
            setLastNameError("Please enter your last name.");
            flag = false;
        } else if (!/^[A-Za-z]+$/i.test(LastName.trim())) {
            setLastNameError("Invalid characters in last name. Only alphabets are allowed.");
            flag = false;
        }

        if (Email === "") {
            setEmailError("Please enter Email id.");
            flag = false;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            setEmailError('Please enter a valid email address');
            flag = false;
        }

        if (Password === "") {
            setPasswordError("Please enter valid Password.");
            flag = false;
        }

        if (CPassword === "") {
            setCPasswordError("Please enter password field.");
            flag = false;
        }

        if (Password !== CPassword) {
            setCPasswordError("Your password and confirmation password do not match.");
        }

        if (Mobile === "") {
            setMobileError("Please Enter Contact number.");
            flag = false;
        } else if (!/^[0-9]{10}$/i.test(Mobile)) {
            setMobileError("Please enter a valid 10-digit Contact number.");
            flag = false;
        }

        if (Gender === 0 || Gender === "") {
            setGenderError("Please select Gender.");
            flag = false;
        }

        if (DOB === "") {
            setDOBError("Please select DOB.");
            flag = false;
        }
        if (!TermsAndCondition) {
            setTermsAndConditionError("Accept Terms and condition");
            flag = false;
        }
        return flag;
    }, [FirstName, LastName, Email, Mobile, DOB, Gender, Password, CPassword, TermsAndCondition]);

    // console.log(props.OrganisingUserId);
    const HandleSubmit = useCallback((e) => {
        e.preventDefault();
        if (HandleValidation()) {
            // setLoader(true);
            const Payload = {
                "firstname": FirstName,
                "lastname": LastName,
                "email": Email,
                "mobile": Mobile,
                "dob": DOB,
                "gender": Gender,
                "password": Password,
                "confirm_password": CPassword,
                "organising_user_id": props.OrganisingUserId ? props.OrganisingUserId : 0
            }

            axios.post("signup", Payload).then((response) => {

                if (response.data.status === 200) {
                    // alert(response.data.message);
                    // toast.success(response.data.message);
                    window.scrollTo(0, 0);

                    // localStorage.setItem(
                    //     "RacesToken",
                    //     JSON.stringify({ token: response.data.data.token })
                    // );

                    localStorage.setItem("ID", response.data.data.userData.id)
                    // localStorage.setItem("EMAIL", response.data.data.userData.email)
                    // localStorage.setItem("MOBILE", response.data.data.userData.mobile)

                    // dispatch(token({ token: response.data.data.token }));
                    //   setLoginLoader(false);
                    props.setSignUp(false);
                    // props.setWelcome(true);
                    props.setRegistration(true);
                    props.setMsgFlag("Register");
                    localStorage.removeItem("Company_info");
                    // props.setUserVerify(true);
                    // navigate("/home");
                }

            }).catch((response) => {
                // setLoader(false);
                const error_field = response.response.data.error_field;
                const message = response.response.data.message;
                const status = response.response.data.status;

                if (status === 400) {
                    // console.log(message);
                    setError(message);
                }
                if (error_field === "firstname") {
                    setFirstNameError(message)
                } else if (error_field === "lastname") {
                    setLastNameError(message)
                } else if (error_field === "email") {
                    setEmailError(message)
                } else if (error_field === "password") {
                    setPasswordError(message)
                } else if (error_field === "confirm_password") {
                    setCPasswordError(message)
                } else if (error_field === "mobile") {
                    setMobileError(message)
                } else if (error_field === "dob") {
                    setDOBError(message)
                } else if (error_field === "gender") {
                    setGenderError(message)
                } else if (error_field === "") {
                    window.scrollTo(0, 0);
                    setGeneralApiError(message)
                }
            });
        }
    }, [FirstName, LastName, Email, Mobile, DOB, Gender, Password, CPassword, HandleValidation, props]);

    const handleSignUpClose = (e) => {
        if (DivRef.current && !DivRef.current.contains(e.target)) {
            // props.setSignUp(false);
        }
    }

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                HandleSubmit(e);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.body.style.overflow = 'auto';
        };
    }, [FirstName, LastName, Email, Mobile, DOB, Gender, Password, CPassword, HandleSubmit]);

    useEffect(() => {
        const handleEscKeyPress = (event) => {
            if (event.key === 'Escape') {
                props.setSignUp(false);
            }
        };
        document.addEventListener('keydown', handleEscKeyPress);
        return () => {
            document.removeEventListener('keydown', handleEscKeyPress);
        };
    }, [props, props.setSignUp]);

    const HandleGoogleSignUp = (e) => {
        // alert("google sign up")
        axios.get("auth/google")
            .then((response) => {
                // console.log(response);
                if (response.data.status === 200) {
                    // alert(response.data.message);
                    // toast.success(response.data.message);
                    window.scrollTo(0, 0);
                    localStorage.setItem(
                        "RacesToken",
                        JSON.stringify({ token: response.data.data.token })
                    );
                    localStorage.setItem("ID", response.data.data.userData.id)
                    localStorage.setItem("EMAIL", response.data.data.userData.email)
                    localStorage.setItem("MOBILE", response.data.data.userData.mobile)
                    dispatch(token({ token: response.data.data.token }));
                    props.setSignUp(false);
                    props.setRegistration(true);
                    props.setMsgFlag("Register");
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return (
        <>
            <ToastContainer theme="colored" />
            {/* {loader ? <Loader /> : null} */}
            {/* <Header /> */}
            <div className="modal is-visible" id="modal2" data-animation="slideInUp" onClick={(e) => { handleSignUpClose(e) }}>
                <div className="modal-dialog quickview__main--wrapper">
                    <header className="modal-header quickview__header">

                    </header>
                    <div className="quickview__inner">
                        <div className="row">

                            <div className="login__section">

                                <form onSubmit={HandleSubmit}>
                                    <div className="login__section--inner">
                                        <div className="p-5">
                                            <div className="account__login--header mb-25">
                                                <h3 className="account__login--header__title mb-10">Let's get you enrolled !</h3>
                                                <p className="account__login--header__desc">Your all-in-one event planning tool</p>
                                                {/* <p className="account__login--header__desc">Your one stop tool for organizing events</p> */}
                                            </div>
                                            <div className="account__login--inner">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating">
                                                            <input className="form-control" placeholder="First name" id='FirstName' type="text" value={FirstName} onChange={(e) => {
                                                                setFirstName(e.target.value)
                                                            }} />
                                                            <label className="checkout__input--label mb-1" htmlFor="FirstName">First Name <span className="checkout__input--label__star">*</span></label>
                                                            <small className="text-danger mb-5">{FirstNameError}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating">

                                                            <input className="form-control" placeholder="Last name" type="text" id="LastName" value={LastName} onChange={(e) => {
                                                                setLastName(e.target.value)
                                                            }} />
                                                            <label className="checkout__input--label mb-1" htmlFor="LastName">Last Name <span className="checkout__input--label__star">*</span></label>
                                                            <small className="text-danger mb-5">{LastNameError}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating">

                                                            <input className="form-control" placeholder="Contact number" type="number" id='Mobile' value={Mobile} onChange={(e) => {
                                                                setMobile(e.target.value)
                                                            }} />
                                                            <label className="checkout__input--label mb-1" htmlFor="Mobile">Contact number <span className="checkout__input--label__star">*</span></label>
                                                            <small className="text-danger mb-5">{MobileError}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating">

                                                            <input className="form-control" placeholder="Email Id" type="email" id='Email' value={Email} onChange={(e) => {
                                                                setEmail(e.target.value)
                                                            }} />
                                                            <label className="checkout__input--label mb-1" htmlFor="Email">Email Id <span className="checkout__input--label__star">*</span></label>
                                                            <small className="text-danger mb-5">{EmailError}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating">
                                                            <input className="form-control" type="date" id='DOB' value={DOB} onChange={(e) => {
                                                                setDOB(e.target.value)
                                                            }} max={new Date().toISOString().split('T')[0]} />
                                                            <label className="select-label" htmlFor="DOB">Date Of Birth <span className="checkout__input--label__star">*</span></label>
                                                            <small className="text-danger mb-5">{DOBError}</small>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-20">
                                                        <div className="form-floating">

                                                            <div className="form-floating">

                                                                <select className="form-control" id="Gender" value={Gender} onChange={(e) => { setGender(e.target.value) }}>
                                                                    <option value={0}>Select Gender</option>
                                                                    <option value={1}>Male</option>
                                                                    <option value={2}>Female</option>
                                                                    <option value={3}>Other</option>
                                                                </select>
                                                                <label className="checkout__input--label mb-1" htmlFor="Gender">Gender <span className="checkout__input--label__star">*</span></label>

                                                                <small className="text-danger mb-5">{GenderError}</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating">

                                                            <input className="form-control" placeholder="Password" type="password" id="Password" value={Password} onChange={(e) => {
                                                                setPassword(e.target.value)
                                                            }} autoComplete="off" />
                                                            <label className="checkout__input--label mb-1" htmlFor="Password">Password <span className="checkout__input--label__star">*</span></label>

                                                            <small className="text-danger mb-5">{PasswordError}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating">

                                                            <input className="form-control" placeholder="Confirm Password" type="password" id='CPassword' value={CPassword} onChange={(e) => {
                                                                setCPassword(e.target.value)
                                                            }} autoComplete="off" />
                                                            <label className="checkout__input--label mb-1" htmlFor="CPassword">Confirm Password <span className="checkout__input--label__star">*</span></label>

                                                            <small className="text-danger mb-5">{CPasswordError}</small>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating d-flex align-items-center">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Password"
                                                                type={showPassword ? 'text' : 'password'}
                                                                id="Password"
                                                                value={Password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                autoComplete="off"
                                                            />

                                                            {showPassword ?

                                                                <svg onClick={(e) => handlePasswordToggle(e)} style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>

                                                                :

                                                                <svg onClick={(e) => handlePasswordToggle(e)} style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>

                                                            }
                                                            <label className="checkout__input--label mb-1" htmlFor="Password">
                                                                Password <span className="checkout__input--label__star">*</span>
                                                            </label>

                                                        </div>
                                                        <small className="text-danger mb-5">{PasswordError}</small>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 mb-20">
                                                        <div className="form-floating d-flex align-items-center">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Confirm Password"
                                                                type={showCPassword ? 'text' : 'password'}
                                                                id='CPassword'
                                                                value={CPassword}
                                                                onChange={(e) => setCPassword(e.target.value)}
                                                                autoComplete="off"
                                                            />

                                                            {showCPassword ?

                                                                <svg style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" onClick={(e) => handleCPasswordToggle(e)} /></svg>
                                                                :
                                                                <svg style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" onClick={(e) => handleCPasswordToggle(e)} /></svg>
                                                            }
                                                            <label className="checkout__input--label mb-1" htmlFor="CPassword">
                                                                Confirm Password <span className="checkout__input--label__star">*</span>
                                                            </label>

                                                        </div>
                                                        <small className="text-danger mb-5">{CPasswordError}</small>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 ">
                                                        <div className="form-check mt-3 mb-0">
                                                            <div className="form-check-group">
                                                                <input type="checkbox" className="form-check-input" id="TermsAndCondition" checked={TermsAndCondition} onChange={(e) => {
                                                                    setTermsAndCondition(!TermsAndCondition)
                                                                }} />

                                                                <label className="form-check-label" htmlFor="exampleCheck1">I acknowledge the <Link to="https://youtoocanrun.com/terms-and-conditions-for-use/" target="blank">Terms of Services</Link> & <Link to="https://youtoocanrun.com/privacy-policy/" target="blank"> Privacy Policies</Link>.</label>
                                                                <small className="text-danger mb-5">{TermsAndConditionError}</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="text-danger mb-5" style={{ "textAlign": "center" }} >{error}</div>
                                                    <div className="d-flex gap-4">
                                                        <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                            props.setSignUp(false);
                                                        }}>Close</button>

                                                        <button className="account__login--btn primary__btn mt-2" type="submit">Register</button>
                                                    </div>

                                                    <div className="account__login--divide my-3">
                                                        <span className="account__login--divide__text">OR</span>
                                                    </div>
                                                    <div className="account__social d-flex justify-content-center mb-5">
                                                        <span>Already have an account? {"  "} </span>&nbsp;
                                                        <span className="account__login--divide__text" style={{ fontSize: "18px" }} onClick={
                                                            () => {
                                                                props.setSignUp(false);
                                                                props.setOpen(true);
                                                            }
                                                        }>Login</span>
                                                    </div>


                                                    {/* <div className="account__social d-flex justify-content-center mb-5">
                                                        <div className="account__social--link border text-secondary" target="_blank" href="https://www.google.com">
                                                            Login
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="account__social d-flex justify-content-center mb-0" onClick={(e) => HandleGoogleSignUp(e)}>
                                                        <div className="account__social--link border text-secondary" target="_blank" href="https://www.google.com">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px" height="25px"><path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg>
                                                            Continue with Google</div>
                                                    </div> */}
                                                    <br />
                                                    <br />
                                                    <br></br>
                                                    <p>By continuing, you agree to YooTooCanRun's {" "}
                                                        <Link to="https://youtoocanrun.com/terms-and-conditions-for-use/" target="blank" >Terms of Services</Link> and <Link to="https://youtoocanrun.com/privacy-policy/" target="blank"> Privacy Statement</Link>.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bg-2">
                <main className="main-body w-100">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5">
                                <h3 className="welcome-title">Sign Up</h3>

                                {GeneralApiSuccess !== "" ? <div className="form-group">
                                    <div className="form-text success-message my-1 mt-3" ref={SuccessRef}> {GeneralApiSuccess}</div>
                                </div> : null}
                                {GeneralApiError !== "" ? <div className="form-group">
                                    <div className="form-text error-message my-1 mt-3 "> {GeneralApiError}</div>
                                </div> : null}
                                <form onSubmit={HandleSubmit}>

                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputFirstname" className="form-label">First name</label>
                                        <input type="text" className="form-control" id="exampleInputFirstname" aria-describedby="FirstnameHelp" placeholder="Enter first name" value={FirstName} onChange={(e) => {
                                            setFirstName(e.target.value)
                                        }} autoComplete='off' />
                                        <div className="form-text validation-message my-1 ">{FirstNameError}</div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputLastname" className="form-label">Last name</label>
                                        <input type="text" className="form-control" id="exampleInputLastname" aria-describedby="LastnameHelp" placeholder="Enter last name" value={LastName} onChange={(e) => {
                                            setLastName(e.target.value)
                                        }} autoComplete='off' />
                                        <div className="form-text validation-message my-1 ">{LastNameError}</div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email id " value={Email} onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} autoComplete='off' />
                                        <div className="form-text validation-message my-1 ">{EmailError}</div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                        <input type="password" placeholder="Enter password " className="form-control" id="exampleInputPassword1" value={Password} onChange={(e) => {
                                            setPassword(e.target.value)
                                        }} autoComplete='off' />
                                        <div className="form-text validation-message my-1 ">{PasswordError}</div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Confirm password</label>
                                        <input type="password" placeholder="Enter confirm password " className="form-control" id="exampleInputCPassword1" value={CPassword} onChange={(e) => {
                                            setCPassword(e.target.value)
                                        }} autoComplete='off' />
                                        <div className="form-text validation-message my-1 ">{CPasswordError}</div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputMobile" className="form-label">Mobile</label>
                                        <input type="text" className="form-control" id="exampleInputMobile" aria-describedby="MobileHelp" placeholder="Enter Mobile" value={Mobile} onChange={(e) => {
                                            setMobile(e.target.value)
                                        }} autoComplete='off' />
                                        <div className="form-text validation-message my-1 ">{MobileError}</div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleSelectGender" className="form-label">Gender</label>
                                        <select name="Gender" className="form-control" value={Gender} onChange={(e) => { setGender(e.target.value) }}>
                                            <option value={0}>Select Gender</option>
                                            <option value={1}>Male</option>
                                            <option value={2}>Female</option>
                                            <option value={3}>Other</option>
                                        </select>
                                        <div className="form-text validation-message my-1 ">{GenderError}</div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputDOB" className="form-label">DOB</label>
                                        <input type="date" className="form-control" id="exampleInputDOB" aria-describedby="DOBHelp" placeholder="Enter DOB" value={DOB} onChange={(e) => {
                                            setDOB(e.target.value)
                                        }} autoComplete='off' />
                                        <div className="form-text validation-message my-1 ">{DOBError}</div>
                                    </div>

                                    <div className="form-check mt-3 mb-0">
                                        <div className="form-check-group">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={TermsAndCondition} onChange={(e) => {
                                                setTermsAndCondition(!TermsAndCondition)
                                            }} />

                                            <label className="form-check-label" htmlFor="exampleCheck1">I agree with the <Link to="/term_and_condition">Terms of Services</Link> and <Link to="/privacy_policy"> Privacy Statement</Link>.</label>
                                        </div>
                                    </div>
                                    <div className="form-text validation-message my-1 ">{TermsAndConditionError}</div>

                                    <button type="submit" className="btn btn-primary submit-btn">Sign Up</button>
                                </form>
                                <div className="que-sign">
                                    <p>Already have an account? <Link to="/login">Sign in</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div> */}
        </>
    )
}

export default SignUpPage