
import React, { useCallback, useEffect, useRef } from 'react'
import Login from "../logo/loginsuccess.gif"

const SuccessForgetPassword = (props) => {
    const DivRef = useRef(null);

    const handleClose = useCallback((e) => {
        if (DivRef.current) {
            props.setResetPasswordEmail("");
        }
    },[props]);

    useEffect((e) => {
        setTimeout(function () {
            handleClose(e)
        }, 3000);
    },[handleClose])

    return (
        <>
            <div className="modal is-visible" data-animation="slideInUp" ref={DivRef}>
                <div className="modal-dialog quickview__main--wrapper">
                    <header className="modal-header quickview__header">
                    </header>
                    <div className="quickview__inner">
                        <div className="row">
                            <div className="login__section">
                                <form action="#">
                                    <div className="login__section--inner">
                                        <div className="p-5">
                                            <div className="account__login--header text-center">
                                                <h3 className="account__login--header__title mb-10">Forgot Password</h3>
                                                <img
                                                    src={`${Login}`}
                                                    alt=""
                                                    style={{ width: 100 }}
                                                />
                                                <h3 className="account__login--header__title my-4">
                                                    Don’t worry, we’ll help you reset it
                                                </h3>
                                                <h4 className="h4 my-4">
                                                    Password reset link has been sent to {props.ResetPasswordEmail}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="modal is-visible" data-animation="slideInUp" onClick={(e) => { handleClose(e) }}>
                <div className="modal-dialog quickview__main--wrapper">
                    <header className="modal-header quickview__header">
                        <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
                            props.setResetPasswordEmail("");
                        }}>✕ </button>
                    </header>
                    <div className="quickview__inner">
                        <div className="row">
                            <div className="login__section">
                                <div className="login__section--inner" >
                                    <div className="p-5">
                                        <div className="account__login--header mb-25">
                                            <h3 className="account__login--header__title mb-10">Forgot Password?</h3>
                                            <p className="account__login--header__desc">Don’t worry, we’ll help you reset it</p>
                                            <p>Password reset link has been sent to {props.ResetPasswordEmail}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>)

}

export default SuccessForgetPassword;