import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PieChart = ({ maleCount, femaleCount, otherCount }) => {
  const options = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Gender Distribution'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: 'Count',
        data: [
          { name: 'Male', y: maleCount },
          { name: 'Female', y: femaleCount },
          { name: 'Other', y: otherCount }
        ]
      }
    ],
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        fontSize: '13px'
      },
      labelFormatter: function () {
        return `${this.name} (${this.y})`;
      }
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
