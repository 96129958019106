import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import NewForm from "../Create_event_details/NewForm";
import 'react-toastify/dist/ReactToastify.css';
import LoaderImg from '../img/logo/running.gif';

const AddCustomFormQuestions = (props) => {
  
 const [Loader, setLoader] = useState(false);
 const [questionTitle, setquestionTitle] = useState('');
 const [questionType, setquestionType] = useState('');
 const [questionHint, setquestionHint] = useState('');
 const [QuestionStatusMClass, setQuestionStatusMClass] = useState('active');
 const [QuestionStatusOpClass, setQuestionStatusOpClass] = useState('');
 const [QuestionStatus, setQuestionStatus] = useState(1);
 
 const [questionTitleError, setquestionTitleError] = useState('');
 const [questionTypeError, setquestionTypeError] = useState('');
 const [answers, setAnswers] = useState(['']);
 const [optionsArray, setoptionsArray] = useState([]);
 const [FormType, setFormType] = useState('');
 const [FormTypeError, setFormTypeError] = useState('');
 const [AddForm, setAddForm] = useState(false);
 const [FormDetailsAll, setFormDetailsAll] = useState([]);

 //console.log(optionsArray);

 const handleAnswerChange = (index, value, flag) => {
  // console.log(index,flag,value);
  setAnswers((prev) => {
    const updatedAnswers = [...prev];
    updatedAnswers[index] = value;
    
    if(value !== undefined && value.trim() === ''){ 
      updatedAnswers.splice(index, 1);
      setoptionsArray(updatedAnswers);
    }else{
      if (flag === 1) {
        updatedAnswers.splice(index, 1);
        //return updatedAnswers;
        setoptionsArray(updatedAnswers);
      }
    }

    if (index === updatedAnswers.length - 1 && flag !== 1) { //&& value.trim() !== ''
      updatedAnswers.push('');
      setoptionsArray(updatedAnswers);
    }
    
    if(flag !== 1){
      if(value.trim() === '' && index === 0 && flag === 0){
        setoptionsArray(updatedAnswers.splice(index, 1));
      }  
    }
    
    setoptionsArray(updatedAnswers);
    return updatedAnswers;
  });
}

const handleValidation = () => {
  //console.log(BorrowingGuaranteed);
  var flag = true;
  
  if (FormType === "") {
    //eventNameRef.current.focus();
    setFormTypeError("Please select form");
    flag = false;
  } else {
    setFormTypeError("");
  }

  if (questionTitle === "") {
    //eventNameRef.current.focus();
    setquestionTitleError("Please enter question title");
    flag = false;
  } else {
    setquestionTitleError("");
  }

  if (questionType === "") {
    //eventNameRef.current.focus();
    setquestionTypeError("Please select question type");
    flag = false;
  } else {
    setquestionTypeError("");
  }

  return flag;
};

const handleSubmit = (e) => {
  e.preventDefault();
 
  const flag = handleValidation();
  if (flag) {
    setLoader(true);
    const payload = {
        user_id: localStorage.getItem("ID") ? localStorage.getItem("ID") : 0,
        form_id: FormType,
        question_label: questionTitle,
        question_form_type: questionType,
        question_hint: questionHint,
        is_manadatory : QuestionStatus,
        question_form_option : optionsArray,
    };
    // console.log(payload);
    axios.post("AddCustomFormQuestions", payload)
      .then((response) => {
        setLoader(false);
        if(response.data.data === 1){
          toast.error(response.data.message);
        }else{
          if (response.status === 200) {
            props.setAddCustomQuestions(false);
            toast.success(response.data.message);
          }
        }
        
      })
      .catch(() => {
        setLoader(false);
        console.log('error');
      });
    }
};

useEffect(() => {
  const payload = {
    form_name: '',
    form_edit_id: 0,
    form_action_flag: 'form_details',
    form_flag: ''
  };
  axios.post("FormCommonDetails", payload)
    .then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setFormDetailsAll(response.data.data.form_details);
      }
    })
    .catch((error) => {
      console.log(error);
    })

}, [AddForm])

useEffect(() => {
  const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setAddForm(false);
      }
  };
  // Add event listener when the component mounts
  document.addEventListener("keydown", handleKeyPress);
  // Remove event listener when the component unmounts
  return () => {
      document.removeEventListener("keydown", handleKeyPress);
  };
}, []);

  return (
    <>
     {AddForm ? <NewForm setAddForm={setAddForm} /> : null}

    <div>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
      
     <form method="post" onSubmit={handleSubmit}>
      <div className="main checkout__mian">
        <div className="section__header mb-4 d-flex align-items-center justify-content-between">
            <h2 className="section__header--title h3">Add Custom Form Questions</h2>
            <div className="continue__shipping--btn secondary__btn border-radius-5"  onClick={() => { setAddForm(true); }} style={{cursor: 'pointer'}}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg> Add New Form
            </div>
        </div>

        <div className="row mb-5">
            
            <div className="col-sm-12 my-3">
              <div className="form-floating">
                  <select className="form-control" name="question_type" value={FormType} onChange={(e) => { setFormType(e.target.value); }}> 
                      <option value="">-- Select --</option>
                      <option value="999999">Do Not Have Form</option>
                      {
                          (FormDetailsAll && FormDetailsAll.length > 0) ?
                                FormDetailsAll.map((item,index) => {
                                  // console.log(item);
                                  return (
                                    <option value={item.id} key={index}>{item.form_name}</option>
                                  )
                              }) : null
                      }
                      
                      {/* <option value="select_amount">Select Amount (Dropdown)</option> */}
                  </select>
                <label>Choose Form<span className="checkout__input--label__star">*</span></label>
              </div>
              <small className="text-danger">{FormTypeError}</small>
            </div>

            <div className="col-sm-12 my-2">
              <div className="form-floating ">
                <input type="text" className="form-control" placeholder="Enter Question Title" value={questionTitle}  onChange={(e) => { setquestionTitle(e.target.value); }} />
                <label>Question Title <span className="checkout__input--label__star">*</span></label>
              </div>
              <small className="text-danger">{questionTitleError}</small>
            </div>

            {/* {questionType !== '' ? */}
              <div className="col-sm-12 my-3">
                <div className="form-floating ">
                  <input type="text" className="form-control" placeholder="Enter Question Title" value={questionHint}  onChange={(e) => { setquestionHint(e.target.value); }} />
                  <label>Question Hint</label>
                </div>
              </div>
            {/* : ''} */}

            <div className="col-sm-12 my-3">
              <div className="form-floating">
                  <select className="form-control" name="question_type" value={questionType} onChange={(e) => { setquestionType(e.target.value); setAnswers(['']); }}> 
                      <option value="">-- Select --</option>
                      <option value="text">Text</option>
                      <option value="email">Email</option>
                      <option value="mobile">Mobile</option>
                      <option value="amount">Amount</option>
                      <option value="textarea">Textarea</option>
                      <option value="checkbox">Checkboxes</option>
                      <option value="radio">Radio</option>
                      <option value="date">Date</option>
                      <option value="time">Time</option>
                      <option value="file">File</option>
                      <option value="select">Select (Dropdown)</option>
                      {/* <option value="select_amount">Select Amount (Dropdown)</option> */}
                  </select>
                <label>Question Type<span className="checkout__input--label__star">*</span></label>
              </div>
              <small className="text-danger">{questionTypeError}</small>
            </div>
           
              {  questionType === 'checkbox' || questionType === 'select' || questionType === 'radio' || questionType === 'select_amount'  ? 
                answers.map((answer, index) => (
                
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 my-3" key={index}>
                    <div className="form-floating ">

                    <input type="text" className="form-control" onChange={(e) => handleAnswerChange(index, e.target.value,0)} value={answer} placeholder={'Enter Option'+index}/> 
                  
                    <label>{`Enter Option ${index + 1}`}</label>
                    </div>
                  </div> 
                  <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center">
                  { index ? 
                      <span className="account__login--btn secondary__btn p-0 d-flex justify-content-center align-items-center" onClick={(e) => handleAnswerChange(index, e.target.value, 1)}>
                          <svg xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 -960 960 960" width={20}  >
                          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                      </svg>
                      </span>
                    : null }
                  </div>
                </div>
                ))
              : null }
            
            <div className="col-sm-6 my-3">
             
             <label className="mb-3"> Question Status </label>

               <div className="event-visibility hand_cursor" style={{width: "fit-content"}}>
                   <div className={'visibility gap-4 ' + QuestionStatusMClass} onClick={() => { setQuestionStatus(1); setQuestionStatusMClass('active'); setQuestionStatusOpClass(''); }}>Mandatory</div>

                   <div className={'visibility gap-4 ' + QuestionStatusOpClass} onClick={() => { setQuestionStatus(0); setQuestionStatusMClass(''); setQuestionStatusOpClass('active'); }} >Optional</div>
             </div>
             {/* <small className="text-danger">{EventNameError}</small> */}
           </div>
          
        </div>  
      </div>

       {/* <div className="d-flex gap-4">
        
          <input type="submit" name="command" value="Save" className="account__login--btn primary__btn mt-2" />
         
          <button className="account__login--btn secondary__btn mt-2" onClick={(e) => { props.setAddCustomQuestions(false); }}>Cancel</button>

        </div> */}

        <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              onClick={(e) => { props.setAddCustomQuestions(false); }}
              style={{cursor:'pointer'}} >
              Cancel
            </div>

            <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />

          </div>

      </form>
   
    </div>
    </>
  )
}

export default AddCustomFormQuestions