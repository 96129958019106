import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useState, useEffect } from 'react';
import axios from "./axios";
// import LazyImg from "../src/img/lazy_images.png"
import backgroundImage from '../src/img/banner-bg-2.jpg'

const Banner = (props) => {
    const BannerImages = props.BannerImages;

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        lazyLoad: 'ondemand'
    };

    const handleItemClick = (url) => {
        // console.log(`Item ${index + 1} clicked`);
        window.open(url, '_blank')
    };
    // console.log(props.BannerBackgroundImg);
    return (
        <>
            {
                (BannerImages && BannerImages.length > 0) ? (
                    <Slider {...settings}>
                        {BannerImages.map((item, index) => (
                            <div key={index} className='d-flex'>
                                <img
                                    src={item.img}
                                    alt={`slide ${index + 1}`}
                                    onClick={() => handleItemClick(item.url)}
                                    className='ytcr-banner-img'
                                    style={{ backgroundImage: `url(${backgroundImage})` }}
                                />
                            </div>
                        ))}
                    </Slider>
                ) :
                    <div className='d-flex'>
                        <img
                            src={backgroundImage}
                            alt=""
                            className='ytcr-banner-img'
                            style={{ backgroundImage: `url(${backgroundImage})` }} />
                    </div>
            }
            {/* <div className="" style={{marginTop:"30px",textAlign:"center"}}>
                <h4 className="h4 d-flex" style={{ height: "55px" }}> RACES is India’s first fully integrated registration platform for running events. Beside
                being a registration platform for running events, we also provide technology solutions,
                back office service and go-to-market offerings to grow your running event.</h4>
            </div> */}
            {/* <div>
                RACES is India’s first fully integrated registration platform for running events. Beside
                being a registration platform for running events, we also provide technology solutions,
                back office service and go-to-market offerings to grow your running event.
            </div> */}
        </>
    );
}

export default Banner;
