import React, { useState, useEffect } from 'react';
import axios from '../axios';

const ViewBookingDetails = (props) => {
    const [ApiCall, setApiCall] = useState(true);
    const [BookingDetails, setBookingDetails] = useState([])
    const [AmountDetails, setAmountDetails] = useState([])
    const [ExtraDetails, setExtraDetails] = useState([])


    useEffect(() => {
        const handleEscKeyPress = (event) => {
            if (event.key === 'Escape') {
                props.setViewBookingDetailsFlag(false);
            }
        };
        document.addEventListener('keydown', handleEscKeyPress);
        return () => {
            document.removeEventListener('keydown', handleEscKeyPress);
        };
    }, [props, props.setViewBookingDetailsFlag]);


    useEffect(() => {
        if (ApiCall) {
            const payload = {
                "event_id": props.EventId,
                "user_id": props.UserId,
                "BookingId": props.BookingId,
                "BookingDetailId": props.BookingDetailId
            }
            axios.post("get_booking_details", payload)
                .then((response) => {
                    // console.log(response)
                    if (response.status === 200) {
                        // console.log(response.data.data.BookingDetails[0].amount_details)
                        setBookingDetails(response.data.data.BookingDetails);
                        // extra_details
                        if (response.data.data.BookingDetails.length > 0) {
                            setAmountDetails(response.data.data.BookingDetails[0].amount_details);
                            setExtraDetails(response.data.data.BookingDetails[0].extra_details)
                        }
                        setApiCall(false);
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [ApiCall, props.BookingDetailId, props.BookingId, props.EventBookingId, props.EventId, props.UserId])
    // console.log(BookingDetails)

    return (
        <div className="modal is-visible" data-animation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper" style={{ width: "100%" }}>
                <header className="modal-header quickview__header">
                </header>
                <div className="quickview__inner">
                    <div className="row">
                        <div className="login__section">
                            <div className='dashboard-card p-4 border-radius-10 '>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <h4>Registration Details</h4>
                                        <table className="table table-hover text-center">
                                            {
                                                BookingDetails && BookingDetails.length > 0 ?
                                                    <>
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th scope="col">Order Id</th>
                                                                <th scope="col">Registration Id</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Registration Date & Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {BookingDetails.map((user, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{user.OrderId}</td>
                                                                        <td>{user.unique_ticket_id}</td>
                                                                        <td>{user.firstname !== "null" ? user.firstname : ""}{" "}{user.lastname !== "null" ? user.lastname : ""}</td>
                                                                        <td>{user.email}</td>
                                                                        <td>{user.booking_start_date + "  " + user.booking_time}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </table>
                                    </div>
                                </div>
                                <br />
                                <hr />
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <h4>Races Category Details</h4>
                                        <table className="table table-hover text-center">
                                            {
                                                BookingDetails && BookingDetails.length > 0 ?
                                                    <>
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th scope="col">Category</th>
                                                                <th scope="col">Quantity</th>
                                                                <th scope="col">Price</th>
                                                                <th scope="col">Discount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {BookingDetails.map((user, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{user.TicketName}</td>
                                                                        <td>1</td>
                                                                        <td>{user.ticket_amount}</td>
                                                                        <td>{user.TicketDiscount}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </table>
                                    </div>
                                </div>
                                <br />

                                {/* {console.log(BookingDetails.amount_details)} */}
                                {
                                    AmountDetails && AmountDetails.length > 0 ?
                                        <>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <h4>Additional Purchases</h4>
                                                    <table className="table table-hover text-center">
                                                        <tbody>
                                                            {AmountDetails.map((user, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <th >{user.question_label}</th>:<td>{user.ActualValue}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                }

                                {
                                    ExtraDetails && ExtraDetails.length > 0 ? (
                                        <>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <h4>Additional Purchases</h4>
                                                    <table className="table table-hover text-center">
                                                        <tbody>
                                                            {ExtraDetails.map((user, index) => {
                                                                const actual_value = parseInt(user.ActualValue); 
                                                                const question_form_option = JSON.parse(user.question_form_option); 

                                                                const label = question_form_option.find(option => option.id === actual_value)?.label; 

                                                                return (
                                                                    <tr key={index}>
                                                                        <th>{user.question_label}</th>:
                                                                        <td>{label}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    ) : null
                                }


                                <div className="d-flex gap-2">
                                    <button className="continue__shipping--btn primary__btn border-radius-5" onClick={() => {
                                        props.setViewBookingDetailsFlag(false);
                                    }}>Close</button>

                                    {/* <button className="account__login--btn primary__btn mt-2" type="submit">Continue</button> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewBookingDetails