import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from './axios';
import 'react-select-search/style.css'
import LocationPopup from './Header/LocationPopup';
import { useNavigate } from 'react-router-dom';

const PopularCities = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [Val, setVal] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [cityData, setcityData] = useState([]);
    const DivRef = useRef(null);
    const [ShowSuggestion, setShowSuggestion] = useState(false);
    const [Popup, setPopup] = useState(false);
    const [popularCitiesOpen, setPopularCitiesOpen] = useState(true);
    const [CityArr, setCityArr] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        axios.get("popular_cities")
            .then((response) => {
                // console.log(response.status);
                if (response.status === 200) {
                    // console.log(response.data.data.CityArr);
                    setCityArr(response.data.data.CityArr)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    useEffect(() => {
        // Toggle body overflow based on the state
        document.body.style.overflow = popularCitiesOpen ? 'hidden' : 'auto';

        return () => {
            // Reset body overflow when the component is unmounted
            document.body.style.overflow = 'auto';
        };
    }, [popularCitiesOpen]);

    useEffect(() => {
        // Toggle body overflow based on the state
        document.body.style.overflow = popularCitiesOpen ? 'hidden' : 'auto';

        // Add event listener for the "Esc" key
        const handleKeyPress = (e) => {
            if (e.key === 'Escape') {
                setPopup(false);
                localStorage.clear();
                props.setShowPopularCitiesFlag(false);
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            // Remove the event listener when the component is unmounted
            document.removeEventListener('keydown', handleKeyPress);

            // Reset body overflow when the component is unmounted
            document.body.style.overflow = 'auto';
        };
    }, [popularCitiesOpen]);


    useEffect(() => {
        axios.post("location_city", {
            'country_code': localStorage.getItem('CountryCode')
        })
            .then((response) => {
                // console.log(response);
                const cityData = response.data.data.AllCities;
                setcityData(cityData);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const handleInputChange = (e) => {
        const value = e.target.value;
        // console.log(value);
        setVal(value);
        // Filter suggestions based on input value
        const filteredSuggestions = cityData.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        const SliceArr = filteredSuggestions.slice(0, 5)
        setSuggestions(SliceArr);
        setShowSuggestion(true);
    };
    
    // console.log(cityData);
    const handleSearch = useCallback((flag) => {
        // alert(inputValue)
        // props.setSearchFlag(flag);
        // props.setHeaderInputCity(inputValue);
        props.setShowPopularCitiesFlag(false);

        localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('SearchEventPageFlag');

        const CITY = localStorage.getItem("CityId");
        props.setEventViewFlag(false)
        props.setSearchEventPageFlag(false)
        // toggle();
        if (parseInt(CITY) === 0) {
            props.setCountrySearchFlag(true);
        } else {
            props.setSearchFlag('HeaderInputCity');
        }
    }, [props]);

    const handleSuggestionClick = (id, name) => {
        // console.log(id,name);
        setInputValue(id);
        setVal(name)
        setSuggestions([]);
        props.setSearchFlag('HeaderInputCity');
        props.setHeaderInputCity(id);
        if (id !== "") {
            localStorage.setItem("CityId", id);
            
            handleSearch('HeaderInputCity');
        }
    };

    useEffect(() => {
            const handleKeyPress = (e) => {
                if (e.key === 'Enter') {
                    handleSearch('HeaderInputCity');
                }
            };
            document.addEventListener('keydown', handleKeyPress);
            return () => {
                document.removeEventListener('keydown', handleKeyPress);
                document.body.style.overflow = 'auto';
            };
    }, [handleSearch]);

    const handleSecondSearch = (id, flag) => {
        props.setSearchFlag(flag);
        props.setHeaderInputCity(id)
        props.setShowPopularCitiesFlag(false);

        localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('SearchEventPageFlag');

        const CITY = localStorage.getItem("CityId");
        props.setEventViewFlag(false)
        props.setSearchEventPageFlag(false)
        // toggle();
        if (parseInt(CITY) === 0) {
            props.setCountrySearchFlag(true);
        } else {
            props.setSearchFlag('HeaderInputCity');
        }
    }

    const handleDetectLocation = (e) => {
        // e.preventDefault();
        var LocationFlag = localStorage.getItem("LocationFlag");
        // console.log(LocationFlag);
        if (LocationFlag === '1') { //ALLOW
            localStorage.clear();
            props.setShowPopularCitiesFlag(false);
            navigate('/')
        } if (LocationFlag === '0') { //NOT ALLOW
            setPopup(true);
        }
        localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('SearchEventPageFlag');

        props.setEventViewFlag(false)
        props.setSearchEventPageFlag(false)

    }

    const handleCloseLocationPopup = () => {
        setPopup(false);
        // localStorage.clear();
        props.setShowPopularCitiesFlag(false);
    }

    // SUGGESTION CLOSE FUNCTIONALITY
    const handleClose = (e) => {
        if (DivRef.current && !DivRef.current.contains(e.target)) {
            setShowSuggestion(false);
        }
    }

    return (
        <>
            {Popup ? <LocationPopup onClose={handleCloseLocationPopup} /> : null}
            {/* Start serch box area */}
            <div className="predictive__search--box color-scheme-3 active" tabIndex="-1" onClick={(e) => { handleClose(e) }}>
                <div className="predictive__search--box__inner">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-9 col-xxl-10 my-2">
                            <div className="predictive__search--form" action="#">
                                <label>
                                    <input className="predictive__search--input" placeholder="Search Here" type="text" onChange={(e) => handleInputChange(e)} value={Val} />
                                    <div className="autocomplete">
                                        {ShowSuggestion ?
                                            <ul ref={DivRef}>
                                                {
                                                    (suggestions.length > 0) ?
                                                        suggestions.map((item) => {
                                                            // console.log(item);
                                                            return (
                                                                <li key={item.id} onClick={(e) => { handleSuggestionClick(item.id, item.name); }}>{item.name}</li>
                                                            )
                                                        }) : null
                                                }
                                            </ul>
                                            : null
                                        }
                                    </div>
                                </label>
                                <button className="predictive__search--button predictive__search--form" style={{ height: '50px' }} aria-label="search button" onClick={(e) => {
                                    handleSearch('HeaderInputCity');
                                }}>
                                    <svg className="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="30.51" height="25.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448" /></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 col-xxl-2 my-2">
                            <div className="d-flex justify-content-center gap-2 detect-location cursor-pointer" >
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-42v-80q-125-14-214.5-103.5T122-440H42v-80h80q14-125 103.5-214.5T440-838v-80h80v80q125 14 214.5 103.5T838-520h80v80h-80q-14 125-103.5 214.5T520-122v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm0-80Z" /></svg>
                                <p className="m-0" onClick={(e) => handleDetectLocation(e)}>Detect my location</p>
                            </div>
                        </div>
                    </div>
                    <hr className="my-5" />
                    <h3 className="mb-5">Popular Cities</h3>

                    <div className="product__section--inner product__swiper--column5 swiper swiper-initialized swiper-horizontal swiper-pointer-events" style={{"padding":"15px"}}>
                        <div className="swiper-wrapper cursor-pointer" id="swiper-wrapper-1044108008d3710377d" aria-live="polite" >
                        <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next row" >
                            {
                                (CityArr && CityArr.length > 0) ? (
                                    CityArr.map((item, index) => (
                                       
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 col-xxl-2 py-1" data-swiper-slide-index={index} role="group" aria-label={index}
                                            key={index} onClick={(e) => { handleSecondSearch(item.id, 'HeaderInputCity'); }}>
                                                <div className="categories__product--items border border-radius-5 text-center">
                                                    <div className="categories__product--items__link">
                                                        <img className="categories__product--thumbnail__img display-block"
                                                            src={item.image}
                                                            alt={`slide ${index + 1}`}
                                                        />
                                                        <h3 className="categories__product--title h4">{item.city}</h3>
                                                        <p className="m-0">{item.event_count + " Events"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                    ))
                                ) :
                                    <p key="no-images">No images found</p>
                            }
                            </div>
                        </div>
                        {/*<div className="swiper__nav--btn swiper-button-next"></div>
                        <div className="swiper__nav--btn swiper-button-prev"></div>*/}
                    </div>
                </div>
                <button className="predictive__search--close__btn" aria-label="search close btn" onClick={() => {
                    props.setShowPopularCitiesFlag(false);
                }}
                >
                    <svg className="predictive__search--close__icon" xmlns="http://www.w3.org/2000/svg" width="40.51" height="30.443" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368" /></svg>
                </button>
            </div >
            {/* End serch box area */}
        </>
    )
}

export default PopularCities;
