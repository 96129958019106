import React, { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import successImg from '../img/other/success1.gif';
import HeaderSecond from '../HeaderSecond';

const PaymentGateway = () => {
  // console.log("Create Event page");
  const navigate = useNavigate();
  const [Loader, setLoader] = useState(false);

  const handleBackToHome = () => {
    localStorage.removeItem('EventViewFlag');
    localStorage.removeItem('EventDetailsId');

    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = localStorage.getItem('Country');
    navigate('/' + CountryCode + '/' + CountryName);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const { Status } = useParams();
  const bookingPayId = localStorage.getItem('booking_pay_id');
  const event_id = localStorage.getItem('RegEventId');
  const url = window.location.origin;
  // console.log(url);
  const [PaymentSuccessPopup, setPaymentSuccessPopup] = React.useState(false);

  useEffect(() => {
    if(Status === 'success'){
      // console.log('ss');
      //setLoader(true);
      setPaymentSuccessPopup(true);

      const payload = {
        booking_pay_id: bookingPayId,
        event_id: event_id,
        event_url: url
      };
      //console.log(payload);
      axios.post("send_email_payment_success", payload)
        .then((response) => {
            //console.log(response);
            if(response.data.success === 200){
              toast.success(response.data.message);
              localStorage.removeItem('booking_pay_id');
            }else{
              toast.error(response.data.message);
            }
            
        })
        .catch((error) => {
          console.log(error);
          //setLoader(false);
        });
    }else{
      navigate('/events')
      setPaymentSuccessPopup(false);
    }

  },  [Status]);

  // useEffect(() => {
  //   if(Status === 'success'){
  //     setPaymentSuccessPopup(true);
  //   }else{
  //     navigate('/events')
  //     setPaymentSuccessPopup(false);
  //   }
  // },  [Status]);


  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

      {PaymentSuccessPopup === true ? 
  
          <div className="modal is-visible" data-animation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper">
              <div className="quickview__inner">
                <div className="row">
                  <div className="login__section">
                    <div className="login__section--inner">
                      <div className="p-5">
                        <div className="account__login--header mb-25 text-center">

                          <img src={successImg} alt="" style={{ height: 150 }} />
                          <p className='h3 my-3'>Payment successful</p>
                          <p className="account__login--header__desc mb-2">We have received payment amount.</p>
                          {/* <p className='h3'>₹2343</p> */}
                        </div>
                        <div className="d-flex gap-4">
                          <button className="account__login--btn secondary__btn mt-2" onClick={(e) => { navigate('/my_bookings'); }} >Close</button> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      :
        ''
      }

      <ToastContainer theme="colored" />
      <HeaderSecond />
      <ToastContainer theme="colored" />
    </>
  )
}

export default PaymentGateway;

