import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from '../axios';
import HeaderSecond from '../HeaderSecond';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import EventView from '../img/events/event-view.jpg'
import QRCode from 'qrcode.react';

const MyBookingsDetails = () => {
    const location = useLocation();
    const EventId = location.state ? (location.state.event_id ? location.state.event_id : 0) : 0;
    const navigate = useNavigate();
    const [BookingData, setBookingData] = useState([]);
    // console.log(BookingData);
    useEffect(() => {
        if (EventId !== null) {
            const payload = {
                "event_id": EventId
            }
            axios.post("get_event_booking_tickets", payload)
                .then((response) => {
                    // console.log(response.data.data.BookingData);
                    setBookingData(response.data.data.BookingData);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [EventId])

    const BackToHome = () => {
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('EventId');
        localStorage.removeItem('EventType');
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }
    const [loading, setLoading] = useState(false);

    const downloadPDF = (ticket) => {
        // console.log(JSON.stringify(ticket));
        var copyText = window.location.origin + process.env.PUBLIC_URL;
        // // console.log(copyText);
        const formattedEventName = ticket.name.replace(/\s/g, '_');
        const EventLink = copyText + "/e/" + formattedEventName;
        //  console.log(ticket,EventLink);
        // setLoading(true);
        axios.post('ticket_pdf', {
            ticket: ticket,
            event_link: EventLink
        })
            .then((response) => {
                // console.log(response.data.data.pdf_link);
                var link = document.createElement("a");
                // link.download = response.data.data.filename;
                link.target = "_blank";
                link.href = response.data.data.pdf_link;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    // console.log(BookingData.qrCode)

    return (
        <>
            <HeaderSecond />
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        My Registration Details
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">

                                        <li className="breadcrumb__content--menu__items" onClick={(e) => BackToHome()}>
                                            <div className="text-white">
                                                Home
                                            </div>
                                        </li>

                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">My Registration Details</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container">
                        <Link to={"/my_bookings"}>
                            <div className="checkout__content--step section__shipping--address ">
                                <div className="mb-5">
                                    <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                                        <div className="event-visibility hand_cursor">
                                            <div className="visibility gap-1 ">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height={20}
                                                    viewBox="0 -960 960 960"
                                                    width={20}
                                                >
                                                    <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
                                                </svg>
                                                Back
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <div className="row booking-details">
                            {/* {console.log(BookingData)} */}

                            {BookingData && BookingData.length > 0 ? BookingData.map((ticket, ticketIndex) => {
                                // const attendeeName = ticket.attendee_name[attendeeIndex]; 
                                // console.log(ticket);
                                return (
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8 pb-5" key={ticket.id}>
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center px-0" style={{ backgroundImage: `url('${ticket.banner_image || EventView}')`, backdropFilter: 'blur(50px)' }}>
                                                    <div className="mb-event-img-bg">
                                                        <img src={`${ticket.banner_image || EventView}`} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center d-flex flex-column justify-content-around my-4">

                                                    <div className="row event-date-time mb-4 mx-1">
                                                        <div className="col-sm-6 text-left">
                                                            <h4 className="py-2"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" /></svg> {ticket.event_start_date}</h4>
                                                        </div>
                                                        <div className="col-sm-6 text-right">
                                                            <h4 className="py-2"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg> {ticket.event_time}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="row py-2 mx-1">
                                                        <div className="col-sm-12 text-center">
                                                            <h3 className="text__secondary">{ticket.display_name}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="row py-2 mx-1">
                                                        <div className="col-sm-12 text-center">
                                                            <h4 className="text-uppercase fst-italic">{ticket.attendee_name}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="row py-2 mx-1">
                                                        <div className="col-sm-12 text-center">
                                                            {
                                                                ticket.TicketStatus !== 2 ?
                                                                    ticket.ticket_discount !== 0 ?
                                                                        <>
                                                                            <div className="">
                                                                                <div className="justify-content-center ticket-prices">
                                                                                    <div className="original-price">
                                                                                        ₹{ticket.ticket_amount}
                                                                                    </div>
                                                                                    <div className="discount-price">
                                                                                        ₹{ticket.strike_out_price}
                                                                                    </div>
                                                                                    <div className="total-discount">
                                                                                        ₹{ticket.ticket_discount}{" "}OFF
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className=""> ₹{ticket.ticket_amount}</div>
                                                                        </>
                                                                    :
                                                                    <>
                                                                        <div className=""><span className="total-discount">Free</span></div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            ticket.PricingDetails && ticket.PricingDetails.length > 0 ?
                                                                ticket.PricingDetails.map((price, priceIndex) => {
                                                                    return (
                                                                        <>
                                                                            {priceIndex !== 0 ? ", " : null}
                                                                            {price.QueLabel}{" : "}{price.ticket_amount}
                                                                        </>
                                                                    );
                                                                })
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div className="row event-date-time mt-4 mx-1">
                                                        <div className="col-sm-12 text-center">
                                                            <p className="py-2 m-0">Race Category:  {ticket.TicketName}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-center align-items-center" style={{ borderLeft: "2px dashed var(--border-color)" }} >
                                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex " >
                                                        <QRCode value={ticket.unique_ticket_id} style={{ height: "40px", width: "40px", marginLeft: "60px" }} />
                                                    </div>
                                                    <h3 className="booking-number font-monospace" style={{ fontSize: "18px", whiteSpace: "nowrap" }}>{ticket.unique_ticket_id}</h3>
                                                </div>

                                            </div>
                                            <div className="downlaod-booking" onClick={() => downloadPDF(ticket)} disabled={loading}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                                            </div>

                                        </div>
                                    </div>
                                );
                            }) : null}

                        </div>
                    </div>
                </section>
            </main >
        </>
    )
}

export default MyBookingsDetails