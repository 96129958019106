import React, { useState } from 'react'
import axios from '../axios'
import { Link, useParams, useLocation } from 'react-router-dom';

const UpdatePassoword = (props) => {
    const [ExistingPassword, setExistingPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [CNewPassword, setCNewPassword] = useState('');

    const [NewPasswordError, setNewPasswordError] = useState("");
    const [CNewPasswordError, setCNewPasswordError] = useState("");
    const [ExistingPasswordError, setExistingPasswordError] = useState("");
    const [GeneralApiError, setGeneralApiError] = useState("");

    const ResetPasswordValidation = () => {
        var flag = true;
        if (NewPassword === "") {
            setNewPasswordError("New password field is required.");
            flag = false;
        }
        if (CNewPassword === "") {
            setCNewPasswordError("Confirm password field is required.");
            flag = false;
        }
        if (NewPassword !== CNewPassword) {
            setCNewPasswordError("Your password and confirmation password do not match.")
            flag = false;
        }
        return flag;
    }
    // console.log(props.setResetEmail);
    const HandleUpdatePassword = (e) => {
        e.preventDefault();
        if (ResetPasswordValidation()) {
            axios.post("update_password", {
                "existing_password": ExistingPassword,
                "new_password": NewPassword,
                "confirm_new_password": CNewPassword
            }).then((response) => {
                // console.log(response.status);
                if (response.status === 200) {
                    props.setUpdatePasswordFlag(false);
                    props.setUpdatePasswordMsg("Password updated Successfully");
                }

            }).catch((error) => {
                // console.log(error.response.data.message);
                if (error.response.status === 400) {
                    setGeneralApiError(error.response.data.message)
                }
            });
        }
    }

    return (
        <>
            <div className="modal is-visible" id="modal1" data-animation="slideInUp">
                <div className="modal-dialog quickview__main--wrapper">
                    <header className="modal-header quickview__header">
                      
                    </header>
                    <div className="quickview__inner">
                        <div className="row">

                            <div className="login__section">
                                {/* <form> */}
                                <form onSubmit={HandleUpdatePassword}>
                                    <div className="login__section--inner" >
                                        <div className="p-5">
                                            <div className="account__login--inner">
                                                <div className="my-3">
                                                    <label>
                                                        <input className="account__login--input mb-2" placeholder="Existing Password" type="password" name="existing_password"
                                                            value={ExistingPassword}
                                                            onChange={(e) => {
                                                                setExistingPassword(e.target.value);
                                                            }}
                                                        />
                                                    </label>
                                                    <small className="text-danger mb-5">{ExistingPasswordError}</small>
                                                </div>

                                                <div className="my-3">
                                                    <label>
                                                        <input className="account__login--input mb-2" placeholder="New Password" type="password" name="new_password"
                                                            value={NewPassword}
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value);
                                                            }}
                                                        />
                                                    </label>
                                                    <small className="text-danger mb-5">{NewPasswordError}</small>
                                                </div>


                                                <div className="my-3">
                                                    <label>
                                                        <input className="account__login--input mb-2" placeholder="Confirm New Password" type="password" name="cnew_passowrd"
                                                            value={CNewPassword}
                                                            onChange={(e) => {
                                                                setCNewPassword(e.target.value);
                                                            }}
                                                        />
                                                    </label>
                                                    <small className="text-danger mb-5">{CNewPasswordError}</small>
                                                </div>

                                                {GeneralApiError !== "" ? 
                                                    <div className="text-danger mb-5" style={{ "textAlign": "center" }} >{GeneralApiError}</div>
                                                 : null}

                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        props.setUpdatePasswordFlag(false);
                                                    }}>Close</button>

                                                    <button className="account__login--btn primary__btn mt-2" type="submit">Continue</button>
                                                </div>

                                                <p>By continuing, you agree to YooTooCanRun's {" "}
                                                    <Link to="https://youtoocanrun.com/terms-and-conditions-for-use/" target="blank" >Terms of Services</Link> and <Link to="https://youtoocanrun.com/privacy-policy/" target="blank"> Privacy Statement</Link>.</p>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePassoword