import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DonutChart = ({ data }) => {
    const chartOptions = {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Category Booking Data'
        },
        tooltip: {
            style: {
                fontSize: '13px' 
            },
            pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        plotOptions: {
            pie: {
                innerSize: '50%',
                depth: 45,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.y}', // Show only the count in the data label
                    style: {
                        fontSize: '13px'
                    },
                    connectorColor: 'silver'
                },
                showInLegend: true
            }
        },
        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            itemStyle: {
                fontSize: '13px'
            },
            labelFormatter: function() {
                return `${this.name} (${this.y})`; // Customize legend label to show name and count
            }
        },
        series: [
            {
                name: 'Categories',
                data: data.map(item => ({
                    name: item.TicketName,
                    y: item.TicketCount
                }))
            }
        ]
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default DonutChart;
