import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Login from "../logo/loginsuccess.gif";

const EventSucessPopup = (props) => {
  
  const navigate = useNavigate();
  const country_code = localStorage.getItem("CountryCode") ? localStorage.getItem("CountryCode") : 0;
  const city = localStorage.getItem("City") ? localStorage.getItem("City") : 0;
  const [Copy, setCopy] = useState('Copy Link');
  const EventId = localStorage.getItem("EventId");
 
  var UrlNewText = window.location.origin;
  UrlNewText = UrlNewText + "/event/" + props.EventName;
  const [UrlText, setUrlText] = useState(UrlNewText);
  
  const url = window.location.origin + process.env.PUBLIC_URL;
  // console.log(url);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.setOpen(false); // Call the onClose function to close the popup
      //navigate("/"+country_code+"/"+city);
      navigate("/e/"+props.EventName.replace(/\s/g, '_'));   
    }, 5000); // Adjust the delay as needed (in milliseconds)

    // Clear the timer if the component is unmounted or the onClose function changes
    return () => clearTimeout(timer);
  },  []);

  // console.log(props.EventName.replace(/[^\w\s]/g, '_'));

  const handleCopyEvent = () => {
    setCopy('Copied');
    var copyText = window.location.origin;
    copyText = copyText + "/e/" + props.EventName.replace(/\s/g, '_');
    navigator.clipboard.writeText(copyText);
  }
  // console.log(props.EventName);
  
  return (
    <>

      {/* <div className="modal is-visible" id="modal1" data-animation="slideInUp">
        <div className="modal-dialog quickview__main--wrapper">
          <header className="modal-header quickview__header"> */}
            {/* <button
              className="close-modal quickview__close--btn"
              aria-label="close modal"
              data-close=""
            >
              ✕{" "}
            </button> */}
          {/* </header> */}
     
          {/* <div className="quickview__inner">
            <div className="row">
              <div className="login__section">
                <form action="#">
                  <div className="login__section--inner">
                    <div className="p-5">
                      <div className="account__login--header text-center">
                        <img
                           src={`${Login}`}
                          alt=""
                          style={{ width: 100 }}
                        />
                          <h4 className="h4 my-4">
                              {props.successMsg}
                          </h4>
                      </div>
                      
                      <div _ngcontent-serverapp-c148="" onClick={(e) => handleCopyEvent(e)}>
                        <div _ngcontent-serverapp-c148="" className="platform text-center cursor-pointer p-2 pr-4 flex-1">
                           <input _ngcontent-serverapp-c148="" type="text" id="event_link" className="copy_input" readOnly value={UrlText} /> &nbsp;&nbsp;
                            <svg width="22.239" height="22.984" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                              <path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" />
                            </svg> <span _ngcontent-serverapp-c148="" className="text-gray-900 text-sm block ng-star-inserted"> {Copy}</span>
                            
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}

        {/* </div>
      </div> */}

      <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <form action="#" >
                                            <div className="login__section--inner">
                                                <div className="p-2">
                                                    <div className="account__login--header text-center">
                                                        <img className="mb-5"  src={`${Login}`} alt="" style={{ width: "30%" }} />
                                                        <h3 className="account__login--header__title mb-10 text__secondary">YAY!</h3>
                                                        <h3 className="account__login--header__title mb-10 text__primary">{props.successMsg}</h3>
                                                        <div className="row mt-4 py-2 event-link-cp" >
                                                            <div className="col-sm-8">
                                                                <p>{UrlText}</p>
                                                            </div>
                                                            <div className="col-sm-4 event-link-cp-btn cursor-pointer" onClick={(e) => handleCopyEvent(e)}>
                                                                <p><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg> 
                                                                <span _ngcontent-serverapp-c148="" className="text-gray-900 text-sm block ng-star-inserted"> {Copy}</span></p>
                                                            </div>
                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      

    </>
  );
};

export default EventSucessPopup;
