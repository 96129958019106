import React, { useState, useEffect } from 'react';
import YtcrBannerImg from '../src/img/banner/ytcr-banner.png'//assets/img/banner/ytcr-banner.png
import axios from "./axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialSection = () => {
  const [testimonials, setTestiminial] = useState(null);
  const [ApiCall, setApiCall] = useState(true);

  const [FilledStar, setFilledStar] = useState(<path
    data-name="star - Copy"
    d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
    transform="translate(0 -0.018)"
    fill="currentColor"
  />);
  const [NonFilledStar, setNonFilledStar] = useState(<path
    data-name="star - Copy"
    d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
    transform="translate(0 -0.018)"
  />);


  useEffect(() => {
    if (ApiCall) {
      axios
        .get("get_testimonial", {})
        .then((response) => {
          // console.log('then::: ', response.data);
          if (response.status === 200) {
            setTestiminial(response.data.data.testimonials);
            setApiCall(false)
          }
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }, [ApiCall]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    lazyLoad: 'ondemand',
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [IsVideo, setIsVideo] = useState(false);
  useEffect(() => {
    const handleKeyPress = (event) => {
        if (event.key === "Escape") {
          setIsVideo(false);
        }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
        document.removeEventListener("keydown", handleKeyPress);
    };
}, []);
  // console.log(window)
  return (
    <>
      {/* Start What Our Top Clints Say */}
      <section className="testimonial__section position__relative testimonial__bg--two section--padding color-scheme-2 my-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="testimonial__section--inner">
                <div className="section__heading mb-2">
                  <h3 className="section__heading--maintitle">Runner Testimonials</h3>
                </div>
                <div className=" testimonial__swiper--column3 testimonial__padding swiper">
                  <div className="swiper-wrapper">
                    {testimonials && testimonials.map((item, index) => (
                      <div className="swiper-slide" key={index}>
                        {
                          (testimonials && testimonials.length > 0) ? (
                            <Slider {...settings}>
                              {testimonials.map((item, index) => (
                                <div key={index} className="testimonial__items--style2 position__relative border-radius-5 d-flex align-items-center">
                                  {/* <img
                                    src={item.testimonial_img}
                                    alt={`slide ${index + 1}`}
                                    className="testimonial__items--thumbnail__img display-block"
                                  /> */}
                                  <div className="testimonial__content--style2">
                                    <h3 className="testimonial__items--author__title h4">{item.name}</h3>
                                    <h4 className="testimonial__items--author__subtitle h5">{item.subtitle}</h4>
                                    <p className="testimonial__items--desc style2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8.101"
                                        height="6.481"
                                        viewBox="0 0 8.101 6.481"
                                      >
                                        <path
                                          data-name="Icon metro-quote"
                                          d="M5.57,9.667v3.24H8.81V9.667H7.19a1.587,1.587,0,0,1,1.62-1.62V6.427A3.174,3.174,0,0,0,5.57,9.667Zm8.1-1.62V6.427a3.174,3.174,0,0,0-3.24,3.24v3.24h3.24V9.667h-1.62A1.587,1.587,0,0,1,13.671,8.047Z"
                                          transform="translate(-5.57 -6.427)"
                                          fill="currentColor"
                                        />
                                      </svg>
                                      {item.description}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="7.774"
                                        height="6.803"
                                        viewBox="0 0 7.774 6.803"
                                      >
                                        <path
                                          data-name="Icon awesome-quote-right"
                                          d="M7.046,1.5H5.1a.729.729,0,0,0-.729.729V4.172A.729.729,0,0,0,5.1,4.9H6.317v.972a.973.973,0,0,1-.972.972H5.223a.364.364,0,0,0-.364.364v.729a.364.364,0,0,0,.364.364h.121a2.429,2.429,0,0,0,2.43-2.43V2.229A.729.729,0,0,0,7.046,1.5Zm-4.373,0H.729A.729.729,0,0,0,0,2.229V4.172A.729.729,0,0,0,.729,4.9H1.944v.972a.973.973,0,0,1-.972.972H.85a.364.364,0,0,0-.364.364v.729A.364.364,0,0,0,.85,8.3H.972A2.429,2.429,0,0,0,3.4,5.873V2.229A.729.729,0,0,0,2.672,1.5Z"
                                          transform="translate(0 -1.5)"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </p>
                                    <ul className="rating testimonial__rating d-flex">
                                      <li className="rating__list">
                                        <span className="rating__list--icon">
                                          <svg
                                            className="rating__list--icon__svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11.105"
                                            height="11.732"
                                            viewBox="0 0 10.105 9.732"
                                          >
                                            {item.stars >= 1 ? FilledStar : NonFilledStar}
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="rating__list">
                                        <span className="rating__list--icon">
                                          <svg
                                            className="rating__list--icon__svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11.105"
                                            height="11.732"
                                            viewBox="0 0 10.105 9.732"
                                          >
                                            {item.stars >= 2 ? FilledStar : NonFilledStar}
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="rating__list">
                                        <span className="rating__list--icon">
                                          <svg className="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="11.105" height="11.732" viewBox="0 0 10.105 9.732">
                                            {item.stars >= 3 ? FilledStar : NonFilledStar}
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="rating__list">
                                        <span className="rating__list--icon">
                                          <svg className="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="11.105" height="11.732" viewBox="0 0 10.105 9.732">
                                            {item.stars >= 4 ? FilledStar : NonFilledStar}
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="rating__list">
                                        <span className="rating__list--icon">
                                          <svg className="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="11.105" height="11.732" viewBox="0 0 10.105 9.732">
                                            {item.stars >= 5 ? FilledStar : NonFilledStar}
                                          </svg>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="testimonial__quote--icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.599" height="22.572" viewBox="0 0 25.599 22.572">
                                      <g data-name="Group 382" transform="translate(-164 -5399)">
                                        <path data-name="Path 131" d="M10.284,11.81a1.231,1.231,0,0,0,.62-1.652L9.892,8.031a1.235,1.235,0,0,0-1.611-.6A14.227,14.227,0,0,0,3.82,10.324,10.79,10.79,0,0,0,.826,15.052,25.936,25.936,0,0,0,0,22.321v6.34A1.243,1.243,0,0,0,1.239,29.9H9.355a1.243,1.243,0,0,0,1.239-1.239V20.545a1.243,1.243,0,0,0-1.239-1.239H5.472a8.707,8.707,0,0,1,1.446-5.018A7.849,7.849,0,0,1,10.284,11.81Z" transform="translate(164 5391.672)" fill="#f1f1f1" />
                                        <path data-name="Path 132" d="M80.963,11.89a1.231,1.231,0,0,0,.62-1.652L80.571,8.132a1.235,1.235,0,0,0-1.611-.6,14.959,14.959,0,0,0-4.44,2.87,11.021,11.021,0,0,0-3.015,4.75A25.587,25.587,0,0,0,70.7,22.4v6.34a1.243,1.243,0,0,0,1.239,1.239h8.116a1.243,1.243,0,0,0,1.239-1.239V20.625a1.243,1.243,0,0,0-1.239-1.239h-3.9A8.709,8.709,0,0,1,77.6,14.368,7.848,7.848,0,0,1,80.963,11.89Z" transform="translate(107.9 5391.592)" fill="#f1f1f1" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>

                              ))}
                            </Slider>
                          ) :
                            <p key="no-images">No images found</p>
                        }
                      </div>
                    ))}
                  </div>
                  <div className="testimonial__pagination swiper-pagination" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* End What Our Top Clints Say */}

      {/* Start Advertisement banner video */}
      <div className="banner__video--section py-5">
        <div className="container-fluid">
          <div className="banner__video--thumbnail position__relative">
            <img
              className="banner__video--thumbnail__img display-block"
              src={`${YtcrBannerImg}`}
              alt="banner-bideo-thumbnail"
            />
            <div className="bideo__play">
              {/* <a
                className="bideo__play--icon glightbox"
                href="https://youtu.be/Md8ZHCliZkY?si=pdZ4GiSJrked8KGw"
                data-gallery="video"
                onClick={openLightbox}
              > */}
              {/* onClick={openModal} */}
              <button className="bideo__play--icon" onClick={(e)=>{setIsVideo(true);}}>
                <svg
                  id="play"
                  xmlns="http://www.w3.org/2000/svg"
                  width="46.302"
                  height="46.302"
                  viewBox="0 0 46.302 46.302"
                >
                  <g
                    id="Group_193"
                    data-name="Group 193"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_116"
                      data-name="Path 116"
                      d="M39.521,6.781a23.151,23.151,0,0,0-32.74,32.74,23.151,23.151,0,0,0,32.74-32.74ZM23.151,44.457A21.306,21.306,0,1,1,44.457,23.151,21.33,21.33,0,0,1,23.151,44.457Z"
                      fill="currentColor"
                    />
                    <g
                      id="Group_188"
                      data-name="Group 188"
                      transform="translate(15.588 11.19)"
                    >
                      <g id="Group_187" data-name="Group 187">
                        <path
                          id="Path_117"
                          data-name="Path 117"
                          d="M190.3,133.213l-13.256-8.964a3,3,0,0,0-4.674,2.482v17.929a2.994,2.994,0,0,0,4.674,2.481l13.256-8.964a3,3,0,0,0,0-4.963Zm-1.033,3.435-13.256,8.964a1.151,1.151,0,0,1-1.8-.953V126.73a1.134,1.134,0,0,1,.611-1.017,1.134,1.134,0,0,1,1.185.063l13.256,8.964a1.151,1.151,0,0,1,0,1.907Z"
                          transform="translate(-172.366 -123.734)"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_190"
                      data-name="Group 190"
                      transform="translate(28.593 5.401)"
                    >
                      <g id="Group_189" data-name="Group 189">
                        <path
                          id="Path_118"
                          data-name="Path 118"
                          d="M328.31,70.492a18.965,18.965,0,0,0-10.886-10.708.922.922,0,1,0-.653,1.725,17.117,17.117,0,0,1,9.825,9.664.922.922,0,1,0,1.714-.682Z"
                          transform="translate(-316.174 -59.724)"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_192"
                      data-name="Group 192"
                      transform="translate(22.228 4.243)"
                    >
                      <g id="Group_191" data-name="Group 191">
                        <path
                          id="Path_119"
                          data-name="Path 119"
                          d="M249.922,47.187a19.08,19.08,0,0,0-3.2-.27.922.922,0,0,0,0,1.845,17.245,17.245,0,0,1,2.889.243.922.922,0,1,0,.31-1.818Z"
                          transform="translate(-245.801 -46.917)"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <span className="visually-hidden">Play</span>
                {/* </a> */}
              </button>
            </div>
          </div>
        </div>

      </div>
      {/* End Advertisement banner video */}

      {/* Start points with icon */}
      
      {/* End points with icon */}



      {
        IsVideo ?

          <div className="modal is-visible" data-animation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper" style={{width: "100%"}}>
              <header className="modal-header quickview__header">
                <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
              setIsVideo(false);
            }}>✕ </button>
              </header>
              <div className="quickview__inner">
              <iframe style={{width: "100%", height: "500px"}} src="https://www.youtube.com/embed/lcgm4iKFM54?si=UvjUOemPcTHL62lA?si=W_E0afyAPubfxuNO&amp;autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          :
        null
      }
    </>
  )
}

export default TestimonialSection