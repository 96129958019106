import React, { useState, useEffect } from 'react';
import axios from '../axios';
import UpdatePassoword from './UpdatePassoword';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShareProfile from './ShareProfile';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const MyProfile = (props) => {
    const [Userdata, setUserdata] = useState([]);
    const [UpdatePasswordFlag, setUpdatePasswordFlag] = useState(false);
    const [UpdatePasswordMsg, setUpdatePasswordMsg] = useState('');
    const [ShareProfileFlag, setShareProfileFlag] = useState(false);
    const [IdProofType, setIdProofType] = useState('');
    const [RelIdProofType, setRelIdProofType] = useState('');

    const [AddressProofType, setAddressProofType] = useState('');

    const Section = props.Tab

    useEffect(() => {
        if (Section) {
            axios.get("get_profile")
                .then((response) => {
                    // console.log(response.data.data.userData);
                    setUserdata(response.data.data.userData[0]);
                    if (response.data.data.userData[0].id_proof_type !== "") {
                        const id_proof_type = response.data.data.userData[0].id_proof_type;
                        const address_proof_type = response.data.data.userData[0].address_proof_type;
                        const rel_id_proof_doc = response.data.data.userData[0].rel_id_proof_doc;

                        // console.log(id_proof_type);
                        if (id_proof_type === '1') {
                            setIdProofType('Aadhar Card')
                        } else if (id_proof_type === '2') {
                            setIdProofType('PAN Card')
                        } else if (id_proof_type === '3') {
                            setIdProofType('Driving Licence')
                        } else if (id_proof_type === '4') {
                            setIdProofType('Passport')
                        } else {
                            setIdProofType('')
                        }

                        if (address_proof_type === '1') {
                            setAddressProofType('Aadhar Card')
                        } else if (address_proof_type === '2') {
                            setAddressProofType('PAN Card')
                        } else if (address_proof_type === '3') {
                            setAddressProofType('Driving Licence')
                        } else if (address_proof_type === '4') {
                            setAddressProofType('Passport')
                        } else {
                            setAddressProofType('')
                        }

                        if (rel_id_proof_doc === '1') {
                            setRelIdProofType('Aadhar Card')
                        } else if (rel_id_proof_doc === '2') {
                            setRelIdProofType('PAN Card')
                        } else if (rel_id_proof_doc === '3') {
                            setRelIdProofType('Driving Licence')
                        } else if (rel_id_proof_doc === '4') {
                            setRelIdProofType('Passport')
                        } else {
                            setRelIdProofType('')
                        }
                    }
                })
                .catch((error) => {
                    // console.log(error);
                })
        }
    }, [Section])

    // console.log(Userdata);

    const handleSocialAccounts = (url) => {
        // alert(url)
        window.open(url, '_blank');
    };
    // console.log(Userdata.facebook_link);
    useEffect(() => {
        if (UpdatePasswordMsg !== "") {
            toast.success(UpdatePasswordMsg);
        }
    }, [UpdatePasswordMsg])

    const handleShareProfile = () => {
        // alert('here');
        setShareProfileFlag(true)
    }
    const renderAboutYou = (about_you) => {
        return { __html: about_you };
    };

    // console.log(Section);
    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const openLightbox = (index) => {
        setImageIndex(index);
        setIsOpen(true);
    };

    const share_profile_svg = "M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z";

    const edit_svg = "M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z";

    //--------------- access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    const OrgUserAccessModules = localStorage.getItem("OrgUserAccessModules") ? JSON.parse(localStorage.getItem("OrgUserAccessModules")) : '';

    return (
        <>
            {/* <ToastContainer theme="colored" /> */}

            {ShareProfileFlag ? <ShareProfile setShareProfileFlag={setShareProfileFlag} /> : null}

            {UpdatePasswordFlag ? <UpdatePassoword setUpdatePasswordFlag={setUpdatePasswordFlag} setUpdatePasswordMsg={setUpdatePasswordMsg} /> : null}

            <div className={Section === 'my-profile' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none"}>
                <div className="main checkout__mian  ">
                    <div className="row">
                        <div className="section__header d-flex align-items-center justify-content-between mb-4">
                            <h2 className="section__header--title h3">Personal Details</h2>
                            <div className="d-flex gap-3">

                                {AccessRightFlag === '1' ?
                                    <>
                                        {OrgUserAccessModules.MyProfile === 2 ?
                                            <div className="hand_cursor continue__shipping--btn secondary__btn profile-btn-p" onClick={(e) => handleShareProfile(e)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height={24}
                                                    viewBox="0 -960 960 960"
                                                    width={24}>
                                                    <path d={share_profile_svg} />
                                                </svg><p className="m-0">Share Profile</p>
                                            </div>
                                        : null }
                                    </>
                                : 
                                    <div className="hand_cursor continue__shipping--btn secondary__btn profile-btn-p" onClick={(e) => handleShareProfile(e)}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={24}
                                            viewBox="0 -960 960 960"
                                            width={24}>
                                            <path d={share_profile_svg} />
                                        </svg><p className="m-0">Share Profile</p>
                                    </div>
                                }

                                {AccessRightFlag === '1' ?
                                    <>
                                        {OrgUserAccessModules.MyProfile === 2 ?
                                            <div className="hand_cursor continue__shipping--btn secondary__btn profile-btn-p"
                                            onClick={(e) => props.setTab('edit-my-profile')} >
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    height={24}
                                                    viewBox="0 -960 960 960"
                                                    width={24} >
                                                    <path d={edit_svg} />
                                                </svg> <p className="m-0">Edit</p>
                                            </div>
                                        : null }
                                    </>
                                : 
                                    <div className="hand_cursor continue__shipping--btn secondary__btn profile-btn-p"
                                    onClick={(e) => props.setTab('edit-my-profile')} >
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            height={24}
                                            viewBox="0 -960 960 960"
                                            width={24} >
                                            <path d={edit_svg} />
                                        </svg> <p className="m-0">Edit</p>
                                    </div>
                                }

                                <a className="hand_cursor continue__shipping--btn secondary__btn border-radius-5 d-none"
                                    href="#"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height={24}
                                        viewBox="0 -960 960 960"
                                        width={24}
                                    >
                                        <path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z" />
                                    </svg>
                                    Save
                                </a>

                                <div className=" secondary__btn border-radius-5 event-card-btn" style={{ cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z" /></svg></div>
                            </div>
                        </div>
                        <div className="profile-card">
                            <div className="card-body p-5">
                                <div className="row align-items-center">

                                    <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 col-xxl-2 d-flex my-3 justify-content-center">
                                        <img
                                            className="user-img"
                                            src={Userdata.profile_pic}
                                            alt={Userdata.firstname + " " + Userdata.lastname}
                                        />
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10 col-xxl-10 my-3">
                                        <h4 className="m-4 h3">{Userdata.firstname + " " + Userdata.lastname}</h4>

                                        <div className='row'>
                                            <div className='col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-6'>

                                                <p className="h4 m-4" title="Contact Number">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={25}
                                                        viewBox="0 -960 960 960"
                                                        width={25}
                                                    >
                                                        <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                                                    </svg> +91 - {Userdata.mobile}
                                                </p>

                                            </div>
                                            <div className='col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-6'>
                                                <p className="h4 m-4" title="Gender">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={25}
                                                        viewBox="0 -960 960 960"
                                                        width={25}
                                                    >
                                                        <path d="M440-120v-80h-80v-80h80v-84q-79-14-129.5-75.5T260-582q0-91 64.5-154.5T480-800q91 0 155.5 63.5T700-582q0 81-50.5 142.5T520-364v84h80v80h-80v80h-80Zm40-320q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41Z" />
                                                    </svg> {Userdata.gender === 1 ? "Male" : Userdata.gender === 2 ? "Female" : Userdata.gender === 3 ? "Other" : ""}

                                                </p>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-6'>
                                                <p className="h4 m-4" title="Email-ID">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={25}
                                                        viewBox="0 -960 960 960"
                                                        width={25}
                                                    >
                                                        <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                                                    </svg> {Userdata.email}
                                                </p>
                                            </div>
                                            <div className='col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-6'>
                                                <p className="h4 m-4" title="Date Of Birth">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={25}
                                                        viewBox="0 -960 960 960"
                                                        width={25}
                                                    >
                                                        <path d="M160-80q-17 0-28.5-11.5T120-120v-200q0-33 23.5-56.5T200-400v-160q0-33 23.5-56.5T280-640h160v-58q-18-12-29-29t-11-41q0-15 6-29.5t18-26.5l56-56 56 56q12 12 18 26.5t6 29.5q0 24-11 41t-29 29v58h160q33 0 56.5 23.5T760-560v160q33 0 56.5 23.5T840-320v200q0 17-11.5 28.5T800-80H160Zm120-320h400v-160H280v160Zm-80 240h560v-160H200v160Zm80-240h400-400Zm-80 240h560-560Zm560-240H200h560Z" />
                                                    </svg> {Userdata.dob ? Userdata.dob : "NA"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                        <p className="h4">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height={25}
                                                viewBox="0 -960 960 960"
                                                width={25}
                                            >
                                                <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h440l200 200v440q0 33-23.5 56.5T760-120H200Zm0-80h560v-400H600v-160H200v560Zm80-80h400v-80H280v80Zm0-320h200v-80H280v80Zm0 160h400v-80H280v80Zm-80-320v160-160 560-560Z" />
                                            </svg> My Bio : </p>
                                        <p className='my-2'>
                                            {/* {Userdata.about_you ? Userdata.about_you : "NA"} */}
                                            <div dangerouslySetInnerHTML={renderAboutYou(Userdata.about_you)}></div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={Section === 'general-details' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none"}>
                <div className="hand_cursor section__header d-flex align-items-center justify-content-between mb-4">
                    <h2 className="section__header--title h3">Basic Information</h2>
                    <div className="d-flex gap-4">
                       
                        {AccessRightFlag === '1' ?
                            <>
                                {OrgUserAccessModules.MyProfile === 2 ?
                                    <div className="continue__shipping--btn secondary__btn border-radius-5 profile-btn-p"
                                    onClick={(e) => props.setTab('edit-general-details')}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={24}
                                            viewBox="0 -960 960 960"
                                            width={24} >
                                            <path d={edit_svg} />
                                        </svg> <p className="m-0">Edit</p>
                                    </div>
                                : null }
                            </>
                        : 
                            <div className="continue__shipping--btn secondary__btn border-radius-5 profile-btn-p"
                            onClick={(e) => props.setTab('edit-general-details')}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={24}
                                    viewBox="0 -960 960 960"
                                    width={24} >
                                    <path d={edit_svg} />
                                </svg> <p className="m-0">Edit</p>
                            </div>
                        }

                        <div className=" secondary__btn border-radius-5 event-card-btn" style={{ cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z" /></svg></div>
                    </div>
                </div>

                <div className="row p-0 mb-5">
                    <h4 className="">Emergency Contact Details</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Emergency Contact Name</h4>
                            <p className="m-4">{Userdata.emergency_contact_person ? Userdata.emergency_contact_person : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Emergency Contact Number</h4>
                            <p className="m-4">{Userdata.emergency_contact_no1 ? Userdata.emergency_contact_no1 : "NA"}</p>
                        </div>
                    </div>
                </div>

                <div className="row p-0 mb-5 mt-4">
                    <h4 className="">Organisation Details</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Organisation</h4>
                            <p className="m-4">{Userdata.organization ? Userdata.organization : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Designation</h4>
                            <p className="m-4">{Userdata.designation ? Userdata.designation : "NA"}</p>
                        </div>
                    </div>
                </div>



                <div className="row p-0 mb-5">
                    <h4 className="">Identification Details</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Id Proof Type</h4>
                            <p className="m-4">{IdProofType ? IdProofType : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Id Proof Number</h4>
                            <p className="m-4">{Userdata.id_proof_no ? Userdata.id_proof_no : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Id Proof Document</h4>
                            <p className="m-4">{Userdata.id_proof_doc_upload ? <img src={Userdata.id_proof_doc_upload} alt='' style={{ 'height': "50px", 'width': '50px', cursor: "pointer" }} onClick={() => openLightbox(0)} /> : "NA"}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className={Section === 'address' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none"}>
                <div className="section__header d-flex align-items-center justify-content-between mb-4">
                    <h2 className="section__header--title h3">
                        Your Address
                    </h2>
                    <div className="d-flex gap-4">
                       
                        {AccessRightFlag === '1' ?
                            <>
                                {OrgUserAccessModules.MyProfile === 2 ?
                                    <div className="hand_cursor continue__shipping--btn secondary__btn border-radius-5 profile-btn-p"
                                    onClick={(e) => props.setTab('edit-address')} >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={24}
                                            viewBox="0 -960 960 960"
                                            width={24} >
                                            <path d={edit_svg} />
                                        </svg> <p className="m-0">Edit</p>
                                    </div>
                                : null }
                            </>
                        : 
                            <div className="hand_cursor continue__shipping--btn secondary__btn border-radius-5 profile-btn-p"
                            onClick={(e) => props.setTab('edit-address')} >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={24}
                                    viewBox="0 -960 960 960"
                                    width={24} >
                                    <path d={edit_svg} />
                                </svg> <p className="m-0">Edit</p>
                            </div>
                        }

                        <div className=" secondary__btn border-radius-5 event-card-btn" style={{ cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z" /></svg></div>
                    </div>
                </div>


                <div className="row p-0 mb-5">
                    <h4 className="">Permanent Address Details</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Flat No./Building No.</h4>
                            <p className="m-4">{Userdata.address1 ? Userdata.address1 : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Street Name, Area Name/Colony</h4>
                            <p className="m-4">{Userdata.address2 ? Userdata.address2 : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">City</h4>
                            <p className="m-4">{Userdata.city_name ? Userdata.city_name : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">State </h4>
                            <p className="m-4">{Userdata.state_name ? Userdata.state_name : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Country </h4>
                            <p className="m-4">{Userdata.country_name ? Userdata.country_name : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Pincode </h4>
                            <p className="m-4">{Userdata.pincode ? Userdata.pincode : "NA"}</p>
                        </div>
                    </div>
                </div>

                <div className="row p-0 mb-5">
                    <h4 className="">Communication Address Details</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Flat No./Building No.</h4>
                            <p className="m-4">{Userdata.ca_address1 ? Userdata.ca_address1 : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Street Name,Area Name/Colony</h4>
                            <p className="m-4"> {Userdata.ca_address2 ? Userdata.ca_address2 : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">City</h4>
                            <p className="m-4">{Userdata.ca_city_name ? Userdata.ca_city_name : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">State </h4>
                            <p className="m-4">{Userdata.ca_state_name ? Userdata.ca_state_name : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Country </h4>
                            <p className="m-4">{Userdata.ca_country_name ? Userdata.ca_country_name : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Pincode </h4>
                            <p className="m-4">{Userdata.ca_pincode ? Userdata.ca_pincode : "NA"}</p>
                        </div>
                    </div>
                </div>

                <div className="row p-0 mb-5">
                    <h4 className="">Address proof details</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Nationality</h4>
                            <p className="m-4">{Userdata.nationality ? Userdata.nationality : "NA"}</p>
                        </div>
                    </div>
                    {/* <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Address Proof Type</h4>
                            <p className="m-4">{AddressProofType ? AddressProofType : "NA"}</p>
                        </div>
                    </div> */}
                    {/* <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Address Proof Document</h4>
                            <p className="m-4"> {Userdata.address_proof_doc_upload ? <img src={Userdata.address_proof_doc_upload} alt='' style={{ 'height': "50px", 'width': '50px', cursor: "pointer" }} onClick={() => openLightbox(1)} /> : "NA"}</p>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className={Section === 'social-media-accounts' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none"}>
                <div className="main checkout__mian ">
                    <div className="row  py-5">
                        <div className="section__header d-flex align-items-center justify-content-between mb-4">
                            <h2 className="section__header--title h3">
                                Digital Presence
                            </h2>
                            <div className="d-flex gap-4">
                                

                            {AccessRightFlag === '1' ?
                                <>
                                    {OrgUserAccessModules.MyProfile === 2 ?
                                        <div className="hand_cursor continue__shipping--btn secondary__btn border-radius-5 profile-btn-p" onClick={(e) => props.setTab('edit-social-media-accounts')} >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height={24}
                                                viewBox="0 -960 960 960"
                                                width={24}>
                                                <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                                            </svg> <p className="m-0">Edit</p>
                                        </div>
                                    : null }
                                </>
                            : 
                                <div className="hand_cursor continue__shipping--btn secondary__btn border-radius-5 profile-btn-p" onClick={(e) => props.setTab('edit-social-media-accounts')} >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height={24}
                                        viewBox="0 -960 960 960"
                                        width={24}>
                                        <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                                    </svg> <p className="m-0">Edit</p>
                                </div>
                            }
                            
                            <div className=" secondary__btn border-radius-5 event-card-btn" style={{ cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z" /></svg></div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-2">
                            <div className="profile-card">
                                <div className="card-body p-5 text-center" onClick={(e) => handleSocialAccounts(Userdata.facebook_link)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 48 48"
                                        width="50px"
                                        height="50px"
                                    >
                                        <path
                                            fill="#039be5"
                                            d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                                        />
                                        <path
                                            fill="#fff"
                                            d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                                        />
                                    </svg>
                                    <p className="h4">Facebook</p>
                                    <div>
                                        <small>{Userdata.facebook_link}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-2">
                            <div className="profile-card">
                                <div className="card-body p-5 text-center" onClick={(e) => handleSocialAccounts(Userdata.twitter_profile_link)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 48 48"
                                        width="50px"
                                        height="50px"
                                    >
                                        <path
                                            fill="#03A9F4"
                                            d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                                        />
                                    </svg>
                                    <p className="h4">Twitter</p>
                                    <div>
                                        <small>{Userdata.twitter_profile_link}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 my-2">
                            <div className="profile-card">
                                <div className="card-body p-5 text-center" onClick={(e) => handleSocialAccounts(Userdata.linkedin_profile_link)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 48 48"
                                        width="50px"
                                        height="50px"
                                    >
                                        <path
                                            fill="#0288D1"
                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                        />
                                    </svg>
                                    <p className="h4">Linkedin</p>
                                    <div>
                                        <small>{Userdata.linkedin_profile_link}</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className={Section === 'health-details' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none"}>
                <div className="section__header d-flex align-items-center justify-content-between mb-4">
                    <h2 className="section__header--title h3">
                        Medical Profile
                    </h2>
                    Last Updated At : {Userdata.updated_at}
                    <div className="d-flex gap-4">
                        
                        {AccessRightFlag === '1' ?
                            <>
                                {OrgUserAccessModules.MyProfile === 2 ?
                                    <div className="hand_cursor continue__shipping--btn secondary__btn border-radius-5 profile-btn-p"
                                    onClick={(e) => props.setTab('edit-health-details')} >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={24}
                                            viewBox="0 -960 960 960"
                                            width={24} >
                                            <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                                        </svg> <p className="m-0">Edit</p>
                                    </div>
                                : null }
                            </>
                        : 
                            <div className="hand_cursor continue__shipping--btn secondary__btn border-radius-5 profile-btn-p"
                            onClick={(e) => props.setTab('edit-health-details')} >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={24}
                                    viewBox="0 -960 960 960"
                                    width={24} >
                                    <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                                </svg> <p className="m-0">Edit</p>
                            </div>
                        }

                        <div className=" secondary__btn border-radius-5 event-card-btn" style={{ cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z" /></svg></div>
                    </div>
                </div>

                <div className="row p-0 mb-5 mt-4">
                    <h4 className="">Physical Attributes</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">What is your blood group?</h4>
                            <p className="m-4">{Userdata.blood_group ? Userdata.blood_group : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">What is your height</h4>
                            <p className="m-4">{Userdata.height ? Userdata.height : "NA"} cm</p>
                        </div>
                    </div>

                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">What is your current weight</h4>
                            <p className="m-4">{Userdata.weight ? Userdata.weight : "NA"} Kg</p>
                        </div>
                    </div>

                </div>

                <div className="row p-0 mb-5 mt-4">
                    <h4 className="">Medical Conditions</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Medical Conditions</h4>
                            <p className="m-4"> {Userdata.medical_conditions ? Userdata.medical_conditions : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Do you have diabetes?</h4>
                            <p className="m-4">{Userdata && Userdata.diabetes !== undefined ? (Userdata.diabetes === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you experienced chest pain in the last 6 weeks?</h4>
                            <p className="m-4">{Userdata && Userdata.chestpain !== undefined ? (Userdata.chestpain === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you ever been diagnosed with angina?</h4>
                            <p className="m-4">{Userdata && Userdata.angina !== undefined ? (Userdata.angina === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you experienced abnormal heart rhythms?</h4>
                            <p className="m-4">{Userdata && Userdata.abnormalheartrhythm !== undefined ? (Userdata.abnormalheartrhythm === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Do you have a pacemaker?</h4>
                            <p className="m-4">{Userdata && Userdata.pacemaker !== undefined ? (Userdata.pacemaker === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you suffered from severe dehydration in the last 4 weeks?</h4>
                            <p className="m-4">{Userdata && Userdata.dehydrationseverity !== undefined ? (Userdata.dehydrationseverity === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you experienced severe muscle cramps in the last 4 weeks?</h4>
                            <p className="m-4">{Userdata && Userdata.musclecramps !== undefined ? (Userdata.musclecramps === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you been diagnosed with high blood pressure?</h4>
                            <p className="m-4">{Userdata && Userdata.highbloodpressure !== undefined ? (Userdata.highbloodpressure === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you had episodes of low blood sugar in the last 4 weeks?</h4>
                            <p className="m-4">{Userdata && Userdata.lowbloodsugar !== undefined ? (Userdata.lowbloodsugar === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you been diagnosed with epilepsy?</h4>
                            <p className="m-4"> {Userdata && Userdata.epilepsy !== undefined ? (Userdata.epilepsy === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Do you have any bleeding disorders?</h4>
                            <p className="m-4">{Userdata && Userdata.bleedingdisorders !== undefined ? (Userdata.bleedingdisorders === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Do you suffer from asthma?</h4>
                            <p className="m-4">{Userdata && Userdata.asthma !== undefined ? (Userdata.asthma === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you been diagnosed with anemia?</h4>
                            <p className="m-4">{Userdata && Userdata.anemia !== undefined ? (Userdata.anemia === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you been hospitalized recently?</h4>
                            <p className="m-4">{Userdata && Userdata.hospitalized !== undefined ? (Userdata.hospitalized === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    {Userdata && Userdata.hospitalized === 1 ?
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Hospitalization Details</h4>
                                <p className="m-4">{Userdata && Userdata.hospitalization_details !== undefined ? Userdata.hospitalization_details : "NA"}</p>
                            </div>
                        </div>
                        : null
                    }

                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Are you currently experiencing any infections?</h4>
                            <p className="m-4">{Userdata && Userdata.infections !== undefined ? (Userdata.infections === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Are you pregnant?</h4>
                            <p className="m-4">{Userdata && Userdata.pregnant !== undefined ? (Userdata.pregnant === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    {Userdata && Userdata.pregnant === 1 ?
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Stage Pregnancy</h4>
                                <p className="m-4">{Userdata.stage_pregnancy ? Userdata.stage_pregnancy : "NA"}</p>
                            </div>
                        </div>
                        : null
                    }

                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Have you suffered from Covid-19?</h4>
                            <p className="m-4">{Userdata && Userdata.covidstatus !== undefined ? (Userdata.covidstatus === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                </div>

                <div className="row p-0 mb-5 mt-4">
                    <h4 className="">Medication and Allergies</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Are you currently under any medication?</h4>
                            <p className="m-4">{Userdata && Userdata.undermedication !== undefined ? (Userdata.undermedication === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    {Userdata && Userdata.undermedication === 1 ?
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Medication Details</h4>
                                <p className="m-4">{Userdata.meditaion_details ? Userdata.meditaion_details : "NA"}</p>
                            </div>
                        </div>
                        : null
                    }
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Current Medications</h4>
                            <p className="m-4">{Userdata && Userdata.currentmedications !== undefined ? (Userdata.currentmedications === 1 ? "Yes" : "No") : "NA"}</p>
                        </div>
                    </div>
                    {Userdata && Userdata.currentmedications === 1 ?
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Medications Name</h4>
                                <p className="m-4">{Userdata.current_medication_names ? Userdata.current_medication_names : "NA"}</p>
                            </div>
                        </div>
                        : null
                    }

                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Do you have any known allergies?</h4>
                            <p className="m-4">{Userdata.allergies ? Userdata.allergies : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Do you have any known drug allergies?</h4>
                            <p className="m-4">{Userdata && Userdata.drugallergy !== undefined ? (Userdata.drugallergy === 1 ? "Yes" : "No") : "NA"}</p>

                        </div>
                    </div>
                    {Userdata && Userdata.drugallergy === 1 ?
                        <div className="col-sm-6 my-3">
                            <div className='profile-card text-center'>
                                <h4 className="m-4">Drug Allergy Details</h4>
                                <p className="m-4 ">{Userdata.drug_allergy_details ? Userdata.drug_allergy_details : "NA"}</p>
                            </div>
                        </div>
                        : null
                    }
                </div>

                <div className="row p-0 mb-5 mt-4">
                    <h4 className="">Healthcare Providers</h4>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Family Doctor Name</h4>
                            <p className="m-4"> {Userdata && Userdata.familydoctorname !== undefined ? Userdata.familydoctorname : "NA"}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 my-3">
                        <div className='profile-card text-center'>
                            <h4 className="m-4">Family Doctor Contact Number</h4>
                            <p className="m-4">{Userdata && (Userdata.familydoctorcontactno !== undefined || Userdata.familydoctorcontactno !== 0) ? Userdata.familydoctorcontactno : "NA"}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={Section === 'communication_settings' ? "row justify-content-center py-5 communication-s" : "row justify-content-center py-5 communication-s d-none"}>
                <div className="section__header d-flex align-items-center justify-content-between mb-4">
                    <h2 className="section__header--title h3">
                        Communication Settings
                    </h2>
                    <div className="hand_cursor continue__shipping--btn secondary__btn border-radius-5"
                        onClick={(e) => props.setTab('edit-communication_settings')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={24}
                            viewBox="0 -960 960 960"
                            width={24}
                        >
                            <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                        </svg>
                        Edit
                    </div>
                </div>
                <div className="col-sm-4 my-2">
                    <div className="profile-card">
                        <div className="card-body p-5 text-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height={50}
                                viewBox="0 -960 960 960"
                                width={50}
                            >
                                <path d="M80-560q0-100 44.5-183.5T244-882l47 64q-60 44-95.5 111T160-560H80Zm720 0q0-80-35.5-147T669-818l47-64q75 55 119.5 138.5T880-560h-80ZM160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" />
                            </svg>
                            <p className="h4">Email notification frequency</p>
                            <small>{Userdata.email_notification_frequency ? "Every Transaction" : "Daily Summary"}</small>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 my-2">
                    <div className="profile-card">
                        <div className="card-body p-5 text-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height={50}
                                viewBox="0 -960 960 960"
                                width={50}
                            >
                                <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                            </svg>
                            <p className="h4">Support Email Id </p>
                            <small>{Userdata.support_email_id ? Userdata.support_email_id : "NA"}</small>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 my-2">
                    <div className="profile-card">
                        <div className="card-body p-5 text-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height={50}
                                viewBox="0 -960 960 960"
                                width={50}
                            >
                                <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                            </svg>
                            <p className="h4">Support Phone No.</p>
                            <a href="">
                                <small>{Userdata.support_mobile ? Userdata.support_mobile : "NA"}</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className={Section === 'family-members' ? "row justify-content-center py-5 communication-s" : "row justify-content-center py-5 communication-s d-none"}>
                <div className="section__header d-flex align-items-center justify-content-between mb-4">
                    <h2 className="section__header--title h3">
                        Family Members
                    </h2>
                    <div
                        className="continue__shipping--btn secondary__btn border-radius-5"
                        onClick={(e) => props.setTab('edit-family-members')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={24}
                            viewBox="0 -960 960 960"
                            width={24}
                        >
                            <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                        </svg>
                        Edit
                    </div>
                </div>

                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Relation      : {Userdata.relation ? Userdata.relation : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Firstname       : {Userdata.rel_firstname ? Userdata.rel_firstname : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Lastname      : {Userdata.rel_lastname ? Userdata.rel_lastname : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Mobile Number       : {Userdata.rel_mobile ? Userdata.rel_mobile : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Email ID       : {Userdata.rel_email ? Userdata.rel_email : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Gender       : {Userdata.rel_gender ? Userdata.rel_gender : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Date Of Birth       : {Userdata.rel_dob ? Userdata.rel_dob : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Id Proof Type    : {RelIdProofType ? RelIdProofType : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Id Proof Number    : {Userdata.rel_id_proof_no ? Userdata.rel_id_proof_no : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                    <p className="m-4 h4">Id Proof Document  : {Userdata.rel_id_proof_doc ? <img src={Userdata.rel_id_proof_doc} alt='' style={{ 'height': "50px", 'width': '50px' }} /> : "NA"}</p>
                </div>
                <div className="col-sm-4 my-2">
                </div>
                <div className="col-sm-4 my-2">
                </div>
            </div> */}
            {isOpen && (
                <Lightbox
                    mainSrc={imageIndex === 0 ? Userdata.id_proof_doc_upload : Userdata.address_proof_doc_upload} // Determine which image to show based on imageIndex
                    onCloseRequest={() => setIsOpen(false)}
                    reactModalStyle={{
                        overlay: { zIndex: 9999 }
                    }}
                />
            )}

        </>
    )
}

export default MyProfile

