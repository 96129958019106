import React from 'react'
import LoaderImg from './img/logo/running.gif'

const Loader = () => {
    // console.log("loader");
    return (
        <div>
            <div id="loader" style={{ backgroundImage: `url(${LoaderImg})` }}></div>
        </div>
    )
}

export default Loader