import React, { useState, useEffect, useCallback } from 'react';
import axios from "../axios";
import { useNavigate, useLocation } from 'react-router-dom';
import ShareEvent from './ShareEvent';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import EventView from '../img/events/event-view.jpg'
import BannerView from '../img/banner/banner-bg-2.png'
import NoEventImg from '../img/events/no-events.png'
import HeaderSecond from '../HeaderSecond';
import NewsLetter from '../NewsLetter';
import Footer from '../Footer';
import Wrapper from '../Wrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { token } from "../features/TokenSlice";

const EventDetails = () => {
  // console.log(props.EventDetailsId,props.EventViewFlag);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [EventData, setEventData] = useState([]);
  const [EventId, setEventId] = useState(0);
  const [OrganiserName, setOrganiserName] = useState("");
  const [OrganiserId, setOrganiserId] = useState("");
  const [UserId, setUserId] = useState("");
  const [Login, setLogin] = useState(false);
  const [Faq, setFaq] = useState([]);
  // console.log(EventId);
  const [ApiCall, setApiCall] = useState(true);
  const [ShareEventFlag, setShareEventFlag] = useState(false);
  const [EventName, setEventName] = useState('');
  const [EventIdShareEvent, setEventIdShareEvent] = useState('');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [AllTickets, setAllTickets] = useState([])
  // console.log(props.EventDetailsId,location);

  useEffect(() => {
    if (location.state) {
      if (location.state.event_id) {
        // console.log(location.state.event_id);
        setEventId(location.state.event_id)
      }
    }
  }, [location.state])

  useEffect(() => {
    if (EventId !== 0) {
      window.scrollTo(0, 0);
      const payload = {
        "event_id": EventId ? EventId : (localStorage.getItem("EventDetailsId") ? localStorage.getItem("EventDetailsId") : 0)
      }

      if (EventId !== 0) {
        axios.post("event_details_page", payload)
          .then((response) => {
            // console.log(response.data.data.EventData[0]);
            setEventData(response.data.data.EventData[0]);
            setAllTickets(response.data.data.AllTickets);
            setOrganiserName(response.data.data.OrganiserName)
            setUserId(response.data.data.UserId)
            setOrganiserId(response.data.data.OrganiserId)
            setFaq(response.data.data.FAQ)
            localStorage.setItem("BookTicketEventId", response.data.data.EventData[0].id);
            setApiCall(false);

          })
          .catch((error) => {
            console.log(error);
          })
      }

    }

  }, [ApiCall, EventId])

  //console.log(EventData);
  const handleBack = useCallback(() => {
    localStorage.removeItem('EventViewFlag');
    localStorage.removeItem('EventDetailsId');
    // props.setCityName(localStorage.getItem("City")) ? localStorage.getItem("City") : localStorage.getItem("Country")
    // props.setEventViewFlag(false)
    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = (localStorage.getItem("City")) ? localStorage.getItem("City") : localStorage.getItem("Country");
    navigate('/' + CountryCode + '/' + CountryName);
  }, [navigate])

  useEffect(() => {
    const handleBackButton = () => {
      // Perform actions when back button is clicked
      // console.log('Back button clicked');
      // You can also access the previous URL using window.location.href
      // console.log('Previous URL:', window.location.href);

      localStorage.removeItem('EventViewFlag');
      localStorage.removeItem('EventDetailsId');
      // props.setCityName(localStorage.getItem("City")) ? localStorage.getItem("City") : localStorage.getItem("Country")
      // props.setEventViewFlag(false)
      const CountryCode = localStorage.getItem('CountryCode');
      const CountryName = (localStorage.getItem("City")) ? localStorage.getItem("City") : localStorage.getItem("Country");
      navigate('/' + CountryCode + '/' + CountryName);
    };

    // Listen for popstate event
    window.addEventListener('popstate', handleBackButton);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  const handleWishList = (eventId, is_follow) => {
    // alert(is_follow)
    // is_follow == 0 then need to follow means add entry in table
    // is_follow == 1 then need to unfollow means delete the entry form table
    const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
    if (RacesToken) {
      const payload = {
        "event_id": eventId,
        "is_follow": is_follow
      }
      axios.post("/follow", payload)
        .then((response) => {
          if (response.status === 200) {
            // console.log("here");
            setApiCall(true);
            toast.success(response.data.message, {
              className: 'toast-success'
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error("Error occured while adding to wishlist", {
              className: 'toast-error'
            })
            console.log('Error occured while adding to wishlist')
          }
        })
    } else {
      setLogin(true);
    }
  }

  const handleRegisterNow = (e) => {
    const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";

    if (RacesToken) {
      const payload = {
        "user_id": localStorage.getItem('ID') ? localStorage.getItem('ID') : 0,
      }
      axios.post("/checkUserLastLoginDetails", payload)
        .then((response) => {
          if (response.status === 200) {
            //  console.log(response.data.data);
            if(response.data.data === 1){
              toast.error("Login session expired");
              const timer = setTimeout(() => {
                //--------------- Logout user
                localStorage.removeItem("Token");
                localStorage.removeItem("Name");
                localStorage.removeItem("Modules");
                localStorage.removeItem("ID");
                localStorage.removeItem("booking_pay_id");
                localStorage.removeItem("RacesToken");
                localStorage.removeItem("LoginAsOrganiser");
                localStorage.removeItem("Access_Right_Flag");
                localStorage.removeItem("OrgUserAccessModules");
                localStorage.removeItem("Role_Name");
                localStorage.removeItem("SuperAdmin");
                localStorage.removeItem("EMAIL");
                localStorage.removeItem("MOBILE");
                localStorage.removeItem("EventDetailsId");
                localStorage.removeItem("RegEventId");
                dispatch(token(""));
                // navigate('/');
                setLogin(true);
                }, 4000); // Adjust the delay as needed (in milliseconds)
              return () => clearTimeout(timer);
            }else{
              if (RacesToken) {
                navigate("/register_now/" + EventId)
              } else {
                setLogin(true);
              }
            }
          }
        })
        .catch((error) => {
           console.log('error');
        })
    }else{
       setLogin(true);
    }
   //console.log(Login);
    // if (RacesToken) {
    //   navigate("/register_now/" + EventId)
    // } else {
    //   setLogin(true);
    // }
  }

  const handleShareEvent = (eventId, eventName) => {
    setShareEventFlag(true);
    setEventName(eventName);
    setEventIdShareEvent(eventId);
  }

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const renderDescription = (description) => {
    return { __html: description };
  };

  const ViewOrganizer = (e, OrganiserName) => {
    const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
    if (RacesToken) {
      const formattedOrganiserName = OrganiserName.replace(/\s/g, '_'); // Replace spaces with underscores
      navigate('/o/' + formattedOrganiserName + '/' + UserId);
    } else {
      setLogin(true);
    }
  }

  const [pageRefreshed, setPageRefreshed] = useState(false);

  useEffect(() => {
    // Check if the current location is different from the previous one
    const isPageRefreshed = window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD;
    // If the page is refreshed, update the state
    if (isPageRefreshed) {
      setPageRefreshed(true);
    }
  }, []);

  useEffect(() => {
    if (pageRefreshed) {
      // navigate('/events', { state: [] });
      localStorage.setItem("EventDetailsId", EventId)
      setApiCall(true);
    }
  }, [EventId, navigate, pageRefreshed]);

  // console.log(BannerView);
  //------------------ Page view count
  const [IpAddress, setIpAddress] = useState('');
  
  useEffect(() => {
    // Function to fetch the network IP address
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api64.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
        
        if (data) {
          axios.post('pageViewDetails',
            {
              'event_id': localStorage.getItem("EventDetailsId"),
              'ip_address': data.ip,
            }
          )
            .then((response) => {
             //console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
            })
        }
        
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
    // Call the fetch function
    fetchIpAddress();
  }, []);

  return (
    <>
      <>
        <ToastContainer theme="colored" />
        <HeaderSecond Login={Login} setLogin={setLogin} ClosedFlag={1} />
        
        {ShareEventFlag ? <ShareEvent setShareEventFlag={setShareEventFlag} EventName={EventName} EventIdShareEvent={EventIdShareEvent} /> : null}
        {/* Start Advertisement banner video */}
        <div className="banner__video--section">
          <div className="">
            {/* <div className="banner__video--thumbnail position__relative event-details-banner-bg" style={{ backgroundImage: `url(${EventData.background_image})` }}>
              <img className="banner__video--thumbnail__img display-block event-details-img" src={EventData.banner_image || EventView} alt="banner-bideo-thumbnail" />
            </div> */}
             <div className="banner__video--thumbnail position__relative event-details-banner-bg"  style={{ backgroundImage: `url(${BannerView})`, filter: 'drop-shadow(0 8px 18px rgba(70, 70, 70, 0.16))' }}>
              <img className="banner__video--thumbnail__img display-block event-details-img" src={EventData.banner_image || EventView} alt="banner-bideo-thumbnail" />
            </div>
          </div>
        </div>
        {/* End Advertisement banner video */}
        {/* Start points with icon */}
        <section className="shipping__section shipping_mt position__relative section--padding pt-0 pb-4">
          <div className="container">
            <div className="shipping__section--inner border-radius-10">
              <div className="row justify-content-between">
                <div className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 col-xxl-8 gap-4 my-0">
                  <h4 className="section__heading--maintitle h3 mb-3">{(EventData.name)}</h4>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item" onClick={(e) => handleBack()}>Home</li>
                      <li className="breadcrumb-item" onClick={(e) => handleBack()}>Events</li>
                      {/* <li className="breadcrumb-item active" aria-current="page">{(EventData.name)}</li> */}
                    </ol>
                  </nav>
                </div>

                <div className="col-12 col-sm-3 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-end gap-4 my-0">
                  <div className="gap-3 like-event" onClick={(e) => handleWishList(EventData.id, EventData.is_follow)}>
                    {EventData.is_follow ?
                      <svg xmlns="http://www.w3.org/2000/svg" height={35} width={35} viewBox="0 -960 960 960"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" /></svg>
                      :
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height={35}
                        viewBox="0 -960 960 960"
                        width={35}
                      >
                        <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                      </svg>
                    }
                  </div>
                  <div className="gap-3 share-event" onClick={(e) => handleShareEvent(EventData.id, EventData.name)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={35}
                      viewBox="0 -960 960 960"
                      width={35}
                    >
                      <path d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End points with icon */}
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <div className="main checkout__mian">
                  <div className="checkout__content--step section__shipping--address py-3">

                    <div className="mb-5">
                      <div className="section__header checkout__section--header mb-4">
                        <h2 className="section__header--title h3">
                          <svg className="pr-3" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" /></svg> Description</h2>
                      </div>
                      <div className="section__body px-4">

                        <div dangerouslySetInnerHTML={renderDescription(EventData.event_description)}></div>
                      </div>
                    </div>

                  </div>

                  <div className="checkout__content--step section__shipping--address py-3">
                    <div className="section__heading mb-4">
                      <h2 className="section__header--title h3">
                        <svg className="pr-3" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m600-120-240-84-186 72q-20 8-37-4.5T120-170v-560q0-13 7.5-23t20.5-15l212-72 240 84 186-72q20-8 37 4.5t17 33.5v560q0 13-7.5 23T812-192l-212 72Zm-40-98v-468l-160-56v468l160 56Zm80 0 120-40v-474l-120 46v468Zm-440-10 120-46v-468l-120 40v474Zm440-458v468-468Zm-320-56v468-468Z" /></svg> Location</h2>
                    </div>
                    {/* {console.log(EventData)} */}
                    <div className="section__body px-4">
                      {(() => {
                        const lat = EventData.latitude ? EventData.latitude : '19.99727000';
                        const long = EventData.longitude ? EventData.longitude : '73.79096000';

                       // const src = `https://www.google.com/maps?q=${lat},${long}&z=15&t=m&output=embed&markers=color:red%7Clabel:P%7C${lat},${long}&persist=true`;
                        const src = EventData.google_map_link;
                        


                        // const pincode = EventData.pincode ? EventData.pincode : '123456'; // Example pincode
                        // const searchQuery = encodeURIComponent(`${pincode}, India`); // Construct search query

                        // const src = `https://www.google.com/maps/place/${searchQuery}`;

                        return (
                          <iframe
                            title='Event'
                            src={src}
                            style={{ width: "100%", height: 300, borderRadius: 10 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                          />
                        );
                      })()}
                    </div>

                    {/* {() => {
                      return (
                        <div className="section__body px-4">
                          <div>
                            <input type="text" value={address} onChange={handleAddressChange} placeholder="Enter address" />
                            <button onClick={handleSearch}>Search</button>
                          </div>
                          <iframe
                            title='Event'
                            src={src}
                            style={{ width: "100%", height: 300, borderRadius: 10 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                          />
                        </div>
                      );
                    }} */}

                  </div>
                  <br />

                  <div className="checkout__content--step section__shipping--address py-3">
                    <div className="section__header checkout__section--header d-flex mb-4">
                      <h2 className="section__header--title h3">
                        <svg className="pr-3" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M200-120v-160h-80v-80h80v-166L88-440l-48-64 440-336 440 336-48 64-112-86v166h80v80h-80v160h-80v-160H520v160h-80v-160H280v160h-80Zm80-240h160v-349L280-587v227Zm240 0h160v-227L520-709v349Z" /></svg> Organiser</h2>
                    </div>
                    <div className="section__body px-4" onClick={(e) => ViewOrganizer(e, OrganiserName)}>
                      {/* <Link to={"/register_now"} className="w-100 px-4"> */}
                      <h2 className="section__header--title h5 org-title-link">{OrganiserName}</h2>
                      {/* </Link> */}
                    </div>
                  </div>
                  <br />

                  {/* FAQ Details */}
                  {Faq && Faq.length > 0 ?
                    <div className="checkout__content--step section__shipping--address py-3">
                      <div className="section__header checkout__section--header d-flex mb-4">
                        <h2 className="section__header--title h3">
                          <svg className="pr-3" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M560-360q17 0 29.5-12.5T602-402q0-17-12.5-29.5T560-444q-17 0-29.5 12.5T518-402q0 17 12.5 29.5T560-360Zm-30-128h60q0-29 6-42.5t28-35.5q30-30 40-48.5t10-43.5q0-45-31.5-73.5T560-760q-41 0-71.5 23T446-676l54 22q9-25 24.5-37.5T560-704q24 0 39 13.5t15 36.5q0 14-8 26.5T578-596q-33 29-40.5 45.5T530-488ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" /></svg> FAQ's</h2>
                      </div>
                      <div className="section__body px-4">
                        <div className="accordion" id="accordionExample">
                          {
                            Faq && Faq.length > 0 ?
                              Faq.map((faq, index) => (
                                <div className="accordion-item" key={index}>
                                  <h2 className="accordion-header" id={`heading${index}`}>
                                    <button
                                      className="accordion-button h4"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${index}`}
                                      aria-expanded="false" // Changed to false
                                      aria-controls={`collapse${index}`}
                                    >
                                      {index + 1}{". "} {faq.question}
                                    </button>
                                  </h2>

                                  <div
                                    id={`collapse${index}`}
                                    className="accordion-collapse collapse" // Removed 'show' class
                                    aria-labelledby={`heading${index}`}
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <b>{"Ans. "}</b>{faq.answer}
                                    </div>
                                  </div>
                                </div>
                              )) : 'No FAQs available.'
                          }
                        </div>
                      </div>
                    </div>
                    : null}
                  {/* FAQ Details */}
                  <br />
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <aside className="checkout__sidebar sidebar border-radius-10">
                 {  EventData.event_overall_limit_flag === 0 ?
                    <div className="product__details--info__price py-4 text-center">

                      {EventData.TicketDetails && EventData.TicketDetails.length > 0 && (
                        EventData.TicketDetails.some(ticket => parseInt(ticket.ticket_status) === 2) ? (
                          <span className="current__price m-0">Free</span>
                        ) : (
                          EventData.TicketDetails.map((ticket, index) => (
                            parseInt(ticket.ticket_status) === 1 && (
                              <span key={index}>
                                {index > 0 && " - "}
                                {ticket.show_early_bird === 1 ? (
                                  <span className="text-left">
                                    <span className="ticket-prices">
                                      <span className="original-price"> ₹{ticket.ticket_price}</span>&nbsp;
                                      <span className="current__price m-0">₹{ticket.discount_ticket_price}</span>
                                    </span>
                                  </span>
                                ) : (
                                  <span className="current__price m-0">₹{ticket.ticket_price}</span>
                                )}
                              </span>
                            )
                          ))
                        )
                      )}
                    </div>
                    : ''
                  }

                  {/* {console.log(EventData.show_registration_button)} */}
                  {  EventData.event_overall_limit_flag === 1 ?
                   <p className="m-0 h4 mt-3 text-center">The maximum number of registrations has been reached.</p>
                   :
                    parseInt(EventData.show_registration_button) === 1 ?
                    <div className="header__account--items m-0 create-event py-4 cursor-pointer">
                      <div
                        className="crate-event-btn d-flex align-items-center gap-2 justify-content-center summary-proceed mobile-btn-ui"
                        rel="nofollow"
                        onClick={(e) => handleRegisterNow(e)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={24}
                          viewBox="0 -960 960 960"
                          width={24}
                        >
                          <path d="M80-160v-112q0-33 17-62t47-44q51-26 115-44t141-18q30 0 58.5 3t55.5 9l-70 70q-11-2-21.5-2H400q-71 0-127.5 17T180-306q-9 5-14.5 14t-5.5 20v32h250l80 80H80Zm542 16L484-282l56-56 82 82 202-202 56 56-258 258ZM400-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm10 240Zm-10-320q33 0 56.5-23.5T480-640q0-33-23.5-56.5T400-720q-33 0-56.5 23.5T320-640q0 33 23.5 56.5T400-560Zm0-80Z" />
                        </svg>
                      
                          <p className="m-0 d-block">Register Now</p>
                      
                      </div>
                      {/* </Link> */}
                    </div>
                    :
                    <>
                      <p className="m-0 h4 mt-3 text-center">{EventData.show_registration_button_msg}</p>
                    </>
                  }

                  <div className="card px-4 my-4">
                    <fieldset className="variant__input--fieldset">
                      {/* <legend className="product__variant--title mb-3">Schedule :</legend> */}
                      <div className="row my-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="event-details-aside-title m-0"><svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                            viewBox="0 -960 960 960"
                            width={20}
                          >
                            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
                          </svg> Starts On</p>
                          <p className="m-0 h4 mt-3">{EventData.start_date}{" "}{EventData.start_time_event}</p>
                        </div>

                      </div>
                    </fieldset>
                  </div>

                  <div className="card px-4 my-4">
                    <fieldset className="variant__input--fieldset">
                      {/* <legend className="product__variant--title mb-3">Schedule :</legend> */}
                      <div className="row my-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="event-details-aside-title m-0"><svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                            viewBox="0 -960 960 960"
                            width={20}
                          >
                            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
                          </svg> Ends On</p>
                          <p className="m-0 h4 mt-3">{EventData.end_date}{" "}{EventData.end_date_event}</p>
                        </div>

                      </div>
                    </fieldset>
                  </div>

                  <div className="card px-4 my-4">
                    <fieldset className="variant__input--fieldset">
                      {/* <legend className="product__variant--title mb-3">Schedule :</legend> */}
                      <div className="row my-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="event-details-aside-title m-0"><svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                            viewBox="0 -960 960 960"
                            width={20}
                          >
                            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
                          </svg> Registration starting at</p>
                          <p className="m-0 h4 mt-3">{EventData.registration_start_date}{" "}{EventData.registration_start_date_time}</p>
                        </div>

                      </div>
                    </fieldset>
                  </div>

                  <div className="card px-4 my-4">
                    <fieldset className="variant__input--fieldset">
                      {/* <legend className="product__variant--title mb-3">Schedule :</legend> */}
                      <div className="row my-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="event-details-aside-title m-0"><svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                            viewBox="0 -960 960 960"
                            width={20}
                          >
                            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
                          </svg> Registration ending on</p>
                          <p className="m-0 h4 mt-3">{EventData.registration_end_date}{" "}{EventData.registration_end_date_time}</p>
                        </div>

                      </div>
                    </fieldset>
                  </div>

                  <div className="card px-4 mb-4">
                    <fieldset className="variant__input--fieldset">
                      {/* <legend className="product__variant--title mb-3">Venue :</legend> */}
                      <div className="row my-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="m-0 event-details-aside-title"><svg
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                            viewBox="0 -960 960 960"
                            width={20}
                          >
                            <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                          </svg> Venue</p>

                          <p className="m-0 h4 mt-3">{EventData.address !== null ? EventData.address + ", " : ""}
                            {" " + EventData.city_name},{" " + EventData.state_name},{" " + EventData.country_name}.</p>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="card px-4 mb-4">
                    <fieldset className="variant__input--fieldset">
                      <div className="row my-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="m-0 event-details-aside-title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height={20}
                              viewBox="0 -960 960 960"
                              width={20}
                            >
                              <path d="M315-240q-77 0-117-57t-38-128l-18-27q-11-17-36.5-77T80-680q0-103 51-171.5T260-920q85 0 132.5 75.5T440-680q0 58-16 107t-28 79l8 13q8 14 22 44.5t14 63.5q0 57-35.5 95T315-240ZM210-496l110-22q13-32 26.5-73t13.5-89q0-60-27.5-110T260-840q-45 0-72.5 50T160-680q0 63 17.5 111.5T210-496Zm105 176q19 0 32-14t13-39q0-17-8-35t-16-32l-96 20q0 40 17.5 70t57.5 30ZM645-40q-54 0-89.5-38T520-173q0-33 14-63.5t22-44.5l8-13q-12-30-28-79t-16-107q0-89 47.5-164.5T700-720q78 0 129 68.5T880-480q0 91-25.5 150.5T818-253l-18 28q1 71-38.5 128T645-40Zm105-256q15-24 32.5-72T800-480q0-60-27.5-110T700-640q-45 0-72.5 50T600-480q0 48 13.5 88.5T640-318l110 22ZM645-120q40 0 57.5-30t17.5-70l-96-20q-8 14-16 32t-8 35q0 20 12.5 36.5T645-120Z" />
                            </svg> Race Category
                          </p>
                          <div className="row variant__size d-flex mt-3">
                            {/* {
                              (EventData.distances && EventData.distances.length > 0 ?
                                EventData.distances.map((item, index) => {
                                  return (
                                    <>
                                      <div key={index} className="col-6 col-sm-4 col-md-6 col-lg-12 col-xl-12 col-xxl-6 variant__size--list m-0 text-center py-2">
                                        <input id="weight1" name="weight" type="radio" disabled />
                                        <label className="py-2 px-4 h4 red" htmlFor="weight1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={20}
                                            viewBox="0 -960 960 960"
                                            width={20}
                                          >
                                            <path d="M360-720h80v-80h-80v80Zm160 0v-80h80v80h-80ZM360-400v-80h80v80h-80Zm320-160v-80h80v80h-80Zm0 160v-80h80v80h-80Zm-160 0v-80h80v80h-80Zm160-320v-80h80v80h-80Zm-240 80v-80h80v80h-80ZM200-160v-640h80v80h80v80h-80v80h80v80h-80v320h-80Zm400-320v-80h80v80h-80Zm-160 0v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm160 0v-80h80v80h-80Zm80-80v-80h80v80h-80Z" />
                                          </svg><br />
                                          {item.distance_name}
                                        </label>
                                      </div>
                                    </>
                                  );
                                }) : null)
                            } */}

                            {
                              AllTickets && AllTickets.length > 0 ?
                                AllTickets.map((item, index) => {
                                  return (
                                    <>
                                      <div key={index} className="col-6 col-sm-4 col-md-6 col-lg-12 col-xl-12 col-xxl-6 variant__size--list m-0 text-center py-2">
                                        <input id="weight1" name="weight" type="radio" disabled />
                                        <label className="py-2 px-4 h4 red" htmlFor="weight1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={20}
                                            viewBox="0 -960 960 960"
                                            width={20}
                                          >
                                            <path d="M360-720h80v-80h-80v80Zm160 0v-80h80v80h-80ZM360-400v-80h80v80h-80Zm320-160v-80h80v80h-80Zm0 160v-80h80v80h-80Zm-160 0v-80h80v80h-80Zm160-320v-80h80v80h-80Zm-240 80v-80h80v80h-80ZM200-160v-640h80v80h80v80h-80v80h80v80h-80v320h-80Zm400-320v-80h80v80h-80Zm-160 0v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm160 0v-80h80v80h-80Zm80-80v-80h80v80h-80Z" />
                                          </svg><br />
                                          {item.ticket_name}
                                        </label>
                                      </div>
                                    </>
                                  );
                                }) : null
                            }
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="card px-4 mb-">
                    <fieldset className="variant__input--fieldset">
                      {/* <legend className="product__variant--title mb-3">Venue :</legend> */}
                      <div className="row my-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="m-0 event-details-aside-title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height={20}
                              viewBox="0 -960 960 960"
                              width={20}
                            >
                              <path d="M666-440 440-666l226-226 226 226-226 226Zm-546-80v-320h320v320H120Zm400 400v-320h320v320H520Zm-400 0v-320h320v320H120Zm80-480h160v-160H200v160Zm467 48 113-113-113-113-113 113 113 113Zm-67 352h160v-160H600v160Zm-400 0h160v-160H200v160Zm160-400Zm194-65ZM360-360Zm240 0Z" />
                            </svg> Category
                          </p>
                          <div className="row variant__size d-flex mt-3">

                            {(EventData.category && EventData.category.length > 0 ?
                              EventData.category.map((item, index) => {
                                return (
                                  <>
                                    <div key={index} className="col-6 col-sm-4 col-md-6 col-lg-12 col-xl-12 col-xxl-6 variant__size--list m-0 text-center py-2">
                                      <input id="weight1" name="weight" type="radio" disabled />
                                      <label className="py-2 px-4 h4 red" htmlFor="weight1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height={20}
                                          viewBox="0 -960 960 960"
                                          width={20}
                                        >
                                          <path d={item.logo} />
                                        </svg> <br /> {item.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              }) : null)
                            }

                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  {/* <div className="py-4">
                    <fieldset className="variant__input--fieldset">
                      <legend className="product__variant--title mb-3">
                        Distance :
                      </legend>
                      <div className="row justify-content-center variant__size d-flex">
                       
                        {
                          (EventData.types && EventData.types.length > 0 ?
                            EventData.types.map((item, index) => {
                              return (
                                <>
                                  <div key={index} className="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-4 col-xxl-4 variant__size--list m-0 text-center py-2">
                                    <input id="weight1" name="weight" type="radio" />
                                    <label className="py-2 px-4 h4 red" htmlFor="weight1">
                                      &lt;{item.name}
                                    </label>
                                  </div>
                                </>
                              );
                            }) : null)
                        }
                      </div>
                    </fieldset>
                  </div> */}

                  {/* EventData.types */}

                  {/* <div className="row justify-content-center gap-4 py-4">
                    <legend className="product__variant--title mb-3">Category :</legend>
                    {
                      (EventData.category && EventData.category.length > 0 ?
                        EventData.category.map((item, index) => {
                          return (
                            <>
                              <div key={index} className="col-4 col-sm-3 col-md-3 col-lg-4 col-xl-4 col-xxl-3 category-badge gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height={18}
                                  viewBox="0 -960 960 960"
                                  width={18}
                                >
                                  <path d={item.logo} />
                                </svg>
                                <p className="m-0 h4">{item.name}</p>
                              </div>
                            </>
                          );
                        }) : null)
                    }
                  </div> */}

                </aside>

              </div>
            </div>
          </div>
        </section>
        {/* Start portfolio section */}
        {
          EventData.event_images && EventData.event_images.length > 0 ?
            <section className="portfolio__section py-5">
              <div className="container">
                <hr />
                <div className="section__heading text-center mb-40">
                  <h2 className="section__heading--maintitle">Gallery</h2>
                </div>
                <div className="portfolio__section--inner">
                  <div className="row ">
                    {
                      EventData.event_images && EventData.event_images.length > 0 ?
                        EventData.event_images.map((image, index) => (
                          <div key={index} className="col-sm-2 mb-30">
                            <div className="portfolio__items">
                              <div className="portfolio__items--thumbnail position__relative">
                                <a
                                  className="portfolio__items--thumbnail__link glightbox"
                                  href={image}
                                  data-gallery="portfolio"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openLightbox(index);
                                  }}
                                >
                                  <img
                                    className="portfolio__items--thumbnail__img"
                                    src={`${image}`}
                                    alt={`portfolio-img-${index}`}
                                  />
                                  <span className="portfolio__view--icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="39.697"
                                      height="27.066"
                                      viewBox="0 0 39.697 27.066"
                                    >
                                      <path
                                        d="M20.849,4.5A21.341,21.341,0,0,0,1,18.033a21.322,21.322,0,0,0,39.7,0A21.341,21.341,0,0,0,20.849,4.5Zm0,22.555a9.022,9.022,0,1,1,9.022-9.022A9.025,9.025,0,0,1,20.849,27.055Zm0-14.435a5.413,5.413,0,1,0,5.413,5.413A5.406,5.406,0,0,0,20.849,12.62Z"
                                        transform="translate(-1 -4.5)"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        ))

                        : <div className="product__section--inner py-5 text-center">
                          <img src={NoEventImg} alt="" style={{ width: 150 }} />
                          <h4 className="pt-4">No gallery images found</h4>
                          <p>
                            Sorry, We couldn't find any images in this event.
                          </p>
                        </div>
                    }
                  </div>
                </div>
              </div>

              {/* {console.log(EventData.event_images[photoIndex])} */}
              {
                isOpen && (
                  <Lightbox
                    mainSrc={`${EventData.event_images[photoIndex]}`}
                    nextSrc={EventData.event_images[(photoIndex + 1) % EventData.event_images.length]}
                    prevSrc={EventData.event_images[(photoIndex + EventData.event_images.length - 1) % EventData.event_images.length]}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() =>
                      setPhotoIndex((photoIndex + EventData.event_images.length - 1) % EventData.event_images.length)
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % EventData.event_images.length)
                    }
                  />
                )}
            </section>
            :
            null}
        {/* End portfolio section */}

        {/* <NewsLetter /> */}
        <Footer />
        <Wrapper />
      </>
    </>
  );
}

export default EventDetails