import React, { useState } from 'react';
import axios from 'axios';

const AddressFetchComponent = () => {
  const [pincode, setPincode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [error, setError] = useState('');

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const fetchAddress = () => {
    if (pincode.length !== 6) {
      setError('Pincode must be 6 digits');
      return;
    }

    axios.get(`https://api.postman.com/postal-codes/${pincode}`)
      .then(response => {
        if (response.data.status === 'success') {
          setCity(response.data.data[0].district);
          setState(response.data.data[0].state);
          setError('');
        } else {
          setError('Pincode not found');
        }
      })
      .catch(error => {
        console.error('Error fetching address:', error);
        setError('Error fetching address. Please try again.');
      });
  };

  return (
    <div>
      <label>
        Enter Pincode:
        <input type="text" value={pincode} onChange={handlePincodeChange} />
      </label>
      <button onClick={fetchAddress}>Fetch Address</button>
      {error && <p>{error}</p>}
      {city && state && (
        <div>
          <p>City: {city}</p>
          <p>State: {state}</p>
        </div>
      )}
    </div>
  );
};

export default AddressFetchComponent;
