import React, { useState } from 'react';
import Logo from "./img/logo/ytcr-logo.png";
import { Link, useNavigate } from 'react-router-dom';
import axios from './axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = (props) => {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [Active, setActive] = useState("");
  
  const toggleActive = (index) => {
    setActive(Active === index ? null : index);
  };

  const handleBackToHome = (e) => {
    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = (localStorage.getItem("City")) ? localStorage.getItem("City") : localStorage.getItem("Country");
    navigate('/' + CountryCode + '/' + CountryName);
  }

  const HandleSubscribe = (e) => {
    e.preventDefault();
    var flag = true;
    if (Email === "") {
      setEmailError("Please enter Email id.");
      flag = false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
      setEmailError('Please enter a valid email address');
      flag = false;
    }

    if (flag) {
      axios.post("add_subscriber", { email: Email })
        .then((response) => {
          // console.log(response.status);
          if (response.status === 200) {
            toast.success(response.data.message);
            setEmail("");
            setEmailError("")
          }
        })
        .catch((error) => {
          if (error.status === 500) {
            toast.error("Internal Server Error!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.message);
          }
        });
    }
  }

  return (
    <>
      {/* Start footer section */}
      <footer className="footer__section footer__bg">
        <div className="container-fluid">
          <div className="main__footer">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className={`footer__widget${Active === 1 ? ' active' : ''}`} onClick={() => toggleActive(1)}>
                  <h2 className="footer__widget--title sm__footer--widget__bock">
                    About Us{" "}
                    <button
                      className="footer__widget--button"
                      aria-label="footer widget button"
                    />
                    <svg
                      className="footer__widget--title__arrowdown--icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.355"
                      height="8.394"
                      viewBox="0 0 10.355 6.394"
                    >
                      <path
                        d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                        transform="translate(-6 -8.59)"
                        fill="currentColor"
                      />
                    </svg>
                  </h2>
                  <div className="footer__widget--inner">
                    <div className="footer__logo cursor-pointer" href="#" onClick={(e) => handleBackToHome(e)} >
                      <img
                        src={`${Logo}`} style={{ "height": "45px" }}
                        alt="footer-logo"
                      />
                    </div>
                    <p className="footer__widget--desc">
                      More than your regular ticketing platform; <br /> We are the biggest and complete running events back-office service.
                    </p>
                    <div className="footer__social cursor-pointer">
                      <ul className="social__shear d-flex">
                        <li className="social__shear--list">
                          <a
                            className="social__shear--list__icon"
                            target="_blank"
                            href="https://www.linkedin.com" rel="noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19.419"
                              height="19.419"
                              viewBox="0 0 19.419 19.419"
                            >
                              <path
                                id="Icon_awesome-linkedin-in"
                                data-name="Icon awesome-linkedin-in"
                                d="M4.347,19.419H.321V6.454H4.347ZM2.332,4.686A2.343,2.343,0,1,1,4.663,2.332,2.351,2.351,0,0,1,2.332,4.686ZM19.415,19.419H15.4V13.108c0-1.5-.03-3.433-2.093-3.433-2.093,0-2.414,1.634-2.414,3.325v6.42H6.869V6.454H10.73V8.223h.056A4.23,4.23,0,0,1,14.6,6.129c4.075,0,4.824,2.683,4.824,6.168v7.122Z"
                                fill="currentColor"
                              />
                            </svg>
                            <span className="visually-hidden">Linkedin</span>
                          </a>
                        </li>
                        <li className="social__shear--list">
                          <a
                            className="social__shear--list__icon"
                            target="_blank"
                            href="https://www.facebook.com" rel="noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.239"
                              height="20.984"
                              viewBox="0 0 11.239 20.984"
                            >
                              <path
                                id="Icon_awesome-facebook-f"
                                data-name="Icon awesome-facebook-f"
                                d="M11.575,11.8l.583-3.8H8.514V5.542A1.9,1.9,0,0,1,10.655,3.49h1.657V.257A20.2,20.2,0,0,0,9.371,0c-3,0-4.962,1.819-4.962,5.112V8.006H1.073v3.8H4.409v9.181H8.514V11.8Z"
                                transform="translate(-1.073)"
                                fill="currentColor"
                              />
                            </svg>
                            <span className="visually-hidden">Facebook</span>
                          </a>
                        </li>
                        <li className="social__shear--list">
                          <a
                            className="social__shear--list__icon"
                            target="_blank"
                            href="https://www.instagram.com" rel="noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19.497"
                              height="19.492"
                              viewBox="0 0 19.497 19.492"
                            >
                              <path
                                id="Icon_awesome-instagram"
                                data-name="Icon awesome-instagram"
                                d="M9.747,6.24a5,5,0,1,0,5,5A4.99,4.99,0,0,0,9.747,6.24Zm0,8.247A3.249,3.249,0,1,1,13,11.238a3.255,3.255,0,0,1-3.249,3.249Zm6.368-8.451A1.166,1.166,0,1,1,14.949,4.87,1.163,1.163,0,0,1,16.115,6.036Zm3.31,1.183A5.769,5.769,0,0,0,17.85,3.135,5.807,5.807,0,0,0,13.766,1.56c-1.609-.091-6.433-.091-8.042,0A5.8,5.8,0,0,0,1.64,3.13,5.788,5.788,0,0,0,.065,7.215c-.091,1.609-.091,6.433,0,8.042A5.769,5.769,0,0,0,1.64,19.341a5.814,5.814,0,0,0,4.084,1.575c1.609.091,6.433.091,8.042,0a5.769,5.769,0,0,0,4.084-1.575,5.807,5.807,0,0,0,1.575-4.084c.091-1.609.091-6.429,0-8.038Zm-2.079,9.765a3.289,3.289,0,0,1-1.853,1.853c-1.283.509-4.328.391-5.746.391S5.28,19.341,4,18.837a3.289,3.289,0,0,1-1.853-1.853c-.509-1.283-.391-4.328-.391-5.746s-.113-4.467.391-5.746A3.289,3.289,0,0,1,4,3.639c1.283-.509,4.328-.391,5.746-.391s4.467-.113,5.746.391a3.289,3.289,0,0,1,1.853,1.853c.509,1.283.391,4.328.391,5.746S17.855,15.705,17.346,16.984Z"
                                transform="translate(0.004 -1.492)"
                                fill="currentColor"
                              />
                            </svg>
                            <span className="visually-hidden">Instagram</span>
                          </a>
                        </li>
                        <li className="social__shear--list">
                          <a
                            className="social__shear--list__icon"
                            target="_blank"
                            href="https://twitter.com" rel="noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height="19.492"
                              viewBox="0 0 24 19.492"
                            >
                              <path
                                id="Icon_awesome-twitter"
                                data-name="Icon awesome-twitter"
                                d="M21.533,7.112c.015.213.015.426.015.64A13.9,13.9,0,0,1,7.553,21.746,13.9,13.9,0,0,1,0,19.538a10.176,10.176,0,0,0,1.188.061,9.851,9.851,0,0,0,6.107-2.1,4.927,4.927,0,0,1-4.6-3.411,6.2,6.2,0,0,0,.929.076,5.2,5.2,0,0,0,1.294-.167A4.919,4.919,0,0,1,.975,9.168V9.107A4.954,4.954,0,0,0,3.2,9.731,4.926,4.926,0,0,1,1.675,3.152,13.981,13.981,0,0,0,11.817,8.3,5.553,5.553,0,0,1,11.7,7.173a4.923,4.923,0,0,1,8.513-3.365A9.684,9.684,0,0,0,23.33,2.619,4.906,4.906,0,0,1,21.167,5.33,9.861,9.861,0,0,0,24,4.569a10.573,10.573,0,0,1-2.467,2.543Z"
                                transform="translate(0 -2.254)"
                                fill="currentColor"
                              />
                            </svg>
                            <span className="visually-hidden">Twitter</span>
                          </a>
                        </li>
                        <li className="social__shear--list">
                          <a
                            className="social__shear--list__icon"
                            target="_blank"
                            href="https://reddit.com" rel="noreferrer"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="9.67" cy="13" r="1.001"/><path d="M14.09 15.391A3.28 3.28 0 0 1 12 16a3.271 3.271 0 0 1-2.081-.63.27.27 0 0 0-.379.38c.71.535 1.582.809 2.471.77a3.811 3.811 0 0 0 2.469-.77v.04a.284.284 0 0 0 .006-.396.28.28 0 0 0-.396-.003zm.209-3.351a1 1 0 0 0 0 2l-.008.039c.016.002.033 0 .051 0a1 1 0 0 0 .958-1.038 1 1 0 0 0-1.001-1.001z" fill="currentColor"/><path d="M12 2C6.479 2 2 6.477 2 12c0 5.521 4.479 10 10 10s10-4.479 10-10c0-5.523-4.479-10-10-10zm5.859 11.33c.012.146.012.293 0 .439 0 2.24-2.609 4.062-5.83 4.062s-5.83-1.82-5.83-4.062a2.681 2.681 0 0 1 0-.439 1.46 1.46 0 0 1-.455-2.327 1.458 1.458 0 0 1 2.063-.063 7.145 7.145 0 0 1 3.899-1.23l.743-3.47v-.004A.313.313 0 0 1 12.82 6l2.449.49a1.001 1.001 0 1 1-.131.61L13 6.65l-.649 3.12a7.123 7.123 0 0 1 3.85 1.23 1.46 1.46 0 0 1 2.469 1c.01.563-.307 1.08-.811 1.33z" fill="currentColor"/></svg>
                            <span className="visually-hidden">Reddit</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 col-xxl-3">
              <div className={`footer__widget${Active === 2 ? ' active' : ''}`} onClick={() => toggleActive(2)}>
                  <h2 className="footer__widget--title ">
                    Quick Links{" "}
                    <button
                      className="footer__widget--button"
                      aria-label="footer widget button"
                    />
                    <svg
                      className="footer__widget--title__arrowdown--icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.355"
                      height="8.394"
                      viewBox="0 0 10.355 6.394"
                    >
                      <path
                        d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                        transform="translate(-6 -8.59)"
                        fill="currentColor"
                      />
                    </svg>
                  </h2>
                  <ul className="footer__widget--menu footer__widget--inner">
                    <li className="footer__widget--menu__list">
                      <Link className="footer__widget--menu__text" to="/disclaimer" >Disclaimer</Link>
                    </li>
                    <li className="footer__widget--menu__list">
                      <Link className="footer__widget--menu__text" to="/terms_conditions" >Terms & Conditions</Link>
                    </li>
                    <li className="footer__widget--menu__list">
                      <Link className="footer__widget--menu__text" to="/privacy_policy" >Privacy Policy</Link>
                    </li>
                    <li className="footer__widget--menu__list">
                      <Link className="footer__widget--menu__text" to="/cancellation_policy" >Cancellation Policy</Link>
                    </li>
                    <li className="footer__widget--menu__list">
                      <Link className="footer__widget--menu__text" to="/contact_us" >Contact Us</Link>
                    </li>
                    <li className="footer__widget--menu__list">
                      <Link className="footer__widget--menu__text" to="/how_it_works" >How It Works?</Link>
                    </li>

                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 col-xxl-3">
              <div className={`footer__widget${Active === 3 ? ' active' : ''}`} onClick={() => toggleActive(3)}>
                  <h2 className="footer__widget--title ">
                    Products & Services{" "}
                    <button
                      className="footer__widget--button"
                      aria-label="footer widget button"
                    />
                    <svg
                      className="footer__widget--title__arrowdown--icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.355"
                      height="8.394"
                      viewBox="0 0 10.355 6.394"
                    >
                      <path
                        d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                        transform="translate(-6 -8.59)"
                        fill="currentColor"
                      />
                    </svg>
                  </h2>
                  <ul className="footer__widget--menu footer__widget--inner">
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://racesregistrations.com/" target='_blank'>
                        Races
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://youtoocanrun.com/race-management-by-youtoocanrun/" target='_blank'>
                        Race Management
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://treasuredmoments.co.in/" target='_blank'>
                        Treasured moments
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://racemart.in/" target='_blank'>
                        Racemart
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://youtoocanrun.com/for-corporates/" target='_blank'>
                        Corporate Wellness
                      </a>
                    </li>
                    
                    {/* celebration Lounge */}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 col-xxl-3">
                <div className={`footer__widget${Active === 4 ? ' active' : ''}`} onClick={() => toggleActive(4)}>
                  <h2 className="footer__widget--title ">
                    {/* Products & Services */}
                    &nbsp;
                    {" "}
                    <button
                      className="footer__widget--button"
                      aria-label="footer widget button"
                    />
                    <svg
                      className="footer__widget--title__arrowdown--icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.355"
                      height="8.394"
                      viewBox="0 0 10.355 6.394"
                    >
                      <path
                        d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                        transform="translate(-6 -8.59)"
                        fill="currentColor"
                      />
                    </svg>
                  </h2>
                  <ul className="footer__widget--menu footer__widget--inner">
                    {/* <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="#">
                        SecuRun
                      </a>
                    </li> */}
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://youtoocanrun.com/celebration-lounge-2/" target='_blank'>
                        Celebration Lounge
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://youtoocanrun.com/rase/" target='_blank'>
                        RASE
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://youtoocanrun.com/youtoocanrun-athlete-id-card/" target='_blank'>
                        Athlete Id Card
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://youtoocanrun.com/2015/10/pace-calculator/" target='_blank'>
                        Pace Calculator
                      </a>
                    </li>
                    <li className="footer__widget--menu__list">
                      <a className="footer__widget--menu__text" href="https://youtoocanrun.com/pacer-teams/" target='_blank'>
                        Pacing Team & Kits
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-12 col-sm-12 col-md-7 col-lg-3 col-xl-3 col-xxl-3">
                <div className="footer__widget">
                  <h2 className="footer__widget--title ">
                    Newsletter{" "}
                    <button
                      className="footer__widget--button"
                      aria-label="footer widget button"
                    />
                    <svg
                      className="footer__widget--title__arrowdown--icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.355"
                      height="8.394"
                      viewBox="0 0 10.355 6.394"
                    >
                      <path
                        d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                        transform="translate(-6 -8.59)"
                        fill="currentColor"
                      />
                    </svg>
                  </h2>
                  <div className="footer__newsletter footer__widget--inner">
                    <p className="footer__newsletter--desc">
                    To get regular event updates, subscribe to our weekly newsletter.
                    </p>
                    <div
                      className="newsletter__subscribe--form__style position__relative"
                      action="#"
                    >
                      <label>
                        <input
                          className="footer__newsletter--input newsletter__subscribe--input"
                          placeholder="Enter your email id"
                          type="email"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </label>
                      <small className="text-danger mb-5">{EmailError}</small>
                      <button
                        className="footer__newsletter--button newsletter__subscribe--button primary__btn"
                        type="submit"
                        onClick={(e) => HandleSubscribe(e)}
                      >
                        Subscribe
                        <svg
                          className="newsletter__subscribe--button__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="9.159"
                          height="7.85"
                          viewBox="0 0 9.159 7.85"
                        >
                          <path
                            data-name="Icon material-send"
                            d="M3,12.35l9.154-3.925L3,4.5,3,7.553l6.542.872L3,9.3Z"
                            transform="translate(-3 -4.5)"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer__bottom d-flex justify-content-between align-items-center">
            <p className="copyright__content">
              Copyright © 2024{" "}
              <a
                className="copyright__content--link"
                // href="https://youtoocanrun.com/"
                target="_blank"
              >
                YooTooCanRun.
              </a>{" "}
              All Rights Reserved. Design By{" "}
              <a className="copyright__content--link" href="https://standardwings.com/" target="_blank">
                StandardWings Technologies Pvt. Ltd.
              </a>
            </p>
            <div className=" invisible footer__payment text-right">
              <img
                className="footer__payment--visa__card display-block"
                src="assets/img/other/payment-visa-card.webp"
                alt="visa-card"
              />
            </div>
          </div>
        </div>
      </footer>


      {/* End footer section */}

      {/* Start News letter popup */}
      <div className="newsletter__popup" data-animation="slideInUp">
        <div className="newsletter__popup--inner">
          <button className="newsletter__popup--close__btn" aria-label="search close button">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path></svg>
          </button>
          <div className="box newsletter__popup--box d-flex align-items-center">
            <div className="newsletter__popup--thumbnail">
              <img className="newsletter__popup--thumbnail__img display-block" src="assets/img/banner/newsletter-popup-thumb.png" alt="newsletter-popup-thumb" />
            </div>
            <div className="newsletter__popup--box__right">
              <h2 className="newsletter__popup--title">Join Our Newsletter</h2>
              <div className="newsletter__popup--content">
                <label className="newsletter__popup--content--desc">Enter your email address to subscribe our notification of our new post &amp; features by email.</label>
                <div className="newsletter__popup--subscribe" id="frm_subscribe">
                  <form className="newsletter__popup--subscribe__form">
                    <input className="newsletter__popup--subscribe__input" type="text" placeholder="Enter you email address here..." />
                    <button className="newsletter__popup--subscribe__btn">Subscribe</button>
                  </form>
                  <div className="newsletter__popup--footer">
                    <input type="checkbox" id="newsletter__dont--show" />
                    <label className="newsletter__popup--dontshow__again--text" htmlFor="newsletter__dont--show">Don't show this popup again</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End News letter popup */}
    </>
  )
}

export default Footer;