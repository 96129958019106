import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from '../axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderImg from '../img/logo/running.gif';
import AddNewOrgUser from "./AddNewOrgUser";

const NewOrgUser = (props) => {

    const [AddNewUsers, setAddNewUsers] = useState(false);
    const [OrgUserDataAll, setOrgUserDataAll] = useState([]);
    const [Loader, setLoader] = useState("");
    const [UserId, setUserId] = useState('');

    const [EditAction, setEditAction] = useState(false);
    const [DeleteCommPopup, setDeleteCommPopup] = useState(false);

    const [ApiCall, setApiCall] = useState(false);

    const handleComm = (comm_id, operate) => {
       
      if (operate === 'edit') {
        setAddNewUsers(true);
      }
      if (operate === "delete") {
          setDeleteCommPopup(true);
      }
      // if (operate === "change_status") {
      //     setDeleteCommPopup(true);
      // }
    }
    

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true);
        const payload = {
          user_id: localStorage.getItem("ID") ? localStorage.getItem("ID") : 0,
        };
        axios.post("org_user_details", payload)
          .then((response) => {
            // console.log(response);
            if (response.status === 200) {
              setOrgUserDataAll(response.data.data.AllOrgUsers !== '' ? response.data.data.AllOrgUsers : []);
              setApiCall(false);
              setUserId('');
              setLoader(false);
            }
          })
          .catch((error) => {
            setLoader(false);
            console.log(error);
          })
    
      }, [ApiCall])

    //   console.log(OrgUserDataAll);

    const DeleteUsers = () => {
        
      //console.log(ConfirmDeleteTicket,CommId);
      if (UserId !== "") {
          axios.post('delete_org_user',
              {
                  'org_user_id': UserId,
              }
          )
          .then((response) => {
              toast.success(response.data.message);
              setDeleteCommPopup(false);
              setApiCall(true);
              setUserId('');
          })
          .catch((error) => {
              console.log(error);
          })
      }
    }


    //--------------- access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    const OrgUserAccessModules = localStorage.getItem("OrgUserAccessModules") ? JSON.parse(localStorage.getItem("OrgUserAccessModules")) : '';

  return (
       
    <>
        {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
        {
                DeleteCommPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">
                                                    
                                                    <p className="account__login--header__desc">Are you sure you want to delete this record?</p>
                                                </div>
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setDeleteCommPopup(false);
                                                    }}>Cancel</button>
                                                    
                                                    {/* { ActionFlag && ActionFlag === 'change_status' ?
                                                        <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmDeleteTicket(true); ActionChangeStatus(); setDeleteCommPopup(false);  }}>Yes</button>
                                                    : */}
                                                       <button className="account__login--btn primary__btn mt-2" onClick={(e) => { DeleteUsers(); setDeleteCommPopup(false);  }}>Yes</button>
                                                    {/* } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        { AddNewUsers === true ?
         
            <AddNewOrgUser setAddNewUsers={setAddNewUsers} UserId={UserId} setUserId={setUserId} setApiCall={setApiCall} />
        :
        
        <div className="main checkout__mian">

            <div className="checkout__content--step section__shipping--address ">
            <div className="mb-5">
           
            <div className="section__header mb-4 d-flex align-items-center justify-content-between">
                <h2 className="section__header--title h3">Organizing Team - New user</h2>
                
                {AccessRightFlag === '1' ?
                    <>
                        {OrgUserAccessModules.MyProfile === 2 ?
                            <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddNewUsers(true);  }} style={{cursor:'pointer'}}>
                               <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add User
                            </div>
                        : null }
                    </>
                : 
                    <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddNewUsers(true);  }} style={{cursor:'pointer'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add User
                    </div>
                }

            </div>

         
                    <div className='dashboard-card p-4 border-radius-10 '>
                        <div className='row'>
                            <div className='col-sm-12'>

                            <table className="table table-hover">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col" className='text-center'>Sr. No.</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Mobile Number</th>
                                            <th scope="col">Gender</th>
                                            <th scope="col">Date of Birth</th>
                                            <th scope="col">Role</th>
                                            <th scope="col" style={{textAlign: 'center'}}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                      
                                        {OrgUserDataAll && OrgUserDataAll.length > 0 ?
                                           OrgUserDataAll.map((item, index) => {
                                            return (
                                                <>
                                                   <tr key={index}>
                                                       <td className='text-center'>{index+1}</td>
                                                       <td>{item.username}</td>
                                                       <td>{item.email}</td>
                                                       <td>{item.mobile} </td>
                                                       <td>{item.gender}</td>
                                                       <td>{item.dob}</td>
                                                       <td>{item.user_role}</td>
                                                       <td className='d-flex' style={{justifyContent: 'center'}}>
                                                          
                                                            {AccessRightFlag === '1' ?
                                                                <>
                                                                    {OrgUserAccessModules.MyProfile === 2 ?
                                                                    <>
                                                                        <div className="mx-3 option-icon-edit" title='Edit User'  onClick={() => { setUserId(item.id); handleComm(item.id, 'edit');  }} style={{cursor:'pointer'}}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                                        </div>

                                                                        <div className="mx-3 option-icon" title='Delete User' onClick={() => { setUserId(item.id); handleComm(item.id, 'delete'); }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                                        </div>
                                                                    </>
                                                                        
                                                                    : <div>&nbsp;</div> }
                                                                </>
                                                            : 
                                                               <>
                                                                    <div className="mx-3 option-icon-edit" title='Edit User'  onClick={() => { setUserId(item.id); handleComm(item.id, 'edit');  }} style={{cursor:'pointer'}}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                                    </div>

                                                                    <div className="mx-3 option-icon" title='Delete User' onClick={() => { setUserId(item.id); handleComm(item.id, 'delete'); }}>
                                                                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                                    </div>
                                                                </>
                                                            }
                                                          
                                                       </td>
                                                   </tr>
                                                   
                                                </>
                                                );
                                            })
                                       
                                        : 
                                         <tr><td colSpan={8} style={{color: 'red'}} align='center'>No Record Found</td></tr>
                                        }
                                        
                                    </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
          
        }
    </>
   
  )
}

export default NewOrgUser