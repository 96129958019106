import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useParams, useNavigate } from 'react-router-dom';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';

const AddFAQ = (props) => {

  const [Loader, setLoader] = useState(false);
  
  const [QuestionName, setQuestionName] = useState('');
  const [QuestionNameError, setQuestionNameError] = useState('');
  const [Answer, setAnswer] = useState('');
  const [AnswerError, setAnswerError] = useState('');
  
  const [EventCommId, setEventCommId] = useState('');

  
  //console.log(props.EventTypeData);
   useEffect(() => {
    
    if (props.CommId && props.CommId !== 0) {
      axios.post('edit_event_comm_faq',
        {
          'event_id': localStorage.getItem("EventId"),
          'event_comm_id': props.CommId,
          'event_edit_flag': 'faq_edit'
        }
      )
        .then((response) => {
         //console.log(response.data);
          if (response.data.data.faq_edit_details) {
            let comm_details = response.data.data.faq_edit_details[0];
              setEventCommId(comm_details.id !== '' ? comm_details.id : '')
              setQuestionName(comm_details.question !== '' ? comm_details.question : '');
              setAnswer(comm_details.answer !== '' ? comm_details.answer : '');
          }

        })
        .catch((error) => {
          console.log(error);
        })
    }
    // props.setTicketDivShowFlag(true);
   }, [props.CommId])
  

  //console.log(StartDate);
  
   const handleValidation = () => {
    // console.log(TicketStatus);
    var flag = true;
    if (QuestionName === "") {
      setQuestionNameError("Please enter question");
      flag = false
    } else {
      setQuestionNameError("");
    }

    if (Answer === "") {
      setAnswerError("Please enter answer");
      flag = false
    } else {
      setAnswerError("");
    }

    return flag;
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      props.setLoader(true);
      props.setTicketDivShowFlag(true);
      const payload = {
        event_id: localStorage.getItem("EventId"),
        user_id: localStorage.getItem("ID"),
        quetion_name: QuestionName,
        answer: Answer,
        event_comm_id : EventCommId
      };
      //console.log(payload);
      axios.post("event_faq", payload)
        .then((response) => {
          props.setLoader(false);
          //console.log(response.data);
          if (response.status === 200) {
            toast.success(response.data.message);
            props.setAddNewFAQ(false);
            props.setEventApiCall(true);
            props.setTicketDivShowFlag(false);
          }
        })
        .catch((error) => {
          props.setLoader(false);
          console.log(error);
        });
    }

  };

  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

      <div className="row">
        <div className="col-lg-12 col-md-6">
        <div className="main checkout__mian">
        <form onSubmit={handleSubmit}>
          <div className="checkout__content--step section__shipping--address ">
            <div className="mb-5">
              <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                <h2 className="section__header--title h3">Add FAQ's</h2>
              </div>
            </div>

            <div className="row ">

                <div className="col-sm-12 mb-4">
                  <div className="form-floating mt-3 mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={QuestionName}
                      onChange={(e) => {
                        setQuestionName(e.target.value);
                      }}
                    />
                    <label>Question <span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">{QuestionNameError}</small>
                </div>

                <div className="col-sm-12">
                  <div className="form-floating mt-3 mb-0">
                  
                    <textarea
                        type="text"
                        className="form-control resp-textarea"
                       
                        value={Answer} onChange={(e) => setAnswer(e.target.value)}
                    />
                    <label>Answer <span className="checkout__input--label__star">*</span></label>

                  </div>
                  <small className="text-danger">{AnswerError}</small>
                </div>
            </div>
          
          </div>
          <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              onClick={(e) => { props.setAddNewFAQ(false); props.setTicketDivShowFlag(false) }}
              style={{cursor:'pointer'}} >
              Cancel
            </div>

            <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />

          </div>
        </form >
     
      </div >
    </div> 
        
          {/* <div className="col-lg-4 col-md-6">
                  <aside className="checkout__sidebar sidebar border-radius-10">
                    <div className="card  p-5">
                      <div className="row">
                        <div className="col-sm-6 text-left">
                          <h2 className="section__header--title h3">Placeholders</h2>
                        </div>
                        
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card p-5">
                         
                          <div className="row">
                            <div className="col-12 text-left">
                              <p className="">First Name</p>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-12 text-left">
                              <p className="">Last Name</p>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-12 text-left">
                              <p className="">Contact No</p>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </aside>
          </div>  */}
        

      </div>

    </>
  )
}

export default AddFAQ;

