import React, { useEffect } from 'react';
import PhysicalEvent from "../img/events/physical-event.png";
import VirtualEvent from "../img/events/virtual-run.png";
import Hybrid from "../img/events/hybrid1.png"

import { useNavigate } from 'react-router-dom';

const EventPopup = (props) => {
    useEffect(() => {
        const handleEscKeyPress = (event) => {
            if (event.key === 'Escape') {
                props.setEvent(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleEscKeyPress);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleEscKeyPress);
        };
    }, [props, props.setEvent]);

    const navigate = useNavigate();
    
    const CompanyInfo = localStorage.getItem("Company_info");
    // console.log(CompanyInfo);

    const handleEventSelType = (event, type) => {
        //console.log(type);
        // if(CompanyInfo && CompanyInfo == 1){
        //     if(type === 1){
        //        localStorage.setItem("EventType", 1);
        //     }else if(type === 2){
        //        localStorage.setItem("EventType", 2);
        //     }
        //     navigate("/create_events");
        // }else{
        //    alert('Complete your company settings page first to design new event');
        //    navigate("/organizer");
        // }  

        if(type === 1){
            localStorage.setItem("EventType", 1);
        }else if(type === 2){
            localStorage.setItem("EventType", 2);
        }else if(type === 3){
            localStorage.setItem("EventType", 3);
        }
        navigate("/create_events");
    }

    return (
        <>
            <div className="modal is-visible" data-animation="slideInUp">
                <div className="modal-dialog quickview__main--wrapper">
                    <header className="modal-header quickview__header">
                        <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
                            props.setEvent(false);
                        }}>✕</button>
                    </header>
                    <div className="quickview__inner">
                        <div className="row">
                            <div className="login__section">
                                <form action="#" >
                                    <div className="login__section--inner">
                                        {CompanyInfo && CompanyInfo == 1 ?
                                        <div className="p-5">
                                            <div className="account__login--header mb-25">
                                                <h3 className="account__login--header__title mb-10">Select Event Type</h3>
                                                <p className="account__login--header__desc">Pick the type of event you want to host</p>
                                            </div>
                                            <div className="row select-event">
                                                <div className="col-sm-4" onClick={(e) => handleEventSelType(e,1)}>
                                                    <div className="card" style={{minHeight: "360px"}}>
                                                        <img  src={`${PhysicalEvent}`} alt="" style={{ width: "100%" }} />
                                                        <h4 className="header-text gap">On Ground Event</h4>
                                                        <p>Host an in-person on ground event using our event management platform.</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4" onClick={(e) => handleEventSelType(e,2)}>
                                                    <div className="card" style={{minHeight: "360px"}} >
                                                        <img  src={`${VirtualEvent}`} alt="" style={{ width: "100%" }} />
                                                        <h4 className="header-text gap">Virtual Run</h4>
                                                        <p>Host virtual challenges like walking, running, cycling etc</p>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4" onClick={(e) => handleEventSelType(e,3)}>
                                                    <div className="card" style={{minHeight: "360px"}} >
                                                        <img  src={`${Hybrid}`} alt="" style={{ width: "100%" }} />
                                                        <h4 className="header-text gap">Hybrid Event</h4>
                                                        <p>Seamlessly blend in-person and virtual experiences using our event management platform.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="p-5">
                                            <div className="account__login--header text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 -960 960 960" width="100px"><path d="M120-120v-555h165v-165h390v330h165v390H533v-165H427v165H120Zm60-60h105v-105H180v105Zm0-165h105v-105H180v105Zm0-165h105v-105H180v105Zm165 165h105v-105H345v105Zm0-165h105v-105H345v105Zm0-165h105v-105H345v105Zm165 330h105v-105H510v105Zm0-165h105v-105H510v105Zm0-165h105v-105H510v105Zm165 495h105v-105H675v105Zm0-165h105v-105H675v105Z"/></svg>
                                            <h4 className="account__login--header__title mb-1">Company settings required</h4>
                                                <p className="">Please update the Company Settings profile by clicking the below button <br /> before designing a new event.</p>
                                                <div>
                                               

                                                <p className="continue__shipping--btn secondary__btn border-radius-5 cursor-pointer" onClick={(e) => { navigate("/organizer") }} >
                                                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px"><path d="M120-120v-555h165v-165h390v330h165v390H533v-165H427v165H120Zm60-60h105v-105H180v105Zm0-165h105v-105H180v105Zm0-165h105v-105H180v105Zm165 165h105v-105H345v105Zm0-165h105v-105H345v105Zm0-165h105v-105H345v105Zm165 330h105v-105H510v105Zm0-165h105v-105H510v105Zm0-165h105v-105H510v105Zm165 495h105v-105H675v105Zm0-165h105v-105H675v105Z"/></svg> Company settings
                                                </p>

                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>    
    );
};

export default EventPopup;