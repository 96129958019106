import axios from "axios";

const instance = axios.create({
  
  baseURL:  "https://racesregistrations.com/races/public/api/",   // live server
//  baseURL: "https://swtprime.com/races/public/api/", // prime server
  // baseURL: "http://127.0.0.1:8000/api/",                       // local server
  //  baseURL:"http://192.168.1.61:8000/api/"   ,         

  headers: {
    'accept': 'application/json',
    'Access-Control-Allow-Origin': "*",
    'content-type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Credentials': 'true',
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("RacesToken") ? JSON.parse(localStorage.getItem("RacesToken")).token : ""}`;
      },
    },
  },
});

export default instance;
