
import HeaderSecond from '../HeaderSecond'
import event_card_img from '../img/events/event-view.jpg';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderImg from '../img/logo/running.gif';
import EventView from '../../src/img/events/event-view.jpg'
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import NoEventImg from '../../src/img/events/no-events.png'
import axios from '../axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UserAccessRights} from '../Custom';

const EditEvent = () => {

    const navigate = useNavigate();
    const [Section, setSection] = useState('my-profile');
    const [EventInfoStatus, setEventInfoStatus] = useState(1);
    const [EventPublicStatus, setEventPublicStatus] = useState('active');
    const [EventPrivateStatus, setEventPrivateStatus] = useState('');
    const [EventDraftStatus, setEventDraftStatus] = useState('');
    const [EventStatus, setEventStatus] = useState(false);
    const [EventFlag, setEventFlag] = useState(false);

    const [AllEventData, setAllEventData] = useState([]);
    const [ConfirmPopup, setConfirmPopup] = useState(false);
    const [EventEditId, setEventEditId] = useState('');
    const [EventType, setEventType] = useState('');
    const [ActionFlag, setActionFlag] = useState('');

    const [ActionableFlag, setActionableFlag] = useState(false);

    const [evntStatus, setevntStatus] = useState('');
    const [eventIndex, seteventIndex] = useState('');

    const [AllOrgEventData, setAllOrgEventData] = useState([]);

    const [Loader, setLoader] = useState(false);
    
    useEffect(() => {
        setLoader(true);
        const payload = {
            event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
            event_info_status: EventInfoStatus !== 0 ? EventInfoStatus : 0,
            user_id: localStorage.getItem("ID") ? localStorage.getItem("ID") : 0,
            login_as_organiser_id: localStorage.getItem("LoginAsOrganiser") ? localStorage.getItem("LoginAsOrganiser") : 0,
        };
        axios.post("AllEventDetails", payload)
            .then((response) => {
                //   console.log(response.data.data);
                if (response.status === 200) {
                   setAllEventData(response.data.data.event_data);
                   setAllOrgEventData(response.data.data.org_event_data ? response.data.data.org_event_data : []);
                   setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
                console.log(error);
            })

    }, [EventFlag, EventInfoStatus])

    const ChangeEventStatus = (event, index, actionFlag) => {
        //console.log(event,index);

        if (actionFlag == 'delete') {
            setConfirmPopup(true);
            // ActionDeleteChangeStatus('','','');
        } else if (actionFlag == 'change_status') {
            setConfirmPopup(true);
            // StatusChange(event,index,actionFlag);
        }
    }


    const ActionDeleteChangeStatus = () => {

        if (ActionFlag == 'change_status') {
            let EventArray = [...AllEventData];
            EventArray[eventIndex]['event_status'] = !evntStatus;
            setAllEventData(EventArray);
        }

        //console.log(AllEventData);
        const payload = {
            event_id: EventEditId,
            event_status: evntStatus,
            action_flag: ActionFlag
        };
        //console.log(payload);
        axios.post("EventDeleteChangeStatus", payload)
            .then((response) => {
                //   console.log(response.data.data);
                if (response.status === 200) {
                    toast.success(response.data.message)
                    setEventFlag(true);
                    setevntStatus('');
                    seteventIndex('');
                    setEventStatus(false)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const ChangeEditEvent = (eventId, eventType) => {
        // console.log(eventId,eventType);
        localStorage.setItem("EventId", eventId);
        localStorage.setItem("EventType", eventType);
        navigate('/create_events');
    }

    const handleBackToHome = () => {
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('EventDetailsId');

        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }

    //--------------- access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    const OrgUserAccessModules = localStorage.getItem("OrgUserAccessModules") ? JSON.parse(localStorage.getItem("OrgUserAccessModules")) : '';

    //-------------- To check user access rights
    useEffect(() => {
        if(AccessRightFlag === '1'){
            const access = UserAccessRights({module_name: "Event", module_display: "yes"});
            if(access === true){
            alert("You don't have any access to this module");
            navigate('/');
            }
        }
    }, []);


    return (
        <>
            {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
            {
                ConfirmPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">
                                                    {ActionFlag && ActionFlag === 'delete' ?
                                                        <p className="account__login--header__desc">Are you sure you want to delete this event?</p>

                                                        : ActionFlag && ActionFlag === 'edit' ?
                                                            <p className="account__login--header__desc">Are you sure you want to edit this event?</p>
                                                            :
                                                            <p className="account__login--header__desc">Are you sure to change status this event?</p>
                                                    }
                                                </div>
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setConfirmPopup(false); setActionableFlag(false);
                                                    }}>Cancel</button>

                                                    {ActionFlag && ActionFlag === 'edit' ?
                                                        <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmPopup(false); ChangeEditEvent(EventEditId, EventType); setActionableFlag(false); }}>Yes</button>
                                                        :
                                                        <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmPopup(false); setActionableFlag(true); ActionDeleteChangeStatus(); }}>Yes</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <HeaderSecond />
            <ToastContainer theme="colored" />
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        My Events
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">My Events</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section--padding  pt-5">
                    <div className="container">
                        {/* use class "saved" when the form is filled with save and replace it with the tick mark icon SVG.  */}
                        {/* Use class "active" when form is active */}
                        <div className="row">
                            <div className="col-sm-12 d-flex align-items-center justify-content-center my-0 pb-0">
                                <div className="event-visibility hand_cursor">
                                    <div className={'visibility gap-1 ' + EventPublicStatus} onClick={() => { setEventInfoStatus(1); setEventPublicStatus('active'); setEventPrivateStatus(''); setEventDraftStatus(''); }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={20}
                                            viewBox="0 -960 960 960"
                                            width={20}
                                        >
                                            <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                                        </svg>
                                        Public
                                    </div>

                                    <div className={'visibility gap-1 ' + EventPrivateStatus} onClick={() => { setEventInfoStatus(2); setEventPublicStatus(''); setEventPrivateStatus('active'); setEventDraftStatus(''); }} >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={20}
                                            viewBox="0 -960 960 960"
                                            width={20}
                                        >
                                            <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                                        </svg>
                                        Private
                                    </div>

                                    <div className={'visibility gap-1 ' + EventDraftStatus} onClick={() => { setEventInfoStatus(3); setEventPublicStatus(''); setEventPrivateStatus(''); setEventDraftStatus('active'); }} >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={20}
                                            viewBox="0 -960 960 960"
                                            width={20}
                                        >
                                            <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                                        </svg>
                                        Draft
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row mt-5" >
                            {/* {console.log(AllEventData)} */}
                            {AllEventData && AllEventData.length > 0 ?
                                AllEventData.map((item, index) => (
                                    <div className="col-sm-3 position-relative" key={index}>
                                        <div className="product__items mt-4 mb-5" >
                                            <div className="product__items--thumbnail" >
                                                <div className="product__items--link">

                                                    <img
                                                        className="product__items--img product__primary--img"
                                                        src={item.banner_image !== '' ? item.banner_image : EventView}
                                                        alt="Event View"
                                                        style={{ height: '230px', objectFit: 'fill' }}
                                                    />

                                                </div>
                                                
                                                {(item.early_bird > 0) ?
                                                    <div className="product__badge style1 special-badge gap-1">
                                                        Early Bird
                                                    </div>
                                                : null}
                                                    
                                                <ul className="product__items--action d-flex justify-content-center">

                                                    {/* Wishlist */}
                                                   
                                                    {AccessRightFlag === '1' ?
                                                        <>
                                                            {OrgUserAccessModules.Event === 2 ?
                                                                <>
                                                                    <li className="product__items--action__list">
                                                                        <div className="product__items--action__btn hand_cursor tooltip" style={{ zIndex: "9999" }} onClick={() => { setConfirmPopup(true); setActionFlag('edit'); setEventEditId(item.id); setEventType(item.event_type); }} >
                                                                            {/* onClick={(e) => {if(window.confirm('Are you sure to edit this record?')){ ChangeEditEvent(item.id,item.event_type);};}} */}
                                                                            <svg
                                                                                className="product__items--action__btn--svg"
                                                                                xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >

                                                                                <path d="M80 0v-160h800V0H80Zm160-320h56l312-311-29-29-28-28-311 312v56Zm-80 80v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm560-504-56-56 56 56ZM608-631l-29-29-28-28 57 57Z"
                                                                                    transform="translate(0 0)"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                            <span className="visually-hidden">Edit</span>
                                                                            <span className="tooltiptext position-fixed w-auto">Edit Event</span>
                                                                        </div>
                                                                    </li>

                                                                    <li className="product__items--action__list">
                                                                        <div className="product__items--action__btn hand_cursor tooltip" onClick={(e) => { ChangeEventStatus(e, index, 'delete'); setActionFlag('delete'); setEventEditId(item.id); setEventType(item.event_type); }}>
                                                                            {/* onClick={(e) => {if(window.confirm('Are you sure to remove this record?')){ ChangeEventStatus(e,index,item.id,'delete');};}} */}
                                                                            <svg
                                                                                className="product__items--action__btn--svg"
                                                                                xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >
                                                                                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                                                                                    transform="translate(0 0)"
                                                                                    fill="currentColor"
                                                                                    title="ss"
                                                                                />
                                                                            </svg>
                                                                            <span className="visually-hidden">Delete</span>
                                                                            <span className="tooltiptext position-fixed w-auto">Delete Event</span>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            : null }
                                                        </>
                                                    : 
                                                        <>
                                                            <li className="product__items--action__list">
                                                                <div className="product__items--action__btn hand_cursor tooltip" style={{ zIndex: "9999" }} onClick={() => { setConfirmPopup(true); setActionFlag('edit'); setEventEditId(item.id); setEventType(item.event_type); }} >
                                                                    {/* onClick={(e) => {if(window.confirm('Are you sure to edit this record?')){ ChangeEditEvent(item.id,item.event_type);};}} */}
                                                                    <svg
                                                                        className="product__items--action__btn--svg"
                                                                        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >

                                                                        <path d="M80 0v-160h800V0H80Zm160-320h56l312-311-29-29-28-28-311 312v56Zm-80 80v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm560-504-56-56 56 56ZM608-631l-29-29-28-28 57 57Z"
                                                                            transform="translate(0 0)"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                    <span className="visually-hidden">Edit</span>
                                                                    <span className="tooltiptext position-fixed w-auto">Edit Event</span>
                                                                </div>
                                                            </li>

                                                            <li className="product__items--action__list">
                                                                <div className="product__items--action__btn hand_cursor tooltip" onClick={(e) => { ChangeEventStatus(e, index, 'delete'); setActionFlag('delete'); setEventEditId(item.id); setEventType(item.event_type); }}>
                                                                    {/* onClick={(e) => {if(window.confirm('Are you sure to remove this record?')){ ChangeEventStatus(e,index,item.id,'delete');};}} */}
                                                                    <svg
                                                                        className="product__items--action__btn--svg"
                                                                        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >
                                                                        <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                                                                            transform="translate(0 0)"
                                                                            fill="currentColor"
                                                                            title="ss"
                                                                        />
                                                                    </svg>
                                                                    <span className="visually-hidden">Delete</span>
                                                                    <span className="tooltiptext position-fixed w-auto">Delete Event</span>
                                                                </div>
                                                            </li>
                                                        </>
                                                    }

                                                </ul>
                                            </div>

                                            <div className="product__items--content cursor-pointer" style={{ height: "200px", overflow: "hidden" }}>

                                                <div className="row">
                                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column align-items-center justify-content-center" style={{ borderRight: "1px solid #f52222" }}>
                                                        <h4 className="h4">{item.start_event_month !== '' ? item.start_event_month : ''}</h4>
                                                        <h4 className="h4">{item.start_event_date !== '' ? item.start_event_date : ''}</h4>
                                                    </div>
                                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10" >

                                                        <h4 className="h4 d-flex" style={{ height: "55px", overflow: "hidden" }}>
                                                            {item.event_name !== '' && item.event_name !== null ? item.event_name : item.display_name !== null ? item.display_name : ''}
                                                        </h4>
                                                    </div>
                                                </div>

                                                <div className='my-3 d-flex gap-3'>

                                                    <small className="m-0 card-event-city">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={17}
                                                            viewBox="0 -960 960 960"
                                                            width={17}
                                                        >
                                                            <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                        </svg>{item.city !== '' ? item.city : ''}</small>
                                                </div>

                                                <div className="row d-flex align-items-center card-rgn-bt-dot">
                                                    {/* <div className="col-sm-12 mt-4 mb-0">
                                                        <small className='mb-2 card-rgn-txt'>Register By
                                                            : {" "}  <span className='rgn-date'>{item.registration_end_date !== '' ? item.registration_end_date : ''}</span></small>
                                                    </div> */}

                                                    <div className="col-sm-12 mt-4 mb-0 d-flex justify-content-between">
                                                        <small className='mb-2 card-rgn-txt'>Register By
                                                            : {" "}  <span className='rgn-date'>{item.registration_end_date !== '' ? item.registration_end_date : ''}</span></small>

                                                            {AccessRightFlag === '1' ?
                                                                <>
                                                                    {OrgUserAccessModules.Insight === 1 || OrgUserAccessModules.Insight === 2 ?
                                                                        <div className='report-icon tooltip' onClick={(e) => { navigate('/event_dashboard/' + item.id, { state: { event_name: item.event_name } }); }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"/></svg>
                                                                            <span className="tooltiptext position-fixed w-auto">View Details</span>
                                                                        </div>
                                                                    : null }
                                                                </>
                                                            : 
                                                                <div className='report-icon tooltip' onClick={(e) => { navigate('/event_dashboard/' + item.id, { state: { event_name: item.event_name } }); }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"/></svg>
                                                                    <span className="tooltiptext position-fixed w-auto">View Details</span>
                                                                </div>
                                                            }
                                                    </div>

                                                    <div className="col-sm-6 my-3">
                                                        <p style={{ whiteSpace: "nowrap" }}>
                                                        {item.TicketDetails && item.TicketDetails.length > 0 && (
                                                            item.TicketDetails.some(ticket => parseInt(ticket.ticket_status) === 2) ? (
                                                            <span className="current__price m-0">Free</span>
                                                            ) : (
                                                            item.TicketDetails.map((ticket, index) => (
                                                                parseInt(ticket.ticket_status) === 1 && (
                                                                <span key={index}>
                                                                    {index > 0 && " - "}
                                                                    {ticket.show_early_bird === 1 ? (
                                                                    <span className="text-left">
                                                                        <span className="ticket-prices">
                                                                        <span className="original-price"> ₹{ticket.ticket_price}</span>&nbsp;
                                                                        <span className="current__price m-0">₹{ticket.discount_ticket_price}</span>
                                                                        </span>
                                                                    </span>
                                                                    ) : (
                                                                    <span className="current__price m-0">₹{ticket.ticket_price}</span>
                                                                    )}
                                                                </span>
                                                                )
                                                            ))
                                                            )
                                                        )}
                                                        </p>
                                                    </div>

                                                    {AccessRightFlag === '1' ?
                                                        <>
                                                            {OrgUserAccessModules.Event === 2 ?
                                                                <div className="col-sm-6 my-3 text-right tooltip">
                                                                    <div className="form-check form-switch d-flex align-items-left justify-content-end my-2 ">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            value={item.event_status}
                                                                            checked={item.event_status}
                                                                            onChange={(e) => setEventStatus(!EventStatus)}
                                                                            onClick={(e) => { ChangeEventStatus(e, index, 'change_status'); setActionFlag('change_status'); setEventEditId(item.id); setEventType(item.event_type); setevntStatus(item.event_status); seteventIndex(index); }}
                                                                        />
            
                                                                    </div>
                                                                    <span className="tooltiptext position-fixed w-auto">Event {item.event_status === true ? 'Active' : 'Deactive'}</span>
                                                                </div>
                                                            : null }
                                                        </>
                                                    : 
                                                        <div className="col-sm-6 my-3 text-right tooltip">
                                                            <div className="form-check form-switch d-flex align-items-left justify-content-end my-2 ">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    value={item.event_status}
                                                                    checked={item.event_status}
                                                                    onChange={(e) => setEventStatus(!EventStatus)}
                                                                    onClick={(e) => { ChangeEventStatus(e, index, 'change_status'); setActionFlag('change_status'); setEventEditId(item.id); setEventType(item.event_type); setevntStatus(item.event_status); seteventIndex(index); }}
                                                                />

                                                            </div>
                                                            <span className="tooltiptext position-fixed w-auto">Event {item.event_status === true ? 'Active' : 'Deactive'}</span>
                                                        </div>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))
                                : 
                               
                                <div className="product__section--inner py-5 text-center">
                                    <img src={NoEventImg} alt="" style={{ width: 250 }} />
                                    <h4 className="pt-4">No events found</h4>
                                    <p>
                                        Sorry, There are no events added yet. Please design your event now!
                                    </p>
                                </div>
                                
                                }
                        </div>

{/* -------------------------------------------------------------------------------------------- */}
                    {AllOrgEventData && AllOrgEventData.length > 0 ?
                      <>
                        <hr className='my-2' style={{background: 'lightgrey'}}/>
                        <div className="row mt-4">
                           
                            <h3>Organiser Events</h3> <br /><br /><br />
                            
                            {AllOrgEventData && AllOrgEventData.length > 0 ?
                                AllOrgEventData.map((item, index) => (
                                    <div className="col-sm-3 position-relative" key={index}>
                                        <div className="product__items mt-4 mb-5" >
                                            <div className="product__items--thumbnail" >
                                                <div className="product__items--link">

                                                    <img
                                                        className="product__items--img product__primary--img"
                                                        src={item.banner_image !== '' ? item.banner_image : EventView}
                                                        alt="Event View"
                                                        style={{ height: '230px', objectFit: 'fill' }}
                                                    />

                                                </div>
                                                
                                                {(item.early_bird > 0) ?
                                                    <div className="product__badge style1 special-badge gap-1">
                                                        Early Bird
                                                    </div>
                                                : null}
                                                    
                                                <ul className="product__items--action d-flex justify-content-center">

                                                    {/* Wishlist */}
                                                   
                                                    {AccessRightFlag === '1' ?
                                                        <>
                                                            {OrgUserAccessModules.Event === 2 ?
                                                                <>
                                                                    <li className="product__items--action__list">
                                                                        <div className="product__items--action__btn hand_cursor tooltip" style={{ zIndex: "9999" }} onClick={() => { setConfirmPopup(true); setActionFlag('edit'); setEventEditId(item.id); setEventType(item.event_type); }} >
                                                                            {/* onClick={(e) => {if(window.confirm('Are you sure to edit this record?')){ ChangeEditEvent(item.id,item.event_type);};}} */}
                                                                            <svg
                                                                                className="product__items--action__btn--svg"
                                                                                xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >

                                                                                <path d="M80 0v-160h800V0H80Zm160-320h56l312-311-29-29-28-28-311 312v56Zm-80 80v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm560-504-56-56 56 56ZM608-631l-29-29-28-28 57 57Z"
                                                                                    transform="translate(0 0)"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                            <span className="visually-hidden">Edit</span>
                                                                            <span className="tooltiptext position-fixed w-auto">Edit Event</span>
                                                                        </div>
                                                                    </li>

                                                                    <li className="product__items--action__list">
                                                                        <div className="product__items--action__btn hand_cursor tooltip" onClick={(e) => { ChangeEventStatus(e, index, 'delete'); setActionFlag('delete'); setEventEditId(item.id); setEventType(item.event_type); }}>
                                                                            {/* onClick={(e) => {if(window.confirm('Are you sure to remove this record?')){ ChangeEventStatus(e,index,item.id,'delete');};}} */}
                                                                            <svg
                                                                                className="product__items--action__btn--svg"
                                                                                xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >
                                                                                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                                                                                    transform="translate(0 0)"
                                                                                    fill="currentColor"
                                                                                    title="ss"
                                                                                />
                                                                            </svg>
                                                                            <span className="visually-hidden">Delete</span>
                                                                            <span className="tooltiptext position-fixed w-auto">Delete Event</span>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            : null }
                                                        </>
                                                    : 
                                                        <>
                                                            <li className="product__items--action__list">
                                                                <div className="product__items--action__btn hand_cursor tooltip" style={{ zIndex: "9999" }} onClick={() => { setConfirmPopup(true); setActionFlag('edit'); setEventEditId(item.id); setEventType(item.event_type); }} >
                                                                    {/* onClick={(e) => {if(window.confirm('Are you sure to edit this record?')){ ChangeEditEvent(item.id,item.event_type);};}} */}
                                                                    <svg
                                                                        className="product__items--action__btn--svg"
                                                                        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >

                                                                        <path d="M80 0v-160h800V0H80Zm160-320h56l312-311-29-29-28-28-311 312v56Zm-80 80v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm560-504-56-56 56 56ZM608-631l-29-29-28-28 57 57Z"
                                                                            transform="translate(0 0)"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                    <span className="visually-hidden">Edit</span>
                                                                    <span className="tooltiptext position-fixed w-auto">Edit Event</span>
                                                                </div>
                                                            </li>

                                                            <li className="product__items--action__list">
                                                                <div className="product__items--action__btn hand_cursor tooltip" onClick={(e) => { ChangeEventStatus(e, index, 'delete'); setActionFlag('delete'); setEventEditId(item.id); setEventType(item.event_type); }}>
                                                                    {/* onClick={(e) => {if(window.confirm('Are you sure to remove this record?')){ ChangeEventStatus(e,index,item.id,'delete');};}} */}
                                                                    <svg
                                                                        className="product__items--action__btn--svg"
                                                                        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" >
                                                                        <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                                                                            transform="translate(0 0)"
                                                                            fill="currentColor"
                                                                            title="ss"
                                                                        />
                                                                    </svg>
                                                                    <span className="visually-hidden">Delete</span>
                                                                    <span className="tooltiptext position-fixed w-auto">Delete Event</span>
                                                                </div>
                                                            </li>
                                                        </>
                                                    }

                                                </ul>
                                            </div>

                                            <div className="product__items--content cursor-pointer" style={{ height: "200px", overflow: "hidden" }}>

                                                <div className="row">
                                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column align-items-center justify-content-center" style={{ borderRight: "1px solid #f52222" }}>
                                                        <h4 className="h4">{item.start_event_month !== '' ? item.start_event_month : ''}</h4>
                                                        <h4 className="h4">{item.start_event_date !== '' ? item.start_event_date : ''}</h4>
                                                    </div>
                                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10" >

                                                        <h4 className="h4 d-flex" style={{ height: "55px", overflow: "hidden" }}>
                                                            {item.event_name !== '' && item.event_name !== null ? item.event_name : item.display_name !== null ? item.display_name : ''}
                                                        </h4>
                                                    </div>
                                                </div>

                                                <div className='my-3 d-flex gap-3'>

                                                    <small className="m-0 card-event-city">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={17}
                                                            viewBox="0 -960 960 960"
                                                            width={17}
                                                        >
                                                            <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                        </svg>{item.city !== '' ? item.city : ''}</small>
                                                </div>

                                                <div className="row d-flex align-items-center card-rgn-bt-dot">
                                                    {/* <div className="col-sm-12 mt-4 mb-0">
                                                        <small className='mb-2 card-rgn-txt'>Register By
                                                            : {" "}  <span className='rgn-date'>{item.registration_end_date !== '' ? item.registration_end_date : ''}</span></small>
                                                    </div> */}

                                                    <div className="col-sm-12 mt-4 mb-0 d-flex justify-content-between">
                                                        <small className='mb-2 card-rgn-txt'>Register By
                                                            : {" "}  <span className='rgn-date'>{item.registration_end_date !== '' ? item.registration_end_date : ''}</span></small>

                                                            {AccessRightFlag === '1' ?
                                                                <>
                                                                    {OrgUserAccessModules.Insight === 1 || OrgUserAccessModules.Insight === 2 ?
                                                                        <div className='report-icon tooltip' onClick={(e) => { navigate('/event_dashboard/' + item.id, { state: { event_name: item.event_name } }); }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"/></svg>
                                                                            <span className="tooltiptext position-fixed w-auto">View Details</span>
                                                                        </div>
                                                                    : null }
                                                                </>
                                                            : 
                                                                <div className='report-icon tooltip' onClick={(e) => { navigate('/event_dashboard/' + item.id, { state: { event_name: item.event_name } }); }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"/></svg>
                                                                    <span className="tooltiptext position-fixed w-auto">View Details</span>
                                                                </div>
                                                            }
                                                    </div>

                                                    <div className="col-sm-6 my-3">
                                                        <p style={{ whiteSpace: "nowrap" }}>
                                                        {item.TicketDetails && item.TicketDetails.length > 0 && (
                                                            item.TicketDetails.some(ticket => parseInt(ticket.ticket_status) === 2) ? (
                                                            <span className="current__price m-0">Free</span>
                                                            ) : (
                                                            item.TicketDetails.map((ticket, index) => (
                                                                parseInt(ticket.ticket_status) === 1 && (
                                                                <span key={index}>
                                                                    {index > 0 && " - "}
                                                                    {ticket.show_early_bird === 1 ? (
                                                                    <span className="text-left">
                                                                        <span className="ticket-prices">
                                                                        <span className="original-price"> ₹{ticket.ticket_price}</span>&nbsp;
                                                                        <span className="current__price m-0">₹{ticket.discount_ticket_price}</span>
                                                                        </span>
                                                                    </span>
                                                                    ) : (
                                                                    <span className="current__price m-0">₹{ticket.ticket_price}</span>
                                                                    )}
                                                                </span>
                                                                )
                                                            ))
                                                            )
                                                        )}
                                                        </p>
                                                    </div>

                                                    {AccessRightFlag === '1' ?
                                                        <>
                                                            {OrgUserAccessModules.Event === 2 ?
                                                                <div className="col-sm-6 my-3 text-right tooltip">
                                                                    <div className="form-check form-switch d-flex align-items-left justify-content-end my-2 ">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            value={item.event_status}
                                                                            checked={item.event_status}
                                                                            onChange={(e) => setEventStatus(!EventStatus)}
                                                                            onClick={(e) => { ChangeEventStatus(e, index, 'change_status'); setActionFlag('change_status'); setEventEditId(item.id); setEventType(item.event_type); setevntStatus(item.event_status); seteventIndex(index); }}
                                                                        />
            
                                                                    </div>
                                                                    <span className="tooltiptext position-fixed w-auto">Event {item.event_status === true ? 'Active' : 'Deactive'}</span>
                                                                </div>
                                                            : null }
                                                        </>
                                                    : 
                                                        <div className="col-sm-6 my-3 text-right tooltip">
                                                            <div className="form-check form-switch d-flex align-items-left justify-content-end my-2 ">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    value={item.event_status}
                                                                    checked={item.event_status}
                                                                    onChange={(e) => setEventStatus(!EventStatus)}
                                                                    onClick={(e) => { ChangeEventStatus(e, index, 'change_status'); setActionFlag('change_status'); setEventEditId(item.id); setEventType(item.event_type); setevntStatus(item.event_status); seteventIndex(index); }}
                                                                />

                                                            </div>
                                                            <span className="tooltiptext position-fixed w-auto">Event {item.event_status === true ? 'Active' : 'Deactive'}</span>
                                                        </div>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))
                                : 
                               
                                <div className="product__section--inner py-5 text-center">
                                    <img src={NoEventImg} alt="" style={{ width: 250 }} />
                                    <h4 className="pt-4">No events found</h4>
                                    <p>
                                        Sorry, There are no events added yet. Please design your event now!
                                    </p>
                                </div>
                                
                                }
                        </div>
                      </>
                    : null }


                        {/*  */}
                    </div>
                </section>


            </main>
        </>

    )
}

export default EditEvent