import axios from '../axios';
import React, { useEffect, useState, useCallback } from 'react'
import LoaderImg from '../img/logo/running.gif';
import { toast } from 'react-toastify';

const AddNewOrgUser = (props) => {
    
    const [FirstName, setFirstName] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastName, setLastName] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [Email, setEmail] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [CPassword, setCPassword] = useState("");
    const [CPasswordError, setCPasswordError] = useState("");
    const [Mobile, setMobile] = useState("");
    const [MobileError, setMobileError] = useState("");
    const [DOB, setDOB] = useState("");
    const [DOBError, setDOBError] = useState("");
    const [Gender, setGender] = useState("");
    const [GenderError, setGenderError] = useState("");
    const [Loader, setLoader] = useState(false);
    const [GeneralApiError, setGeneralApiError] = useState("");
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);

    const [UserRole, setUserRole] = useState(0);
    const [UserRoleError, setUserRoleError] = useState("");

    const [AllRolesData, setAllRolesData] = useState([]);
    const [AllEventsData, setAllEventsData] = useState([]);
  
    const [ApplayEvents, setApplayEvents] = useState(1);
    const [AllEvents, setAllEvents] = useState('active');  
    const [SelectedEvent, setSelectedEvent] = useState('');
    const [SelectedEventError, setSelectedEventError] = useState("");
    
   
    // const handlePasswordToggle = (e) => { 
    //     e.preventDefault()
    //     setShowPassword(!showPassword);
    // };

    // const handleCPasswordToggle = (e) => {
    //     e.preventDefault()
    //     setShowCPassword(!showCPassword);
    // };
    // console.log(props.UserId);

    useEffect(() => {
        axios.post("get_roles")
            .then((response) => {
                // console.log(response.data);
                setAllRolesData(response.data.data.AllRoles);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    useEffect(() => {
        axios.post("get_events")
            .then((response) => {
                // console.log(response.data);
                setAllEventsData(response.data.data.AllEvents);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);


    useEffect(() => {
        if(props.UserId !== ''){

            setLoader(true);
            const payload = {
               org_user_id: props.UserId !== '' ? props.UserId : 0,
            };
            axios.post("edit_org_user", payload)
            .then((response) => {
              
                if (response.status === 200) {
                    const UserDetails = response.data.data.AllOrgUsers[0];
                    // console.log(UserDetails);
                    setFirstName(UserDetails.firstname); setLastName(UserDetails.lastname); 
                    setEmail(UserDetails.email); setMobile(UserDetails.mobile); 
                    setDOB(UserDetails.dob); setGender(UserDetails.gender); 
                    setApplayEvents(UserDetails.event_selected_type);
                    setUserRole(UserDetails.user_role);
                    if(UserDetails.event_selected_type === 1){
                        setAllEvents('active'); setSelectedEvent('');
                    }else{ setSelectedEvent('active'); setAllEvents(''); }

                    setAllEventsData(UserDetails.eventData);
                }
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                console.log(error);
            })
        }
    
      }, [props.UserId])

// console.log(AllEventsData);
    
    const handleEventChange = (index, evnt) => {
   
        let EventArray = [...AllEventsData];
        EventArray[index]['checked'] = evnt.target.checked;
        setAllEventsData(EventArray);
    //console.log(TicketsData);
    }
    
    const handleValidation = () => {
   
        var flag = true;
        
        if (UserRole === 0) {
          setUserRoleError("Please select role");
          flag = false
        } else {
          setUserRoleError("");
        }

        if (FirstName === "") {
          setFirstNameError("Please enter first name");
          flag = false
        } else {
          setFirstNameError("");
        }

        if (LastName === "") {
          setLastNameError("Please enter last name");
          flag = false
        } else {
          setLastNameError("");
        }

        if (Mobile === "") {
          setMobileError("Please enter contact number");
          flag = false
        } else {
          setMobileError("");
        }

        if (DOB === "") {
           setDOBError("Please select date of birth");
           flag = false
        } else {
           setDOBError("");
        }

        if (Email === "") {
          setEmailError("Please select email id");
          flag = false
        }else if(Email !== ""){
           
            const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}(?:, ?[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})*$/;
            const isValid = emailRegex.test(Email);
           
            if (!isValid){
                setEmailError("Invalid email id. Please enter valid email id");
              flag = false;
            }else {
                 setEmailError("");
            }
        }else {
          setEmailError("");
        }
       
        if (Gender === "") {
           setGenderError("Please select gender");
           flag = false
        } else {
           setGenderError("");
        }
    
        var error_cat_flag = 0;
        if(ApplayEvents === 2){
          for (var j = 0; j < AllEventsData.length; j++) {
            if (AllEventsData[j]['checked'] === true) {
              error_cat_flag = 1;
            }
          }
          //console.log(error_cat_flag);
          if (error_cat_flag === 0) {
            setSelectedEventError("Please select at least one event");
            flag = false;
          } else {
            setSelectedEventError("");
          }
        }else{
           setSelectedEventError("");
        }

        return flag;
      };

    const HandleSubmit = (e) => {

        e.preventDefault();
        const flag = handleValidation();

        if(flag) {
            setLoader(true);
            const jsonData = AllEventsData ? JSON.stringify(AllEventsData) : '';
            const Payload = {
                "edit_org_user_id": props.UserId !== '' ? props.UserId : 0,
                "user_role": UserRole,
                "firstname": FirstName,
                "lastname": LastName,
                "email": Email,
                "mobile": Mobile,
                "dob": DOB,
                "gender": Gender,
                "event_selected_type": ApplayEvents,
                "event_data": jsonData
            }
            // console.log(Payload);

            axios.post("add_edit_org_user", Payload).then((response) => {
                // console.log(response);
                // console.log(response.status);
                if (response.status === 200) {
                   
                    if(response.data.data === 1 || response.data.data === 2){
                        toast.error(response.data.message);
                    }else{
                        toast.success(response.data.message);
                        props.setAddNewUsers(false);
                        props.setApiCall(true);
                    }
                    setLoader(false);
                }

            }).catch((response) => {
                setLoader(false);
            });

        }
    }

    return (
        <>
          {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
            <div className="row justify-content-center py-5 org-team">
                <div className="section__header mb-4 d-flex align-items-center justify-content-between">
                    <h2 className="section__header--title h3">Organising Team - Add User</h2>
                </div>
            </div>
            <div className="row justify-content-center py-4">
                <div className="col-sm-12">
                    {/* onSubmit={(e) => HandleSubmit(e)} */}
                    <form onSubmit={(e) => HandleSubmit(e)} >
                        <div className="profile-card">
                            <div className="card-body p-5">
                                <div className="row align-items-center">

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating">
                                            <select className="form-control" name="category" value={UserRole} onChange={(e) => { setUserRole(e.target.value); }}>
                                            <option value="0">-- Select Roles --</option>
                                            {AllRolesData.map((item) => {
                                                return (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                                );
                                            })}
                                            </select>
                                            <label>Manage Roles <span className="checkout__input--label__star">*</span></label>
                                        </div>
                                        <small className="text-danger mb-5">{UserRoleError}</small>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        &nbsp;
                                    </div>
                                     

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating">
                                            <input className="form-control" placeholder="First name" id='FirstName' type="text" value={FirstName} onChange={(e) => {
                                                setFirstName(e.target.value)
                                            }} />
                                            <label className="checkout__input--label mb-1" htmlFor="FirstName">First Name <span className="checkout__input--label__star">*</span></label>
                                            <small className="text-danger mb-5">{FirstNameError}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating">

                                            <input className="form-control" placeholder="Last name" type="text" id="LastName" value={LastName} onChange={(e) => {
                                                setLastName(e.target.value)
                                            }} />
                                            <label className="checkout__input--label mb-1" htmlFor="LastName">Last Name <span className="checkout__input--label__star">*</span></label>
                                            <small className="text-danger mb-5">{LastNameError}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating">

                                            <input className="form-control" placeholder="Contact number" type="text" id='Mobile' value={Mobile} onChange={(e) => {
                                                setMobile(e.target.value)
                                            }} maxLength={10} minLength={10} />
                                            <label className="checkout__input--label mb-1" htmlFor="Mobile">Contact number <span className="checkout__input--label__star">*</span></label>
                                            <small className="text-danger mb-5">{MobileError}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating">

                                            <input className="form-control" placeholder="Email Id" type="text" id='Email' value={Email} onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} />
                                            <label className="checkout__input--label mb-1" htmlFor="Email">Email Id <span className="checkout__input--label__star">*</span></label>
                                            <small className="text-danger mb-5">{EmailError}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating">
                                            <input className="form-control" type="date" id='DOB' value={DOB} onChange={(e) => {
                                                setDOB(e.target.value)
                                            }} max={new Date().toISOString().split('T')[0]} />
                                            <label className="select-label" htmlFor="DOB">Date Of Birth <span className="checkout__input--label__star">*</span></label>
                                            <small className="text-danger mb-5">{DOBError}</small>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 mb-20">
                                        <div className="form-floating">

                                            <div className="form-floating">

                                                <select className="form-control" id="Gender" value={Gender} onChange={(e) => { setGender(e.target.value) }}>
                                                    <option value={0}>Select Gender</option>
                                                    <option value={1}>Male</option>
                                                    <option value={2}>Female</option>
                                                    <option value={3}>Other</option>
                                                </select>
                                                <label className="checkout__input--label mb-1" htmlFor="Gender">Gender <span className="checkout__input--label__star">*</span></label>

                                                <small className="text-danger mb-5">{GenderError}</small>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating d-flex align-items-center">
                                            <input
                                                className="form-control"
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                id="Password"
                                                value={Password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                autoComplete="off"
                                            />

                                            {showPassword ?

                                                <svg onClick={(e) => handlePasswordToggle(e)} style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>

                                                :

                                                <svg onClick={(e) => handlePasswordToggle(e)} style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>

                                            }
                                            <label className="checkout__input--label mb-1" htmlFor="Password">
                                                Password <span className="checkout__input--label__star">*</span>
                                            </label>

                                        </div>
                                        <small className="text-danger mb-5">{PasswordError}</small>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mb-20">
                                        <div className="form-floating d-flex align-items-center">
                                            <input
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                type={showCPassword ? 'text' : 'password'}
                                                id='CPassword'
                                                value={CPassword}
                                                onChange={(e) => setCPassword(e.target.value)}
                                                autoComplete="off"
                                            />

                                            {showCPassword ?

                                                <svg style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" onClick={(e) => handleCPasswordToggle(e)} /></svg>
                                                :
                                                <svg style={{ position: "absolute", right: "10px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" onClick={(e) => handleCPasswordToggle(e)} /></svg>
                                            }
                                            <label className="checkout__input--label mb-1" htmlFor="CPassword">
                                                Confirm Password <span className="checkout__input--label__star">*</span>
                                            </label>

                                        </div>
                                        <small className="text-danger mb-5">{CPasswordError}</small>
                                    </div> */}

                                    <div className="col-lg-6 mb-20">
                                        
                                        <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mt-4">
                                        
                                            {/* <h2 className="section__header--title h3"> Applay Tickets</h2> */}
                                            <div className="ebi">
                                                <div className="event-visibility hand_cursor">
                                                <div className={'visibility gap-1 ' + AllEvents} onClick={() => { setApplayEvents(1); setAllEvents('active'); setSelectedEvent(''); }}>
                                                    <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height={20}
                                                    viewBox="0 -960 960 960"
                                                    width={20}
                                                    >
                                                    <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                                                    </svg>
                                                    All Events
                                                </div>

                                                <div className={'visibility gap-1 ' + SelectedEvent} onClick={() => { setApplayEvents(2); setAllEvents(''); setSelectedEvent('active'); }} > 
                                                    <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height={20}
                                                    viewBox="0 -960 960 960"
                                                    width={20}
                                                    >
                                                    <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                                                    </svg>
                                                    Select Events
                                                </div>

                                                </div>
                                            </div>
                                        </div>
                                                
                                    </div>
                                    <div className="col-lg-6 mb-20">&nbsp;</div>

                                    {AllEventsData && AllEventsData.length > 0 ?
                                        AllEventsData.map((item, index) => (
                                           <>
                                              {ApplayEvents === 2 ?
                                                <div className="col-sm-4 my-3" key={index+'11'}>
                                                <div className="plans">
                                                    <label className="plan basic-plan">
                                                    <input name="1"
                                                        type="checkbox"
                                                        className="btn-check"
                                                        value={item.checked}
                                                        defaultChecked={item.checked}
                                                        onClick={(evnt) => { handleEventChange(index, evnt); }}
                                                    />
                                                    <div className="plan-content">
                                                        <div className="plan-details" for='btn-check-outlined-1'>
                                                        <span className="h3 m-0"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160q17 0 28.5-11.5T520-480q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm320 440H160q-33 0-56.5-23.5T80-240v-160q33 0 56.5-23.5T160-480q0-33-23.5-56.5T80-560v-160q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v160q-33 0-56.5 23.5T800-480q0 33 23.5 56.5T880-400v160q0 33-23.5 56.5T800-160Zm0-80v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102h640ZM480-480Z"/></svg> {item.name}</span>
                                                        </div>
                                                    </div>
                                                    </label>
                                                </div>
                                                </div>  : null }
                                           </>
                                        ))
                                    : null }

                                </div>

                                <small className="text-danger">{SelectedEventError}</small>


                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-center gap-3">
                                        <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                            <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { props.setAddNewUsers(false); props.setUserId('');  }} 
                                            >Cancel</div>
                                           
                                            <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddNewOrgUser