import axios from '../axios';
import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import SuccessResetPassword from './SuccessResetPassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = (props) => {
    const { reset_password_token } = useParams();
    const navigate = useNavigate();

    const [NewPassword, setNewPassword] = useState("");
    const [NewPasswordError, setNewPasswordError] = useState("");
    const [CNewPassword, setCNewPassword] = useState("");
    const [CNewPasswordError, setCNewPasswordError] = useState("");
    const [loader, setLoader] = useState("");
    const [GeneralApiError, setGeneralApiError] = useState("");

    const ResetPasswordValidation = useCallback(() => {
        var flag = true;
        if (NewPassword === "") {
            setNewPasswordError("New password field is required.");
            flag = false;
        }else{ setNewPasswordError(''); }
        
        if (CNewPassword === "") {
            setCNewPasswordError("Confirm password field is required.");
            flag = false;
        }else{ setCNewPasswordError(''); }
        
        if (NewPassword !== CNewPassword) {
            setCNewPasswordError("Your password and confirmation password do not match.")
            flag = false;
        }
        return flag;
    }, [NewPassword, CNewPassword]);

    const [SuccessResetPasswordFlag, setSuccessResetPasswordFlag] = useState(false);
    // console.log(SuccessResetPasswordFlag);
    const HandleResetPassword = useCallback((e) => {
        e.preventDefault();
        if (ResetPasswordValidation() && reset_password_token !== "") {
            setLoader(true);
            axios.post("reset_password/" + reset_password_token, {
                "new_password": NewPassword,
                "confirm_new_password": CNewPassword
            }).then((response) => {
               //console.log(response.data.message);
                if (parseInt(response.data.success) === 0) {
                    toast.error(response.data.message);
                    if (response.data.error_field === "new_password") {
                        setNewPasswordError(response.data.message)
                    } else if (response.data.error_field === "confirm_new_password") {
                        setCNewPasswordError(response.data.message)
                    } else {
                        setGeneralApiError(response.data.message);
                        setCNewPasswordError(response.data.message);
                    }
                } else {
                   
                    setSuccessResetPasswordFlag(true);
                    setNewPassword("")
                    setCNewPassword("")
                }
                setLoader(false);

            }).catch((data) => {
                console.log(data);
                // setGeneralApiError(data.response.data.message)
                setLoader(false);
            });
        }
    }, [ResetPasswordValidation, reset_password_token, NewPassword, CNewPassword]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                HandleResetPassword(e);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.body.style.overflow = 'auto';
        };
    }, [NewPassword, CNewPassword, HandleResetPassword]);
    
    useEffect(() => {
        const handleKeyPress = (event) => {
            //console.log('sss');
            if (event.key === "Escape") {
                setSuccessResetPasswordFlag(false); 
                navigate('/');
            }
        };
        // Add event listener when the component mounts
        document.addEventListener("keydown", handleKeyPress);
        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);


    return (
        <>
        <ToastContainer theme="colored" />
            {/* {loader ? <Loader /> : null} */}
            {SuccessResetPasswordFlag ? <SuccessResetPassword setSuccessResetPasswordFlag={setSuccessResetPasswordFlag} /> :

                <>
                    <div className="modal is-visible" id="modal1" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <header className="modal-header quickview__header">
                                <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => { setSuccessResetPasswordFlag(false);  navigate('/');
                                    //props.setOpen(false);
                                }}>✕ </button>
                            </header>
                            <div className="quickview__inner">
                                <div className="row">

                                    <div className="login__section">

                                        <form onSubmit={HandleResetPassword}>
                                            <div className="login__section--inner" >
                                                <div className="p-5">
                                                    <div className="account__login--inner">
                                                        <div className="my-3">
                                                            <label>
                                                                <input className="account__login--input mb-2" placeholder="New Password" type="text" name="username"
                                                                    value={NewPassword} onChange={(e) => {
                                                                        setNewPassword(e.target.value);
                                                                    }} />
                                                            </label>
                                                            <small className="text-danger mb-5">{NewPasswordError}</small>
                                                        </div>


                                                        <div className="my-3">
                                                            <label>
                                                                <input className="account__login--input mb-2" placeholder="Confirm New Password" type="text" name="username"
                                                                    value={CNewPassword} onChange={(e) => {
                                                                        setCNewPassword(e.target.value);
                                                                    }} />
                                                            </label>
                                                            <small className="text-danger mb-5">{CNewPasswordError}</small>
                                                        </div>

                                                        <button className="account__login--btn primary__btn mt-4" type="submit">Continue</button>

                                                        {/* <div className="account__login--divide my-3">
                                                            <span className="account__login--divide__text">OR</span>
                                                        </div>
                                                        <div className="account__social d-flex justify-content-center mb-5">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px" height="25px"><path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg>
                                                            Continue with Google
                                                        </div> */}
                                                        <br /><br />
                                                        <p>By continuing, you agree to YooTooCanRun's {" "}
                                                            <Link to="https://youtoocanrun.com/terms-and-conditions-for-use/" target="blank" >Terms of Services</Link> and <Link to="https://youtoocanrun.com/privacy-policy/" target="blank"> Privacy Statement</Link>.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }


        </>)
}

export default ResetPassword