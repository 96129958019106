import React, { useEffect, useState, useCallback } from 'react'
import axios from '../axios'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const EditOrganizer = (props) => {
    const Section = props.Tab;
    const [OrganiserId, setOrganiserId] = useState(0);
    const [Name, setName] = useState("");
    const [NameError, setNameError] = useState("");
    const [Email, setEmail] = useState('');
    const [EmailError, setEmailError] = useState("");
    const [Mobile, setMobile] = useState('');
    const [MobileError, setMobileError] = useState("");
    const [About, setAbout] = useState('');
    const [AboutError, setAboutError] = useState("");
    const [GST, setGST] = useState("");
    const [BannerImage, setBannerImage] = useState();
    const [LogoImage, setLogoImage] = useState();
    const [BannerImageError, setBannerImageError] = useState();
    const [LogoImageError, setLogoImageError] = useState();

    const [CompanyPancard, setCompanyPancard] = useState();
    const [CompanyPancardErr, setCompanyPancardErr] = useState();
    const [GstCertificate, setGstCertificate] = useState();
    const [GstCertificateError, setGstCertificateError] = useState();

    const [GSTNo, setGSTNo] = useState("");
    const [GSTNoError, setGSTNoError] = useState("");
    const [GSTPercent, setGSTPercent] = useState("18");
    const [GSTPercentError, setGSTPercentError] = useState("");

    const [ContactPerson, setContactPerson] = useState("");
    const [ContactPersonError, setContactPersonError] = useState("");

    const [ContactNumber, setContactNumber] = useState("");
    const [ContactNumberError, setContactNumberError] = useState("");

    const [OrganiserData, setOrganiserData] = useState([]);
    const [SendEmailFlag, setSendEmailFlag] = useState("");

    useEffect(() => {
        axios.post("get_organizer", {})
            .then((response) => {
               // console.log(response.data.data.organizerData[0]);
                setOrganiserData(response.data.data.organizerData);
                if (response.data.data.organizerData.length > 0) {
                    let organiser = response.data.data.organizerData[0];
                    setOrganiserId(organiser.id);
                    setName(organiser.name);
                    setEmail(organiser.email);
                    setMobile(organiser.mobile);
                    setAbout(organiser.about);
                    setGST(organiser.gst);
                    setGSTNo(organiser.gst_number);
                    // setGSTPercent(organiser.gst_percentage);
                    setContactPerson(organiser.contact_person);
                    setContactNumber(organiser.contact_no);
                    setLogoImage(organiser.logo_image);
                    setBannerImage(organiser.banner_image);
                    setSendEmailFlag(organiser.send_email_flag);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const handleAbout = (e, editor) => {
        const data = editor.getData();
        setAbout(data)
    };
    // console.log(GST);

    const ValidateOrganizer = useCallback((e) => {
        setNameError("");
        setEmailError("");
        setMobileError("");
        setAboutError("");
        setGSTNoError("");
        setGSTPercentError("");
        setContactPersonError("");
        setContactNumberError("");
        let flag = true;
        let flag_field = '';
        if (Name.trim() === "") {
            setNameError("Please enter your name.");
            flag = false;
            flag_field = 'Name';
        } else if (!/^[A-Za-z\s.]+$/i.test(Name.trim())) {
            setNameError("Invalid characters in name.");
            flag = false;
            flag_field = 'Name';
        } else if (Name.length > 100) {
            setNameError("Maximum 100 characters allow in name.");
            flag = false;
            flag_field = 'Name';
        }

        if (Email === "") {
            setEmailError("Please enter Email id.");
            flag = false;
            flag_field = 'Email';
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            setEmailError('Please enter a valid email address');
            flag = false;
            flag_field = 'Email';
        }

        if (!/^[0-9]{10}$/i.test(Mobile)) {
            setMobileError("Please enter a valid 10-digit mobile number.");
            flag = false;
            flag_field = 'Mobile';
        }

        if (About === "") {
            setAboutError("Please enter About.");
            flag = false;
            flag_field = 'About';
        }

        if (GST === 1) {
            if (GSTNo === "") {
                setGSTNoError("Please enter GST Number.");
                flag = false;
                flag_field = 'GSTNo';
            } else if (!/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/i.test(GSTNo)) {
                setGSTNoError("Invalid GST Number format. Please enter a valid GST Number.");
                flag = false;
                flag_field = 'GSTNo';
            }

            if (GSTPercent === "" || GSTPercent === 0) {
                setGSTPercentError("Please enter GST Percentage.");
                flag = false;
                flag_field = 'GSTPercent';
            }
        }

        if (ContactPerson !== "") {
            if (!/^[A-Za-z\s]+$/i.test(ContactPerson.trim())) {
                setContactPersonError("Invalid characters in contact person name. Only alphabets and spaces are allowed.");
                flag = false;
                flag_field = 'Contact Person Name';
            }
        }


        // console.log(ContactPerson,ContactNumber);
        if (ContactNumber !== "" || ContactNumber === 0 || ContactNumber === 'null') {
            if (!/^[0-9]{10}$/i.test(ContactNumber)) {
                setContactNumberError("Please enter a valid 10-digit Contact number.");
                flag = false;
                flag_field = 'ContactNumber';
            }
        }

        if (LogoImage === "") {
            setLogoImageError('Please select an logo image file.');
            flag = false;
            flag_field = 'LogoImage';
        }
        // console.log("HandleValidation field", flag_field);
        // console.log("HandleValidation flag", flag);

        return flag;
    }, [Name, Email, Mobile, About, GST, ContactPerson, ContactNumber, LogoImage, GSTNo, GSTPercent]);


    // console.log(GST);
    const SubmitOrganizer = useCallback((e) => {
        e.preventDefault();
        const flag = ValidateOrganizer();
        if (flag) {
            const payload = {
                "oragniser_id": OrganiserId,
                "name": Name,
                "email": Email,
                "mobile": Mobile,
                "about": About,
                "gst": GST ? 1 : 0,
                "gst_number": GSTNo,
                "gst_percentage": GSTPercent,
                "contact_person": ContactPerson,
                "contact_no": ContactNumber
            }
            const formData = new FormData();

            Object.keys(payload).forEach(key => {
                formData.append(key, payload[key]);
            });
            // console.log(CompanyPancard, GstCertificate);
            formData.append('banner_image', BannerImage);
            formData.append('logo_image', LogoImage);
            formData.append('company_pancard', CompanyPancard);
            formData.append('gst_certificate', GstCertificate);

            // console.log(formData);

            axios.post("add_edit_organizer", formData)
                .then((response) => {
                    toast.success(response.data.message);
                    props.setTab('show');
                    localStorage.setItem("Company_info", 1);
                })
                .catch((error) => {
                    // console.log(error);
                    if (error.response.status === 400) {
                        toast.error(error.response.data.message);
                    }
                })
                
                if(SendEmailFlag === 0){
                    // ------- send email to user
                    axios.post("send_email_organiser_confirmation")
                    .then((response) => {
                        if(response.data.success === 200){
                            toast.success(response.data.message);
                        }else{
                            toast.error(response.data.message);
                        } 
                    })
                    .catch((error) => {
                    console.log(error);
                    });
                }
        }
    }, [ValidateOrganizer, OrganiserId, Name, Email, Mobile, About, GST, GSTNo, GSTPercent, ContactPerson, ContactNumber, BannerImage, LogoImage, CompanyPancard, GstCertificate, props]);

    const handleBannerFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setBannerImage(file);
        } else {
            setBannerImageError('Please select an image file.');
            setBannerImage("");
        }
    }

    const handleLogoFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setLogoImage(file);
        } else {
            setLogoImageError('Please select an image file.');
            setLogoImage("");
        }
    }

    const validateForm = (e, input) => {
        e.preventDefault();

        const fileInput = e.target.files[0];
        const validExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];

        if (fileInput) {
            const fileExtension = fileInput.name.split('.').pop().toLowerCase();
            if (!validExtensions.includes(fileExtension)) {
                if (input === 'company_pancard') {
                    setCompanyPancardErr("Please select a file with a valid extension (jpg, jpeg, png, pdf, doc, docx)");
                } else if (input === 'gst_certificate') {
                    setGstCertificateError("Please select a file with a valid extension (jpg, jpeg, png, pdf, doc, docx)");
                }
            } else {
                if (input === 'company_pancard') { setCompanyPancard(fileInput); setCompanyPancardErr("") }
                else if (input === 'gst_certificate') { setGstCertificate(fileInput); setGstCertificateError("") }
            }
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const openLightbox = (index) => {
        setImageIndex(index);
        setIsOpen(true);
    };

    return (
        <>
            <form onSubmit={(e) => SubmitOrganizer(e)}>
                <div className={(Section === 'edit_org' ? "row justify-content-center py-4" : "row justify-content-center py-4 d-none")}>
                    <div className="section__header mb-4">
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <label>Organisation Name<span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{NameError}</small>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={Email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                    <label>Email ID<span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{EmailError}</small>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        value={Mobile}
                                        onChange={(e) => { setMobile(e.target.value) }}
                                    />
                                    <label>Mobile <span className="checkout__input--label__star">*</span></label>
                                </div>
                                <small className="text-danger">{MobileError}</small>
                            </div>
                        </div>

                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <label>About<span className="checkout__input--label__star">*</span></label>
                                <div className="form-floating mt-3 mb-0">
                                    {/* <label>About</label> */}
                                    <CKEditor
                                        config={{
                                            // extraPlugins: [uploadPlugin]
                                        }}
                                        editor={ClassicEditor}
                                        data={About ? About : ""}
                                        onChange={handleAbout}
                                    />
                                </div>
                            </div>
                            <small className="text-danger">{AboutError}</small>
                        </div>
                        <br />
                        {/* {console.log(GST)} */}
                        <div className="row">
                            <div className="col-lg-12 mb-20">
                                <div className="form-check form-switch d-flex align-items-left justify-content-left">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        value={GST}
                                        checked={GST}
                                        onChange={() => {
                                            setGST(!GST);
                                            // console.log('GST:', !GST); // Log the value of GST after toggling
                                        }}
                                    />
                                    <label className="form-check-label">GST</label>
                                </div>
                            </div>
                        </div>


                        {
                            GST ?
                                <>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-floating mt-3 mb-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={GSTNo}
                                                    onChange={(e) => {
                                                        setGSTNo(e.target.value);
                                                    }}
                                                />
                                                <label>GST Number <span className="checkout__input--label__star">*</span></label>
                                            </div>
                                            <small className="text-danger">{GSTNoError}</small>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-floating mt-3 mb-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={GSTPercent}
                                                    onChange={(e) => {
                                                        setGSTPercent(e.target.value.replace(/\D/g, ''));
                                                    }}
                                                    disabled
                                                />
                                                <label>GST Percentage <span className="checkout__input--label__star">*</span></label>
                                            </div>
                                            <small className="text-danger">{GSTPercentError}</small>
                                        </div>

                                    </div>
                                </>
                                :
                                null
                        }

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={ContactPerson}
                                        onChange={(e) => setContactPerson(e.target.value)}
                                    />
                                    <label> Contact Person</label>
                                    <small className="text-danger">{ContactPersonError}</small>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-floating mt-3 mb-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={ContactNumber}
                                        onChange={(e) => setContactNumber(e.target.value)}
                                    />
                                    <label>Contact Number</label>
                                </div>
                                <small className="text-danger">{ContactNumberError}</small>
                            </div>
                        </div>
                        <br />
                        {/* {console.log(LogoImage)} */}
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Organiser Logo Image <span className="checkout__input--label__star">*</span>
                                    <small style={{ color: 'gray' }}>(In jpg, jpeg, png formats. Max upto 5MB.
                                        <br /> Dimensions- 1920 px x 744 px)</small>
                                </label>
                                <input type="file" onChange={(e) => handleLogoFileChange(e)} />
                                {LogoImage !== "" ?
                                    <img
                                        src={LogoImage}
                                        alt=""
                                        style={{ height: "50px", width: "50px", cursor: "pointer" }}
                                        onClick={() => openLightbox(0)}
                                    />
                                    : ""}

                                <small className="text-danger">{LogoImageError}</small>
                            </div>
                            <div className="col-sm-6">
                                <label> Banner Image  <small style={{ color: 'gray' }}>(In jpg, jpeg, png formats. Max upto 5MB.
                                    <br /> Dimensions- 1920 px x 744 px)</small></label>
                                <input type="file" onChange={(e) => handleBannerFileChange(e)} />
                                {BannerImage ?
                                    <img src={BannerImage ? BannerImage : ""} alt="" style={{ height: "50px", width: "100px", cursor: "pointer" }} onClick={() => openLightbox(1)} />
                                    :
                                    null}
                                <small className="text-danger">{BannerImageError}</small>
                            </div>
                        </div>

                        <br />
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Registered Pancard
                                    <small style={{ color: 'gray' }}>(In jpg, jpeg, png formats. Max upto 5MB.
                                        <br /> Dimensions- 1920 px x 744 px)</small>
                                </label>
                                <input type="file" onChange={(e) => validateForm(e, "company_pancard")} />
                                <small className="text-danger">{CompanyPancardErr}</small>
                            </div>
                            <div className="col-sm-6">
                                <label>Registered GST Certificate
                                    <small style={{ color: 'gray' }}>(In jpg, jpeg, png formats. Max upto 5MB.
                                        <br /> Dimensions- 1920 px x 744 px)</small>

                                </label>
                                <input type="file" onChange={(e) => validateForm(e, "gst_certificate")} />

                                <small className="text-danger">{GstCertificateError}</small>
                            </div>
                        </div>

                        <br /><br /><br /><br />

                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-center gap-3">
                                <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                    <div className="continue__shipping--btn secondary__btn border-radius-5"
                                    >
                                        <div
                                            onClick={() => props.setTab('show')}
                                        > Cancel</div>
                                    </div>

                                    <button className="continue__shipping--btn primary__btn border-radius-5" type="submit">Save</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </form >

            {isOpen && (
                <Lightbox
                    mainSrc={imageIndex === 0 ? LogoImage : BannerImage}
                    onCloseRequest={() => setIsOpen(false)}
                    reactModalStyle={{
                        overlay: { zIndex: 9999 }
                    }}
                />
            )}
        </>
    )
}

export default EditOrganizer