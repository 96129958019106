import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
import LoaderImg from '../img/logo/running.gif';
// import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';

const EventDuration = (props) => {

    const navigate = useNavigate();
    const [Pincode, setPincode] = useState('');
    const [PincodeError, setPincodeError] = useState('');
    const inputRef = useRef(null);
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);

    const [EventTimeZoneAll, setEventTimeZoneAll] = useState([]);
    const [TimeZoneId, setTimeZoneId] = useState(0);
    const [CountryAll, setCountryAll] = useState([]);
    const [CountryId, setCountryId] = useState(0);
    const [StateAll, setStateAll] = useState([]);
    const [StateId, setStateId] = useState(0);
    const [CityAll, setCityAll] = useState([]);
    const [CityId, setCityId] = useState(0);

    const [RepeatingEventStatus, setRepeatingEventStatus] = useState(false);
    const [EventStartDate, setEventStartDate] = useState('');
    const [EventStartTime, setEventStartTime] = useState('');
    const [EventEndDate, setEventEndDate] = useState('');
    const [EventEndTime, setEventEndTime] = useState('');

    const [EventRepeatingType, setEventRepeatingType] = useState(0);
    const [RepeatStartTime, setRepeatStartTime] = useState('');
    const [RepeatEndTime, setRepeatEndTime] = useState('');
    const [Address, setAddress] = useState('');
    // const [Loader, props.setLoader] = useState(false);

    const [TimeZoneIdError, setTimeZoneIdError] = useState('');
    const [EventStartDateError, setEventStartDateError] = useState('');
    const [EventStartTimeError, setEventStartTimeError] = useState('');
    const [EventEndDateError, setEventEndDateError] = useState('');
    const [EventEndTimeError, setEventEndTimeError] = useState('');

    const [EventRepeatingTypeError, setEventRepeatingTypeError] = useState('');
    const [RepeatStartTimeError, setRepeatStartTimeError] = useState('');
    const [RepeatEndTimeError, setRepeatEndTimeError] = useState('');

    const [CountryIdError, setCountryIdError] = useState('');
    const [StateIdError, setStateIdError] = useState('');
    const [CityIdError, setCityIdError] = useState('');
    const [AddressError, setAddressError] = useState('');

    const [TimeZoneVal, setTimeZoneVal] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [ShowSuggestion, setShowSuggestion] = useState(false);
    const DivRef = useRef(null);
    const [CountryVal, setCountryVal] = useState('');
    const [CountrySuggestions, setCountrySuggestions] = useState([]);
    const [ShowCountrySuggestion, setShowCountrySuggestion] = useState(false);
    const [StateVal, setStateVal] = useState('');
    const [StateSuggestions, setStateSuggestions] = useState([]);
    const [ShowStateSuggestion, setShowStateSuggestion] = useState(false);
    const [CityVal, setCityVal] = useState('');
    const [CitySuggestions, setCitySuggestions] = useState([]);
    const [ShowCitySuggestion, setShowCitySuggestion] = useState(false);

    const EventDetailsData = props.EventData ? props.EventData : '';
    // console.log(Address); 
    const [RegistrationStartDate, setRegistrationStartDate] = useState('');
    const [RegistrationStartTime, setRegistrationStartTime] = useState('');
    const [RegistrationEndDate, setRegistrationEndDate] = useState('');
    const [RegistrationEndTime, setRegistrationEndTime] = useState('');

    const [RegistrationStartDateError, setRegistrationStartDateError] = useState('');
    const [RegistrationStartTimeError, setRegistrationStartTimeError] = useState('');
    const [RegistrationEndDateError, setRegistrationEndDateError] = useState('');
    const [RegistrationEndTimeError, setRegistrationEndTimeError] = useState('');

    const [EditRegistrationStartDate, setEditRegistrationStartDate] = useState('');
    const [EditEventStartDate, setEditEventStartDate] = useState('');
    const [AddressApiCall, setAddressApiCall] = useState(false);
    
    const [Latitude, setLatitude] = useState('');
    const [Longitude, setLongitude] = useState('');
    const [GoogleMapLink, setGoogleMapLink] = useState('');
    
   
    // const [loader, props.setLoader] = useState(false);
    // console.log(EditRegistrationStartDate);
    const handleInputChange = () => {
        inputRef.current.blur();
        inputRef1.current.blur();
        inputRef2.current.blur();
        inputRef3.current.blur();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (EventDetailsData) {
            // console.log("Event Details : "+EventDetailsData.time_zone);
            setTimeZoneId(EventDetailsData.time_zone); setTimeZoneVal(EventDetailsData.time_zone_name); setCountryId(EventDetailsData.country); setCountryVal(EventDetailsData.country_name);
            setPincode(EventDetailsData.pincode);
            setStateId(EventDetailsData.state); setCityId(EventDetailsData.city); setStateVal(EventDetailsData.state_name); setCityVal(EventDetailsData.city_name)
            setAddress(EventDetailsData.address);
            setEventStartDate(EventDetailsData.start_date && EventDetailsData.start_date !== 0 ? EventDetailsData.start_date : '');
            setEventStartTime(EventDetailsData.start_time_event && EventDetailsData.start_time_event !== 0 ? EventDetailsData.start_time_event : '');
            setEventEndDate(EventDetailsData.end_date && EventDetailsData.end_date !== 0 ? EventDetailsData.end_date : '');
            setEventEndTime(EventDetailsData.end_time_event && EventDetailsData.end_time_event !== 0 ? EventDetailsData.end_time_event : '');

            setRegistrationStartDate(EventDetailsData.diplay_registration_start_date && EventDetailsData.diplay_registration_start_date !== 0 ? EventDetailsData.diplay_registration_start_date : '');
            setRegistrationStartTime(EventDetailsData.diplay_registration_start_time && EventDetailsData.diplay_registration_start_time !== 0 ? EventDetailsData.diplay_registration_start_time : '');
            setRegistrationEndDate(EventDetailsData.diplay_registration_end_date && EventDetailsData.diplay_registration_end_date !== 0 ? EventDetailsData.diplay_registration_end_date : '');
            setRegistrationEndTime(EventDetailsData.diplay_registration_end_time && EventDetailsData.diplay_registration_end_time !== 0 ? EventDetailsData.diplay_registration_end_time : '');

            setEditRegistrationStartDate(EventDetailsData.diplay_registration_start_date && EventDetailsData.diplay_registration_start_date !== 0 ? EventDetailsData.diplay_registration_start_date : '');
            setEditEventStartDate(EventDetailsData.start_date && EventDetailsData.start_date !== 0 ? EventDetailsData.start_date : '');

            setLatitude(EventDetailsData.latitude && EventDetailsData.latitude !== '' ? EventDetailsData.latitude : '');
            setLongitude(EventDetailsData.longitude && EventDetailsData.longitude !== '' ? EventDetailsData.longitude : '');
            setGoogleMapLink(EventDetailsData.google_map_link && EventDetailsData.google_map_link !== '' ? EventDetailsData.google_map_link : '');
        }
    }, [EventDetailsData])
    //console.log(EventDetailsData);
    // console.log(EventStartDate,EventEndDate,RegistrationStartDate,RegistrationEndDate);

    //------------- Event TimeZone -------------
    useEffect(() => {
        axios.post("timezone")
            .then((response) => {
                //console.log(response.data.status);
                if (response.data.status === 200) {
                    setEventTimeZoneAll(response.data.data.AllTimezones);
                }
            })
            .catch(() => {
                console.log('error');
            });
        // //------------- event form added in manual
        // const payload = {
        //     event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0
        // };
        //   // console.log(payload);
        // axios.post("AddMaualeventFormQuestions", payload)
        // .then((response) => {
        //     if (response.status === 200) {
        //        //toast.success(response.data.message);
        //     }
        // })
        // .catch(() => {
        //     console.log('error');
        // });

    }, []);

    const handleTimeZoneChange = (e) => {
        const value = e.target.value;
        setTimeZoneVal(value);

        // Filter suggestions based on input value
        const filteredSuggestions = EventTimeZoneAll.filter((item) =>
            item.area.toLowerCase().includes(value.toLowerCase())
        );

        const SliceArr = filteredSuggestions.slice(0, 5)
        setSuggestions(SliceArr);
        setShowSuggestion(true);
    };
    const handleSuggestionClick = (e, id, name) => {
        setTimeZoneId(id);
        setTimeZoneVal(name)
        setSuggestions([]);
        // handleSubmit(e)
    };
    const handleClose = (e) => {
        if (DivRef.current && !DivRef.current.contains(e.target)) {
            setShowSuggestion(false);
            setShowCountrySuggestion(false)
        }
    }
    // console.log(TimeZoneId);

    //------------- Country ---------------
    useEffect(() => {
        axios.post("country")
            .then((response) => {
                //console.log(response.data);
                if (response.data.status === 200) {
                    setCountryAll(response.data.data.AllCountries);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    const handleCountryChange = (e) => {
        const value = e.target.value;
        if (e.target.value !== "")
            setCountryVal(value);
        else {
            setCountryVal(''); setCountryCode('')
        }

        // Filter suggestions based on input value
        const filteredSuggestions = CountryAll.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );

        const SliceArr = filteredSuggestions.slice(0, 5)
        setCountrySuggestions(SliceArr);
        setShowCountrySuggestion(true);
        // getCountryCode('');
    };
    const handleCountrySuggestionClick = (e, id, name) => {
        // console.log(name);
        setCountryId(id);
        setCountryVal(name)
        setCountrySuggestions([]);
        // getCountryCode(name);

        setStateId("");
        setStateVal("");
        setCityId("");
        setCityVal("");
        setPincode("");
        setPincodeError("")
    };

    // console.log(CityAll);
    //------------- State ----------------
    useEffect(() => {
        if (CountryId) {
            const payload = {
                country_id: CountryId,
            };
            axios.post("state", payload)
                .then((response) => {
                    //console.log(response.data);
                    if (response.data.status === 200) {
                        setStateAll(response.data.data.AllState);
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        }
    }, [CountryId]);

    const handleStateChange = (e) => {
        const value = e.target.value;
        setStateVal(value);

        // Filter suggestions based on input value
        const filteredSuggestions = StateAll.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );

        const SliceArr = filteredSuggestions.slice(0, 5)
        setStateSuggestions(SliceArr);
        setShowStateSuggestion(true);
    };
    const handleStateSuggestionClick = (e, id, name) => {
        setStateId(id);
        setStateVal(name)
        setStateSuggestions([]);
        // handleSubmit(e)
    };
    //console.log(CountryId,StateId,CityId);
    //------------- City ----------------
    useEffect(() => {
        if (StateId) {
            const payload = {
                state_id: StateId,
            };
            axios.post("city", payload)
                .then((response) => {
                    //console.log(response.data);
                    if (response.data.status === 200) {
                        setCityAll(response.data.data.AllCities);
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        }
    }, [StateId]);

    const handleCityChange = (e) => {
        const value = e.target.value;
        setCityVal(value !== '' ? value : '');
        //console.log(value);
        // Filter suggestions based on input value
        const filteredSuggestions = CityAll.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );

        const SliceArr = filteredSuggestions.slice(0, 5)
        setCitySuggestions(SliceArr);
        setShowCitySuggestion(true);
    };
    const handleCitySuggestionClick = (e, id, name) => {
        setCityId(id);
        setCityVal(name)
        setCitySuggestions([]);
    };

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month index starts from 0
    const day = String(currentDate.getDate()).padStart(2, '0');
    const CurrentDate = `${year}-${month}-${day}`;

    //console.log(CurrentDate);

    const handleValidation = () => {
        //console.log(BorrowingGuaranteed);
        var flag = true;

        if (TimeZoneId === 0) {
            setTimeZoneIdError("Please select Timezone");
            flag = false;
        } else {
            setTimeZoneIdError("");
        }
        if (Pincode === "") {
            setPincodeError("Please enter your pincode.");
            flag = false;
        } else if (!/^\d{6}$/i.test(Pincode)) {
            setPincodeError("Invalid pincode. Please enter valid pincode.");
            flag = false;
        }else{ setPincodeError(""); }

        if (EventStartDate === '' || EventStartDate === 0) {
            setEventStartDateError("Please select event start date");
            flag = false;
        } else { setEventStartDateError(""); }

        if (EventStartTime === '' || EventStartTime === 0) {
            setEventStartTimeError("Please select event start time");
            flag = false;
        } else { setEventStartTimeError(""); }

        if (EventEndDate === '' || EventEndDate === 0) {
            setEventEndDateError("Please select event end date");
            flag = false;
        } else { setEventEndDateError(""); }

        if (EventEndTime === '' || EventEndTime === 0) {
            setEventEndTimeError("Please select event end time");
            flag = false;
        }

        if (EventStartTime !== '' && EventEndTime !== '') {

            const Eventstart = new Date(`${EventStartDate}T${EventStartTime}`);
            const Eventend = new Date(`${EventEndDate}T${EventEndTime}`);

            if (Eventstart >= Eventend) {
                setEventEndTimeError("Please select end time is greater than start time");
                flag = false;
            } else {
                setEventEndTimeError('');
            }
        }

        if (RegistrationStartDate === '' || RegistrationStartDate === 0) {
            setRegistrationStartDateError("Please select registration start date");
            flag = false;
        } else { setRegistrationStartDateError(""); }

        if (RegistrationStartTime === '' || RegistrationStartTime === 0) {
            setRegistrationStartTimeError("Please select registration start time");
            flag = false;
        } else { setRegistrationStartTimeError(""); }

        if (RegistrationEndDate === '' || RegistrationEndDate === 0) {
            setRegistrationEndDateError("Please select registration end date");
            flag = false;
        } else { setRegistrationEndDateError(""); }

        if (RegistrationEndTime === '' || RegistrationEndTime === 0) {
            setRegistrationEndTimeError("Please select registration end time");
            flag = false;
        }

        if (RegistrationStartTime !== '' && RegistrationEndTime !== '') {
            // const start_hr =  parseInt(RegistrationStartTime.split(':')[0]);
            // const end_hr =  parseInt(RegistrationEndTime.split(':')[0]);
            // const start_min =  parseInt(RegistrationStartTime.split(':')[1]);
            // const end_min =  parseInt(RegistrationEndTime.split(':')[1]);

            // if (start_hr > end_hr) {
            //     setRegistrationEndTimeError("Please select end time is greater than start time");
            //     flag = false;
            // }else if(start_min >= end_min) {
            //     setRegistrationEndTimeError("Please select end time is greater than start time");
            //     flag = false;
            // }else{
            //     setRegistrationEndTimeError(''); 
            // }

            const Regstart = new Date(`${RegistrationStartDate}T${RegistrationStartTime}`);
            const Regend = new Date(`${RegistrationEndDate}T${RegistrationEndTime}`);

            if (Regstart >= Regend) {
                setRegistrationEndTimeError("Please select end time is greater than start time");
                flag = false;
            } else {
                setRegistrationEndTimeError('');
            }
        }

        if (EventEndTime !== '' && RegistrationEndTime !== '') {
            const Eventend = new Date(`${EventEndDate}T${EventEndTime}`);
            const Regend = new Date(`${RegistrationEndDate}T${RegistrationEndTime}`);
          // console.log(Eventend,Regend);
            if (Regend > Eventend) {
                setRegistrationEndTimeError("Please select registration end time less than event end time");
                flag = false;
            } else {
                setRegistrationEndTimeError('');
            }
        }

        if (CountryId === 0 || CountryId === null) {
            setCountryIdError("Please select country");
            flag = false;
        } else { setCountryIdError(""); }

        if (StateId === 0 || StateId === null) {
            setStateIdError("Please select state");
            flag = false;
        } else { setStateIdError(""); }

        if (CityId === 0 || CityId === null) {
            setCityIdError("Please select city");
            flag = false;
        } else { setCityIdError(""); }

        if (Address === "" || Address === null) {
            setAddressError("Please enter address");
            flag = false;
        } else { setAddressError(""); }

        // if (EventUrl === "") {
        // //eventNameRef.current.focus();
        // setEventUrlError("Please enter event url");
        // flag = false;
        // }
        // else if (EventUrl !== "" && !validator.isURL(EventUrl)) {
        // setEventUrlError('Please enter valid url');
        // flag = false;
        // }
        // else {
        // setEventUrlError("");
        // }


        return flag;
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            props.setLoader(true);
            props.setEventResponceTabs(1);
            const payload = {
                event_id: localStorage.getItem("EventId"),
                timezone_id: TimeZoneId,
                event_start_date: EventStartDate,
                event_start_time: EventStartTime,
                event_end_date: EventEndDate,
                event_end_time: EventEndTime,
                repeating_event: RepeatingEventStatus === true ? 1 : 0,
                pincode: Pincode,
                country_id: CountryId,
                state_id: StateId,
                city_id: CityId,
                address: Address,
                latitude: Latitude,
                longitude: Longitude,
                registration_start_date: RegistrationStartDate,
                registration_start_time: RegistrationStartTime,
                registration_end_date: RegistrationEndDate,
                registration_end_time: RegistrationEndTime,
                google_map_link: GoogleMapLink
            };
            // console.log(payload);
            axios.post("event_duration", payload)
                .then((response) => {
                    props.setLoader(false);
                    // console.log(response.data.message);
                    if (response.status === 200) {
                        props.setEventResponceTabs(2);
                        // props.setEventDurationMsg(response.data.message);
                        toast.success(response.data.message)
                    }
                })
                .catch(() => {
                    props.setLoader(false);
                    console.log('error');
                });
        }
    };

    const [AddressSearchFlag, setAddressSearchFlag] = useState(false);
    const [CityName, setCityName] = useState('');
    const [StateName, setStateName] = useState('');
    const [CountryName, setCountryName] = useState('');
    const [CountryCode, setCountryCode] = useState('');
    // const [CountryError, setCountryError] = useState('')
    // useEffect(() => {
    //     if (CountryVal !== "") {
    //         getCountryCode(CountryVal);
    //     }
    // }, [CountryVal]);

    const getCountryCode = (CountryName) => {
        // console.log(CountryName);
        if (CountryName !== "") {
            return fetch(`https://restcountries.com/v3.1/name/${CountryName}`)
                .then(response => {
                    if (!response.ok) {
                        setCountryIdError("Failed to fetch country data")
                        throw new Error('Failed to fetch country data');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.length > 0) {
                        const countryCode = data[0].cca2;
                        // console.log(countryCode);
                        setCountryCode(countryCode)
                        setCountryIdError('')
                    } else {
                        setCountryIdError("Country code not found for", CountryName);
                        setCountryCode('')
                    }
                })
                .catch(error => {
                    setCountryIdError("Error fetching country data:", error);
                    setCountryCode('')
                });
        } else {
            setCountryId("")
            setCountryIdError("Please select country");
            setCountryCode('')
        }
    }
    // console.log(CountryVal, CountryCode);

    const GetLocation = (pincode) => {
        // console.log(CountryVal, CountryCode, pincode);
        if (CountryVal !== "") {
            if (pincode.length !== 6) {
                setPincodeError('Pincode must be 6 digits');
                return;
            } else {
                setPincodeError("")
            }
            // console.log(CountryCode);
            if (CountryCode !== "") {
                props.setLoader(true)
                setCityId('');
                setCityVal('');
                setStateId('');
                setStateVal('');
                setPincodeError('');
                axios.get(`https://nominatim.openstreetmap.org/search?postalcode=${pincode}&country=${CountryCode}&format=json`)
                    .then((response) => {
                        // console.log(rLoaderesponse);
                        if (response.length !== 0) {
                            // console.log("response.length !== 0");
                            if (response.data && response.data.length > 0) {
                                // console.log("response.data.length !== 0");
                                const addressData = response.data[0];
                                // console.log(addressData);
                                const display_name = addressData.display_name.split(', ');

                                let city = display_name[display_name.length - 3].trim();
                                const state = display_name[display_name.length - 2].trim();
                                const country = display_name[display_name.length - 1].trim();
                                if (city.toLowerCase().includes('district')) {
                                    city = city.replace(/district/i, '').trim();
                                } else if (city.toLowerCase().includes('city')) {
                                    city = city.replace(/city/i, '').trim();
                                }
                                // console.log("City:", city);
                                // console.log("State:", state);
                                // console.log("Country:", country);

                                setCityName(city);
                                setStateName(state);
                                setCountryName(country)
                                setAddressSearchFlag(true);
                            } else {
                                // console.log("response.data.length === 0");
                                setPincodeError('Location not found for this pincode.');
                                setCityId('');
                                setCityVal('');
                                setStateId('');
                                setStateVal('');
                                props.setLoader(false)
                            }
                        } else {
                            // console.log("response.length === 0");
                            setPincodeError('Location not found for this pincode.');
                            setCityId('');
                            setCityVal('');
                            setStateId('');
                            setStateVal('');
                            props.setLoader(false)
                        }
                    })
                    .catch((error) => {
                        // console.log("catch");
                        setPincodeError('Error fetching location data.');
                        props.setLoader(false)
                        console.error(error);
                    });
            } else {
                // console.log("CountryCode is empty");
                setCountryIdError("Select country first");
                setPincodeError('');
                props.setLoader(false);
            }
        } else {
            // console.log("CountryVal is empty");
            setPincodeError("Select country first");
        }
    }

    useEffect(() => {
        // console.log("here",AddressSearchFlag,loader,CityName, StateName, CountryName);
        if (AddressSearchFlag) {
            const payload = {
                "city_name": CityName,
                "state_name": StateName,
                "country_name": CountryName
            }
            // console.log(payload);
            axios.post("get_location", payload)
                .then((response) => {
                    // console.log(response.data.data);
                    setAddressSearchFlag(false);
                    props.setLoader(false);

                    if (response.data.data.CityId === 0 || response.data.data.StateId === 0 || response.data.data.CountryId === 0) {
                        setPincodeError('Error fetching location data.');
                    } else {
                        handleCitySuggestionClick(0, response.data.data.CityId, response.data.data.NewCityName)
                        handleStateSuggestionClick(0, response.data.data.StateId, response.data.data.NewStateName)
                        // handleCountrySuggestionClick(0, response.data.data.CountryId, response.data.data.NewCountryName)
                    }
                })
                .catch((error) => {
                    setPincodeError('Error fetching location data.');
                    props.setLoader(false)
                    // console.log(error);
                })
        }
    }, [AddressSearchFlag, CityName, CountryName, StateName, props])

    // console.log(CountryId)
    useEffect(() => {
        if (AddressApiCall) {
            if (CountryId !== "") {
                if (CountryId === 101) {
                    const pincodeString = String(Pincode);  // Ensure Pincode is a string
                    if (pincodeString.length !== 6) {
                        setCountryIdError("");
                        setPincodeError('Pincode must be 6 digits');
                        setAddressApiCall(false); // Reset the flag after processing
                        return;
                    }
                    const payload = {
                        "country_id": CountryId,
                        "pincode": Pincode
                    }
                    axios.post("get_address", payload)
                        .then((response) => {
                            if (response.status === 200) {
                                let FullAddress = response.data.data.FullAddress;
                                setStateId(FullAddress.state_id);
                                setStateVal(FullAddress.state_name);
                                setCityId(FullAddress.city_id);
                                setCityVal(FullAddress.city_name)
                                setPincodeError("");
                                setCountryIdError("");
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            } else {
                setCountryIdError("Please select country");
                setPincodeError('');
            }
            setAddressApiCall(false);
        }
    }, [AddressApiCall, CountryId, Pincode])

    // console.log(loader);
    // console.log(CountryId, CityId, CityVal);
    return (
        <>
            {/* {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null} */}
            <div className="main checkout__mian" onClick={(e) => { handleClose(e) }}>
                <form method="post" onSubmit={handleSubmit} >

                    <div className="checkout__content--step section__shipping--address ">
                        <div className="mb-2">
                            <div className="section__header d-flex align-items-center justify-content-between mb-4">
                                <h2 className="section__header--title h3">Event Scheduling</h2>
                                <div className=" secondary__btn border-radius-5 event-card-btn" style={{ cursor: 'pointer' }}>Event card</div>
                            </div>
                            <div className="row ">

                                {/* <TimePicker onChange={onChange} value={value} clockAriaLabel format='h:m'  /> */}

                                <div className="col-sm-12">
                                    <label className="checkout__input--label" htmlFor="input1">
                                        Time Zone <span className="checkout__input--label__star">*</span>
                                    </label>
                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Select Time Zone" type="text" onChange={(e) => handleTimeZoneChange(e)} value={TimeZoneVal} />
                                        <div className="autocomplete">
                                            {ShowSuggestion ?
                                                <ul ref={DivRef}>
                                                    {
                                                        (suggestions.length > 0) ?
                                                            suggestions.map((item) => {
                                                                // console.log(item);
                                                                return (
                                                                    <li key={item.id}
                                                                        onClick={(e) => handleSuggestionClick(e, item.id, item.area)}
                                                                    >{item.area}</li>
                                                                )
                                                            }) : null
                                                    }
                                                </ul>
                                                : null
                                            }
                                        </div>
                                        <small className="text-danger">{TimeZoneIdError}</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {RepeatingEventStatus == true ? ''
                            // <div className="row ">
                            //     <div className="col-lg-6 col-md-6 mb-20">
                            //         <div className="checkout__input--list">
                            //         <label className="checkout__input--label mb-3" htmlFor="state">Event Repeats <span className="checkout__input--label__star">*</span></label>
                            //         <div className="checkout__input--select select">
                            //             <select className="checkout__input--select__field border-radius-5" id="repeat_type" name='repeat_type' value={EventRepeatingType} onChange={(e) => setEventRepeatingType(e.target.value)} >
                            //                 <option value="0">-- Select Event Repeats --</option>
                            //                 <option value="1">Daily</option>
                            //                 <option value="2">Weakly</option>
                            //                 <option value="3">Monthly</option>    
                            //             </select>
                            //         </div>
                            //          <small className="text-danger">{EventRepeatingTypeError}</small>
                            //         </div>   
                            //     </div>

                            //     <div className="col-lg-6 col-md-6 mb-20">
                            //         <div className="checkout__input--list ">

                            //         <div className="row">

                            //             <div className="col-sm-6">
                            //             <label className="checkout__input--label mb-3" htmlFor="input1">From Time <span className="checkout__input--label__star"> *</span></label>
                            //                 <input className="checkout__input--field border-radius-5" name='repeat_start_time' type="time" value={RepeatStartTime} onChange={(e) => setRepeatStartTime(e.target.value)} />
                            //                 <small className="text-danger">{RepeatStartTimeError}</small>
                            //             </div>
                            //             <div className="col-sm-6">
                            //             <label className="checkout__input--label mb-3" htmlFor="input1">To Time <span className="checkout__input--label__star"> *</span></label>
                            //                 <input className="checkout__input--field border-radius-5" type="time" name='repeat_end_time' value={RepeatEndTime} onChange={(e) => setRepeatEndTime(e.target.value)} />
                            //                 <small className="text-danger">{RepeatEndTimeError}</small>
                            //             </div>
                            //         </div>
                            //         </div>   
                            //     </div>
                            // </div>
                            : ''}

                        <div className="mb-5">
                            {/* <div className="section__header mb-4">
                                <h2 className="section__header--title h3">Event Address</h2>
                            </div> */}
                            {/* {console.log(CountryVal)} */}
                            <div className="row">
                                <div className="col-sm-6">
                                    <label className="checkout__input--label" htmlFor="input1">
                                        Country <span className="checkout__input--label__star">*</span>
                                    </label>
                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Select Country" type="text" onChange={(e) => {
                                            handleCountryChange(e);
                                            setAddressApiCall(true);
                                        }} value={CountryVal} />
                                        <div className="autocomplete">
                                            {ShowCountrySuggestion ?
                                                <ul ref={DivRef}>
                                                    {
                                                        (CountrySuggestions.length > 0) ?
                                                            CountrySuggestions.map((item) => {
                                                                // console.log(item);
                                                                return (
                                                                    <li key={item.id}
                                                                        onClick={(e) => handleCountrySuggestionClick(e, item.id, item.name)}
                                                                    >{item.name}</li>
                                                                )
                                                            }) : null
                                                    }
                                                </ul>
                                                : null
                                            }
                                        </div>
                                        <small className="text-danger">{CountryIdError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label className="checkout__input--label" htmlFor="input1">
                                        Pincode <span className="checkout__input--label__star">*</span>
                                    </label>
                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Enter Pincode" type="text" onChange={(e) => {
                                            setPincode(e.target.value.replace(/\D/g, ''));
                                            setAddressApiCall(true);
                                            // GetLocation(e.target.value.replace(/\D/g, '')) 
                                        }} value={Pincode} />
                                        <small className="text-danger">{PincodeError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label className="checkout__input--label" htmlFor="input1">
                                        State <span className="checkout__input--label__star">*</span>
                                    </label>

                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Select State" type="text" onChange={(e) => handleStateChange(e)} value={StateVal} />
                                        <div className="autocomplete">
                                            {ShowStateSuggestion ?
                                                <ul ref={DivRef}>
                                                    {
                                                        (StateSuggestions.length > 0) ?
                                                            StateSuggestions.map((item) => {
                                                                // console.log(item);
                                                                return (
                                                                    <li key={item.id}
                                                                        onClick={(e) => handleStateSuggestionClick(e, item.id, item.name)}
                                                                    >{item.name}</li>
                                                                )
                                                            }) : null
                                                    }
                                                </ul>
                                                : null
                                            }
                                        </div>
                                        <small className="text-danger">{StateIdError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label className="checkout__input--label mt-1" htmlFor="input1">
                                        City <span className="checkout__input--label__star">*</span>
                                    </label>

                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Select City" type="text" onChange={(e) => handleCityChange(e)} value={CityVal} />
                                        <div className="autocomplete">
                                            {ShowCitySuggestion ?
                                                <ul ref={DivRef}>
                                                    {
                                                        (CitySuggestions.length > 0) ?
                                                            CitySuggestions.map((item) => {
                                                                // console.log(item);
                                                                return (
                                                                    <li key={item.id}
                                                                        onClick={(e) => handleCitySuggestionClick(e, item.id, item.name)}
                                                                    >{item.name}</li>
                                                                )
                                                            }) : null
                                                    }
                                                </ul>
                                                : null
                                            }
                                        </div>
                                        <small className="text-danger">{CityIdError}</small>
                                    </div>
                                </div>

                                {/* <div className="col-sm-6">
                                    <label className="checkout__input--label" htmlFor="input1">
                                        Latitude
                                    </label>
                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Enter Latitude" type="text" onChange={(e) => {
                                            setLatitude(e.target.value);
                                        }} value={Latitude} />
                                    
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label className="checkout__input--label" htmlFor="input1">
                                        Longitude
                                    </label>
                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Enter Longitude" type="text" onChange={(e) => {
                                            setLongitude(e.target.value);
                                        }} value={Longitude} />
                                        <small className="text-danger">{PincodeError}</small>
                                    </div>
                                </div>  */}

                                <div className="col-sm-12">
                                    <label className="checkout__input--label" htmlFor="input1">
                                        Google Map Embed Code
                                    </label>
                                    <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder="Enter Google Map Link" type="text" onChange={(e) => {
                                            setGoogleMapLink(e.target.value);
                                        }} value={GoogleMapLink} />
                                    
                                    </div>
                                </div>


                                <div className="col-sm-12">
                                    <div className="form-floating mt-3 mb-0">
                                        <textarea
                                            type="text"
                                            className="form-control resp-textarea"
                                            value={Address} onChange={(e) => setAddress(e.target.value)}
                                        />
                                        <label>Event Address <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                    <small className="text-danger">{AddressError}</small>
                                </div>

                                {/* datetime-local */}
                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={EventStartDate}
                                            onChange={(e) => { setEventStartDate(e.target.value); setEventEndDate(''); setEventEndTime(''); setRegistrationStartDate(''); setRegistrationEndDate(''); }}

                                            // min={EventStartDate == '' ? new Date(CurrentDate).toISOString().split("T")[0] : EditEventStartDate !== '' ? new Date(CurrentDate).toISOString().split('T')[0] : new Date(CurrentDate).toISOString().split("T")[0]}
                                            //EditEventStartDate !== '' ? new Date(EditEventStartDate).toISOString().split('T')[0] :

                                            
                                            min={EventStartDate == '' ? new Date(CurrentDate).toISOString().split("T")[0] : EditEventStartDate !== '' && (CurrentDate >= EditEventStartDate) ? new Date(EditEventStartDate).toISOString().split("T")[0] : new Date(CurrentDate).toISOString().split("T")[0]}

                                        />
                                        <label className="select-label">Event Start Date <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{EventStartDateError}</small>
                                    </div>
                                </div>
                                {/*  min={EventStartDate == '' ? new Date(CurrentDate).toISOString().split("T")[0] : new Date(EditEventStartDate).toISOString().split('T')[0]} */}

                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">

                                        <input
                                            className="form-control"
                                            type="time"
                                            name="event-date"
                                            value={EventStartTime}
                                            ref={inputRef}
                                            onChange={(e) => { setEventStartTime(e.target.value); handleInputChange(); }}
                                        />
                                        <label className="select-label">Event Start Time <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{EventStartTimeError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={EventEndDate}
                                            onChange={(e) => { setEventEndDate(e.target.value); setRegistrationStartDate(''); setRegistrationEndDate(''); }}
                                            min={EventStartDate ? new Date(EventStartDate).toISOString().split("T")[0] : new Date().toISOString().split('T')[0]}
                                        />
                                        <label className="select-label">Event End Date <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{EventEndDateError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            type="time"
                                            name="event-date"
                                            value={EventEndTime}
                                            ref={inputRef1}
                                            onChange={(e) => { setEventEndTime(e.target.value); handleInputChange(); }}

                                        />
                                        <label className="select-label">Event End Time <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{EventEndTimeError}</small>
                                    </div>
                                </div>

                                {/* Registration date */}
                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={RegistrationStartDate}
                                            disabled={EventStartDate && EventEndDate ? false : true}
                                            onChange={(e) => { setRegistrationStartDate(e.target.value); setRegistrationEndDate(''); setRegistrationEndTime(''); }}

                                            // min={RegistrationStartDate == '' && EventStartDate !== '' ? new Date(EventStartDate).toISOString().split("T")[0] : EditRegistrationStartDate !== '' ? new Date(EventStartDate).toISOString().split('T')[0] : RegistrationStartDate !== '' ? new Date(currentDate).toISOString().split("T")[0] : new Date(currentDate).toISOString().split("T")[0]}
                                            min={RegistrationStartDate == '' && EventStartDate !== '' ? new Date(currentDate).toISOString().split("T")[0] : EditRegistrationStartDate !== '' && (RegistrationStartDate <= EditEventStartDate) ? new Date(EditRegistrationStartDate).toISOString().split('T')[0] :  EditRegistrationStartDate === '' ? new Date(currentDate).toISOString().split("T")[0] : new Date(currentDate).toISOString().split("T")[0] }

                                            max={RegistrationStartDate == '' && EventEndDate !== '' ? new Date(EventEndDate).toISOString().split("T")[0] : EditRegistrationStartDate !== '' && EventEndDate !== '' ? new Date(EventEndDate).toISOString().split('T')[0] : RegistrationStartDate !== '' && EventEndDate !== '' ? new Date(EventEndDate).toISOString().split("T")[0] : new Date(currentDate).toISOString().split("T")[0]}

                                        />

                                        <label className="select-label">Registration Start Date <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{RegistrationStartDateError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">

                                        <input
                                            className="form-control"
                                            type="time"
                                            name="event-date"
                                            value={RegistrationStartTime}
                                            ref={inputRef2}
                                            onChange={(e) => { setRegistrationStartTime(e.target.value); handleInputChange(); }}

                                        />
                                        <label className="select-label">Registration Start Time <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{RegistrationStartTimeError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={RegistrationEndDate}
                                            onChange={(e) => { setRegistrationEndDate(e.target.value) }}
                                            min={RegistrationStartDate ? new Date(RegistrationStartDate).toISOString().split("T")[0] : ''}

                                            max={RegistrationStartDate ? new Date(EventEndDate).toISOString().split("T")[0] : ''}
                                        />
                                        <label className="select-label">Registration End Date <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{RegistrationEndDateError}</small>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-floating mt-3 mb-0">
                                        <input
                                            className="form-control"
                                            type="time"
                                            name="event-date"
                                            value={RegistrationEndTime}
                                            ref={inputRef3}
                                            onChange={(e) => { setRegistrationEndTime(e.target.value); handleInputChange(); }}

                                        />
                                        <label className="select-label">Registration End Time <span className="checkout__input--label__star">*</span></label>
                                        <small className="text-danger">{RegistrationEndTimeError}</small>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                        <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(0) }} style={{ cursor: 'pointer' }}>Back</div>

                        <input type="submit" name="command" value="Save & Next (2/11)" className="continue__shipping--btn primary__btn border-radius-5" />
                    </div>
                </form>
            </div>

        </>
    )
}

export default EventDuration;

