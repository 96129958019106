import React, { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../axios';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import HeaderSecond from '../HeaderSecond';


const ContactUs = () => {
  // console.log("Create Event page");
  const navigate = useNavigate();
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Email, setEmail] = useState('');
  const [ContactNo, setContactNo] = useState('');
  const [Message, setMessage] = useState('');
  
  const [FirstNameError, setFirstNameError] = useState('');
  const [LastNameError, setLastNameError] = useState('');
  const [EmailError, setEmailError] = useState('');
  const [ContactNoError, setContactNoError] = useState('');
  const [MessageError, setMessageError] = useState('');
  

  const handleBackToHome = () => {
    localStorage.removeItem('EventViewFlag');
    localStorage.removeItem('EventDetailsId');

    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = localStorage.getItem('Country');
    navigate('/' + CountryCode + '/' + CountryName);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleValidation = () => {
    
    var flag = true;
    if (FirstName === "") {
      setFirstNameError("Please enter first name");
      flag = false
    } else {
      setFirstNameError("");
    }

    if (LastName === "") {
      setLastNameError("Please enter last name");
      flag = false
    } else {
      setLastNameError("");
    }
   
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (Email === "") {
      setEmailError("Please enter email id");
      flag = false
    } else if(!re.test(Email)){
      setEmailError("Please enter valid email id");
      flag = false
    }else {
      setEmailError("");
    }

    if (ContactNo === "") {
      setContactNoError("Please enter contact no");
      flag = false
    } else {
      setContactNoError("");
    }

    if (Message === "") {
      setMessageError("Please enter message");
      flag = false
    } else {
      setMessageError("");
    }

    return flag;
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      
      const payload = {
        // event_id: localStorage.getItem("EventId"),
        // user_id: localStorage.getItem("ID"),
        first_name: FirstName,
        last_name: LastName,
        email : Email,
        contact_no : ContactNo,
        message : Message
      };
      //console.log(payload);
      axios.post("send_email_contact_us", payload)
        .then((response) => {
        
          //console.log(response.data.data.data);
          if (response.status === 200) {
            if(response.data.data.data === 1){
              toast.success(response.data.message);
              setFirstName(''); setLastName(''); setEmail('');
              setContactNo(''); setMessage('');
            }else{
              toast.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const validateInteger = () => {
  //   // Validate if input is a valid integer
  //   if (/^-?\d*$/.test(numberValue)) {
  //     setIsValid(true);
  //   } else {
  //     setIsValid(false);
  //   }
  // };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^-?\d*$/.test(value)) {
      setContactNo(value);
    }else{
      setContactNo('');
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />

      <HeaderSecond />
      <main className="main__content_wrapper">
        {/* Start breadcrumb section */}
        <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <div className="breadcrumb__content">
                  <h1 className="breadcrumb__content--title text-white mb-10">
                  Contact Us
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex">
                    <li className="breadcrumb__content--menu__items">
                      <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                        Home
                      </div>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">Contact Us</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End breadcrumb section */}

        <section className="section--padding py-5 contactpageui">
          <div className="container">
            
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3 className='mb-3'>YouTooCanRun Sports Management Pvt Ltd.</h3>
            </div>
          </div>

            <div className="row">
              
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
              
                <div className="card my-5 mt-4 p-5 contact-us">
                    <div className="row d-flex align-items-center mail-ui">
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center align-items-center">
                        <div className="icon mailbg">
                          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
                        </div>
                      </div>
                      <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                          <h4>Email</h4>
                          <p>support@youtoocanrun.com</p>
                      </div>
                    </div>
                </div>
                <div className="card my-5 p-5 contact-us">
                    <div className="row d-flex align-items-center mail-ui">
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center align-items-center">
                        <div className="icon phonebg">
                          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed"><path d="M760-480q0-117-81.5-198.5T480-760v-80q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480h-80Zm-160 0q0-50-35-85t-85-35v-80q83 0 141.5 58.5T680-480h-80Zm198 360q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"/></svg> 
                        </div>
                      </div>
                      <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                          <h4>Phone</h4>
                          <p>+91 9920142195</p>
                      </div>
                    </div>
                </div>
                <div className="card my-5 p-5 contact-us">
                    <div className="row d-flex align-items-center mail-ui">
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center align-items-center">
                        <div className="icon addressbg">
                          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed"><path d="M480-301q99-80 149.5-154T680-594q0-90-56-148t-144-58q-88 0-144 58t-56 148q0 65 50.5 139T480-301Zm0 101Q339-304 269.5-402T200-594q0-125 78-205.5T480-880q124 0 202 80.5T760-594q0 94-69.5 192T480-200Zm0-320q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM200-80v-80h560v80H200Zm280-520Z"/></svg>
                        </div>
                      </div>
                      <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                          <h4>Address</h4>
                          <p>3A, Valmiki, Next to Pharmacy College, Behind Kalina Muncipal School, Sunder Nagar, Kalina, Mumbai 400098</p>
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
              <form onSubmit={handleSubmit} >
                <div className="card my-4 p-5 contact-us">
                  {/* <p className="m-0 pb-2">Get in touch</p>  */}
                  <h3 className="pb-2" style={{fontWeight:"300",}}>Let's chat, Reach out to us</h3>
                  <small>Need help or have feedback? Message us, and we'll respond within 24 hours.</small>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-floating my-3">
                        <input type="text" className="form-control" placeholder="" value={FirstName} onChange={(e) => { setFirstName(e.target.value);}} />
                        <label>First Name <span className="checkout__input--label__star">*</span></label>
                        <small className="text-danger">{FirstNameError}</small>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating my-3">
                        <input type="text" className="form-control" placeholder="" value={LastName} onChange={(e) => { setLastName(e.target.value);}} />
                        <label>Last Name <span className="checkout__input--label__star">*</span></label>
                        <small className="text-danger">{LastNameError}</small>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating my-3">
                        <input type="text" className="form-control" placeholder="" value={Email} onChange={(e) => { setEmail(e.target.value);}} />
                        <label>Email ID <span className="checkout__input--label__star">*</span></label>
                        <small className="text-danger">{EmailError}</small>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating my-3">
                        <input type="text" className="form-control" placeholder="" value={ContactNo} onChange={handleInputChange}  maxLength={12}/>
                        <label>Contact Number <span className="checkout__input--label__star">*</span></label>
                        <small className="text-danger">{ContactNoError}</small>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div class="form-floating my-3">
                        <textarea type="text" class="form-control resp-textarea" value={Message} onChange={(e) => { setMessage(e.target.value);}} ></textarea><label>Your message <span class="checkout__input--label__star">*</span></label>
                        <small className="text-danger">{MessageError}</small>
                      </div>
                    </div>
                    <div className="col-sm-12 d-flex align-items-center justify-content-center">
                      <div className="checkout__content--step__footer my-3">
                        <input type="submit" name="command" class="continue__shipping--btn primary__btn border-radius-5" value="Submit"/>
                        </div>
                    </div>

                  </div>
                </div>
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card p-5 contact-us">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7541.393824497682!2d72.860194!3d19.077059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8fbcf182445%3A0x46734cc99263ee65!2sYouTooCanRun%20Sports%20Management%20Pvt.%20Ltd!5e0!3m2!1sen!2sus!4v1719482958239!5m2!1sen!2sus" style={{width:"100%", height:"450px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main >

    </>
  )
}

export default ContactUs;

