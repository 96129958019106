import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import TokenReducer from "./features/TokenSlice";
import TimeZoneReducer from './features/TimeZoneSlice';

const store = configureStore({
  reducer: {
    token: TokenReducer,
    TimeZone: TimeZoneReducer
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
// console.log(root)
root.render(
  <Provider store={store}>
    {/* <BrowserRouter basename="/Races2.0_Frontend"> */}
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>
);
