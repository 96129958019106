import React, { useEffect } from 'react'

const LocationPopup = ({onClose}) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose(); // Call the onClose function to close the popup
        }, 3000); // Delay of 10 seconds (in milliseconds)

        // Clear the timer if the component is unmounted or the onClose function changes
        return () => clearTimeout(timer);
    }, [onClose]);


    return (
        <div className="modal is-visible" data-animation="slideInUp"
            // onClick={(e) => { handleClose(e) }}
            style={{ zIndex: 999999 }}
        >
            <div className="modal-dialog quickview__main--wrapper">
                <header className="modal-header quickview__header">
                    {/* <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
                        // props.setResetPasswordEmail("");
                    }}>✕ </button> */}
                </header>
                <div className="quickview__inner">
                    <div className="row">
                        <div className="login__section">
                            <div className="login__section--inner" >
                                <div className="p-5">
                                    <div className="account__login--header mb-25">
                                        <h3 className="account__login--header__title mb-10">Location Detection</h3>
                                        <p className="account__login--header__desc">You have block the location</p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocationPopup