import React, { useState, useEffect } from 'react'
import HeaderSecond from '../HeaderSecond'
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import ShowOrganizer from './ShowOrganizer';
import EditOrganizer from './EditOrganizer';
import axios from '../axios';

const OrganizerSettings = () => {
  const navigate = useNavigate();
  const [Section, setSection] = useState('show');
  const [OrganiserData, setOrganiserData] = useState([])

  const handleBackToHome = () => {
    localStorage.removeItem('EventViewFlag');
    localStorage.removeItem('EventDetailsId');
    localStorage.removeItem('EventId');
    localStorage.removeItem('EventType');
    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = localStorage.getItem('Country');
    navigate('/' + CountryCode + '/' + CountryName);
  }

  useEffect(() => {
      axios.post("get_organizer", {})
          .then((response) => {
              // console.log(response.data.data.organizerData[0]);
              setOrganiserData(response.data.data.organizerData[0])
          })
          .catch((error) => {
              console.log(error);
          })
  }, [])

  
  return (
    <>
      <HeaderSecond />
      <ToastContainer theme="colored" />

      <main className="main__content_wrapper">
        {/* Start breadcrumb section */}
        <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
          <div className="container">
            <div className="row row-cols-1 ">
              <div className="col">
                <div className="breadcrumb__content">
                  <h1 className="breadcrumb__content--title text-white mb-10">
                  Organiser Profile
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex">
                    <li className="breadcrumb__content--menu__items">
                      <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                        Home
                      </div>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">Organiser Profile</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End breadcrumb section */}

        <section className="section--padding  pt-5">
          <div className="container">

            <div className="row justify-content-center pt-5">

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8">
                {/* SHOW ORGANAIZER PROFILE */}
                {
                  Section === 'show' ?
                    <ShowOrganizer Tab={Section} setTab={setSection}/>
                    :
                    null
                }
                {
                  Section === 'edit_org' ?
                    <EditOrganizer Tab={Section} setTab={setSection}/> 
                    : 
                    null
                }
              </div>
            </div>
          </div>
        </section>


      </main>
    </>
  )
}

export default OrganizerSettings