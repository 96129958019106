import React, { useState } from 'react';
import axios from './axios';

const LocationComponent = () => {
  const [pincode, setPincode] = useState('');
  const [city, setCity] = useState('');
  const [Address, setAddress] = useState('');

  const [country, setCountry] = useState('');
  const [error, setError] = useState('');

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };

  const handleSearch = () => {
    axios
      .get(`https://nominatim.openstreetmap.org/search?postalcode=${pincode}&format=json`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
            // console.log(response.data[0].display_name);
            setAddress(response.data[0].display_name)
            //   setCity(response.data[0].address.city);
            //   setCountry(response.data[0].address.country);
          setError('');
        } else {
            setAddress('')
          setError('Location not found for this pincode.');
          setCity('');
          setCountry('');
        }
      })
      .catch((error) => {
        setError('Error fetching location data.');
        console.error(error);
      });
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter pincode"
        value={pincode}
        onChange={handlePincodeChange}
      />
      <button onClick={handleSearch}>Search</button>
      {Address && (
        <div>
          <p>Address: {Address}</p>
          {/* <p>Country: {country}</p> */}
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default LocationComponent;
