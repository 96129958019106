import React, { useEffect, useState } from "react";
import axios from "../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import LoaderImg from '../img/logo/running.gif';

const NewForm = (props) => {

  const [FromName, setFromName] = useState('');
  const [FromNameError, setFromNameError] = useState('');
  const [FromEditId, setFromEditId] = useState(0);
  const [FormDetailsAll, setFormDetailsAll] = useState([]);
  const [addeditFlag, setaddeditFlag] = useState(false);

  const [Loader, setLoader] = useState(false);

  const handleValidation = () => {

    var flag = true;

    if (FromName === "") {
      setFromNameError("Please enter form name");
      flag = false;
    } else {
      setFromNameError("");
    }

    return flag;
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {

      setLoader(true);
      const payload = {
        form_name: FromName,
        form_edit_id: FromEditId,
        form_action_flag: 'data_add_edit'
      };
      // console.log(payload);
      axios.post("FormCommonDetails", payload)
        .then((response) => {
          setLoader(false);
          //console.log(response.data.message);
          if (response.status === 200) {
            if (response.data.message === "error") {
              setaddeditFlag(true);
              setFromNameError("Form name is already exists, please use another name.");
            }else{
              props.setAddForm(false);
              toast.success(response.data.message);
              setaddeditFlag(true);
              setFromEditId(0); 
              setFromName('');         
            }
          }
        })
        .catch(() => {
          setLoader(false);
          console.log('error');
          //props.setOpenTree(false);
        });
    }
  };

  useEffect(() => {
    const payload = {
      form_name: FromName,
      form_edit_id: FromEditId,
      form_action_flag: 'form_details',
      form_flag: ''
    };
    axios.post("FormCommonDetails", payload)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setFormDetailsAll(response.data.data.form_details);
          if(response.data.data.form_edit_details){
            setFromName(response.data.data.form_edit_details[0].form_name !== '' ? response.data.data.form_edit_details[0].form_name : '');
          }
          setaddeditFlag(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [addeditFlag])
 //console.log(addeditFlag);

  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
      <div className="modal is-visible" data-animation="slideInUp" >
      <div className="modal-dialog quickview__main--wrapper w-100">

          <form method="post" onSubmit={handleSubmit}>
            <div className="quickview__inner">
              <div className="row">

                <div className="login__section">

                  <div className="login__section--inner" >
                    <div className="p-5">
                      <div className="account__login--header mb-25">
                        <h3 className="account__login--header__title mb-10">Form Details</h3>
                      </div>

                      <div className="account__login--inner">

                        <div className="my-5">
                          <div className="form-floating mt-3 mb-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={FromName}
                              onChange={(e) => { setFromName(e.target.value); }}
                              maxLength={50}
                            />
                            <label>Form Name <span className="checkout__input--label__star">*</span></label>
                            <small className="text-danger">{FromNameError}</small>
                          </div>
                        </div>

                        <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                          <button className="account__login--btn secondary__btn mt-2" onClick={() => { props.setAddForm(false); }}>Close</button>

                          <input type="submit" name="command" value="Save" className="account__login--btn primary__btn mt-2" />

                        </div>
                        
                        {/* get form details */}
                        <hr />
                        <div className="section__header mb-4 mt-4 d-flex align-items-center justify-content-between">
                            <h2 className="section__header--title h3">Form Details</h2>
                        </div>

                        <div className="row mt-3">
                          {FormDetailsAll && FormDetailsAll.length > 0 ?
                            FormDetailsAll.map((item, index) => {
                              return (
                                <>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-5 form-que" key={index+134}>
                                    <div className="card">

                                      <div className="mx-3 input-field">
                                        <div className="form-floating mb-0 text-left">
                                          <div className="">
                                            <h4 className="m-0">{item.form_name}</h4>
                                          </div>
                                        </div>
                                      </div>
                                      
                                      <div className="mx-3 option-icon" onClick={() => { setFromEditId(item.id); setaddeditFlag(true); }} style={{cursor:'pointer'}} title="Edit">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                      </div>

                                    </div>
                                  </div>
                                </>
                              );
                            })
                            :
                            ''
                          }
                        </div>

                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewForm;
