import React, { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import HeaderSecond from '../HeaderSecond';
import axios from '../axios';


const EventTermsConditions = () => {
    const navigate = useNavigate();
    const { EventId } = useParams();
    const [TermsConditions, setTermsConditions] = useState([]);

    useEffect(() => {
        if (EventId) {
            axios.post("get_event_term_and_conditions", {
                event_id: EventId
            })
                .then((response) => {
                    setTermsConditions(response.data.data.TermsConditions);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [EventId]);

    const handleBackToHome = () => {
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <ToastContainer theme="colored" />

            <HeaderSecond />
            <main className="main__content_wrapper">
                {/* Start breadcrumb section */}
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        Terms & Conditions
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">Terms & Conditions</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End breadcrumb section */}

                <section className="section--padding pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 my-5" style={{ textAlign: "justify" }}>
                                <div dangerouslySetInnerHTML={{ __html: TermsConditions }} />
                            </div>
                        </div>
                    </div>
                </section>

            </main >

        </>
    )
}

export default EventTermsConditions;

