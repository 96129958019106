import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useParams, useNavigate } from 'react-router-dom';
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddTermConditions = (props) => {

  const [Loader, setLoader] = useState(false);
  //console.log(PlatformFeePercent);
  const [Title, setTitle] = useState('');
  const [TitleError, setTitleError] = useState('');
  const [TermConditions, setTermConditions] = useState('');
  const [TermConditionsError, setTermConditionsError] = useState('');
  const [EventCommId, setEventCommId] = useState('');

  //console.log(props.EventTypeData);
   useEffect(() => {
    
    if (props.CommId && props.CommId !== 0) {
      axios.post('edit_event_comm_faq',
        {
          'event_id': localStorage.getItem("EventId"),
          'event_comm_id': props.CommId,
          'event_edit_flag': 'term_conditions_edit'
        }
      )
        .then((response) => {
         //console.log(response.data);
          if (response.data.data.terms_conditions_details) {
            let comm_details = response.data.data.terms_conditions_details[0];
              setEventCommId(comm_details.id !== '' ? comm_details.id : '')
              setTitle(comm_details.title !== '' ? comm_details.title : '');
              setTermConditions(comm_details.terms_conditions !== '' ? comm_details.terms_conditions : '');
          }

        })
        .catch((error) => {
          console.log(error);
        })
    }
   }, [props.CommId])
  
  
  
  //console.log(StartDate);
  
   const handleValidation = () => {
    // console.log(TicketStatus);
    var flag = true;
    if (Title === "") {
      setTitleError("Please enter title");
      flag = false
    } else {
      setTitleError("");
    }

    if (TermConditions === "") {
      setTermConditionsError("Please enter terms & conditions");
      flag = false
    } else {
      setTermConditionsError("");
    }

    return flag;
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      props.setLoader(true);
      props.setTicketDivShowFlag(true);
      const payload = {
        event_id: localStorage.getItem("EventId"),
        user_id: localStorage.getItem("ID"),
        title: Title,
        terms_conditions: TermConditions,
        event_comm_id : EventCommId
      };
      //console.log(payload);
      axios.post("add_edit_terms_conditions", payload)
        .then((response) => {
          props.setLoader(false);
          //console.log(response.data);
          if (response.status === 200) {
            toast.success(response.data.message);
            props.setAddNewTermConditions(false);
            props.setEventApiCall(true);
            props.setTicketDivShowFlag(false);
          }
        })
        .catch((error) => {
          props.setLoader(false);
          console.log(error);
        });
    }

  };

  const handleDescriptionChange = (e, editor) => {
    const data = editor.getData();
    setTermConditions(data)
  };

  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

     
        <div className="main checkout__mian">
        <form onSubmit={handleSubmit}>
          <div className="checkout__content--step section__shipping--address ">
            <div className="mb-5">
              <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                <h2 className="section__header--title h3">Add Terms & Conditions</h2>
                <div className="event-visibility hand_cursor">

                </div>
              </div>
            </div>
             
            <div className="row ">
                <div className="col-sm-12">
                  <div className="form-floating mt-3 mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={Title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                    <label>Title <span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">{TitleError}</small>
                </div>

                <div className="col-sm-12 mt-4">
                    <label>Terms & Conditions <span className="checkout__input--label__star">*</span> </label>
                    <div className="form-floating mt-3 mb-0">
                        <CKEditor
                          
                            editor={ClassicEditor}
                            data={TermConditions ? TermConditions : ""}
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <small className="text-danger">{TermConditionsError}</small>
                </div>
            </div>
          
          </div>
          <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              onClick={(e) => { props.setAddNewTermConditions(false); props.setTicketDivShowFlag(false) }}
              style={{cursor:'pointer'}} >
              Cancel
            </div>

            <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />

          </div>
        </form >
   
      </div>

    </>
  )
}

export default AddTermConditions;

