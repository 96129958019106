import React, { useState, useEffect } from 'react';
import axios from "./axios";
import { Link, useParams, useNavigate } from 'react-router-dom';
// import Logo from "../logo/ytcr-logo.png";
import Logo from "./logo/ytcr-logo.png";

const Wrapper = () => {
    return (
        <>
        {/* Quickview Wrapper */}
       
        <div className="modal" id="modal1" dataanimation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper">
                <header className="modal-header quickview__header">
                    <button className="close-modal quickview__close--btn" aria-label="close modal" data-close>✕ </button>
                </header>
                <div className="quickview__inner">
                    <div className="row row-cols-lg-2 row-cols-md-2">
                        <div className="col">
                        <div className="product__details--media">
                            <div className="product__media--preview  swiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                    <div className="product__media--preview__items">
                                        <a className="product__media--preview__items--link glightbox" data-gallery="product-media-preview" href="assets/img/events/event-1.jpg"><img className="product__media--preview__items--img" src="assets/img/events/event-1.jpg" alt="product-media-img"/></a>
                                        <div className="product__media--view__icon">
                                            <a className="product__media--view__icon--link glightbox" href="assets/img/events/event-1.jpg" data-gallery="product-media-preview">
                                                <svg className="product__media--view__icon--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="22.443" viewBox="0 0 512 512">
                                                <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"></path>
                                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"></path>
                                                </svg>
                                                <span className="visually-hidden">Media Gallery</span>
                                            </a>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="swiper-slide">
                                    <div className="product__media--preview__items">
                                        <a className="product__media--preview__items--link glightbox" data-gallery="product-media-preview" href="assets/img/product/big-product2.webp"><img className="product__media--preview__items--img" src="assets/img/product/big-product2.webp" alt="product-media-img"/></a>
                                        <div className="product__media--view__icon">
                                            <a className="product__media--view__icon--link glightbox" href="assets/img/product/big-product2.webp" data-gallery="product-media-preview">
                                                <svg className="product__media--view__icon--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="22.443" viewBox="0 0 512 512">
                                                <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"></path>
                                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"></path>
                                                </svg>
                                                <span className="visually-hidden">Media Gallery</span>
                                            </a>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="swiper-slide">
                                    <div className="product__media--preview__items">
                                        <a className="product__media--preview__items--link glightbox" data-gallery="product-media-preview" href="assets/img/product/big-product3.webp"><img className="product__media--preview__items--img" src="assets/img/product/big-product3.webp" alt="product-media-img"/></a>
                                        <div className="product__media--view__icon">
                                            <a className="product__media--view__icon--link glightbox" href="assets/img/product/big-product3.webp" data-gallery="product-media-preview">
                                                <svg className="product__media--view__icon--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="22.443" viewBox="0 0 512 512">
                                                <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"></path>
                                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"></path>
                                                </svg>
                                                <span className="visually-hidden">Media Gallery</span>
                                            </a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product__media--nav swiper">
                                <div className="swiper-wrapper">
                                    {/* <div className="swiper-slide">
                                    <div className="product__media--nav__items">
                                        <img className="product__media--nav__items--img" src="assets/img/product/small-product1.webp" alt="product-nav-img">
                                    </div>
                                    </div>
                                    <div className="swiper-slide">
                                    <div className="product__media--nav__items">
                                        <img className="product__media--nav__items--img" src="assets/img/product/small-product2.webp" alt="product-nav-img">
                                    </div>
                                    </div>
                                    <div className="swiper-slide">
                                    <div className="product__media--nav__items">
                                        <img className="product__media--nav__items--img" src="assets/img/product/small-product3.webp" alt="product-nav-img">
                                    </div>
                                    </div> */}
                                </div>
                                <div className="swiper__nav--btn swiper-button-next"></div>
                                <div className="swiper__nav--btn swiper-button-prev"></div>
                            </div>
                        </div>
                        </div>
                        <div className="col">
                        <div className="quickview__info">
                            <form action="#">
                                <h2 className="product__details--info__title mb-15">500K Run Challange by Redbull</h2>
                                <div className="product__details--info__price mb-12">
                                    <span className="current__price">₹200.00</span>
                                    <span className="old__price">₹250.00</span>
                                </div>
                                <div className="my-4">
                                    <div className="row gap-3 px-4 mb-3">
                                    <div className="col-sm-3 category-badge gap-2 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                                            <path d="M520-40v-240l-84-80-40 176-276-56 16-80 192 40 64-324-72 28v136h-80v-188l158-68q35-15 51.5-19.5T480-720q21 0 39 11t29 29l40 64q26 42 70.5 69T760-520v80q-66 0-123.5-27.5T540-540l-24 120 84 80v300h-80Zm20-700q-33 0-56.5-23.5T460-820q0-33 23.5-56.5T540-900q33 0 56.5 23.5T620-820q0 33-23.5 56.5T540-740Z"/>
                                        </svg>
                                        <small className="m-0">Running</small>
                                    </div>
                                    <div className="col-sm-3 category-badge gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                                            <path d="m280-40 112-564-72 28v136h-80v-188l202-86q14-6 29.5-7t29.5 4q14 5 26.5 14t20.5 23l40 64q26 42 70.5 69T760-520v80q-70 0-125-29t-94-74l-25 123 84 80v300h-80v-260l-84-64-72 324h-84Zm260-700q-33 0-56.5-23.5T460-820q0-33 23.5-56.5T540-900q33 0 56.5 23.5T620-820q0 33-23.5 56.5T540-740Z"/>
                                        </svg>
                                        <small className="m-0">Walking</small>
                                    </div>
                                    <div className="col-sm-3 category-badge gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                                            <path d="M620-740q-33 0-56.5-23.5T540-820q0-33 23.5-56.5T620-900q33 0 56.5 23.5T700-820q0 33-23.5 56.5T620-740ZM432-540l88 92v248h-80v-200L312-512q-14-12-19-25t-5-31q0-18 5.5-30.5T312-624l112-112q13-13 27.5-18.5T484-760q18 0 32.5 5.5T544-736l76 76q27 27 63 43.5t81 16.5v80q-63 0-114-22.5T560-604l-32-32-96 96Zm-232 60q85 0 142.5 57.5T400-280q0 85-57.5 142.5T200-80q-85 0-142.5-57.5T0-280q0-85 57.5-142.5T200-480Zm0 340q57 0 98.5-41.5T340-280q0-57-41.5-98.5T200-420q-57 0-98.5 41.5T60-280q0 57 41.5 98.5T200-140Zm560-340q85 0 142.5 57.5T960-280q0 85-57.5 142.5T760-80q-85 0-142.5-57.5T560-280q0-85 57.5-142.5T760-480Zm0 340q57 0 98.5-41.5T900-280q0-57-41.5-98.5T760-420q-57 0-98.5 41.5T620-280q0 57 41.5 98.5T760-140Z"/>
                                        </svg>
                                        <small className="m-0">Cycling</small>
                                    </div>
                                    </div>
                                </div>
                                <div className="product__variant">
                                    <div className="product__variant--list mb-15">
                                    <fieldset className="variant__input--fieldset">
                                        <legend className="product__variant--title mb-8">Distance :</legend>
                                        <ul className="variant__size d-flex">
                                            <li className="variant__size--list">
                                                <input id="weight1" name="weight" type="radio" />
                                                <label className="variant__size--value red" htmlFor="weight1">&lt;5k</label>
                                            </li>
                                            <li className="variant__size--list">
                                                <input id="weight2" name="weight" type="radio"/>
                                                <label className="variant__size--value red" htmlFor="weight2">5K</label>
                                            </li>
                                            <li className="variant__size--list">
                                                <input id="weight3" name="weight" type="radio"/>
                                                <label className="variant__size--value red" htmlFor="weight3">10K</label>
                                            </li>
                                        </ul>
                                    </fieldset>
                                    </div>
                                    <div className="quickview__variant--list variant__wishlist my-5">
                                    <a className="variant__wishlist--icon" href="#" title="Add to wishlist">
                                        <svg className="quickview__variant--wishlist__svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512">
                                            <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                                        </svg>
                                        Add to Wishlist
                                    </a>
                                    </div>
                                </div>
                                <div className="quickview__social d-flex align-items-center">
                                    <label className="quickview__social--title">Social Share:</label>
                                    <ul className="quickview__social--wrapper mt-0 d-flex">
                                    <li className="quickview__social--list">
                                        <a className="quickview__social--icon" target="_blank" href="https://www.facebook.com">
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="7.667" height="16.524" viewBox="0 0 7.667 16.524">
                                                <path  data-name="Path 237" d="M967.495,353.678h-2.3v8.253h-3.437v-8.253H960.13V350.77h1.624v-1.888a4.087,4.087,0,0,1,.264-1.492,2.9,2.9,0,0,1,1.039-1.379,3.626,3.626,0,0,1,2.153-.6l2.549.019v2.833h-1.851a.732.732,0,0,0-.472.151.8.8,0,0,0-.246.642v1.719H967.8Z" transform="translate(-960.13 -345.407)" fill="currentColor"/>
                                            </svg>
                                            <span className="visually-hidden">Facebook</span>
                                        </a>
                                    </li>
                                    <li className="quickview__social--list">
                                        <a className="quickview__social--icon" target="_blank" href="https://twitter.com">
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="16.489" height="13.384" viewBox="0 0 16.489 13.384">
                                                <path  data-name="Path 303" d="M966.025,1144.2v.433a9.783,9.783,0,0,1-.621,3.388,10.1,10.1,0,0,1-1.845,3.087,9.153,9.153,0,0,1-3.012,2.259,9.825,9.825,0,0,1-4.122.866,9.632,9.632,0,0,1-2.748-.4,9.346,9.346,0,0,1-2.447-1.11q.4.038.809.038a6.723,6.723,0,0,0,2.24-.376,7.022,7.022,0,0,0,1.958-1.054,3.379,3.379,0,0,1-1.958-.687,3.259,3.259,0,0,1-1.186-1.666,3.364,3.364,0,0,0,.621.056,3.488,3.488,0,0,0,.885-.113,3.267,3.267,0,0,1-1.374-.631,3.356,3.356,0,0,1-.969-1.186,3.524,3.524,0,0,1-.367-1.5v-.057a3.172,3.172,0,0,0,1.544.433,3.407,3.407,0,0,1-1.1-1.214,3.308,3.308,0,0,1-.4-1.609,3.362,3.362,0,0,1,.452-1.694,9.652,9.652,0,0,0,6.964,3.538,3.911,3.911,0,0,1-.075-.772,3.293,3.293,0,0,1,.452-1.694,3.409,3.409,0,0,1,1.233-1.233,3.257,3.257,0,0,1,1.685-.461,3.351,3.351,0,0,1,2.466,1.073,6.572,6.572,0,0,0,2.146-.828,3.272,3.272,0,0,1-.574,1.083,3.477,3.477,0,0,1-.913.8,6.869,6.869,0,0,0,1.958-.546A7.074,7.074,0,0,1,966.025,1144.2Z" transform="translate(-951.23 -1140.849)" fill="currentColor"/>
                                            </svg>
                                            <span className="visually-hidden">Twitter</span> 
                                        </a>
                                    </li>
                                    <li className="quickview__social--list">
                                        <a className="quickview__social--icon" target="_blank" href="https://www.skype.com">
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="16.482" height="16.481" viewBox="0 0 16.482 16.481">
                                                <path  data-name="Path 284" d="M879,926.615a4.479,4.479,0,0,1,.622-2.317,4.666,4.666,0,0,1,1.676-1.677,4.482,4.482,0,0,1,2.317-.622,4.577,4.577,0,0,1,2.43.678,7.58,7.58,0,0,1,5.048.961,7.561,7.561,0,0,1,3.786,6.593,8,8,0,0,1-.094,1.206,4.676,4.676,0,0,1,.7,2.411,4.53,4.53,0,0,1-.622,2.326,4.62,4.62,0,0,1-1.686,1.686,4.626,4.626,0,0,1-4.756-.075,7.7,7.7,0,0,1-1.187.094,7.623,7.623,0,0,1-7.647-7.647,7.46,7.46,0,0,1,.094-1.187A4.424,4.424,0,0,1,879,926.615Zm4.107,1.714a2.473,2.473,0,0,0,.282,1.234,2.41,2.41,0,0,0,.782.829,5.091,5.091,0,0,0,1.215.565,15.981,15.981,0,0,0,1.582.424q.678.151.979.235a3.091,3.091,0,0,1,.593.235,1.388,1.388,0,0,1,.452.348.738.738,0,0,1,.16.481.91.91,0,0,1-.48.753,2.254,2.254,0,0,1-1.271.321,2.105,2.105,0,0,1-1.253-.292,2.262,2.262,0,0,1-.65-.838,2.42,2.42,0,0,0-.414-.546.853.853,0,0,0-.584-.17.893.893,0,0,0-.669.283.919.919,0,0,0-.273.659,1.654,1.654,0,0,0,.217.782,2.456,2.456,0,0,0,.678.763,3.64,3.64,0,0,0,1.158.574,5.931,5.931,0,0,0,1.639.235,5.767,5.767,0,0,0,2.072-.339,2.982,2.982,0,0,0,1.356-.961,2.306,2.306,0,0,0,.471-1.431,2.161,2.161,0,0,0-.443-1.375,3.009,3.009,0,0,0-1.2-.894,10.118,10.118,0,0,0-1.865-.575,11.2,11.2,0,0,1-1.309-.311,2.011,2.011,0,0,1-.8-.452.992.992,0,0,1-.3-.744,1.143,1.143,0,0,1,.565-.97,2.59,2.59,0,0,1,1.488-.386,2.538,2.538,0,0,1,1.074.188,1.634,1.634,0,0,1,.622.49,3.477,3.477,0,0,1,.414.753,1.568,1.568,0,0,0,.4.594.866.866,0,0,0,.574.2,1,1,0,0,0,.706-.254.828.828,0,0,0,.273-.631,2.234,2.234,0,0,0-.443-1.253,3.321,3.321,0,0,0-1.158-1.046,5.375,5.375,0,0,0-2.524-.527,5.764,5.764,0,0,0-2.213.386,3.161,3.161,0,0,0-1.422,1.083A2.738,2.738,0,0,0,883.106,928.329Z" transform="translate(-878.999 -922)" fill="currentColor"/>
                                            </svg>
                                            <span className="visually-hidden">Skype</span>    
                                        </a>
                                    </li>
                                    <li className="quickview__social--list">
                                        <a className="quickview__social--icon" target="_blank" href="https://www.youtube.com">
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="16.49" height="11.582" viewBox="0 0 16.49 11.582">
                                                <path  data-name="Path 321" d="M967.759,1365.592q0,1.377-.019,1.717-.076,1.114-.151,1.622a3.981,3.981,0,0,1-.245.925,1.847,1.847,0,0,1-.453.717,2.171,2.171,0,0,1-1.151.6q-3.585.265-7.641.189-2.377-.038-3.387-.085a11.337,11.337,0,0,1-1.5-.142,2.206,2.206,0,0,1-1.113-.585,2.562,2.562,0,0,1-.528-1.037,3.523,3.523,0,0,1-.141-.585c-.032-.2-.06-.5-.085-.906a38.894,38.894,0,0,1,0-4.867l.113-.925a4.382,4.382,0,0,1,.208-.906,2.069,2.069,0,0,1,.491-.755,2.409,2.409,0,0,1,1.113-.566,19.2,19.2,0,0,1,2.292-.151q1.82-.056,3.953-.056t3.952.066q1.821.067,2.311.142a2.3,2.3,0,0,1,.726.283,1.865,1.865,0,0,1,.557.49,3.425,3.425,0,0,1,.434,1.019,5.72,5.72,0,0,1,.189,1.075q0,.095.057,1C967.752,1364.1,967.759,1364.677,967.759,1365.592Zm-7.6.925q1.49-.754,2.113-1.094l-4.434-2.339v4.66Q958.609,1367.311,960.156,1366.517Z" transform="translate(-951.269 -1359.8)" fill="currentColor"/>
                                            </svg>
                                            <span className="visually-hidden">Youtube</span>  
                                        </a>
                                    </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        {/* Quickview Wrapper End */}
        </>
    )
}

export default Wrapper