import axios from './axios';
import React from 'react'
// import { GoogleLogin } from 'react-google-login';

const responseGoogle = (response) => {

    console.log(response);
    console.log("Full Response:", response);

    // Access specific properties of the response
    console.log("User's Google ID:", response.googleId);
    console.log("User's Name:", response.profileObj.name);
    console.log("User's Email:", response.profileObj.email);
    console.log("User's Profile Picture URL:", response.profileObj.imageUrl);
}

const onFailureGoogle = (error) => {
    if (error.error === "popup_closed_by_user") {
      console.log("User closed the Google Sign-In popup.");
    } else {
      console.error("Google Sign-In failed with error:", error);
    }
  }

  const HandleSignUp = (e) => {
    axios.get("auth/google")
    .then((response)=>{
        console.log(response);
    })
    .catch((error)=>{
        console.log(error);
    })
  }
  

const GoogleLoginPage = () => {
    return (
        // <GoogleLogin
        //     clientId="300373314303-h4p19miaa7u1nsr8li4qqkdva8vobf3b.apps.googleusercontent.com"
        //     buttonText="Login with Google"
        //     onSuccess={responseGoogle}
        //     onFailure={onFailureGoogle}
        //     cookiePolicy={'single_host_origin'}
            
        // />
        <p onClick={(e)=>HandleSignUp(e)}>Google Sign up</p>
    );
}

export default GoogleLoginPage;

