import axios from '../axios';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { token } from "../features/TokenSlice";
import { toast } from 'react-toastify';

const UserVerification = (props) => {
    const dispatch = useDispatch();

    const [EmailOtp, setEmailOtp] = useState('');
    const [MobileOtp, setMobileOtp] = useState('');
    const [Error, setError] = useState('');
    const [Success, setSuccess] = useState('');
    const [timer, setTimer] = useState(0);


    const HandleResendOtp = (e) => {
        e.preventDefault();
        const UserId = localStorage.getItem("ID");
        axios.post('/resend_otp', { "UserId": UserId })
            .then((response) => {
                console.log(response)
                if (response.data.status === 200) {
                    // toast.success(response.data.message)
                    setSuccess('OTP has been resent successfully.');
                    setTimer(60);
                }
            })
            .catch((error) => {
                console.log(error)
                setSuccess("")
                setError('Failed to resend OTP. Please try again.');
            })
    }

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(countdown); 
        }
    }, [timer]);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const UserId = localStorage.getItem("ID");
        // console.log(EmailOtp, MobileOtp, UserId);
        const payload = {
            "UserId": UserId,
            "EmailOtp": EmailOtp,
            "MobileOtp": MobileOtp
        };
        // console.log(payload)
        axios.post("validate_otp", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    window.scrollTo(0, 0);
                    localStorage.setItem(
                        "RacesToken",
                        JSON.stringify({ token: response.data.data.token })
                    );

                    localStorage.setItem("ID", response.data.data.userData.id)
                    localStorage.setItem("EMAIL", response.data.data.userData.email)
                    localStorage.setItem("MOBILE", response.data.data.userData.mobile)

                    dispatch(token({ token: response.data.data.token }));

                    props.setUserVerify(false);
                    props.setUserVerifySuccess(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                const message = error.response.data.message;
                const status = error.response.data.status;
                if (status === 400) {
                    // console.log(message);
                    setError(message);
                    setSuccess("")
                }
            });
    };

    useEffect(() => {
        const handleEscKeyPress = (event) => {
            if (event.key === 'Escape') {
                props.setUserVerify(false);
            }
        };
        document.addEventListener('keydown', handleEscKeyPress);
        return () => {
            document.removeEventListener('keydown', handleEscKeyPress);
        };
    }, [props, props.setOpen]);

    return (
        <div className="modal is-visible" id="modal1" data-animation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper">
                <header className="modal-header quickview__header">
                </header>
                <div className="quickview__inner">
                    <div className="row">
                        <div className="login__section">
                            <div className="login__section--inner">
                                <div className="p-5">
                                    <div className="account__login--header text-center">
                                        <h3 className="account__login--header__title my-4">
                                            User Verification
                                        </h3>
                                        <h4 className="h4 my-4">
                                        We have sent an OTP to your email and mobile number. Please enter either one to verify and proceed.
                                        </h4>
                                    </div>
                                    <form onSubmit={(e) => HandleSubmit(e)}>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-6 mb-20">
                                                <div className="form-floating">
                                                    <input className="form-control" placeholder="Email OTP" id='EmailOtp' type="text" value={EmailOtp} onChange={(e) => {
                                                        setEmailOtp(e.target.value)
                                                    }} />
                                                    <label className="checkout__input--label mb-1" htmlFor="EmailOtp">Email OTP <span className="checkout__input--label__star">*</span></label>
                                                    {/* <small className="text-danger mb-5">{Error}</small> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-6 mb-20">
                                                <div className="form-floating text-center">
                                                   OR
                                                </div>
                                            </div>


                                            <div className="col-lg-12 col-md-6 mb-20">
                                                <div className="form-floating">
                                                    <input className="form-control" placeholder="Mobile OTP" id='MobileOtp' type="text" value={MobileOtp} onChange={(e) => {
                                                        setMobileOtp(e.target.value)
                                                    }} />
                                                    <label className="checkout__input--label mb-1" htmlFor="MobileOtp">Mobile OTP <span className="checkout__input--label__star">*</span></label>
                                                    {/* <small className="text-danger mb-5">{Error}</small> */}
                                                </div>
                                            </div>

                                            <div className="text-danger mb-5" style={{ "textAlign": "center" }} >{Error}</div>


                                            <div className="d-flex gap-4">
                                                <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                    props.setUserVerify(false);
                                                }}>Close</button>
                                                <button className="account__login--btn primary__btn mt-2" type="submit">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="text-center mt-3">
                                        Not received? {" "}
                                        <button
                                            className="ccount__login--btn secondary__btn mt-2"
                                            onClick={HandleResendOtp}
                                            disabled={timer > 0} 
                                        >
                                            {timer > 0 ? `Resend OTP (${timer}s)` : 'Resend OTP'}
                                        </button>

                                    </div>
                                    {Success && (
                                        <div className="text-success mt-3" style={{ textAlign: "center" }}>
                                            {Success}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserVerification;
