import React, { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Login from "../logo/loginsuccess.gif";

const LogoutPopup = ({ onClose},props) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose(); // Call the onClose function to close the popup
        }, 3000); // Adjust the delay as needed (in milliseconds)

        // Clear the timer if the component is unmounted or the onClose function changes
        return () => clearTimeout(timer);
    },  [onClose]);

    // console.log("here:--",props.MsgFlag,props.setMsgFlag);
    

    return (
        <div className="modal is-visible" id="modal1" data-animation="slideInUp">
        <div className="modal-dialog quickview__main--wrapper">
          <header className="modal-header quickview__header">
            {/* <button
              className="close-modal quickview__close--btn"
              aria-label="close modal"
              data-close=""
            >
              ✕{" "}
            </button> */}
          </header>
          <div className="quickview__inner">
            <div className="row">
              <div className="login__section">
                <form action="#">
                  <div className="login__section--inner">
                    <div className="p-5">
                      <div className="account__login--header text-center">
                        <img
                           src={`${Login}`}
                          alt=""
                          style={{ width: 100 }}
                        />
                        <h3 className="account__login--header__title my-4">
                          Logout Successful!
                        </h3>
                        <h4 className="h4 my-4">
                          {/* Your account has been logout successfully. */}
                          You have been successfully logout
                        </h4>
                      </div>
                      {/* <button
                        className="account__login--btn primary__btn mt-4"
                        type="submit"
                      >
                        Okay
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
};

export default LogoutPopup;
