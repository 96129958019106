import React, { useEffect, useState } from "react";
import axios from "../axios";
import { ToastContainer, toast } from 'react-toastify';
import csvEx from "../img/other/csv-ex.png";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const CouponCsvUploadDetails = (props) => {
 
  return (
    <>
    
      <div className="modal is-visible" data-animation="slideInUp" >
        <div className="modal-dialog quickview__main--wrapper">
         
        <form method="post">
          <div className="quickview__inner">
            <div className="row">

              <div className="login__section--inner" >
                <div className="p-5">
                  <div className="account__login--header d-flex justify-content-between mb-25">
                    <h3 className="account__login--header__title mb-10">Upload CSV LIST OF DISCOUNT CODE</h3>
                    <svg className="close-popup-icon" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" onClick={(e) => { props.setCsvUpoldedPopup(false); }} ><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                  </div>
                  <div className="account__login--header">
                    <div className="row">
                      <div className="col-sm-4">
                        <img className="" src={`${csvEx}`} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="col-sm-8">
                        <h4 className="m-0 text__secondary">Instrusction to upload list of codes:</h4>
                        <ol className="upload-csv-intro-list">
                          <li className="py-2">1. File should be in CSV format.</li>
                          <li className="py-2">2. Should have only one column</li>
                          <li className="py-2">3. First row should be header DISCOUNT_CODE</li>
                          <li className="py-2">4. Length of code should be maximum 15 characters</li>
                          <li className="py-2">5. All code should be unique.</li>
                          <li className="py-2">6. Maximum 5,000 code can be added.</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>

            </div>
          </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CouponCsvUploadDetails;
