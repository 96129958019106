import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { json } from 'react-router-dom';

function MyLocation() {
  const [add, setAdd] = useState('')

  useEffect(() => {
    // console.log((UserAddress));
    if (add !== "") {
      // console.log(add);
      const  UserAddress  = add;
      // console.log(UserAddress.city, "UserAddress");
      if (UserAddress !== "") {
        // console.log(UserAddress);
        axios.post("get_data_location_wise", {
          address: UserAddress,
          city: UserAddress.city
        })
          .then((response) => {
            console.log(response);
          })
          .catch((response) => {
            console.log(response);
          })
      }
    }

  }, [add])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(pos => {
      const { latitude, longitude } = pos.coords;
      // console.log(latitude, longitude)
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url).then(res => res.json()).then(data => setAdd(data.address))
    })
  }, [])

  useEffect(() => {
    if (JSON.stringify(add) !== "{}") {
      localStorage.setItem("UserAddress", JSON.stringify({ add }));
    }
  }, [add])


  // useEffect(() => {
  //   if (UserAddress !== "") {
  //     axios.post("get_data_location_wise", {
  //       address: UserAddress
  //     })
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((response) => {
  //         console.log(response);
  //       })
  //   }
  // }, [UserAddress])
  // console.log(add)
  return (
    <>
      <p>state : {add.state}</p>
      <p>city : {add.city}</p>
      <p>country :{add.country}</p>
      <p>postcode :{add.postcode}</p>
      <p>country code :{add.country_code}</p>


    </>
  )
}

export default MyLocation;