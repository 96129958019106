import React, { useState, useEffect } from 'react';
import HeaderSecond from '../HeaderSecond'
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../axios'
import EventView from '../img/banner/ytcr-banner.png'
import EventBg from '../img/banner/banner-bg-2.jpg'
import NoEventImg from '../img/events/no-events.png'
import BannerView from '../img/banner/banner-bg-org.jpg'
import OrgProfile from '../img/logo/org-logo.jpg'
import ShareEvent from '../Event/ShareEvent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Footer';
import CustomerService from '../img/customer-service.png';
import PersonalizedFeedback from '../img/Personalized Feedback.png';
import DataDriven from '../img/data-driven.png';

const ViewOrganizer = () => {

    const navigate = useNavigate()
    const [ApiCall, setApiCall] = useState(true);
    const [ShareEventFlag, setShareEventFlag] = useState(false);
    const [EventName, setEventName] = useState('');
    const [EventIdShareEvent, setEventIdShareEvent] = useState('');

    const [UpcomingEvents, setUpcomingEvents] = useState([]);
    const [PastEvents, setPastEvents] = useState([]);
    const [OrganizerData, setOrganizerData] = useState([]);
    const [ModalFlag, setModalFlag] = useState(false);
    const { OrganiserName } = useParams();
    const { UserId } = useParams();

    // SEND MAIL FUNCTIONALITY
    const [FullName, setFullName] = useState('');
    const [FullNameError, setFullNameError] = useState('');
    const [Email, setEmail] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [ContactNo, setContactNo] = useState('');
    const [ContactNoError, setContactNoError] = useState('');
    const [Message, setMessage] = useState('');
    const [MessageError, setMessageError] = useState('');

    // console.log(OrganizerData);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (ApiCall) {
            const payload = {
                user_id: UserId ? UserId : 0,
                organiser_name: OrganiserName
            };
            axios.post("organizer_details", payload)
                .then((response) => {
                    console.log(response.data.data.Organizer[0]);
                    if (response.status === 200) {
                        setOrganizerData(response.data.data.Organizer[0]);
                        setPastEvents(response.data.data.PastEvents);
                        setUpcomingEvents(response.data.data.UpcomingEvents);
                        setApiCall(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [ApiCall, OrganiserName, UserId])

    const handleShareEvent = (eventId, eventName) => {
        setShareEventFlag(true);
        setEventName(eventName);
        setEventIdShareEvent(eventId);
    }

    const handleWishList = (eventId, is_follow) => {

        const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
        if (RacesToken) {
            const payload = {
                "event_id": eventId,
                "is_follow": is_follow
            }
            // console.log(payload)
            axios.post("/follow", payload)
                .then((response) => {
                    // console.log(response.data.message);
                    if (response.status === 200) {
                        // toast.success(response.data.message);
                        toast.success(response.data.message, {
                            className: "toast-success"
                        });
                        setApiCall(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('Error occured while adding to wishlist')
                    }
                })
        } else {
            // console.log("token is not available");
            // props.setLoginWishlist(true);
        }
    }

    const handleFollow = (OrgId, is_follow) => {
        // console.log(OrgId,is_follow)
        const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
        if (RacesToken) {
            const payload = {
                "org_id": OrgId,
                "is_follow": is_follow
            }
            // console.log(payload)
            axios.post("/organizer_follow", payload)
                .then((response) => {
                    // console.log(response.data.message);
                    if (response.status === 200) {
                        toast.success(response.data.message, {
                            className: "toast-success"
                        });
                        setApiCall(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('Error occured while adding to wishlist')
                    }
                })
        }
        // else {
        // console.log("token is not available");
        // props.setLoginWishlist(true);
        // }
    }

    const renderAboutYou = (about_you) => {
        return { __html: about_you };
    };

    const HandleViewEvent = (EventId) => {
        navigate('/event_details', { state: { event_id: EventId } })
    }

    const HandleValidation = (e) => {

        setFullNameError("");
        setEmailError("");
        setContactNoError("");
        setMessageError("");
        let flag = true;
        if (FullName.trim() === "") {
            setFullNameError("Please enter your full name.");
            flag = false;
        } else if (!/^[A-Za-z\s.]+$/i.test(FullName.trim())) {
            setFullNameError("Invalid characters in full name. Only alphabets are allowed.");
            flag = false;
        }

        if (Email === "") {
            setEmailError("Please enter Email id.");
            flag = false;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            setEmailError('Please enter a valid email address');
            flag = false;
        }

        if (ContactNo === "") {
            setContactNoError("Please Enter Contact number.");
            flag = false;
        } else if (!/^[0-9]{10}$/i.test(ContactNo)) {
            setContactNoError("Please enter a valid 10-digit Contact number.");
            flag = false;
        }

        if (Message === "") {
            setMessageError("Please enter message.");
            flag = false;
        }
        // console.log("flag : " + flag);
        return flag;
    }

    const HandleSendMail = (e) => {
        // alert("here")
        e.preventDefault();
        if (HandleValidation()) {
            const payload = {
                "fullname": FullName,
                "email": Email,
                "contact_no": ContactNo,
                "message": Message
            }
            axios.post("send_notification_org", payload)
                .then((response) => {
                    // console.log(response.data.message);
                    if (response.data.status === 200) {
                        setModalFlag(false);
                        toast.success(response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Escape") {
                setModalFlag(false);
            }
        };
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);
    return (
        <>
            <ToastContainer theme='coloured' />
            <HeaderSecond />

            {ShareEventFlag ? <ShareEvent setShareEventFlag={setShareEventFlag} EventName={EventName} EventIdShareEvent={EventIdShareEvent} /> : null}

            <main className="main__content_wrapper organizerview">
                {/* Start breadcrumb section */}
                <div className="banner__video--section">
                    <div className="">
                        {/* <div className="banner__video--thumbnail position__relative event-details-banner-bg" style={{ backgroundImage: `url(${EventBg})` }}>
                            <div className="mb-event-img-bg">
                                <img className="banner__video--thumbnail__img display-block event-details-img" src={OrganizerData.banner_image !== "" ? OrganizerData.banner_image : EventView} alt="banner-bideo-thumbnail" />
                            </div>
                        </div> */}
                        <div className="banner__video--thumbnail position__relative event-details-banner-bg"  style={{ backgroundImage: `url(${BannerView})`, filter: 'drop-shadow(0 8px 18px rgba(70, 70, 70, 0.16))' }}>
                             <img className="banner__video--thumbnail__img display-block event-details-img" src={OrganizerData.banner_image || EventView} alt="banner-bideo-thumbnail" />
                        </div>
                    </div>
                </div>

                <section className="">
                    <div className="container">
                        <div className="">
                            <img className="org-profile-image" src={OrganizerData.logo_image !== "" ? OrganizerData.logo_image : OrgProfile} alt="" />
                        </div>
                    </div>
                </section>

                <section className="shipping__section shipping_mt position__relative section--padding pt-0 pb-4">
                    <div className="container">
                        <div className="shipping__section--inner border-radius-10">
                            <div className="row justify-content-between">
                                <div className="col-6 col-sm-7 col-md-8 col-lg-8 col-xl-8 col-xxl-8 gap-4 my-0">
                                    <h4 className="section__heading--maintitle h3 mb-3">{OrganizerData.name}</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active" aria-current="page">Joined on {OrganizerData.join_on}</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-6 col-sm-7 col-md-8 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-end gap-4 my-0">
                                    <div className="continue__shipping--btn secondary__btn border-radius-5"
                                        onClick={(e) => handleFollow(OrganizerData.id, OrganizerData.is_follow)}>
                                        {OrganizerData.is_follow === 0 ?
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height={25}
                                                viewBox="0 -960 960 960"
                                                width={25}
                                            >
                                                <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                                            </svg>

                                            :

                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" /></svg>
                                        }

                                        {OrganizerData.is_follow === 1 ? " Following" : " Follow"}
                                    </div>


                                    <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => setModalFlag(true)}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={25}
                                            viewBox="0 -960 960 960"
                                            width={25}
                                        >
                                            <path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product__section color-scheme-3 pt-5 mt-5">
                    <div className="container">
                        <div className="section__heading mb-3">
                            <h3 className="section__heading--maintitle">About</h3>
                        </div>
                        <div className="row py-3">
                            <div className="col-sm-12">
                                <p dangerouslySetInnerHTML={renderAboutYou(OrganizerData.about)}></p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product__section color-scheme-3 pt-5 mt-5">
                    <div className="container">
                        <div className="section__heading mb-3">
                            <h3 className="section__heading--maintitle">Upcoming Events</h3>
                        </div>
                        <div className="row">
                            {UpcomingEvents && UpcomingEvents.length > 0 ?
                                UpcomingEvents.map((item, index) => (
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 position-relative" onClick={(e) => { HandleViewEvent(item.id) }}>
                                        <div className="product__section--inner product__swiper--column4 swiper py-5" >
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide popular-events">
                                                    <div className="product__items mt-4 mb-5">
                                                        <div className="product__items--thumbnail" >
                                                            <div className="product__items--link">
                                                                <img className="product__items--img product__primary--img"
                                                                    src={item.banner_image !== '' ? item.banner_image : EventView}
                                                                    alt="Event View"
                                                                    style={{ height: '230px', objectFit: 'fill' }}
                                                                />
                                                            </div>
                                                            {item.TicketDetails && item.TicketDetails.length > 0 && (
                                                                item.TicketDetails.some(ticket => (parseInt(ticket.show_early_bird) === 1 && parseInt(ticket.ticket_status) !== 2)) ? (
                                                                    <div className="product__badge style1 special-badge gap-1">
                                                                        Early Bird
                                                                    </div>
                                                                ) : null
                                                            )}

                                                            <ul className="product__items--action d-flex justify-content-center">
                                                                {/* Wishlist */}
                                                                <li className="product__items--action__list">
                                                                    <div className={item.is_follow ? "hand_cursor product__items--action__btn wishlisted" : "hand_cursor product__items--action__btn"} onClick={(e) => {
                                                                        e.stopPropagation(); // Stop event propagation
                                                                        handleWishList(item.id, item.is_follow);
                                                                    }}>
                                                                        <svg
                                                                            className="product__items--action__btn--svg"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="17.51"
                                                                            height="15.443"
                                                                            viewBox="0 0 24.526 21.82"
                                                                        >
                                                                            <path
                                                                                d="M12.263,21.82a1.438,1.438,0,0,1-.948-.356c-.991-.866-1.946-1.681-2.789-2.4l0,0a51.865,51.865,0,0,1-6.089-5.715A9.129,9.129,0,0,1,0,7.371,7.666,7.666,0,0,1,1.946,2.135,6.6,6.6,0,0,1,6.852,0a6.169,6.169,0,0,1,3.854,1.33,7.884,7.884,0,0,1,1.558,1.627A7.885,7.885,0,0,1,13.821,1.33,6.169,6.169,0,0,1,17.675,0,6.6,6.6,0,0,1,22.58,2.135a7.665,7.665,0,0,1,1.945,5.235,9.128,9.128,0,0,1-2.432,5.975,51.86,51.86,0,0,1-6.089,5.715c-.844.719-1.8,1.535-2.794,2.4a1.439,1.439,0,0,1-.948.356ZM6.852,1.437A5.174,5.174,0,0,0,3,3.109,6.236,6.236,0,0,0,1.437,7.371a7.681,7.681,0,0,0,2.1,5.059,51.039,51.039,0,0,0,5.915,5.539l0,0c.846.721,1.8,1.538,2.8,2.411,1-.874,1.965-1.693,2.812-2.415a51.052,51.052,0,0,0,5.914-5.538,7.682,7.682,0,0,0,2.1-5.059,6.236,6.236,0,0,0-1.565-4.262,5.174,5.174,0,0,0-3.85-1.672A4.765,4.765,0,0,0,14.7,2.467a6.971,6.971,0,0,0-1.658,1.918.907.907,0,0,1-1.558,0A6.965,6.965,0,0,0,9.826,2.467a4.765,4.765,0,0,0-2.975-1.03Zm0,0"
                                                                                transform="translate(0 0)"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                        <span className="visually-hidden">Favourites</span>
                                                                    </div>
                                                                </li>
                                                                <li className="product__items--action__list">
                                                                    <div className="product__items--action__btn hand_cursor" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleShareEvent(item.id, item.name)
                                                                    }}>
                                                                        <svg
                                                                            className="product__items--action__btn--svg"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16.47"
                                                                            height="13.088"
                                                                            viewBox="0 0 15.47 11.088"
                                                                        >
                                                                            <g transform="translate(0 -72.508)">
                                                                                <path
                                                                                    data-name="Path 114"
                                                                                    d="M15.359,80.9l-2.011-2.011a.525.525,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49l1.106,1.106H10.576L8.3,78.052l2.273-2.274h1.618l-1.106,1.106a.287.287,0,0,0,.2.49h1.683a.531.531,0,0,0,.374-.155l2.011-2.011a.38.38,0,0,0,0-.535l-.859-.859a.227.227,0,1,0-.32.321l.806.806L13.027,76.9a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542h-2.04a.227.227,0,0,0-.16.066l-2.34,2.34-.544-.544,2.519-2.519a.382.382,0,0,1,.272-.112h2.293a.317.317,0,0,0,.225-.542l-1.054-1.054h1.282a.076.076,0,0,1,.053.022l.4.4a.227.227,0,1,0,.32-.321l-.4-.4a.531.531,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49L12.194,74.1H10.229a.832.832,0,0,0-.592.245L7.118,76.867,4.6,74.349a.832.832,0,0,0-.592-.245H.378A.378.378,0,0,0,0,74.481v.92a.378.378,0,0,0,.378.378H3.66l2.273,2.274L3.66,80.326H.378A.378.378,0,0,0,0,80.7v.92A.378.378,0,0,0,.378,82H4.007a.835.835,0,0,0,.592-.245l2.519-2.519.8.8a.227.227,0,1,0,.32-.32L3.914,75.392a.227.227,0,0,0-.16-.066H.453v-.769H4.007a.382.382,0,0,1,.272.113l6.043,6.043a.227.227,0,0,0,.16.066h2.04a.317.317,0,0,0,.224-.542l-1.054-1.054h1.282a.075.075,0,0,1,.053.022l1.958,1.958-1.958,1.958a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542H10.229a.383.383,0,0,1-.272-.113l-.968-.968a.227.227,0,0,0-.32.32l.968.968a.833.833,0,0,0,.592.245h1.965l-1.105,1.105a.287.287,0,0,0,.2.49h1.683a.525.525,0,0,0,.374-.155l2.011-2.011A.379.379,0,0,0,15.359,80.9Zm-11.08.539a.389.389,0,0,1-.272.113H.453v-.769h3.3a.226.226,0,0,0,.16-.066l2.34-2.34.543.544Z"
                                                                                    transform="translate(0 0)"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <span className="visually-hidden">Compare</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="product__items--content cursor-pointer" style={{ height: "200px", overflow: "hidden" }}>
                                                            <div className="row">
                                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column align-items-center justify-content-center" style={{ borderRight: "1px solid #f52222" }}>
                                                                    <h4 className="h4">{item.start_event_month !== '' ? item.start_event_month : ''}</h4>
                                                                    <h4 className="h4">{item.start_event_date !== '' ? item.start_event_date : ''}</h4>
                                                                </div>
                                                                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10" >
                                                                    <h4 className="h4 d-flex" style={{ height: "55px", overflow: "hidden" }}>
                                                                        {item.display_name}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            {item.city_name !== '' ?
                                                                <div className='my-3 d-flex gap-3'>
                                                                    <small className="m-0 card-event-city">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            height={17}
                                                                            viewBox="0 -960 960 960"
                                                                            width={17}
                                                                        >
                                                                            <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                                        </svg>
                                                                        {item.city_name !== '' ? item.city_name : ''}
                                                                    </small>
                                                                </div>
                                                                :
                                                                null}
                                                            <div className="row d-flex align-items-center card-rgn-bt-dot">
                                                                <div className="col-sm-12 mt-4 mb-0">
                                                                    <small className='mb-2 card-rgn-txt'>Register By
                                                                        : {" "}  <span className='rgn-date'>{item.registration_end_date !== '' ? item.registration_end_date : ''}</span></small>
                                                                </div>
                                                                {/* <div className="col-sm-6 my-3">
                                                                    <p className="current__price m-0">
                                                                        {item.min_price !== 0 ? '₹' + item.min_price : ''}
                                                                        {item.max_price !== 0 ? ' - ₹' + item.max_price : ''}
                                                                    </p>
                                                                </div> */}
                                                                <div className="col-sm-6 my-3">
                                                                    <p style={{ whiteSpace: "nowrap" }}>
                                                                        {item.TicketDetails && item.TicketDetails.length > 0 && (
                                                                            item.TicketDetails.some(ticket => parseInt(ticket.ticket_status) === 2) ? (
                                                                                <span className="current__price m-0">Free</span>
                                                                            ) : (
                                                                                item.TicketDetails.map((ticket, index) => (
                                                                                    parseInt(ticket.ticket_status) === 1 && (
                                                                                        <span key={index}>
                                                                                            {index > 0 && " - "}
                                                                                            {ticket.show_early_bird === 1 ? (
                                                                                                <span className="text-left">
                                                                                                    <span className="ticket-prices">
                                                                                                        <span className="original-price"> ₹{ticket.ticket_price}</span>&nbsp;
                                                                                                        <span className="current__price m-0">₹{ticket.discount_ticket_price}</span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span className="current__price m-0">₹{ticket.ticket_price}</span>
                                                                                            )}
                                                                                        </span>
                                                                                    )
                                                                                ))
                                                                            )
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-6 my-3 text-right">
                                                                    <p className="blog__content--btn primary__btn">Register</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <>
                                    <div className="col-sm-12">
                                        <div className="product__section--inner py-5 text-center">
                                            <img src={NoEventImg} alt="" style={{ width: 250 }} />
                                            <h4 className="pt-4">No Upcoming Event found</h4>
                                            <p>Sorry, We couldn't find upcoming event.</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </section>

                <section className="product__section color-scheme-3 pt-5 mt-5">
                    <div className="container">
                        <div className="section__heading mb-3">
                            <h3 className="section__heading--maintitle">Past Events</h3>
                        </div>
                        <div className="row">
                            {PastEvents && PastEvents.length > 0 ?
                                PastEvents.map((item, index) => (
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 position-relative" onClick={(e) => { HandleViewEvent(item.id) }}>
                                        <div className="product__section--inner product__swiper--column4 swiper py-5" >
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide popular-events">
                                                    <div className="product__items mt-4 mb-5">
                                                        <div className="product__items--thumbnail" >
                                                            <div className="product__items--link">
                                                                <img
                                                                    className="product__items--img product__primary--img"
                                                                    src={item.banner_image !== '' ? item.banner_image : EventView}
                                                                    alt="Event View"
                                                                    style={{ height: '230px', objectFit: 'fill' }}
                                                                />
                                                            </div>
                                                            {item.TicketDetails && item.TicketDetails.length > 0 && (
                                                                item.TicketDetails.some(ticket => (parseInt(ticket.show_early_bird) === 1 && parseInt(ticket.ticket_status) !== 2)) ? (
                                                                    <div className="product__badge style1 special-badge gap-1">
                                                                        Early Bird
                                                                    </div>
                                                                ) : null
                                                            )}
                                                            <ul className="product__items--action d-flex justify-content-center">
                                                                {/* Wishlist */}
                                                                <li className="product__items--action__list">
                                                                    <div className={item.is_follow ? "hand_cursor product__items--action__btn wishlisted" : "hand_cursor product__items--action__btn"} onClick={(e) => {
                                                                        e.stopPropagation(); // Stop event propagation
                                                                        handleWishList(item.id, item.is_follow);
                                                                    }}>
                                                                        <svg
                                                                            className="product__items--action__btn--svg"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="17.51"
                                                                            height="15.443"
                                                                            viewBox="0 0 24.526 21.82"
                                                                        >
                                                                            <path
                                                                                d="M12.263,21.82a1.438,1.438,0,0,1-.948-.356c-.991-.866-1.946-1.681-2.789-2.4l0,0a51.865,51.865,0,0,1-6.089-5.715A9.129,9.129,0,0,1,0,7.371,7.666,7.666,0,0,1,1.946,2.135,6.6,6.6,0,0,1,6.852,0a6.169,6.169,0,0,1,3.854,1.33,7.884,7.884,0,0,1,1.558,1.627A7.885,7.885,0,0,1,13.821,1.33,6.169,6.169,0,0,1,17.675,0,6.6,6.6,0,0,1,22.58,2.135a7.665,7.665,0,0,1,1.945,5.235,9.128,9.128,0,0,1-2.432,5.975,51.86,51.86,0,0,1-6.089,5.715c-.844.719-1.8,1.535-2.794,2.4a1.439,1.439,0,0,1-.948.356ZM6.852,1.437A5.174,5.174,0,0,0,3,3.109,6.236,6.236,0,0,0,1.437,7.371a7.681,7.681,0,0,0,2.1,5.059,51.039,51.039,0,0,0,5.915,5.539l0,0c.846.721,1.8,1.538,2.8,2.411,1-.874,1.965-1.693,2.812-2.415a51.052,51.052,0,0,0,5.914-5.538,7.682,7.682,0,0,0,2.1-5.059,6.236,6.236,0,0,0-1.565-4.262,5.174,5.174,0,0,0-3.85-1.672A4.765,4.765,0,0,0,14.7,2.467a6.971,6.971,0,0,0-1.658,1.918.907.907,0,0,1-1.558,0A6.965,6.965,0,0,0,9.826,2.467a4.765,4.765,0,0,0-2.975-1.03Zm0,0"
                                                                                transform="translate(0 0)"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                        <span className="visually-hidden">Favourites</span>
                                                                    </div>
                                                                </li>
                                                                <li className="product__items--action__list">
                                                                    <div className="product__items--action__btn hand_cursor" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleShareEvent(item.id, item.name)
                                                                    }}>
                                                                        <svg
                                                                            className="product__items--action__btn--svg"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16.47"
                                                                            height="13.088"
                                                                            viewBox="0 0 15.47 11.088"
                                                                        >
                                                                            <g transform="translate(0 -72.508)">
                                                                                <path
                                                                                    data-name="Path 114"
                                                                                    d="M15.359,80.9l-2.011-2.011a.525.525,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49l1.106,1.106H10.576L8.3,78.052l2.273-2.274h1.618l-1.106,1.106a.287.287,0,0,0,.2.49h1.683a.531.531,0,0,0,.374-.155l2.011-2.011a.38.38,0,0,0,0-.535l-.859-.859a.227.227,0,1,0-.32.321l.806.806L13.027,76.9a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542h-2.04a.227.227,0,0,0-.16.066l-2.34,2.34-.544-.544,2.519-2.519a.382.382,0,0,1,.272-.112h2.293a.317.317,0,0,0,.225-.542l-1.054-1.054h1.282a.076.076,0,0,1,.053.022l.4.4a.227.227,0,1,0,.32-.321l-.4-.4a.531.531,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49L12.194,74.1H10.229a.832.832,0,0,0-.592.245L7.118,76.867,4.6,74.349a.832.832,0,0,0-.592-.245H.378A.378.378,0,0,0,0,74.481v.92a.378.378,0,0,0,.378.378H3.66l2.273,2.274L3.66,80.326H.378A.378.378,0,0,0,0,80.7v.92A.378.378,0,0,0,.378,82H4.007a.835.835,0,0,0,.592-.245l2.519-2.519.8.8a.227.227,0,1,0,.32-.32L3.914,75.392a.227.227,0,0,0-.16-.066H.453v-.769H4.007a.382.382,0,0,1,.272.113l6.043,6.043a.227.227,0,0,0,.16.066h2.04a.317.317,0,0,0,.224-.542l-1.054-1.054h1.282a.075.075,0,0,1,.053.022l1.958,1.958-1.958,1.958a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542H10.229a.383.383,0,0,1-.272-.113l-.968-.968a.227.227,0,0,0-.32.32l.968.968a.833.833,0,0,0,.592.245h1.965l-1.105,1.105a.287.287,0,0,0,.2.49h1.683a.525.525,0,0,0,.374-.155l2.011-2.011A.379.379,0,0,0,15.359,80.9Zm-11.08.539a.389.389,0,0,1-.272.113H.453v-.769h3.3a.226.226,0,0,0,.16-.066l2.34-2.34.543.544Z"
                                                                                    transform="translate(0 0)"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <span className="visually-hidden">Compare</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="product__items--content cursor-pointer" style={{ height: "200px", overflow: "hidden" }}>
                                                            <div className="row">
                                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column align-items-center justify-content-center" style={{ borderRight: "1px solid #f52222" }}>
                                                                    <h4 className="h4">{item.start_event_month !== '' ? item.start_event_month : ''}</h4>
                                                                    <h4 className="h4">{item.start_event_date !== '' ? item.start_event_date : ''}</h4>
                                                                </div>
                                                                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10" >
                                                                    <h4 className="h4 d-flex" style={{ height: "55px", overflow: "hidden" }}>
                                                                        {item.display_name}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            {item.city_name !== '' ?
                                                                <div className='my-3 d-flex gap-3'>
                                                                    <small className="m-0 card-event-city">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            height={17}
                                                                            viewBox="0 -960 960 960"
                                                                            width={17}
                                                                        >
                                                                            <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                                        </svg>
                                                                        {item.city_name !== '' ? item.city_name : ''}
                                                                    </small>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div className="row d-flex align-items-center card-rgn-bt-dot">
                                                                <div className="col-sm-12 mt-4 mb-0">
                                                                    <small className='mb-2 card-rgn-txt'>Register By
                                                                        : {" "}  <span className='rgn-date'>{item.registration_end_date !== '' ? item.registration_end_date : ''}</span></small>
                                                                </div>
                                                                {/* <div className="col-sm-6 my-3">
                                                                    <p className="current__price m-0">
                                                                        {(item.min_price !== 0) ? '₹' + item.min_price : ''}
                                                                        {item.max_price !== 0 ? ' - ₹' + item.max_price : ''}
                                                                    </p>
                                                                </div> */}
                                                                <div className="col-sm-6 my-3">
                                                                    <p style={{ whiteSpace: "nowrap" }}>
                                                                        {item.TicketDetails && item.TicketDetails.length > 0 && (
                                                                            item.TicketDetails.some(ticket => parseInt(ticket.ticket_status) === 2) ? (
                                                                                <span className="current__price m-0">Free</span>
                                                                            ) : (
                                                                                item.TicketDetails.map((ticket, index) => (
                                                                                    parseInt(ticket.ticket_status) === 1 && (
                                                                                        <span key={index}>
                                                                                            {index > 0 && " - "}
                                                                                            {ticket.show_early_bird === 1 ? (
                                                                                                <span className="text-left">
                                                                                                    <span className="ticket-prices">
                                                                                                        <span className="original-price"> ₹{ticket.ticket_price}</span>&nbsp;
                                                                                                        <span className="current__price m-0">₹{ticket.discount_ticket_price}</span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span className="current__price m-0">₹{ticket.ticket_price}</span>
                                                                                            )}
                                                                                        </span>
                                                                                    )
                                                                                ))
                                                                            )
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-6 my-3 text-right">
                                                                    <p className="blog__content--btn primary__btn">Register</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <>
                                    <div className="col-sm-12">
                                        <div className="product__section--inner py-5 text-center">
                                            <img src={NoEventImg} alt="" style={{ width: 250 }} />
                                            <h4 className="pt-4">No Past Event found</h4>
                                            <p>Sorry, We couldn't find past event.</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </section>


                <section className="shipping__section shipping_mt position__relative section--padding pt-0" style={{paddingBottom:"0rem",marginTop:"20px"}}>
                    <div className="container">
                        <div className="shipping__section--inner border-radius-10 d-flex justify-content-center" style={{ border:"1px solid #eee"}}>
                            <div className="shipping__items text-center">
                                <div className="shipping__items--icon">
                                    <img src={CustomerService} alt="" style={{ borderRadius: "50%", height: "45px", width: "45px", objectFit: "cover" }} />
                                </div>
                                <div className="shipping__items--content">
                                    <h3 className="shipping__items--content__title">Round The Clock Support</h3>
                                </div>
                            </div>
                            <div className="shipping__items text-center">
                                <div className="shipping__items--icon">
                                    <img src={PersonalizedFeedback} alt="" style={{ borderRadius: "50%", height: "45px", width: "45px", objectFit: "cover" }} />
                                </div>
                                <div className="shipping__items--content">
                                    <h3 className="shipping__items--content__title">
                                        Personalized Experience
                                    </h3>
                                </div>
                            </div>
                            <div className="shipping__items text-center">
                                <div className="shipping__items--icon">
                                    <img src={DataDriven} alt="" style={{ height: "45px", width: "45px", objectFit: "contain" }} />
                                </div>
                                <div className="shipping__items--content">
                                    <h3 className="shipping__items--content__title">Data Driven Metrics</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* is-visible */}
                <div className={ModalFlag ? "modal is-visible" : "modal"} data-animation="slideInUp">
                    <div className="modal-dialog quickview__main--wrapper">
                        <header className="modal-header quickview__header">
                            <button className="close-modal quickview__close--btn" aria-label="close modal" onClick={(e) => setModalFlag(false)}>✕</button>
                        </header>
                        <div className="quickview__inner">
                            <div className="row">
                                <div className="login__section">
                                    <form action="#" >
                                        <div className="login__section--inner">
                                            <div className="p-2">
                                                <div className="account__login--header text-center">
                                                    <h3 className="account__login--header__title mb-10 text__primary">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={30}
                                                            viewBox="0 -960 960 960"
                                                            width={30}
                                                        >
                                                            <path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" /></svg> Send Message To Organiser</h3>
                                                    <div className="row">
                                                        <div className="col-sm-12 text-left">
                                                            <div className="form-floating mt-3 mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={FullName}
                                                                    onChange={(e) => setFullName(e.target.value)}
                                                                />
                                                                <label>Your Full Name<span className="checkout__input--label__star">*</span></label>
                                                            </div>
                                                            <small className="text-danger">{FullNameError}</small>
                                                        </div>
                                                        <div className="col-sm-6 text-left">
                                                            <div className="form-floating mt-3 mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={Email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                />
                                                                <label>Your Email Address<span className="checkout__input--label__star">*</span></label>
                                                            </div>
                                                            <small className="text-danger">{EmailError}</small>
                                                        </div>
                                                        <div className="col-sm-6 text-left">
                                                            <div className="form-floating mt-3 mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={ContactNo}
                                                                    onChange={(e) => setContactNo(e.target.value)}
                                                                />
                                                                <label>Your Contact number<span className="checkout__input--label__star">*</span></label>
                                                            </div>
                                                            <small className="text-danger">{ContactNoError}</small>
                                                        </div>
                                                        <div className="col-sm-12 text-left">
                                                            <div className="form-floating mt-3 mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={Message}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                />
                                                                <label>Your Message<span className="checkout__input--label__star">*</span></label>
                                                            </div>
                                                            <small className="text-danger">{MessageError}</small>
                                                        </div>
                                                    </div>

                                                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-center gap-4 pt-4">
                                                        <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => setModalFlag(false)}>Cancel</div>
                                                        <div className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => HandleSendMail(e)}>Send</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End breadcrumb section */}
            </main>

            <Footer />
        </>
    )
}

export default ViewOrganizer