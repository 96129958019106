import axios from '../axios';
import React, { useEffect, useState, useCallback } from 'react'

const NewUser = () => {

    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [NameError, setNameError] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [AllRoles, setAllRoles] = useState([]);
    const [AllEvents, setAllEvents] = useState([]);
    const [AllOrganizers, setAllOrganizers] = useState([]);
    const [Role, setRole] = useState('');
    const [RoleError, setRoleError] = useState('');
    const [EventError, setEventError] = useState('');


    const [ApiCall, setApiCall] = useState(true)

    useEffect(() => {
        if (ApiCall) {
            axios.get("get_organizing_team")
                .then((response) => {
                    // console.log(response.data.data.roles);
                    setAllRoles(response.data.data.roles);
                    setAllEvents(response.data.data.events);
                    setAllOrganizers(response.data.data.organizers);
                    setApiCall(false)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [ApiCall])

    const handleRoles = (RoleId) => {
        // console.log(RoleId);
        const RoleArr = [...AllRoles];
        if (RoleId !== "") {
            for (let index = 0; index < RoleArr.length; index++) {
                const element = RoleArr[index];
                // console.log(element.id);
                if (element.id === RoleId) {
                    RoleArr[index]['checked'] = 1;
                } else {
                    RoleArr[index]['checked'] = 0;
                }
            }
        }
        setAllRoles(RoleArr);
        // console.log(RoleArr);
    }

    const handleEvents = (EventId) => {
        const EventArr = [...AllEvents];
        if (EventId !== "") {
            for (let index = 0; index < EventArr.length; index++) {
                const element = EventArr[index];
                // console.log(element.id);
                if (element.id === EventId) {
                    if (EventArr[index]['checked'] === 1) {
                        EventArr[index]['checked'] = 0
                    } else {
                        EventArr[index]['checked'] = 1;
                    }
                }
            }
        }
        setAllEvents(EventArr);
        // console.log(EventArr);
    }

    const HandleValidation = useCallback((e) => {
        //console.log(BorrowingGuaranteed);
        var flag = true;

        if (Name === "") {
            //NameRef.current.focus();
            setNameError("Please enter event name");
            flag = false;
        } else {
            setNameError("");
        }

        if (Email === "") {
            setEmailError("Please enter Email id.");
            flag = false;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            setEmailError('Please enter a valid email address');
            flag = false;
        }
        return flag;

    }, [Email, Name])
    const HandleSubmit = useCallback((e) => {
        e.preventDefault();
        const flag = HandleValidation();
        if (flag) {
            const Payload = {
                "name": Name,
                "email": Email,
                "roles": AllRoles,
                "events": AllEvents
            }
            axios.post("signup", Payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }, [AllEvents, AllRoles, Email, HandleValidation, Name])

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                HandleSubmit(e);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.body.style.overflow = 'auto';
        };
    }, [Email, HandleSubmit]);

    return (
        <>
            <div className="row justify-content-center py-5 org-team">
                <div className="section__header mb-4 d-flex align-items-center justify-content-between">
                    <h2 className="section__header--title h3">Organizing Team - New user</h2>
                </div>
            </div>
            <div className="row justify-content-center py-4">
                <div className="col-sm-8">
                    <form onSubmit={(e) => HandleSubmit(e)}>
                        <div className="profile-card">
                            <div className="card-body p-5">
                                <div className="row align-items-center">

                                    <div className="col-sm-6">
                                        <div className="form-floating mt-3 mb-0">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={Name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            <label>Your Name<span className="checkout__input--label__star">*</span></label>
                                        </div>
                                        <small className="text-danger">{NameError}</small>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-floating mt-3 mb-0">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={Email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                            <label>Email ID<span className="checkout__input--label__star">*</span></label>
                                        </div>
                                        <small className="text-danger">{EmailError}</small>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="checkout__email--phone mb-20">
                                            <label className="checkout__input--label mb-2" htmlFor="input1">
                                                Role(User Control){" "}
                                                <span className="checkout__input--label__star">*</span>
                                            </label>
                                            <div className="row mb-3">
                                                {
                                                    AllRoles && AllRoles.length > 0 ?
                                                        AllRoles.map((item, index) => (
                                                            <div className="col-sm-4 mb-3" key={index}>
                                                                <input
                                                                    name="roles"
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    id={`btn-check-outlined${index}`}
                                                                    autoComplete="off"
                                                                    onClick={(e) => handleRoles(item.id)}
                                                                    defaultChecked={item.checked}
                                                                />
                                                                <label
                                                                    className="btn btn-outline-primary w-100 payment__history--link primary__btn"
                                                                    htmlFor={`btn-check-outlined${index}`}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        height={20}
                                                                        viewBox="0 -960 960 960"
                                                                        width={20}
                                                                    >
                                                                        <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 440q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-400Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Z" />
                                                                    </svg>
                                                                    {item.role_name}
                                                                </label>
                                                            </div>

                                                        ))
                                                        : null
                                                }
                                                <small className="text-danger">{RoleError}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="checkout__email--phone mb-20">
                                            <label className="checkout__input--label mb-2" htmlFor="input1">
                                                Events managed
                                                <span className="checkout__input--label__star">*</span>
                                            </label>
                                            <div className="row event-managed">
                                                {/* {console.log(AllEvents)} */}
                                                {
                                                    AllEvents && AllEvents.length > 0 ?
                                                        AllEvents.map((event, index) => (
                                                            <div className="col-sm-4 my-2" key={index} onClick={(e) => handleEvents(event.id)}>
                                                                <div className={parseInt(event.checked) === 1 ? "profile-card active" : "profile-card"}>
                                                                    <div className="card-body p-5 text-center">
                                                                        <p className="h4">
                                                                            {event.name}
                                                                        </p>
                                                                        <div className="row mt-3">
                                                                            <div className="col-sm-6">
                                                                                <p>{event.start_date}</p>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <p>{event.city}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : null
                                                }
                                            </div>
                                            <small className="text-danger">
                                                {EventError}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-center gap-3">
                                        <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                                            <div className="continue__shipping--btn secondary__btn border-radius-5"
                                            >Cancel</div>
                                            <div className="continue__shipping--btn primary__btn border-radius-5"
                                            >Save</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewUser